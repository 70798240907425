import {BLUE_DARK, PURPLE_DARK} from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	padding: 15px;
`

export const Title = styled.h1`
	font-size: 26px;
	font-weight: 600;
	color: ${PURPLE_DARK};
	padding: 0;
	margin: 0;
`

export const DateContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0 10px;
`

export const DateSelect = styled.select`
	padding: 5px;
	border: 1px solid #fdfdfd;
	background-color: #ffffff;
	border-radius: 5px;
	color: #333333;
	margin: 5px;
`

export const DateRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
`

export const DateDescription = styled.p`
	color: ${BLUE_DARK};
	margin: 5px;
`

export const DateTitle = styled.p`
	color: #495d63;
	font-size: medium;
	margin: 5px;
	text-transform: uppercase;
`
