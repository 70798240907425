import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts'
import { CustomTooltip } from './customTooltip'
import React from "react"
import Skeleton from "src/components/Skeleton"
import { fetchData } from "src/hooks/useFetch"

const ChargerStatusCard = () => {
	const [data, setData] = React.useState()
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const loadData = async () => {
			try {
				const response = await fetchData('/dashboard/chargeStatusReport')
				setData(response)
				setLoading(false)
			} catch {
				setLoading(false)
			}
		}

		loadData()
	}, [])

	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<div style={{ minHeight: '300px', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden' }}>
				{loading ? (
					<Skeleton height="200px" width="100%" />
				) : (
					<BarChart width={550} height={450} data={data} margin={{ bottom: 120 }}>
						<XAxis dataKey="label" angle={90} textAnchor='start' padding={{ left: 5 }} />
						<YAxis />
						<Tooltip content={<CustomTooltip />} />
						<Bar dataKey="value" fill="#5e0ea0" fillOpacity={0.5} />
					</BarChart>
				)}
			</div>
		</div>
	)
}

export default ChargerStatusCard