const { faInbox } = require("@fortawesome/free-solid-svg-icons")
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome")

const NotData = ({ width, height }) => {

	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '10px', gap: '10px', borderRadius: '8x', background: "linear-gradient(45deg, #f6f6f6, #e9e9e9)" }}>
			<FontAwesomeIcon icon={faInbox} style={{ fontSize: 40 }} />
			<p>Não há dados a serem mostrados</p>
		</div>
	)
}

export default NotData