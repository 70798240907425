import { TBody, TCell, THead, THeader, TRow, Table } from './styles'
import { createFetchRequest, getColorAutonomy } from 'src/genericFunctions'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions'
import { FlexCenter } from 'src/styles/globalStyles'
import NotData from '../NotData'
import React from 'react'
import Skeleton from '../Skeleton'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getItem } from 'src/utils/localStorage'

const ChartsDashboard = ({ title, url, showClient, name, maxHeight = "300px" }) => {
	const userInfos = getItem('session')
	const [loading, setLoading] = React.useState(true)
	const [dataChart, setDataChart] = React.useState()
	if (userInfos === null) window.location.reload()
	const [columns, setColumns] = React.useState([])

	const loadPageData = React.useCallback(() => {
		setLoading(true)
		showLoadingBlur()

		createFetchRequest(url, 'get', null, async ({ err, result }) => {
			if (err) {
				alert(err)
			}
			else if (!err && result) {
				setDataChart(result.rows)
				setColumns(result.columns)
			}

			setLoading(false)
			hideLoadingBlur()
		})
	}, [url])

	React.useEffect(() => {
		loadPageData()
	}, [loadPageData])

	return (
		<>
			{!loading ? (
				<div style={{ margin: '10px' }}>
					{(dataChart && dataChart.length > 0) ? (
						<>
							<h2 style={{ textAlign: 'center', fontSize: '18px' }}>{title}</h2>
							<div style={{ maxHeight: maxHeight, overflowX: 'hidden', overflowY: "auto", position: 'relative' }}>
								<Table>
									<THeader>
										<TRow>
											<THead bold={true} color='#bdbdbd'>{name}</THead>
											{columns.map(x => (
												<THead key={x} bold={true} color='#bdbdbd'>{x}</THead>
											))}
											{showClient && (
												<THead bold={true} color='#bdbdbd'>Cliente</THead>
											)}
										</TRow>
									</THeader>

									<TBody>
										{dataChart.map(row => (
											<TRow key={row}>
												<TCell>{row?.label}</TCell>
												{columns.map(col => (
													<TCell key={col} color={getColorAutonomy(row?.[col])}>{row?.[col] && row?.[col] !== 0 ? row?.[col] : '---'}</TCell>
												))}
												{showClient && userInfos.profile_id === 1 && (
													<TCell>{row?.client}</TCell>
												)}
											</TRow>
										))}
									</TBody>
								</Table>
							</div>
						</>
					) : (
						<NotData />
					)}
				</div>
			) : (
				<div style={{ display: 'flex', height: '300px', width: '300px', flexWrap: 'wrap', }}>
					<FlexCenter style={{ flex: 1 }}><Skeleton /></FlexCenter>
				</div>
			)
			}
		</>
	)
}


const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(ChartsDashboard)