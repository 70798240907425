import { convertManufactoryDate, createFetchRequest, isUndefinedOrNull } from 'src/genericFunctions'
import {hideLoadingBlur, showLoadingBlur} from 'src/actions/index'
import FloatingTable from 'src/components/Table'
import { MainContentDiv } from 'src/styles/globalStyles'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'

const formatColumns = (profileId) => {
	let columns = [
		{ 
			accessor: 'id',
			Header: <p>ID</p>,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			// order: true,			
			// showFilter: true,
		},
		{
			accessor: 'name',
			Header: dictionary.Name,
			csvHeader: 'Nome',
			width: 'auto',
			priority: 1,
			// order: true,
			showFilter: true,
		},
		{
			accessor: 'serial',
			Header: dictionary.Serial,
			csvHeader: 'Serial',
			width: 'auto',
			priority: 1,
			// order: true,
			showFilter: true,
		},
		{
			accessor: 'manufacturing_date',
			Header: <p>Data <br/>de<br/>Fabricação</p>,
			csvHeader: 'Data de Fabricação',
			width: 'auto',
			priority: 1,
			// order: true,
			showFilter: true,
			filterType: 'dateYear',
			valueFormatter: (row, value) => {
				const date = new Date(value).toLocaleDateString('pt-BR', {
					day: '2-digit',
					month: 'numeric',
					year: 'numeric'
				})

				return convertManufactoryDate(date, '/')
			}
		},
		{
			accessor: 'model',
			Header: dictionary.Model,
			csvHeader: 'Modelo',
			width: 'auto',
			priority: 1,
			// order: true,
			showFilter: true,
		},
		{
			accessor: 'application',
			Header: dictionary.Application,
			csvHeader: 'Aplicação',
			width: 'auto',
			priority: 1,
			// order: true,
			showFilter: true,
		},
		{
			accessor: 'brand',
			Header: dictionary.Brand,
			csvHeader: 'Marca',
			width: 'auto',
			priority: 1,
			// order: true,
			showFilter: true,
		},
		{
			accessor: 'client',
			Header: dictionary.client,
			csvHeader: 'Cliente',
			width: 'auto',
			priority: 1,
			showColumn: profileId === 1,
			// order: true,
			showFilter: true,
		},
		{
			accessor: 'branch',
			Header: dictionary.Branch,
			csvHeader: 'Sede',
			width: 'auto',
			priority: 1,
			// order: true,
			showFilter: true,
		},
		{
			accessor: 'last_date',
			Header: <p>Data <br/> do Último <br/> Registro</p>,
			csvHeader: 'Data do Último Registro',
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				if(isUndefinedOrNull(value)) {
					return null
				}

				const date = new Date(value).toLocaleDateString('pt-BR', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
				})

				return date
			}
		}
	]

	return columns
}


function FloatingBatteries({ hideLoadingBlur, showLoadingBlur}) {
	const userInfos = getItem('session')
	const profileId = userInfos.profile_id
	const limit = 100
	const [page, setPage] = React.useState(1)
	const [columns, setColumns] = React.useState([])
	const [list, setList] = React.useState([])
	const [pagination, setPagination] = React.useState({
		totalPages: 0,
		total: 0,
		page: 1,
	})
	const [dateFilter, setDateFilter] = React.useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})

	const [filters, setFilters] = React.useState({
		name: '',
		serial: '',
		model: '',
		application: '',
		brand: '',
		client: '',
		branch: '',
	})

	const loadData = React.useCallback(() => {
		let opts = filters
		opts.startdate = dateFilter.initialDate
		opts.finaldate = dateFilter.endDate
		showLoadingBlur()

		createFetchRequest(`report/floatingBatteries/${page}/${limit}`, 'post', filters, ({ err, result }) => {
			if (err) setList([])

			else if (!err && result) {				
				console.log(result)
				setList(result.rows)
				setPagination({ totalPages: result.totalPages, total: result.total, page: result.page})

				if (page > parseInt(result.totalPages)) 
					setPage(1)
		
			}
			hideLoadingBlur()
		})
	}, [dateFilter, filters, hideLoadingBlur, page, showLoadingBlur])

	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({initialDate: start, endDate: end})
	}

	const filterColumn = (filter, column) => {
		filters[column] = filter
		setFilters({...filters})
	}

	const filter = {
		filterDate,
		filterColumn,
		previous: () => { setPage(page - 1) },
		next: () => { setPage(page + 1) },
		lastPage: () => { setPage(pagination.totalPages) },
		firstPage: () => { setPage(1) },
		selectPage: (value) => { setPage(parseInt(value)) },
		dateFilter,
		searchFilters: filters,
	}

	React.useEffect(() => {
		let columns = formatColumns(profileId)
		setColumns(columns)		
	}, [profileId])

	React.useEffect(() => {
		loadData()
	}, [loadData])


	return (
		<MainContentDiv>
			<FloatingTable
				name="RELATÓRIO DE BATERIAS FLUTUANTES"
				columns={columns}
				rows={list}
				pagination={pagination}
				filters={filter}
				hideSearchPeriod={false}
				showHiddenColumn={true}	
			/>
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(FloatingBatteries)