import {AppStateProvider} from 'src/hooks/useAppState'
import {ModalProvider} from 'src/hooks/useInfoModal'
import React from 'react'
import {SessionProvider} from 'src/hooks/useSession'

const AppProvider = ({children}) => {
	return (
		<React.StrictMode>
			<SessionProvider>
				<AppStateProvider>
					<ModalProvider>{children}</ModalProvider>
				</AppStateProvider>
			</SessionProvider>
		</React.StrictMode>
	)
}

export default AppProvider
