import { cnpjMask, createFetchRequest, phoneMask } from 'src/genericFunctions'
import { faCog, faEdit, faMinusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from 'src/actions/index'
import CustomerTable from 'src/components/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import dictionary from 'src/utils/dictionary'

const formatColumns = () => {
	let columns = [
		{ 
			accessor: 'id',
			Header: dictionary.id,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,						
			showFilter: false,
		},
		{ 
			accessor: 'name',
			Header: dictionary.Name,
			csvHeader: 'Nome',
			width: 'auto',
			priority: 1,
			order: true,						
			showFilter: false,
		},
		{ 
			accessor: 'cnpj',   
			Header: dictionary.CNPJ,
			csvHeader: 'CNPJ',
			width: 'auto',
			priority: 1,
			order: true,						
			showFilter: false,
			valueFormatter: (row, value) => {
				return cnpjMask(value)
			}
		},
		{ 
			accessor: 'address',
			Header: dictionary.Address,
			csvHeader: 'Endereço',
			width: 'auto',
			priority: 1,
			order: true,						
			showFilter: false,
		},
		{ 
			accessor: 'phone',
			Header: dictionary.Phone,
			csvHeader: 'Telefone',
			width: 'auto',
			priority: 1,
			order: true,						
			showFilter: false,
			valueFormatter: (row, value) => {
				return phoneMask(value)
			}
		},
		{ 
			accessor: 'disabled',
			Header: 'Habilitado',
			csvHeader: 'Habilitado',
			width: 'auto',
			priority: 1,
			order: true,						
			showFilter: false,
			valueFormatter: (row, value) => {				
				if (value === false || value === 'false') {					
					return 'SIM'
				}

				return 'NÃO'
			}
		},
	]

	return columns
}


const CustomersList = ({onConfigClient, onEditClient, clients, loadPageData }) => {
	const [list, setList] = React.useState([])
	const [loading, setLoading] = React.useState(false)
	const [columns,setColumns] = React.useState([])
	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({ 
		totalPages: 1,
		total: 0,
		page: 1,
	 })

	const loadData = React.useCallback(() => {
		setLoading(true)
		let _columns = formatColumns()
		setColumns(_columns)
		setList(clients)
		setLoading(false)
		setPagination({ totalPages: 1, page: 1, total: clients.length})
	}, [clients])

	React.useEffect(() => {
		loadData()
	}, [loadData])

	const filter = {
		previous: () => { setPage(page - 1) },
		next: () => { setPage(page + 1) },
		lastPage: () => { setPage(pagination.totalPages) },
		firstPage: () => { setPage(1) },
		selectPage: (value) => { setPage(parseInt(value)) },
	}

	const onDelete = React.useCallback((params) => {
		let isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			var context = {
				id: params.id,
			}

			createFetchRequest(
				'client/delete/' + params.id,
				'delete',
				context,
				async ({err, result}) => {
					if (err) {
						console.log(err)
					}

					//Reset inputs values
					await loadPageData()
				},
			)
		} else {
			hideLoadingBlur()
		}
	}, [loadPageData])

	const onDisable = React.useCallback((params) => {
		let isConfirmed = window.confirm('Deseja desabilitar o cliente?')

		if (isConfirmed) {
			var context = {
				id: params.id,
			}

			createFetchRequest(
				'client/disable/' + params.id,
				'put',
				context,
				async ({err, result}) => {
					if (err) {
						console.log(err)
					}

					//Reset inputs values
					await loadPageData()
				},
			)
		} else {
			hideLoadingBlur()
		}
	}, [loadPageData])

	const style = {
		'borderRadius': '50%',
		'padding': '2px',
		'width': '28px',
		'height': '28px'
	}


	const actions = [
		{ label: <FontAwesomeIcon icon={faEdit}/>, action: onEditClient, title: 'Editar', style: style, view: true },
		{ label: <FontAwesomeIcon icon={faTrash}/>, action: onDelete, title: 'Excluir', style: style, view: true },		
		{ label: <FontAwesomeIcon icon={faCog} />, action: onConfigClient, title: 'Configurar', style: style, view: true},
		{ label: <FontAwesomeIcon icon={faMinusCircle} />, action: onDisable, title: 'Desabilitar/Habilitar', style: style, view: true}
	]

	return (
		<CustomerTable 
			pagination={pagination}
			columns={columns}
			rows={list}								
			loading={loading}
			filters={filter}
			hideSearchPeriod={true}
			actions={actions}
			name="Lista de Clientes"
		/>		
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(CustomersList)