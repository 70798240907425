import {ButtonAdd, Container} from './styles'
import {hideLoadingBlur, showLoadingBlur} from 'src/actions'
import CreateCustomer from './CustomerCreate'
import CustomerConfig from 'src/components/CustomerConfig'
import CustomerList from './CustomerList'
import EditCustomer from './CustomerEdit'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from 'src/genericFunctions'

const Customer = ({hideLoadingBlur, showLoadingBlur}) => {
	const [view, setView] = React.useState('list')
	const [clients, setClients] = React.useState([])
	const [editClient, setEditClient] = React.useState(null)
	const [configClient, setConfigClient] = React.useState(null)

	const loadPageData = React.useCallback(() => {
		showLoadingBlur()
		createFetchRequest('client/getAll', 'get', null, ({err, result}) => {
			if (err) {
				setClients([])
			} else if (!err && result) {
				setClients(result)
			}

			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	React.useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const onChangeView = (value) => {
		setView(value)
	}

	const onEditClient = React.useCallback((client) => {
		setEditClient(client)
		onChangeView('edit')
	}, [])

	const onConfigClient = React.useCallback((client) => {
		setConfigClient(client)
		onChangeView('config')
	}, [])

	return (
		<Container>
			{view === 'list' && (
				<>
					<ButtonAdd onClick={() => onChangeView('create')}>
						+ Adicionar Cliente
					</ButtonAdd>
					<CustomerList
						onConfigClient={onConfigClient}
						onEditClient={onEditClient}
						loadPageData={loadPageData}
						clients={clients}
					/>
				</>
			)}
			{view === 'create' && (
				<CreateCustomer onChangeView={onChangeView} updatePage={loadPageData} />
			)}
			{view === 'edit' && (
				<EditCustomer
					editData={editClient}
					updatePage={loadPageData}
					handleCancelEdit={() => onChangeView('list')}
					onChangeView={onChangeView}
				/>
			)}
			{view === 'config' && (
				<CustomerConfig
					onChangeView={onChangeView}
					updatePage={loadPageData}
					_cancel={() => onChangeView('list')}
					_client={configClient}
				/>
			)}
		</Container>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(Customer)
