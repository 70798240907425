import {
	Button,
	EditModalBackgroundDiv,
	EditModalDiv,
} from '../../styles/globalStyles'
import React, {useCallback, useEffect, useState} from 'react'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'

import {RowCalendarDateHour} from '../../components/RowCalendarDateHour/index.jsx'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {RowSelectBranch} from 'src/components/RowSelectBranch'
import {RowTextArea} from 'src/components/RowTextArea'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

/**
 * @author Carlos Padovezi
 * @function CreateEmployee
 * @file EmployeesCrud.js
 * @summary Function to edit Employees register view
 * @returns Employees edit crud page
 */
function CycleEdit({
	editData,
	handleCancelEdit,
	updatePage,
	hideLoadingBlur,
	showLoadingBlur,
	onDismiss
}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [isEditing, setEditinState] = useState(false)

	const [clientsList, setClientsList] = useState([])
	const [batteryList, setBatteryList] = useState([])
	const [forkliftList, setForkliftList] = useState([])
	const [forkliftModelList, setForkliftModelList] = useState([])
	// const [employeeList, setEmployeesList] = useState([])
	console.log(editData)

	const [formData, setFormData] = useState({
		id: editData.id ? editData.id : '',
		client_id: editData.client?.id ? editData.client.id : '',
		branch_id: editData.branch?.id ? editData.branch.id : '',
		battery_cycle_id: editData?.battery_cycle?.id
			? editData.battery_cycle.id
			: '',
		battery_empty_id: editData?.battery_empty?.id
			? editData.battery_empty.id
			: '',
		battery_full_id: editData?.battery_full?.id ? editData.battery_full.id : '',
		battery_recommended: editData?.battery_recommended
			? editData.battery_recommended
			: '',
		forklift_id: editData?.forklift?.id ? editData.forklift.id : '',
		forklift_model_id: editData?.forklift_model?.id
			? editData.forklift_model.id
			: '',
		application_id: editData?.application?.id ? editData.application.id : '',
		start: editData?.start ? editData.start : '',
		employee_starter: editData?.employee_starter?.name
			? editData.employee_starter.name
			: '',
		end: editData?.end ? editData.end : '',
		employee_closer: editData?.employee_closer?.name
			? editData.employee_closer.name
			: '',
		hourmeter_start: editData.hourmeter_start ? editData.hourmeter_start : '',
		hourmeter_end: editData.hourmeter_end ? editData.hourmeter_end : '',
	})

	//Get clients START
	const loadClientData = useCallback(() => {
		showLoadingBlur()

		//get client list
		createFetchRequest('client/getAll', 'get', null, async ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}

			const myList = []
			if (result && result.length > 0) {
				for (const client of result) {
					myList.push({value: client.id, label: client.name})
				}
			}

			setClientsList(myList)
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadClientData()
	}, [loadClientData])
	//Get clients END

	//Get batteries list START
	const loadBatteriesList = useCallback(() => {
		showLoadingBlur()

		createFetchRequest(
			`battery/getAll/?client=${formData.client_id}`,
			'get',
			null,
			async ({err, result}) => {
				if (err) {
					console.log(err)
					return
				}

				const myList = []
				if (result && result.length > 0) {
					for (let battery of result) {
						myList.push({value: battery.id, label: battery.name})
					}
				}

				setBatteryList(myList)
				hideLoadingBlur()
			},
		)
	}, [formData.client_id, hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadBatteriesList()
	}, [loadBatteriesList])
	//Get batteries list END

	// //Get batteries list START
	// const loadEmployeelist = useCallback(() => {
	// 	showLoadingBlur()

	// 	createFetchRequest(
	// 		`employee/getAll`,
	// 		'get',
	// 		null,
	// 		async ({err, result}) => {
	// 			if (err) {
	// 				console.log(err)
	// 				return
	// 			}

	// 			const myList = []
	// 			if (result && result.length > 0) {
	// 				for (let employee of result) {
	// 					myList.push({value: employee.id, label: employee.name})
	// 				}
	// 			}

	// 			setEmployeesList(myList)
	// 			hideLoadingBlur()
	// 		},
	// 	)
	// }, [hideLoadingBlur, showLoadingBlur])

	// useEffect(() => {
	// 	loadEmployeelist()
	// }, [loadEmployeelist])
	// //Get batteries list END

	//Get forklifts list START
	const loadForkliftsList = useCallback(() => {
		showLoadingBlur()

		createFetchRequest(
			`forklift/getAll/?client=${formData.client_id}`,
			'get',
			null,
			async ({err, result}) => {
				if (err) {
					console.log(err)
					return
				}

				const myList = []
				if (result && result.length > 0) {
					for (let forklift of result) {
						myList.push({
							value: forklift.id,
							label: forklift.name,
							model_id: forklift.forklift_model.id,
						})
					}
				}

				setForkliftList(myList)
				hideLoadingBlur()
			},
		)
	}, [formData.client_id, hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadForkliftsList()
	}, [loadForkliftsList])

	const loadForkliftsModelsList = useCallback(() => {
		showLoadingBlur()

		createFetchRequest('forkliftModel/getAll', 'get', null, ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}

			const myList = []
			if (result && result.length > 0) {
				for (let model of result) {
					myList.push({
						value: model.id,
						label: model.name,
					})
				}
			}

			setForkliftModelList(myList)
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadForkliftsModelsList()
	}, [loadForkliftsModelsList])

	const changeForkliftModel = useCallback(() => {
		if (formData.forklift_id) {
			const myForklift = forkliftList.filter(
				(forklift) => parseInt(forklift.id) === parseInt(formData.forklift_id),
			)

			if (myForklift && myForklift.forklift_model) {
				const forklift_model_id = myForklift.forklift_model.id

				console.log(forklift_model_id)

				setFormData((state) => ({...state, forklift_model_id}))
			}
		}
	}, [forkliftList, formData.forklift_id])

	useEffect(() => {
		changeForkliftModel()
	}, [changeForkliftModel])
	//Get forklifts list END

	const handleSaveCycleEdit = () => {
		if (Number.isInteger(parseInt(formData.hourmeter_end)) && 
				Number.isInteger(parseInt(formData.hourmeter_start)) &&
				parseInt(formData.hourmeter_start) > parseInt(formData.hourmeter_end)) {
			alert(dictionary.hourmeterInvalid)
			return
		}

		const context = {
			id: formData.id,
			// client_id: formData.client_id,
			// branch_id: formData.branch_id,
			// battery_cycle_id: formData.battery_cycle_id,
			battery_empty_id: formData.battery_empty_id,
			battery_full_id: formData.battery_full_id,
			forklift_id: formData.forklift_id,
			// forklift_model_id: formData.forklift_model_id,
			// application_id: formData.application_id,
			// start: formData.start,
			// employee_starter: formData.employee_starter,
			// end: formData.end,
			// employee_closer: formData.employee_closer,
			hourmeter_start: formData.hourmeter_start,
			hourmeter_end: formData.hourmeter_end,
		}

		createFetchRequest(
			'exchangeBattery/cycle',
			'put',
			context,
			async ({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					onDismiss()
					await updatePage()
				}
			},
		)
	}

	let validButton = isEditing && formData.id

	if (editData.battery_full_id !== null && formData.battery_full_id === null)
		validButton = false

	if (editData.battery_empty_id !== null && formData.battery_empty_id === null)
		validButton = false

	if (editData.hourmeter_start !== null && formData.hourmeter_start === null)
		validButton = false

	if (editData.hourmeter_end !== null && formData.hourmeter_end === null)
		validButton = false

	const buttonEditSave = isEditing ? (
		<Button
			name={'buttonConfirm'}
			onClick={handleSaveCycleEdit}
			disabled={!validButton}
			style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
			<b>{dictionary.Save}</b>
		</Button>
	) : (
		<Button
			name={'buttonEdit'}
			onClick={() => setEditinState(true)}
			style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
			<b>{dictionary.EditCycle}</b>
		</Button>
	)

	return (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput value={formData.id} label={dictionary.id} readOnly={true} />
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={clientsList}
						isObligatory={false}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id}))
						}
						// readOnly={!isEditing}
						readOnly={true}
					/>
				) : null}
				<RowSelectBranch
					clientId={formData.client_id}
					value={formData.branch_id}
					isObligatory={false}
					onChange={(branch_id) =>
						setFormData((state) => ({...state, branch_id}))
					}
					// readOnly={!isEditing}
					readOnly={true}
				/>
				<RowSelect
					value={formData.battery_cycle_id}
					label={dictionary.batteryCycle}
					optionsList={batteryList}
					isObligatory={false}
					onChange={(battery_cycle_id) =>
						setFormData((state) => ({...state, battery_cycle_id}))
					}
					// readOnly={!isEditing}
					readOnly={true}
				/>
				<RowSelect
					value={formData.battery_empty_id}
					label={dictionary.batteryWithoutCharging}
					optionsList={batteryList}
					isObligatory={editData.battery_empty_id !== null}
					onChange={(battery_empty_id) =>
						setFormData((state) => ({...state, battery_empty_id}))
					}
					readOnly={true}
				/>
				<RowSelect
					value={formData.battery_full_id}
					label={dictionary.batteryFulled}
					optionsList={batteryList}
					isObligatory={editData.battery_full_id !== null}
					onChange={(battery_full_id) =>
						setFormData((state) => ({...state, battery_full_id}))
					}
					readOnly={true}
				/>
				<RowSelect
					value={formData.forklift_id}
					label={dictionary.Forklift}
					optionsList={forkliftList}
					isObligatory={false}
					onChange={(forklift_id) =>
						setFormData((state) => ({...state, forklift_id}))
					}
					// readOnly={!isEditing}
					readOnly={true}
				/>
				<RowSelect
					value={formData.application_id}
					label={dictionary.Application}
					optionsList={forkliftModelList}
					isObligatory={false}
					onChange={(application_id) =>
						setFormData((state) => ({...state, application_id}))
					}
					// readOnly={!isEditing}
					readOnly={true}
				/>
				<RowInput
					value={formData.employee_starter}
					label={dictionary.employeeStarter}
					isObligatory={false}
					onChange={(employee_starter) =>
						setFormData((state) => ({...state, employee_starter}))
					}
					// readOnly={!isEditing}
					readOnly={true}
				/>
				<RowCalendarDateHour
					value={formData.start}
					label={dictionary.Start}
					isObligatory={false}
					onChange={(start) => setFormData((state) => ({...state, start}))}
					// readOnly={!isEditing}
					readOnly={true}
				/>
				<RowInput
					value={formData.employee_closer}
					label={dictionary.employeeCloser}
					isObligatory={false}
					onChange={(employee_closer) =>
						setFormData((state) => ({...state, employee_closer}))
					}
					// readOnly={!isEditing}
					readOnly={true}
				/>
				<RowCalendarDateHour
					value={formData.end}
					label={dictionary.End}
					isObligatory={false}
					onChange={(end) => setFormData((state) => ({...state, end}))}
					// readOnly={!isEditing}
					readOnly={true}
				/>
				<RowInput
					value={formData.hourmeter_start}
					label={dictionary.HourMeterStart}
					isObligatory={editData.hourmeter_start !== null}
					type={'number'}
					onChange={(hourmeter_start) =>
						setFormData((state) => ({...state, hourmeter_start}))
					}
					readOnly={!isEditing}
				/>
				<RowInput
					value={formData.hourmeter_end}
					label={dictionary.HourMeterEnd}
					isObligatory={editData.hourmeter_end !== null}
					type={'number'}
					onChange={(hourmeter_end) =>
						setFormData((state) => ({...state, hourmeter_end}))
					}
					readOnly={!isEditing}
				/>
				<RowTextArea
					value={editData.reason_change_battery_recomended}
					label={dictionary.batteryNotRecomendedCommentary}
					isObligatory={editData.hourmeter_end !== null}
					readOnly={true}
					resizeEnabled={false}
					height={'100px'}
				/>
				<RowTextArea
					value={editData.errors.map(x =>  '-' + x.description + '\n')}
					label={dictionary.errorCycles}
					isObligatory={editData.hourmeter_end !== null}
					readOnly={true}
					resizeEnabled={false}
					height={'100px'}
				/>
				<div>
					{buttonEditSave}
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(CycleEdit)
