import { Avatar, Card, CardActions, CardContent, CardHeader, Collapse, IconButton, makeStyles } from "@material-ui/core"
import { CardDescription, CardTitle, CardTitleContainer } from "./styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PURPLE_DARK } from "src/styles/colors"
import React from "react"
import Skeleton from "src/components/Skeleton"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"

const styles = makeStyles((theme) => ({
	card: {
		minWidth: 180,
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	actions: {
		display: 'flex',
		padding: 0,
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
		marginBottom: '2px',
		padding: '4px',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	avatar: {
		backgroundColor: '#101d33',
	},
	header: {
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0 10px',
		maxHeight: '100px'
	},
	collapse: {
		position: 'absolute',
		backgroundColor: '#ffffff',
		border: '1px solid #acacac',
		zIndex: 100,
		borderRadius: '10px'
	}
}))


const IndicatorCard = ({ title, value, loading, icon, isCustomIcon, children }) => {
	const [expanded, setExpanded] = React.useState(false)
	const classes = styles()

	const handleExpandClick = () => {
		setExpanded(!expanded)
	}

	return (
		<Card className={classes.card} >
			<CardHeader className={classes.header}
				avatar={
					<Avatar className={classes.avatar} style={{ backgroundColor: PURPLE_DARK }} >
						{isCustomIcon ? icon : <FontAwesomeIcon size={"sm"} icon={icon} />}
					</Avatar>
				}
				subheader={
					<CardContent>
						<CardTitle>{title}</CardTitle>
						<CardDescription>{loading ? <Skeleton width="80px" /> : value}</CardDescription>
					</CardContent>
				} >
			</CardHeader>
			<CardActions className={classes.actions}>
				<CardTitleContainer>
					{children && (
						<IconButton
							className={`${classes.expand} ${expanded ? classes.expandOpen : ''}`}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="Show more"
						>
							<FontAwesomeIcon style={{ fontSize: '17px' }} icon={faArrowDown} />
						</IconButton>
					)}
				</CardTitleContainer>
			</CardActions>
			<Collapse className={classes.collapse} in={expanded} timeout="auto" unmountOnExit>
				<CardContent>
					{children && (<>{children}</>)}
				</CardContent>
			</Collapse>
		</Card>
	)
}

export default IndicatorCard