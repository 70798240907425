import {PURPLE_DARK} from '../../styles/colors'
import styled from 'styled-components'

export const PageContentDiv = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`

export const LoginContentDiv = styled.div`
	width: 400px;
	height: 400px;
	background-color: #fff;
	position: absolute;
	border-radius: 5px;
`

export const InputsContentDiv = styled.div`
	width: 100%;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const ButtonsContentDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

export const LoginPhrase = styled.div`
	width: 100%;
	font-size: 16px;
	font-weight: 500px;
	text-align: center;
	color: ${PURPLE_DARK};
`

export const LoginErrorDiv = styled.div`
	width: 70%;
	height: 30px;

	margin-bottom: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
`

export const CopyrightDiv = styled.div`
	text-align: center;
`

export const PassContainer = styled.div`
	display: flex;
`

export const InputPass = styled.input.attrs(({viewPass}) => ({
	type: viewPass ? 'text' : 'password',
}))`
	width: 270px;
	height: 30px;
	opacity: 1;
	font-size: 16px;
	text-align: center;
	padding-left: 0px;
	color: #565656;
	outline: none;
	border: none;
	border-bottom: 1px solid;
	border-color: #c4c4c4;
	-webkit-transition: border-color 1s;
	transition: border-color 1s;
`

export const ViewPassButton = styled.button`
	right: 30px;
	height: 32px;
	cursor: pointer;
	border: none;
	background: #fff;
	:hover {
		background: #ebebea;
	}
`
export const LabelPass = styled.div`
	text-align: left;
	width: 80%;
	font-family: Roboto, sans-serif;
	/* margin-left: 80px; */
	padding-left: 38px;
	/* font-size: 17px; */
	color: #565656;
	font-weight: 600;
	font-size: 16px;
`
