import { createFetchRequest, formatFullDateBR } from 'src/genericFunctions'
import {
	faExclamationTriangle,
	faEye,
	faHistory,
} from '@fortawesome/free-solid-svg-icons'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions'
import ChecklistTable from 'src/components/Table'
import { Colors } from 'src/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MainContentDiv } from 'src/styles/globalStyles'
import React from 'react'
import ViewHistoryChecklist from 'src/components/ViewHistoryChecklist'
import ViewLastChecklistModal from 'src/components/ViewLastChecklistModal'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'

const formatColumns = (profileId, viewHistory, orderCallback) => {
	let columns = [
		{
			accessor: 'id',
			Header: dictionary.id,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
		},
		{
			accessor: 'client',
			Header: dictionary.client,
			csvHeader: dictionary.client,
			width: 'auto',
			priority: 1,
			showColumn: profileId === 1,
			showFilter: true,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'branch',
			Header: dictionary.branch,
			csvHeader: 'Sede',
			width: 'auto',
			priority: 1,
			showFilter: true,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'name',
			Header: dictionary.Name,
			csvHeader: dictionary.Name,
			width: 'auto',
			priority: 1,
			showFilter: true,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			valueFormatter: (row, value) => {
				if (
					row.historyChecklist &&
					Array.isArray(row.historyChecklist) &&
					row.historyChecklist.length > 0
				) {
					const lastChecklist = row.historyChecklist[0]

					if (lastChecklist.is_error) {
						return (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}>
								<div>{value}</div>
								<FontAwesomeIcon
									title="Checklist contém incoformidade"
									style={{ marginLeft: '10px', color: Colors.RED }}
									icon={faExclamationTriangle}
								/>
							</div>
						)
					}
				}

				return value
			},
		},
		{
			accessor: 'equipmentType',
			Header: (
				<p>
					Tipo de <br /> Equipamento
				</p>
			),
			csvHeader: 'Tipo de equipamento',
			width: 'auto',
			priority: 1,
			showFilter: true,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'lastChecklist',
			Header: (
				<p>
					Data último <br /> Checklist
				</p>
			),
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				if (value) {
					const dt = formatFullDateBR(value)

					return dt
				}

				return null
			},
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'employeeLastChecklist',
			Header: (
				<p>
					Colaborador <br /> realizou
				</p>
			),
			csvHeader: 'Colaborador realizou',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'totalChecklist',
			Header: (
				<p>
					Total de <br /> checklist
				</p>
			),
			csvHeader: 'Total de checklist',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
		},
		{
			accessor: 'historyChecklist',
			Header: <p>Histórico</p>,
			csvHeader: 'Histórico',
			valueFormatter: (row, value) => {
				if (typeof viewHistory === 'function') {
					return (
						<FontAwesomeIcon
							onClick={() => viewHistory(value, row)}
							icon={faHistory}
						/>
					)
				}

				return null
			},
		},
	]

	return columns
}

const ChecklistReport = ({ hideLoadingBlur, showLoadingBlur }) => {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const profileId = userInfos.profile_id
	const [columns, setColumns] = React.useState([])
	const [rows, setRows] = React.useState([])
	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({
		totalPages: 0,
		total: 0,
		page: 1,
	})
	const [showHistory, setShowHistory] = React.useState({
		show: false,
		history: [],
		equipment: '',
	})
	const [showLastChecklist, setShowLastChecklist] = React.useState({
		show: false,
		equipmentType: '',
		equipmentId: -1,
	})
	const [filters, setFilters] = React.useState({
		client: '',
		branch: '',
		equipmentType: '',
		name: '',
	})
	const [orderFilter, setOrderFilter] = React.useState({
		orderField: '',
		order: '',
	})

	const limit = 100

	const getData = React.useCallback(() => {
		showLoadingBlur()
		createFetchRequest(
			`report/checklist/${limit}/${page}/?client=${filters.client}&branch=${filters.branch}&equipmentType=${filters.equipmentType}&name=${filters.name}&order=${orderFilter.order}&orderField=${orderFilter.orderField}`,
			'get',
			null,
			({ err, result }) => {
				if (err) {
					hideLoadingBlur()
					return
				}

				if (result) {
					setPagination({
						totalPages: result.totalPages,
						total: result.total,
						page: page,
					})
					setRows(result.rows)
					hideLoadingBlur()
				}
			},
		)
	}, [
		showLoadingBlur,
		page,
		filters.client,
		filters.branch,
		filters.equipmentType,
		filters.name,
		orderFilter.order,
		orderFilter.orderField,
		hideLoadingBlur,
	])

	//Filtros
	const filterColumn = (filter, column) => {
		filters[column] = filter
		setFilters({ ...filters })
	}

	const filterValueColumn = React.useCallback((orderField, order) => {
		setOrderFilter({ orderField, order })
	}, [])

	const filter = {
		previous: () => {
			setPage(page - 1)
		},
		next: () => {
			setPage(page + 1)
		},
		lastPage: () => {
			setPage(pagination.totalPages)
		},
		firstPage: () => {
			setPage(1)
		},
		selectPage: (value) => {
			setPage(parseInt(value))
		},
		filterColumn,
	}

	const viewHistory = React.useCallback((history, equipment) => {
		setShowHistory({
			history,
			show: true,
			equipment: `${equipment.equipmentType} ${equipment.name}`,
		})
	}, [])

	React.useEffect(() => {
		let columns = formatColumns(profileId, viewHistory, filterValueColumn)
		setColumns(columns)
	}, [filterValueColumn, profileId, viewHistory])

	React.useEffect(() => {
		getData()
	}, [getData])

	React.useEffect(() => {
		setColumns(formatColumns(profileId, viewHistory, filterValueColumn))
	}, [filterValueColumn, profileId, viewHistory])

	const onViewLastChecklist = React.useCallback((row) => {
		setShowLastChecklist({
			equipmentId: row.id,
			equipmentType: row.equipmentType,
			show: true,
		})
	}, [])

	const style = {
		borderRadius: '50%',
		padding: '2px',
		width: '28px',
		height: '28px',
	}

	const actions = [
		{
			label: <FontAwesomeIcon icon={faEye} />,
			action: onViewLastChecklist,
			title: 'Detalhes',
			style: style,
			view: true,
		},
	]

	return (
		<MainContentDiv>
			<ChecklistTable
				actions={actions}
				columns={columns}
				rows={rows}
				name="Relatório de checklist"
				pagination={pagination}
				filters={filter}
				hideSearchPeriod={true}
				showHiddenColumn={false}
			/>
			<ViewHistoryChecklist
				history={showHistory.history}
				show={showHistory.show}
				equipment={showHistory.equipment}
				onClose={() =>
					setShowHistory({ show: false, history: [], equipment: '' })
				}
			/>
			<ViewLastChecklistModal
				show={showLastChecklist.show}
				equipmentId={showLastChecklist.equipmentId}
				equipmentType={showLastChecklist.equipmentType}
				onClose={() =>
					setShowLastChecklist({
						equipmentId: -1,
						equipmentType: '',
						show: false,
					})
				}
			/>
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(ChecklistReport)
