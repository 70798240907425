const dictionary = {
	//Sytem
	Home: 'Home',
	Configurations: 'Configurações',
	invalidInput: 'O texto inserido não é válido.',
	Alerts: 'Alertas',

	//System errors
	//401
	error401Title: 'Problema com autenticação',
	error401Description: 'Por favor, atualize a página e teste novamente.',
	//403
	error403Title: 'Sem permissão de acesso',
	error403Description: 'O usuário não tem a permissão para realizar a ação.',
	//404
	error404Title: 'Item não encontrado',
	error404Description: 'O item procurado não foi encontrado no sistema.',
	//409
	error409Title: 'Item duplicado',
	error409Description: 'O item já existe e não pode ter mais de um no sistema.',
	//500
	error500Title: 'Problema não identicado',
	error500Description:
		'Houve um problema inesperado, favor contate o administrador.',
	endDateIsMinorThanStart:
		'A data final não pode ser menor que a data de início',
	startDateIsBiggerThanEnd:
		'A data de início não pode ser maior que a data de final',
	hourmeterInvalid: 'Horímetro inicial maior do que o final.',

	//List
	Search: 'Pesquisar',
	Results: 'Resultados',
	Result: 'Resultado',
	searchInResults: (resultsQuantity) =>
		resultsQuantity === 1
			? `${resultsQuantity} resultado`
			: `${resultsQuantity} resultados`,
	Page: 'Página',
	PageXofX: (init, length) => `Página ${init} de ${length}`,
	goToPage: 'Ir para a página',

	//General strings
	Status: 'Status',
	details: 'detalhes',
	update: 'Atualizar',
	of: 'de',
	Show: 'Mostrar',
	Name: 'Nome',
	CNPJ: 'CNPJ',
	Phone: 'Telefone',
	Address: 'Endereço',
	Email: 'Email',
	CreateDate: 'Data de criação',
	LastUpdate: 'Última atualização',
	Confirm: 'Confirmar',
	Save: 'Salvar',
	Serial: 'Serial',
	ManufacturingDate: 'Data de Fabricação',
	Model: 'Modelo',
	Enabled: 'Habilitado',
	Disabled: 'Desabilitado',
	Disable: 'Desabilitar',
	Selected: 'Selecionado',
	SelectOption: 'Selecione uma opção',
	loading: 'Carregando',
	Login: 'Login',
	LoginOnSystem: 'Faça seu login',
	loginError:
		'Login ou senha incorreto! Tente novamente ou solicite a alteração.',
	Connect: 'Conectar',
	copyright: '© 2019 Copyright',
	cobeControl: 'COBE Tecnologia LTDA.',
	Edit: 'editar',
	View: 'visualizar',
	Cancel: 'Cancelar',
	Logout: 'Sair',
	Delete: 'excluir',
	Brands: 'Marcas',
	Brand: 'Marca',
	BrandsList: 'Lista de marcas',
	BrandRegister: 'Cadastrar nova marca',
	Models: 'Modelos',
	ModelsList: 'Lista de modelos',
	ModelRegister: 'Cadastrar novo modelo',
	Capacity: 'Capacidade',
	Voltage: 'Tensão',
	Description: 'Descrição',
	printTags: 'Imprimir etiquetas',
	exportTableToCSV: 'Exportar tabela para CSV',
	Actions: 'Ações',
	AreYourSureToDelete: 'Tem certeza que deseja excluir?',
	AreYourSureToFinished:
		'Deseja realmente finalizar este registro de avaria/manutenção?',
	noRowsSelected: 'Nenhuma linha selecionada.',
	totalHours: 'Total de Horas',
	totalCycles: 'Total de Ciclos',
	Autonomy: 'Autonomia',
	Application: 'Aplicação',
	inputTypeLabel: 'Escolher arquivo',

	//Dates
	averageOfTheWeek: 'Média da semana',
	timesStringsList: [
		'< 1 hora',
		'1 a 2 horas',
		'2 a 3 horas',
		'3 a 4 horas',
		'4 a 5 horas',
		'5 a 6 horas',
		'7 a 8 horas',
		'> 8 horas',
	],
	arrayNamesMonthsAbbr: [
		'Jan',
		'Fev',
		'Mar',
		'Abr',
		'Mai',
		'Jun',
		'Jul',
		'Ago',
		'Set',
		'Out',
		'Nov',
		'Dez',
	],
	arrayNamesMonths: [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	],
	arrayNamesDaysWeek: [
		'Domingo',
		'Segunda',
		'Terça',
		'Quarta',
		'Quinta',
		'Sexta',
		'Sabado',
	],
	arrayNamesDaysWeekAbbr: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],

	//User
	User: 'Usuário',
	Users: 'Usuários',
	Operator: 'Operador',
	Operators: 'Operadores',
	OperatorsTotal: 'Total de Operadores',
	MyTasks: 'Minhas tarefas',
	Employees: 'Colaboradores',
	Employee: 'Colaborador',
	EmployeeRegister: 'Cadastrar Colaborador',
	EmployeesList: 'Lista de Colaboradores',
	Password: 'Senha',
	cpf: 'CPF',
	Profile: 'Perfil de permissões',
	userDeleted: 'O usuário foi removido com sucesso.',

	//Company
	Company: 'Empresa',
	Customers: 'Clientes',
	Customer: 'Cliente',
	client: 'Cliente',
	clientImage: 'Logo',
	Branchs: 'Sedes',
	Branch: 'Sede',
	branch: 'Sede',
	BranchsRegister: 'Cadastrar Nova Sede',
	BranchsList: 'Lista de Sedes',
	OperatorPositions: 'Cargos',
	OperatorPosition: 'Cargo',
	CustomersRegister: 'Cadastrar Novo Cliente',
	CustomersList: 'Lista de Clientes',
	clientsTotal: 'Total de Clientes',
	employesTotal: 'Total de Colaboradores',

	//Equipaments
	Equipments: 'Equipamentos',
	Equipment: 'Equipamento',
	Breakdowns: 'Avarias',
	Breakdown: 'Avaria',
	Cycles: 'Cliclos',
	Cycle: 'Cliclo',
	EditCycle: 'Editar ciclo',
	OperatingHours: 'Horas operacionais',

	//Battery
	Batteries: 'Baterias',
	Battery: 'Bateria',
	battery: 'Bateria',
	Dashboard: 'Dashboards',
	BatteriesDashboards: 'Dashboards de Baterias',
	BatteryRooms: 'Sala de Baterias',
	BatteryRegister: 'Cadastrar Nova Bateria',
	BatteriesTotal: 'Total de baterias',
	BatteriesList: 'Lista de Baterias',
	BatteryRoomsRegister: 'Cadastrar Nova Sala de Baterias',
	BatteryRoomsList: 'Lista de Sala de Baterias',
	BatteryChangesAmount: 'Quantidade de troca de baterias',
	batteryXBatteryLife: 'QUANTIDADE DE BATERIAS X AUTONOMIA',
	BatteryLifeIdealXReal: 'Autonomia da bateria IDEAL X REAL',
	BatteryStatusWhenChanged: 'Status da bateria quando trocada',
	BatteryEmpty: 'Descarregada',
	BatteryHalf: 'Meia carga',
	BatteryFull: 'Carregadas',
	BatteryFullAndRepoused: 'Descansadas',
	BatteryReport: 'Relatório de Baterias',
	RegistredCycles: 'Ciclos Registrados',
	EstimatedCycles: 'Ciclos Estimados',
	RemainingCycles: 'Ciclos Restantes',
	AmpsHourChargingTime: 'Carregamento por A/H',
	BatteryToCharge: 'Bateria para carregar',
	batteryFulled: 'Bateria Cheia',
	batteryWithoutCharging: 'Bateria Vazia',
	batteryRecomended: 'Bateria Recomendada',
	employeeStarter: 'Colaborador Iniciou',
	employeeCloser: 'Colaborador Finalizou',
	LifeCycle: 'Ciclo de vida',
	batteryCycle: 'Bateria do ciclo',
	batteryInOperation: 'Bateria do Ciclo',
	batteryNotRecomendedCommentary:
		'Comentários por não utilizar a bateria recomendada',
	errorCycles: 'Erros no ciclo',
	freeTimeToCharge: 'Tempo disponível para carga',
	InconsistentCycles: 'Ciclos Inconsistentes',

	//Charger
	ChargersTotal: 'Total de carregadores',
	Chargers: 'Carregadores',
	Charger: 'Carregador',
	charger: 'Carregador',
	Charges: 'Cargas',
	Charge: 'Carga',
	ChargersList: 'Lista de Carregadores',
	ChargerRegister: 'Cadastrar Novo Carregador',
	ChargingVoltage: 'Tensão de carregamento',
	ConsumptionKwh: 'Consumo Kw/h',
	EstimatedChargingTime: 'Tempo de recarga estimado',
	BatteryCapacityToCharge: 'Tensão da bateria',
	TotalCharge: 'Quantidade de Cargas',

	//Fork-lift
	Forklifts: 'Máquinas',
	Forklift: 'Máquina',
	forklift: 'Máquina',
	forklift_model: 'Aplicação',
	ForkliftDisponibilityIdealXReal: 'Disponibilidade de máquina IDEAL X REAL',
	ForkliftModel: 'Modelo da Máquina',
	ForkliftRegister: 'Cadastrar Nova Máquina',
	ForkliftsList: 'Listar Máquinas',
	HourMeter: 'Horímetro',
	HourMeterStart: 'Horímetro Inicial',
	HourMeterEnd: 'Horímetro Final',
	ForkliftsTotal: 'Total de máquinas',
	Idleness: 'Ociosidade',
	AmountOfUse: 'Quantidade de Uso',
	AmountOfHours: 'Horas de Uso',
	FinishedCycles: 'Ciclos Finalizados',

	//Reports
	Reports: 'Relatórios',
	Report: 'Relatório',
	Heatmap: 'Mapa de Calor',
	EquipmentUnderMaintenance: 'Equipamento em manutenção',
	CycleReport: 'Relatório de Ciclos',
	BatteriesChargeReport: 'Relatório de Cargas',
	maintenance: 'Manutenção',
	damaged: 'Avaria',
	type: 'Tipo de Falhas',
	typeEquipment: 'Tipo do Equipamento',
	WorkedHours: 'Horas Trabalhadas',
	MalfunctionReport: 'Relatório de Falhas',

	//Usual words
	ideal: 'Ideal',
	real: 'Real',
	Start: 'Inicio',
	End: 'Final',
	usageTime: 'Tempo de Uso',
	difference: 'Diferença',
	id: 'ID',
	invalidDate: 'Data inválida',

	//Dashboard
	total_enabled: 'Habilitado',
	total_disabled: 'Desabilitado',
	in_use: 'Em uso',
	unused: 'Ocioso',
	Create: 'criar',
}

export default dictionary
