import {PURPLE} from 'src/styles/colors'
import styled from 'styled-components'

export const Select = styled.select`
	border: 1px solid ${PURPLE};
	background-color: #fdfdfd;
	color: ${PURPLE};
	border-radius: 10px;
	border: 1px solid ${PURPLE};
	width: 100%;
	text-align: center;
	height: auto;
	max-height: 20px;
`
export const Option = styled.option`
	padding: 4px;
	color: ${PURPLE};
	border-radius: 8px;
`
