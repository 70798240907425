import {PURPLE} from 'src/styles/colors'
import styled from 'styled-components'

export const DateInput = styled.input.attrs({
	type: 'date',
})`
	border: 1px solid ${PURPLE};
	background-color: #fdfdfd;
	color: ${PURPLE};
	border-radius: 10px;
	border: 1px solid ${PURPLE};
`
