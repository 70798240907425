import {BLUE_DARK} from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 10px;
	align-items: center;
`

export const Select = styled.select`
	background-color: ${BLUE_DARK};
	color: #fff;
	border: none;
	border-radius: 5px;
	height: 30px;
	width: 70%;
`
export const Option = styled.option`
	background-color: ${BLUE_DARK};
	color: #fff;
	border: none;
	display: flex;
	align-items: center;
`
