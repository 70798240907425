import { Button, EditModalBackgroundDiv, FlexCenter, FlexColumn, ViewModalDiv } from 'src/styles/globalStyles'
import { Form, Input, InputContainer, InputLabel, Option, Select } from './styles'
import React from 'react'
import { Typography } from '@material-ui/core'

const styles = {
	margin: '8px'
}

const JustificationForm = ({justification, onSubmit, onCancel, show, onChange}) => {
	const [error, setError] = React.useState(false)


	const onConfirm = React.useCallback(() => {
		if (justification.description.length === 0) {
			setError(true)
			return
		}

		setError(false)
		onSubmit()
	}, [justification, onSubmit])

	return show  && justification ?  (
		<EditModalBackgroundDiv>
			<ViewModalDiv>
				<Typography id="modal-modal-title" variant="h6" component="h2">
					{justification.id !== -1 ? 'Editar Justificativa' : 'Criar Justificativa'}
				</Typography>
				<FlexColumn>
					<Form>
						<InputContainer>
							<InputLabel>Descrição *</InputLabel>
							<Input placeholder='Descrição' value={justification.description} onChange={(e) => onChange('description', e.target.value) } />
							{error && (
								<label style={{ color: 'red'}}>Descrição é obrigatório</label>
							)}
						</InputContainer>
						<InputContainer>
							<InputLabel>Remover da Operação?</InputLabel>
							<Select placeholder='Descrição' defaultValue={justification.removeOperation ?? false} onChange={(e) => onChange('removeOperation', e.target.value) }>
								<Option value={false}>Não</Option>
								<Option value={true}>Sim</Option>
							</Select>
						</InputContainer>
					</Form>
					<FlexCenter>
						<Button style={styles} onClick={onCancel}>Cancelar</Button>
						<Button style={styles} onClick={onConfirm}>{justification.id !== -1 ? 'Editar' : 'Criar'}</Button>
					</FlexCenter>	
				</FlexColumn>
			</ViewModalDiv>
		</EditModalBackgroundDiv>
	) : null
}

export default JustificationForm