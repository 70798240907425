/**
 * @author Carlos Padovezi
 * @function Logo
 * @file Logo.jsx
 * @summary Function to construct COBE logo image
 * @param {String} width - Width of logo image parent div
 * @param {String} height - height of logo image parent div
 * @param {String} backgroundColor - backgroundColor of logo image parent div
 * @param {Boolean} isLink - Boolean to indicate if logo is clicable and redirect home page
 * @param {Object} style - Object with image styles
 * @param {String} type -
 * @returns Logo image
 */
export function Logo({width, height, backgroundColor, isLink, style, type}) {
	//Get logo dimentions
	let myWidth = width ? width : '100px'
	let myHeight = height ? height : '75px'

	let mySrc =
		type === 'svg'
			? process.env.PUBLIC_URL + '/images/logo_footer.svg'
			: process.env.PUBLIC_URL + '/images/logo_footer.png'

	//Mount redirect home function
	let myDomain = window.location.origin
	const handleImgClick = () =>
		isLink ? window.location.replace(myDomain) : null

	return (
		<div
			style={{
				width: myWidth,
				height: myHeight,
				minWidth: myWidth,
				minHeight: myHeight,
				display: 'block',
				background: backgroundColor ? backgroundColor : '',
			}}>
			<img
				src={mySrc}
				alt="logo"
				onClick={handleImgClick}
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'contain',
					cursor: isLink ? 'pointer' : 'auto',
					...style,
				}}
			/>
		</div>
	)
}
