import {clear, getItem, setItem} from 'src/utils/localStorage'

import api from 'src/services/api'
import dictionary from 'src/utils/dictionary'
import {updateApiHeader} from './services/pdf-api'

/**
 * @author Carlos Padovezi
 * @function createFetchRequest
 * @file genericFunctions.js
 * @param {String} service -
 * @param {String} method -
 * @param {Object} context -
 * @param {Function} callback -
 * @param {Bollean=} shouldNotShowAlert -
 * @param {Bollean=} shoulNotLogoffUserWhitError -
 * @summary Function to create customers crud view
 * @returns Customers crud page
 */
export async function createFetchRequest(
	service,
	method,
	context,
	callback,
	shouldNotShowAlert,
	shoulNotLogoffUserWhitError,
) {
	const ret = {err: null, result: {}, status: null}
	let client = getItem('selected_client')
	if (client !== null) client = client.selectedClient

	if (!callback || typeof callback !== 'function') {
		ret.err = 'createFetchRequest - missing callback'
		ret.status = 500
		return
	}

	if (!service || typeof service !== 'string' || service.length === 0) {
		ret.err = 'createFetchRequest - Request without service way'
		ret.status = 500
		callback(ret)
		return
	}

	if (!method || typeof method !== 'string' || method.length === 0) {
		ret.err = 'createFetchRequest - Request without method'
		ret.status = 500
		callback(ret)
		return
	}

	let alreadyCallCbFunction = false

	api({
		method,
		url: service,
		data: context,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getItem('token')}`,
			SelectedClient: client,
		},
	})
		.then(function (res) {
			const body = res.data

			if (res.status === 200 || res.status === 201) {
				ret.result = body.data
				ret.err = null
				ret.status = res.status

				if (res.headers && res.headers.refreshtoken) {
					updateApiHeader({Authorization: `Bearer ${res.headers.refreshtoken}`})
					setItem('token', res.headers.refreshtoken)
				} else if (body && body.token) {
					updateApiHeader({Authorization: `Bearer ${body.token}`})
					setItem('token', body.token)
				}

				callback(ret)
				return
			} else if (res.status && res.status !== 200) {
				ret.status = res.status
				ret.result = null
				//Show error to user
				if (shouldNotShowAlert !== true)
					window.alert(`ERRO ${res.status}\n${body.message}`)

				if (res.status === 401) {
					if (shoulNotLogoffUserWhitError !== true) {
						clear('token')
						clear('session')
					}
				} else {
					let error = body.error ? body.error : 'fetch with error'
					ret.err = error
				}

				alreadyCallCbFunction = true
				callback(ret)
				return
			} else {
				let error = 'createFetchRequest - Network response was not ok.'
				ret.err = error
				ret.result = null
				ret.status = res.status

				alreadyCallCbFunction = true
				callback(ret)
				return
			}
		})
		.catch(function (requestError) {
			console.log()
			let status = 0
			if (requestError?.message?.includes('Network Error')) status = 666
			else if (requestError?.response?.status)
				status = requestError.response.status
			else status = 500

			if (requestError && requestError.response) {
				const body = requestError.response.data

				if (requestError.response.status && body.message) {
					if (shouldNotShowAlert !== true)
						//Show error to user
						window.alert(
							`ERRO: ${requestError.response.status}\n${body.message}`,
						)

					if (status === 401) {
						if (shoulNotLogoffUserWhitError !== true) {
							clear('token')
							clear('session')
						}
					} else {
						let error = body.error ? body.error : 'fetch with error'
						ret.err = error
					}

					ret.status = status
					ret.result = null
					if (alreadyCallCbFunction === false) callback(ret)
					return
				} else {
					let error = `createFetchRequest - There has been a problem with your fetch operation: ${requestError.message}`
					ret.err = error
					ret.result = null
					ret.status = status

					if (alreadyCallCbFunction === false) callback(ret)
					return
				}
			} else {
				let error = `createFetchRequest - There has been a problem with your fetch operation: ${requestError.message}`
				ret.err = error
				ret.result = null
				ret.status = status

				if (alreadyCallCbFunction === false) callback(ret)
				return
			}
		})
}

export function cnpjMask(value) {
	let myString = value ? value : ''

	if (myString === '' || typeof myString !== 'string') return ''

	myString = myString.replace(/\D/g, '') // Remove all not numbers
	myString = myString.replace(/^(\d{2})(\d)/, '$1.$2') // Put dot between 2º e 3º chars
	myString = myString.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Put dot between 5º and 6º chars
	myString = myString.replace(/\.(\d{3})(\d)/, '.$1/$2') // Put bar between 8º and 9º chars
	myString = myString.replace(/(\d{4})(\d)/, '$1-$2')

	return myString
}

export function cpfMask(value) {
	let myString = value ? value : ''

	if (myString === '' || typeof myString !== 'string') return ''

	myString = myString.replace(/\D/g, '') // Remove all not numbers
	myString = myString.replace(/(\d{3})(\d)/, '$1.$2') // Put dot after 3º charcter
	myString = myString.replace(/(\d{3})(\d)/, '$1.$2') // Put dot after 6º charcter
	myString = myString.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

	return myString
}

export function phoneMask(value) {
	let myString = value ? value : ''

	if (myString === '' || typeof myString !== 'string') return ''

	if (value.length > 11) value = value.substr(0, 11)

	let isCellPhoneNumber = value.length === 11

	myString = myString.replace(/\D/g, '')
	myString = myString.replace(/(\d{1})(\d)/, '$1$2 ')
	myString = isCellPhoneNumber
		? myString.replace(/(\d{5})(\d)/, '$1-$2')
		: myString.replace(/(\d{4})(\d)/, '$1-$2')

	return myString
}

export const validateEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	return re.test(String(email).toLowerCase())
}

export const convertManufactoryDate = (date, separator = '-') => {
	let myDate = date.split(separator)

	let month = myDate[1]
	let year = myDate[2]

	myDate = `${dictionary.arrayNamesMonthsAbbr[parseInt(month) - 1]}/${year}`

	return myDate
}

export const getColorAutonomy = (value) => {
	const colorValue = parseInt(value)
	if (colorValue === 0) {
		return 'rgba(253, 253, 253, 0.7)'
	}

	if (colorValue > 0 && colorValue <= 3) {
		return 'rgba(255, 1, 1, 0.7)'
	}

	if (colorValue === 4) {
		return 'rgba(255, 109, 53, 0.7)'
	}

	if (colorValue === 5) {
		return 'rgba(255, 215, 102, 0.7)'
	}

	if (colorValue === 6) {
		return 'rgba(189, 182, 79, 0.7)'
	}

	if (colorValue === 7) {
		return 'rgba(189, 182, 79, 0.7)'
	}

	if (colorValue >= 8) {
		return 'rgba(59, 117, 30, 0.7)'
	}
}

export const getColorHexAutonomy = (value) => {
	const colorValue = parseInt(value)
	if (colorValue === 0) {
		return '#FDFDFD'
	}

	if (colorValue > 0 && colorValue <= 3) {
		return '#FB0101'
	}

	if (colorValue === 4) {
		return '#FF6D35'
	}

	if (colorValue === 5) {
		return '#FFD766'
	}

	if (colorValue === 6) {
		return '#BDB64F'
	}

	if (colorValue === 7) {
		return '#BDB64F'
	}

	if (colorValue >= 8) {
		return '#3B751E'
	}
}

export const isUndefinedOrNull = (value) => {
	return (
		value === undefined ||
		value === 'undefined' ||
		value === null ||
		value === '' ||
		value === 'null'
	)
}

export const dateInputFormat = (date) => {
	const newDate = new Date(date)
	if (newDate) {
		const year = newDate.getFullYear()
		const month =
			newDate.getMonth() + 1 > 9
				? (newDate.getMonth() + 1).toString()
				: '0'.concat((newDate.getMonth() + 1).toString())
		const day =
			newDate.getDate() > 9
				? newDate.getDate().toString()
				: '0'.concat(newDate.getDate().toString())

		return `${year}-${month}-${day}`
	}

	return ''
}

export const formatDateFull = (date) => {
	if (!isUndefinedOrNull(date)) {
		const newDate = new Date(date)
		const year = newDate.getFullYear()
		const month =
			newDate.getMonth() + 1 > 9
				? (newDate.getMonth() + 1).toString()
				: '0'.concat((newDate.getMonth() + 1).toString())
		const day =
			newDate.getDate() > 9
				? newDate.getDate().toString()
				: '0'.concat(newDate.getDate().toString())

		return `${day}/${month}/${year} ${newDate.toLocaleTimeString()}`
	}

	return ''
}

export const formatManufacturingDate = (manufacturing_date) => {
	let manufacturingDate = manufacturing_date.split('-')
	manufacturingDate = `${manufacturingDate[2]}-${manufacturingDate[1]}-${manufacturingDate[0]}`
	return manufacturingDate
}

export const getAutonomyStyle = (autonomyStyle) => {
	const deafultCellStyle = {
		textAlign: 'center',
		fontWeight: 700,
	}

	autonomyStyle = parseFloat(autonomyStyle)

	if (autonomyStyle === null || autonomyStyle === 'null') {
		return {
			...deafultCellStyle,
			backgroundColor: getColorAutonomy(autonomyStyle),
		}
	} else if (autonomyStyle >= 1 && autonomyStyle <= 3) {
		return {
			...deafultCellStyle,
			backgroundColor: getColorAutonomy(autonomyStyle),
			color: '#333',
		}
	} else if (autonomyStyle >= 4 && autonomyStyle < 5) {
		return {
			...deafultCellStyle,
			backgroundColor: getColorAutonomy(autonomyStyle),
			color: '#333',
		}
	} else if (autonomyStyle >= 5 && autonomyStyle < 6) {
		return {
			...deafultCellStyle,
			backgroundColor: getColorAutonomy(autonomyStyle),
			color: '#333',
		}
	} else if (autonomyStyle >= 6 && autonomyStyle < 7) {
		return {
			...deafultCellStyle,
			backgroundColor: getColorAutonomy(autonomyStyle),
			color: '#333',
		}
	} else if (autonomyStyle >= 7) {
		return {
			...deafultCellStyle,
			backgroundColor: getColorAutonomy(autonomyStyle),
			color: '#333',
		}
	} else if (autonomyStyle === 0.0) {
		return {...deafultCellStyle}
	} else {
		return {
			...deafultCellStyle,
			backgroundColor: getColorAutonomy(autonomyStyle),
			color: '#333',
		}
	}
}

export const formatFullDateBR = (date) => {
	return new Date(date).toLocaleDateString('pt-BR', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	})
}
