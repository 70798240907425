import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Line } from 'react-chartjs-2'

import React from 'react'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels)

export const options = {
	responsive: true,
	scales: {
		yAxis: {
			min: 0,
			max: 15,
		},
	},
	plugins: {
		legend: {
			position: 'top',
			display: false,
		},
		title: {
			display: false,
			text: 'Chart.js Line Chart',
		},
		datasets: {
			min: 0,
			max: 40,
			padding: 10,
		},
		datalabels: {
			display: true,
			anchor: 'center',
			align: 'top',
			font: {
				weight: 'bold',
				size: 10,
			},
			color: '#000000'
		}
	},
}

export function AverageOperationLine({ values, backgroundColor = 'rgb(47, 255, 0)' }) {
	const labels = values.map((e) => e.label)

	const data = {
		labels,
		datasets: [
			{
				label: '',
				data: values.map((e) => e.value),
				borderColor: backgroundColor,
				backgroundColor: backgroundColor,
			},
		],
	}

	return <Line options={options} data={data} />
}
