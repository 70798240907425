import {
	Button,
	EditModalBackgroundDiv,
	EditModalDiv,
} from '../../styles/globalStyles'
import React, {useCallback, useEffect, useState} from 'react'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'

import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {RowSelectBranch} from 'src/components/RowSelectBranch'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

/**
 * @author Carlos Padovezi
 * @function CreateEmployee
 * @file EmployeesCrud.js
 * @summary Function to edit Employees register view
 * @returns Employees edit crud page
 */
function EditEmployee({
	editData,
	handleCancelEdit,
	updatePage,
	hideLoadingBlur,
	showLoadingBlur,
}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [profilesList, setProfilesList] = useState([])
	const [clientsList, setClientsList] = useState([])
	const [formData, setFormData] = useState({
		editingId: editData.editingId ? editData.editingId : '',
		name: editData.name ? editData.name : '',
		email: editData.email ? editData.email : '',
		password: editData.password ? editData.password : '',
		profile_id: editData.profile_id ? editData.profile_id : '',
		cpf: editData.cpf ? editData.cpf : '',
		enable:
			editData.enable === true ? true : editData.enable === false ? false : '',
		client_id: editData.client_id ? editData.client_id : userInfos.client_id,
		branch_id: editData.branch_id ? editData.branch_id : '',
		receive_email: editData.receive_email ? editData.receive_email : false,
	})

	const loadEditData = useCallback(() => {
		showLoadingBlur()

		//get client list
		createFetchRequest('client/getAll', 'get', null, async ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}

			const myList = []
			if (result && result.length > 0) {
				for (const client of result) {
					myList.push({value: client.id, label: client.name})
				}
			}

			setClientsList(myList)

			//get profile list
			createFetchRequest(
				'profile/getAll',
				'get',
				null,
				async ({err, result}) => {
					if (err) {
						console.log(err)
						return
					}
					const myProfiles = []
					if (result && result.length > 0) {
						for (const profile of result) {
							myProfiles.push({value: profile.id, label: profile.type})
						}
					}
					setProfilesList(myProfiles)

					hideLoadingBlur()
				},
			)
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadEditData()
	}, [loadEditData])

	const handleSaveEditEmployee = (event) => {
		event.preventDefault()

		var context = {
			id: formData.editingId,
			name: formData.name,
			email: formData.email,
			profile_id: formData.profile_id,
			cpf: formData.cpf,
			enable: formData.enable,
			branch_id: formData.branch_id,
			receive_email: formData.receive_email
		}

		if (formData.password !== null && formData.password !== '') context.password = formData.password

		createFetchRequest(
			'employee/update',
			'put',
			context,
			async ({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					await updatePage()
				}
			},
		)
	}

	const validButton =
		formData.name &&
		formData.cpf &&
		formData.profile_id &&
		(formData.enable === 'true' ||
			formData.enable === 'false' ||
			formData.enable === true ||
			formData.enable === false) &&
		formData.branch_id &&
		(formData.password === null || formData.password.length >= 6 || formData.password === '')

	return (
		<EditModalBackgroundDiv>
			<EditModalDiv>				
				<RowInput
					value={formData.cpf}
					label={dictionary.cpf}
					type={'cpf'}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(cpf) => setFormData((state) => ({...state, cpf}))}										
				/>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				<RowInput
					value={formData.email}
					label={dictionary.Email}
					isObligatory={false}
					fontSize={'16px'}
					type={'email'}
					onChange={(email) => setFormData((state) => ({...state, email}))}
					autoComplete="new-email"
					name="hidden"
				/>
				<RowInput
					value={formData.password}
					label={dictionary.Password}
					type={'password'}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(password) =>
						setFormData((state) => ({...state, password}))
					}
					maxLength={50}
					autoComplete="new-password"
					name="hidden"
				/>
				<RowSelect
					value={formData.profile_id}
					label={dictionary.Profile}
					optionsList={profilesList}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(profile_id) =>
						setFormData((state) => ({...state, profile_id}))
					}
				/>
				<RowSelect
					value={formData.enable}
					label={dictionary.Enabled}
					optionsList={[
						{value: false, label: dictionary.Disabled},
						{value: true, label: dictionary.Enabled},
					]}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(enable) => setFormData((state) => ({...state, enable}))}
				/>
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={clientsList}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id}))
						}
					/>
				) : null}
				<RowSelectBranch
					clientId={formData.client_id}
					value={formData.branch_id}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(branch_id) =>
						setFormData((state) => ({...state, branch_id}))
					}
				/>
				<RowSelect
					value={formData.receive_email}
					label={'Receber e-mail informativo?'}
					optionsList={[
						{value: false, label: 'Não'},
						{value: true, label: 'Sim'},
					]}
					isObligatory={false}
					fontSize={'16px'}
					onChange={(receive_email) => setFormData((state) => ({...state, receive_email}))}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						onClick={handleSaveEditEmployee}
						disabled={!validButton}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>				
			</EditModalDiv>
		</EditModalBackgroundDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(EditEmployee)
