import {
	ButtonChecklist,
	Container,
	InputContainer,
	IsActive,
	IsDisabled,
	Label,
	Row,
	Title,
} from './styles'
import { Button } from 'src/styles/globalStyles'
import InfoModal from 'src/components/InfoModal'
import React from 'react'
import { createFetchRequest } from 'src/genericFunctions'
import { useSession } from 'src/hooks/useSession'

const ChecklistClient = () => {
	const { clientId } = useSession()
	const [clientChecklist, setClientChecklist] = React.useState([])
	const [typeEquipment, setTypeEquipment] = React.useState('battery')
	const [infoModal, setInfoModal] = React.useState({
		show: false,
		message: '',
		title: '',
	})
	const [checklist, setChecklist] = React.useState([])

	const getChecklist = React.useCallback(() => {
		const url = `/checklist/client/${typeEquipment}/${clientId}`

		createFetchRequest(url, 'get', null, ({ err, result }) => {
			if (!err) {
				setChecklist(result.checklist)
				setClientChecklist(result.clientChecklist)
			}
		})
	}, [typeEquipment, clientId])

	const onSubmit = React.useCallback(() => {
		createFetchRequest(
			`checklist/client/${typeEquipment}/${clientId}`,
			'put',
			clientChecklist,
			({ err, result }) => {
				if (!err) {
					setInfoModal({
						message: 'Checklist editado com sucesso!',
						title: 'OK',
						show: true,
					})
				}
			},
		)
	}, [clientChecklist, typeEquipment, clientId])

	const onChangeTypeEquipment = (value) => {
		setTypeEquipment(value)
	}

	const onChangeChecklist = React.useCallback(
		(id, value) => {
			if (value === true) {
				const exist = clientChecklist.find((e) => e.check_id === id)

				if (!exist) {
					setClientChecklist((prev) =>
						prev.concat([{ check_id: id, client_id: clientId }]),
					)
				}
			} else {
				setClientChecklist((prev) => prev.filter((e) => e.check_id !== id))
			}
		},
		[clientId, clientChecklist],
	)

	const onCloseModal = React.useCallback(() => {
		setInfoModal({
			message: '',
			title: '',
			show: false,
		})
	}, [])

	React.useEffect(() => {
		getChecklist()
	}, [getChecklist])

	return (
		<Container>
			<Title>Checklist</Title>
			<Row>
				<ButtonChecklist
					active={typeEquipment === 'battery'}
					onClick={() => onChangeTypeEquipment('battery')}>
					Bateria
				</ButtonChecklist>
				<ButtonChecklist
					active={typeEquipment === 'forklift'}
					onClick={() => onChangeTypeEquipment('forklift')}>
					Máquina
				</ButtonChecklist>
				<ButtonChecklist
					active={typeEquipment === 'charger'}
					onClick={() => onChangeTypeEquipment('charger')}>
					Carregador
				</ButtonChecklist>
			</Row>
			<InputContainer>
				{checklist.map((row, index) => (
					<Row key={index}>
						<Label>{row.description}</Label>
						<IsActive
							active={
								clientChecklist.findIndex((e) => e.check_id === row.id) >= 0
							}
							onClick={() => onChangeChecklist(row.id, true)}>
							Sim
						</IsActive>
						<IsDisabled
							active={
								clientChecklist.findIndex((e) => e.check_id === row.id) < 0
							}
							onClick={() => onChangeChecklist(row.id, false)}>
							Não
						</IsDisabled>
					</Row>
				))}
			</InputContainer>
			<Row>
				<Button
					style={{
						margin: '4px',
						padding: '10px 15px',
						height: 'auto',
						width: 'auto',
						borderRadius: '4px',
					}}
					onClick={onSubmit}>
					Editar
				</Button>
			</Row>
			<InfoModal modal={infoModal} closeModal={onCloseModal} />
		</Container>
	)
}

export default ChecklistClient
