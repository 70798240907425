import { Colors } from 'src/styles'
import React from 'react'
import { createFetchRequest } from 'src/genericFunctions'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h1`
  font-size: 25px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  color: #8A05BE;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: calc(100% - 100px);
  width: 100%;
  max-height: calc(100% - 35px);
  height: 100%;
  padding: 35px;
`

export const Card = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #f8f8f8;
  height: 200px;
  margin: 10px;
  background-color: ${Colors.GRAY_MEDIUM};
  background: #e0e0e0;
  box-shadow:  10px 10px 30px #bebebe,
             -10px -10px 20px #ffffff;
  min-width: 324px;
`

export const Name = styled.h1`
  padding: 15px;
  font-size: 20px;
  color: ${Colors.WHITE};
  background-color: ${Colors.PURPLE_DARK};
  margin: 0;
  height: 40px;
  border-radius: 10px;
`

export const Value = styled.p`
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: ${Colors.BLUE_DARK};
`

const RecurringInconsistencyReport = () => {
	const [data, setData] = React.useState([])

	React.useEffect(() => {
		createFetchRequest('/report/recurringInconsistency', 'get', null, ({ err, result }) => {
			if (err) {
				return
			} else if (result) {
				setData(result)
			}
		})
	}, [])

	return (
		<Container>
			<Title>Inconsistências Recorrentes</Title>
			<Row>
				{data.length > 0 && data.map(row => (
					<Card>
						<Name>{row.name}</Name>
						<Value>{row.count}</Value>
					</Card>
				))}
			</Row>
		</Container>
	)
}

export default RecurringInconsistencyReport