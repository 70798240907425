import { ButtonStep, Column, ColumnStep, Container, Content, Flex, Line, Row, Step, TableContent, Title } from './styles'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions/index'
import ImportBatteriesTable from './importBattery'
import ImportChargersTable from './importCharger'
import ImportEmployeesTable from './importEmployee'
import ImportForkliftTable from './importForklift'
import ImportInfo from './importInfo'
import InfoModal from 'src/components/InfoModal'
import InputFile from 'src/components/InputFile'
import { MainContentDiv } from 'src/styles/globalStyles'
import { PURPLE } from 'src/styles/colors'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createFetchRequest } from 'src/genericFunctions'
import { dateInputFormat } from 'src/genericFunctions'
import { isUndefinedOrNull } from 'src/genericFunctions'
import readXlsxFile from 'read-excel-file'

const ImportForklifts = async (file, brands, clients) => {
	const map = {
		'Cliente': 'client',
		'Sede': 'branch',
		'N° da Máquina': 'name',
		'N° de Série': 'serial',
		'Data Fabricação': 'manufacturing_date',
		'Marca': 'brand',
		'Modelo': 'model',
	}

	const readForklifts = await readXlsxFile(file, { sheet: 'Máquinas', map })

	const forklifts = readForklifts.rows.map((r) => {
		const brand = brands.find((e) => e.name === r.brand)
		const client = clients.find((c) => c.name === r.client)

		let model = null
		let branch = null

		if (!isUndefinedOrNull(brand) && !isUndefinedOrNull(brand.models)) {
			model = brand.models.find((e) => e.name.toLowerCase() === r.model.toLowerCase())
		}

		if (!isUndefinedOrNull(client) && !isUndefinedOrNull(client.branchs)) {
			branch = client.branchs.find((b) => b.name.toLowerCase() === r.branch.toLowerCase())
		}

		const manufacturing_date = dateInputFormat(r.manufacturing_date)

		return {
			name: r.name,
			serial: r.serial,
			client_id: client ? client.id : -1,
			branch_id: branch ? branch.id : -1,
			brand_id: brand ? brand.id : -1,
			model_id: model ? model.id : -1,
			manufacturing_date: manufacturing_date,
		}
	})

	return forklifts
}

const ImportBatteries = async (file, brands_battery, forklift_models, clients) => {
	const map = {
		'Cliente': 'client',
		'Sede': 'branch',
		'N° da Bateria': 'name',
		'N° de Série': 'serial',
		'Data Fabricação': 'manufacturing_date',
		'Marca': 'brand',
		'Modelo': 'model',
		'Aplicação': 'forklift_model',
	}

	const readBatteries = await readXlsxFile(file, { sheet: 'Baterias', map })
	console.log(readBatteries)

	const batteries = readBatteries.rows.map((r) => {
		const brand = brands_battery.find((e) => e.name === r.brand)
		const client = clients.find((c) => c.name === r.client)
		const application = forklift_models.find((m) => m.name === r.forklift_model)

		let model = null
		let branch = null

		if (!isUndefinedOrNull(brand) && !isUndefinedOrNull(brand.models)) {
			model = brand.models.find((e) => e.name.toLowerCase() === r.model.toLowerCase())
		}

		if (!isUndefinedOrNull(client) && !isUndefinedOrNull(client.branchs)) {
			branch = client.branchs.find((b) => b.name.toLowerCase() === r.branch.toLowerCase())
		}

		const manufacturing_date = dateInputFormat(r.manufacturing_date)

		return {
			name: r.name,
			serial: r.serial,
			client_id: client ? client.id : -1,
			branch_id: branch ? branch.id : -1,
			brand_id: brand ? brand.id : -1,
			model_id: model ? model.id : -1,
			forklift_model_id: application ? application.id : -1,
			manufacturing_date: manufacturing_date,
		}
	})

	return batteries
}

const ImportChargers = async (file, brands, clients, battery_rooms) => {
	const map = {
		'Cliente': 'client',
		'Sede': 'branch',
		'N° do Carregador': 'name',
		'N° de Série': 'serial',
		'Data Fabricação': 'manufacturing_date',
		'Marca': 'brand',
		'Modelo': 'model',
		'Sala de Bateria': 'battery_room',
	}

	const readForklifts = await readXlsxFile(file, { sheet: 'Carregadores', map })

	const chargers = readForklifts.rows.map((r) => {
		const brand = brands.find((e) => e.name.toLowerCase() === r.brand.toLowerCase())
		const client = clients.find((c) => c.name.toLowerCase() === r.client.toLowerCase())

		let model = null
		let branch = null
		let battery_room = null

		if (!isUndefinedOrNull(brand) && !isUndefinedOrNull(brand.models)) {
			model = brand.models.find((e) => e.name.toLowerCase() === r.model.toLowerCase())
		}

		if (!isUndefinedOrNull(client) && !isUndefinedOrNull(client.branchs)) {
			branch = client.branchs.find((b) => b.name.toLowerCase() === r.branch.toLowerCase())
		}

		if (!isUndefinedOrNull(branch)) {
			battery_room = battery_rooms.find((room) => room.branch_id === branch.id && room.name.toLowerCase() === r.battery_room.toLowerCase())
		}

		const manufacturing_date = dateInputFormat(r.manufacturing_date)

		return {
			name: r.name,
			serial: r.serial,
			client_id: client ? client.id : -1,
			branch_id: branch ? branch.id : -1,
			brand_id: brand ? brand.id : -1,
			model_id: model ? model.id : -1,
			manufacturing_date,
			battery_room_id: battery_room ? battery_room.id : -1
		}
	})

	return chargers
}

const ImportEmployees = async (file, clients, profiles) => {
	const map = {
		'Cliente': 'client',
		'Sede': 'branch',
		'Nome do Colaborador': 'name',
		'CPF': 'cpf',
		'E-mail': 'email',
		'Função': 'profile',
	}

	const readEmployees = await readXlsxFile(file, { sheet: 'Operadores', map })

	const employees = readEmployees.rows.map((r) => {
		const client = clients.find((c) => c.name === r.client)
		let branch = null

		if (!isUndefinedOrNull(client) && !isUndefinedOrNull(client.branchs)) {
			branch = client.branchs.find((b) => b.name === r.branch)
		}

		const profile = profiles.find((p) => p.type === r.profile)

		return {
			name: r.name,
			cpf: r.cpf,
			email: r.email,
			client_id: client ? client.id : -1,
			branch_id: branch ? branch.id : -1,
			profile_id: profile ? profile.id : -1
		}
	})

	return employees
}

const ImportCustomer = ({ hideLoadingBlur, showLoadingBlur }) => {
	const [brands, setBrands] = React.useState({ batteries: [], chargers: [], forklifts: [], forklift_models: [] })
	const [clients, setClients] = React.useState([])
	const [profiles, setProfiles] = React.useState([])
	const [batteryRooms, setBatteryRooms] = React.useState([])
	const [file, setFile] = React.useState(null)
	const [data, setData] = React.useState({
		batteries: [],
		chargers: [],
		forklifts: [],
		employees: [],
	})

	const [step, setStep] = React.useState(0)
	const [infoModal, setInfoModal] = React.useState({
		message: '',
		title: '',
		show: false,
	})

	const onChangeValue = React.useCallback(({ state, index, field, value }) => {
		let newState = data[state]
		let row = newState[index]
		row[field] = value

		if (field === 'client_id') {
			row.branch_id = -1
		}

		if (field === 'brand_id') {
			row.model_id = -1
		}

		newState[index] = row
		setData((prev) => ({ ...prev, [state]: newState }))
	}, [data])

	const loadData = React.useCallback(() => {
		showLoadingBlur()
		createFetchRequest('import/data-import', 'get', null, ({ err, result }) => {
			if (err) {
				console.log(err)
				hideLoadingBlur()
				return
			}

			else if (!err && result) {
				let forkliftModels = []
				let models = result.forkliftBrands.map((brand) => { return brand.models })

				models.forEach((model) => {
					forkliftModels = forkliftModels.concat(model)
				})
				setBrands({ batteries: result.batteryBrands, chargers: result.chargerBrands, forklifts: result.forkliftBrands, forklift_models: forkliftModels })
				setClients(result.clients)
				setProfiles(result.profiles)
				setBatteryRooms(result.batteryRooms)
				hideLoadingBlur()
			}
		})
	}, [hideLoadingBlur, showLoadingBlur])

	const importCustomer = React.useCallback(() => {
		showLoadingBlur()
		createFetchRequest('import/customer', 'post', data, ({ err, result }) => {
			if (err) {
				setInfoModal({
					message: 'Erro ao importar dados.',
					title: 'Erro!',
					show: true,
				})

				hideLoadingBlur()
				return
			}

			setInfoModal({
				message: <Column>
					<ul>
						<li>Máquinas criadas: {result?.forkliftsInsert?.created?.length}</li>
						<li>Máquinas duplicadas: {result?.forkliftsInsert?.notCreated?.length}</li>
						<li>Baterias criadas: {result?.batteriesInsert?.created?.length}</li>
						<li>Baterias duplicadas: {result?.batteriesInsert?.notCreated?.length}</li>
						<li>Carregadores criados: {result?.chargersInsert?.created?.length}</li>
						<li>Carregadores duplicados: {result?.chargersInsert?.notCreated?.length}</li>
						<li>Colaboradores criados: {result?.employeesInsert?.created?.length}</li>
						<li>Colaboradores duplicados: {result?.employeesInsert?.notCreated?.length}</li>
					</ul>
				</Column>,
				title: 'Dados importados com sucesso!',
				show: true,
			})


			setData({ batteries: [], chargers: [], employees: [], forklifts: [] })
			setFile(null)
			setStep(0)
			hideLoadingBlur()
		})
	}, [data, hideLoadingBlur, showLoadingBlur])

	React.useEffect(() => {
		loadData()
	}, [loadData])

	const onChange = React.useCallback(async (event) => {
		const file = event?.target?.files[0]
		if (!file) {
			window.alert('Erro ao ler o arquivo.')
			return
		}

		if (!file.name.includes('xlsx')) {
			window.alert('Arquivo não suportado')
			return
		}

		setFile(file)

		const batteries = await ImportBatteries(file, brands.batteries, brands.forklift_models, clients)
		const forklifts = await ImportForklifts(file, brands.forklifts, clients)
		const chargers = await ImportChargers(file, brands.chargers, clients, batteryRooms)
		const employees = await ImportEmployees(file, clients, profiles)
		setData((prev) => ({ ...prev, forklifts: forklifts, batteries: batteries, chargers: chargers, employees: employees }))
	}, [batteryRooms, brands, clients, profiles])

	const validFields = (value) => {
		const valuesField = Object.values(value)
		const fields = Object.keys(value)

		const isValid = valuesField.every((x, index) => {
			console.log(x, fields[index])
			if (typeof x === 'string' && fields[index] !== 'email') {
				return x !== ''
			} else if (typeof x === 'number') {
				return x > -1
			} else if (isUndefinedOrNull(x) && fields[index] === 'email') {
				return true
			} else {
				return Boolean(x)
			}
		})

		return isValid
	}

	const validStep = React.useCallback(async (state) => {
		const values = data[state]

		if (Array.isArray(values) && values.length > 0) {
			const isValid = values.every(validFields)

			return isValid
		}

		return true
	}, [data])

	const onNext = React.useCallback(async () => {

		if (step > 0 && step < 5) {
			const stepsValidation = ['forklifts', 'batteries', 'chargers', 'employees']
			const isValid = await validStep(stepsValidation[step - 1])

			if (isValid) {
				setStep(step + 1)
			} else {
				setInfoModal({
					message: <label>Existem  uma ou mais linhas com erro. <br /> Para prosseguir corrija ou exclua a linha.</label>,
					title: 'Atenção',
					show: true
				})
			}
		} else {
			setStep(step + 1)
		}

	}, [step, validStep])

	const onPrevious = React.useCallback(() => {
		setStep(step - 1)
	}, [step])


	return (
		<MainContentDiv>
			<InfoModal
				modal={infoModal}
				closeModal={() => setInfoModal({ message: '', title: '', show: false })} />
			<Container>
				<ColumnStep>
					<Row>
						<ButtonStep disabled={step === 0} onClick={onPrevious} >Anterior</ButtonStep>
						<Flex>
							<Step isTrue={step >= 0}>1</Step>
							<ColumnStep>
								<span style={{ color: step >= 0 ? PURPLE : '#3d3d3d', padding: 4 }}>Selecionar Arquivo</span>
								<Line isTrue={step >= 0} />
							</ColumnStep>
						</Flex>
						<Flex>
							<Step isTrue={step >= 1}>2</Step>
							<ColumnStep>
								<span style={{ color: step >= 1 ? PURPLE : '#3d3d3d', padding: 4 }}>Máquinas</span>
								<Line isTrue={step >= 1} />
							</ColumnStep>
						</Flex>
						<Flex>
							<Step isTrue={step >= 2}>3</Step>
							<ColumnStep>
								<span style={{ color: step >= 2 ? PURPLE : '#3d3d3d', padding: 4 }}>Baterias</span>
								<Line isTrue={step >= 2} />
							</ColumnStep>
						</Flex>
						<Flex>
							<Step isTrue={step >= 3}>4</Step>
							<ColumnStep>
								<span style={{ color: step >= 3 ? PURPLE : '#3d3d3d', padding: 4 }}>Carregadores</span>
								<Line isTrue={step >= 3} />
							</ColumnStep>
						</Flex>
						<Step isTrue={step >= 4}>5</Step>
						<ColumnStep>
							<span style={{ color: step >= 4 ? PURPLE : '#3d3d3d', padding: 4 }}>Colaboradores</span>
							<Line isTrue={step >= 4} />
						</ColumnStep>
						<Step isTrue={step >= 5}>6</Step>
						<ColumnStep>
							<span style={{ color: step >= 5 ? PURPLE : '#3d3d3d', padding: 4 }}>Finalizar</span>
						</ColumnStep>
						{step <= 4 && (
							<ButtonStep disabled={step === 5 || (step === 0 && file === null)} onClick={onNext} >Próximo</ButtonStep>
						)}
						{step === 5 && (
							<ButtonStep onClick={importCustomer} >Finalizar</ButtonStep>
						)}
					</Row>
				</ColumnStep>

				<Content>
					{step === 0 && (
						<Column>
							<Title>Selecionar arquivo</Title>
							<TableContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<InputFile accept=".xls, .xlsx" onChange={onChange} file={file} />
							</TableContent>
						</Column>
					)}
					{step === 1 && (
						<ImportForkliftTable
							forklifts={data.forklifts}
							brands={brands.forklifts}
							clients={clients}
							onChangeValue={onChangeValue}
						/>
					)}
					{step === 2 && (
						<ImportBatteriesTable
							batteries={data.batteries}
							brands={brands.batteries}
							forklift_models={brands.forklift_models}
							clients={clients}
							onChangeValue={onChangeValue}
						/>
					)}
					{step === 3 && (
						<ImportChargersTable
							chargers={data.chargers}
							brands={brands.chargers}
							clients={clients}
							batteryRooms={batteryRooms}
							onChangeValue={onChangeValue}
						/>
					)}
					{step === 4 && (
						<ImportEmployeesTable
							employees={data.employees}
							clients={clients}
							profiles={profiles}
							onChangeValue={onChangeValue}
						/>
					)}
					{step === 5 && (
						<ImportInfo
							customer={data}
						/>
					)}
				</Content>
			</Container>
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(ImportCustomer)