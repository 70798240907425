import styled from 'styled-components'

export const RowCards = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
`
export const Card = styled.div`
	max-width: calc(50% - 10px);
	width: 100%;
	margin: 5px;
`
