import { Container, Title } from "./styles"


/**
 * 
 * @param {TooltipProps<ValueType, NameType>} payload 
 */
export const CustomTooltip = ({ active, payload, label }) => {
	return (
		<Container>
			<Title>{label}</Title>
			<br />
			Horas: {payload[0]?.value}
		</Container>
	)
}