import {CreateContentDiv, CreateFormDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function CreateForkliftModel
 * @file CreateForkliftModel.js
 * @summary Function to create Fokrlift model register view
 * @returns Fokrlift model create crud page
 */
export default function CreateForkliftModel({updatePage}) {
	const [brandsList, setBrandsList] = useState([])
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		voltage: '',
		brand_id: '',
	})

	useEffect(() => {
		//get branch list
		createFetchRequest('forkliftBrand/getAll', 'get', null, ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}

			const myList = []
			if (result && result.length > 0) {
				for (let brand of result) {
					myList.push({value: brand.id, label: brand.name})
				}
			}

			setBrandsList(myList)
		})
	}, [])

	function handleSaveNewFokrliftModel() {
		const context = {
			name: formData.name,
			description: formData.description,
			voltage: formData.voltage,
			brand_id: formData.brand_id,
		}

		createFetchRequest(
			'forkliftModel/create',
			'post',
			context,
			({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					updatePage()
				}
			},
		)
	}

	const validButton = formData.name && formData.voltage && formData.brand_id

	return (
		<CreateContentDiv>
			<CreateFormDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				<RowInput
					value={formData.voltage}
					label={dictionary.Voltage}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(voltage) => setFormData((state) => ({...state, voltage}))}
				/>
				<RowSelect
					value={formData.brand_id}
					label={dictionary.Brand}
					optionsList={brandsList}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(brand_id) =>
						setFormData((state) => ({...state, brand_id}))
					}
				/>
				<RowInput
					value={formData.description}
					label={dictionary.Description}
					fontSize={'16px'}
					onChange={(description) =>
						setFormData((state) => ({...state, description}))
					}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveNewFokrliftModel}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={updatePage}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b> {dictionary.Cancel}</b>
					</Button>
				</div>
			</CreateFormDiv>
		</CreateContentDiv>
	)
}
