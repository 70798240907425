import React, {useCallback, useEffect, useState} from 'react'
import {convertManufactoryDate, createFetchRequest} from 'src/genericFunctions'
import { faBatteryHalf, faTools } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from 'src/actions/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ForkLiftIcon} from 'src/components/ForkliftIcon'
// import {CrudModal} from 'src/components/CrudModal'
import HeatmapTable from 'src/components/Table'
import {MainContentDiv} from 'src/styles/globalStyles'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

function getDayStyle(dayValue) {
	const deafultCellStyle = {
		textAlign: 'center',
		fontWeight: 700,
		width: '100%',
		minWidth: '30px !important',
		maxWidth: '30px !important',
	}

	if (dayValue === null || dayValue === 'null') {
		return {...deafultCellStyle, backgroundColor: '#ccc'}
	} else if (dayValue >= 7) {
		return {...deafultCellStyle, backgroundColor: '#204E4A', color: '#fff'}
	} else if (dayValue >= 6) {
		return {...deafultCellStyle, backgroundColor: '#2E933C', color: '#fff'}
	} else if (dayValue >= 5) {
		return {...deafultCellStyle, backgroundColor: '#f4b106', color: '#fff'}
	} else if (dayValue >= 4) {
		return {...deafultCellStyle, backgroundColor: '#e85d04', color: '#fff'}
	} else {
		return {...deafultCellStyle, backgroundColor: '#d00000', color: '#fff'}
	}
}

const formatManufacturingDate = (manufacturing_date) => {
	let manufacturingDate = manufacturing_date.split('-')
	manufacturingDate = `${manufacturingDate[2]}-${manufacturingDate[1]}-${manufacturingDate[0]}`
	return manufacturingDate
}

const formatColumns = (daysColumn, profileId) => {
	let columns = [
		{ 
			accessor: 'client',
			Header: dictionary.client,
			showColumn: profileId === 1,
			csvHeader: 'Cliente',
			width: '90px',
			fixed: true,
			priority: 1,
			showFilter: true,
			order: true,
		}, 
		{
			accessor: 'branch',
			Header: dictionary.branch,
			csvHeader: 'Sede',
			width: '90px',
			fixed: true,
			priority: 1,
			showFilter: true,
			order: true,
		},
		{ 
			accessor: 'battery',
			Header: dictionary.battery,
			csvHeader: 'Bateria',
			width: '90px',
			fixed: true,
			priority: 1,
			showFilter: true,
			order: true,
		},
		{ 
			accessor: 'application',
			Header: dictionary.forklift_model,
			csvHeader: 'Cliente',
			width: '80px',
			fixed: true,
			priority: 1,
			showFilter: true,
			order: true,
		},
		{ 
			accessor: 'brand',
			Header: dictionary.Model,
			csvHeader: 'Modelo',
			width: '90px',
			priority: 1,
			showFilter: true,
			fixed: true,
			order: true,
		},
		{ 
			accessor: 'manufacturing_date',
			Header: <p style={{lineHeight: 'normal'}}>Data de<br/>Fabricação</p>,
			csvHeader: 'Data de fabricação',
			width: '140px',
			priority: 1,
			showFilter: true,
			filterType: 'dateYear',
			fixed: true,
			order: true,
			orderType: 'date',
		},
		{ 
			accessor: 'location',
			Header: dictionary.Status,
			csvHeader: 'Status',			
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				if (value === 'loading') {
					return <FontAwesomeIcon title='Carregando' icon={faBatteryHalf} />
				}

				if (value === 'maintenance') {
					return <FontAwesomeIcon title='Manutenção' icon={faTools}/>
				}

				if (value === 'operation') {
					return <ForkLiftIcon title='Em Operação' color="#000000" opacity={1} />
				}
			}
		},
	]

	for (let i = 0;  i < daysColumn.length; i++) {
		columns.push({
			accessor: daysColumn[i],
			Header: daysColumn[i],
			csvHeader: daysColumn[i],
			priority: 2,
			width: '65px',
			getStyle: (values) => getDayStyle(values)
		})
	}

	columns = columns.sort((a, b) => { return  a.priority - b.priority })

	return columns
}

async function formatRows(data) {
	console.log(data)
	let rows = []

	for (let i = 0; i < data.length; i++) {
		data[i].application = data[i].battery.forklift_model.name
		data[i].branch = data[i].battery.branch.name
		data[i].client = data[i].battery.branch.client.name
		let formatDate = formatManufacturingDate(data[i].battery.manufacturing_date)
		formatDate = convertManufactoryDate(formatDate)
		data[i].manufacturing_date = formatDate
		// data[i].brand = data[i]?.battery?.battery_model?.battery_brand?.name
		data[i].brand = data[i]?.battery?.battery_model?.name
		data[i].location = data[i].battery.location
		data[i].battery = data[i].battery.name
		rows.push(data[i])
	}

	return rows
}

function Heatmap({hideLoadingBlur, showLoadingBlur}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()
	const profileId = userInfos.profile_id
	const [data, setData] = useState([])
	const [columns, setColumns] = useState([])
	const [dateFilter, setDateFilter] = useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})
	const [loading, setLoading] = React.useState(true)
	const [filters, setFilters] = React.useState({ 
		client: '', 
		branch: '', 
		battery: '', 
		application: '', 
		brand: '', 
		orderField: '',
		order: 'ASC' || 'DESC',
		manufacturing_date: ''
	})
	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({ 
		totalPages: 0,
		total: 0,
		page: 1,
	 })

	const limit = 100 

	//Filtros
	const filterColumn = (filter, column) => {
		console.log(filter, column)
		filters[column] = filter
		setFilters({...filters})
	}

	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({initialDate: start, endDate: end})
	}

	const orderChange = (orderField, order = 'ASC' || 'DESC') => {
		setFilters({...filter, order, orderField})
	}

	const filter = {
		filterDate,
		filterColumn,
		previous: () => { setPage(page - 1) },
		next: () => { setPage(page + 1) },
		lastPage: () => { setPage(pagination.totalPages) },
		firstPage: () => { setPage(1) },
		selectPage: (value) => { setPage(parseInt(value)) },
		dateFilter,
		searchFilters: filters,
	}

	const loadData = useCallback(() => {
		setLoading(true)
		showLoadingBlur()
		createFetchRequest(
			`report/heatmap/${limit}/${page}?startdate=${dateFilter.initialDate}&finaldate=${dateFilter.endDate}&client=${filters.client}&branch=${filters.branch}&batteryname=${filters.battery}&application=${filters.application}&brand=${filters.brand}&manufacturing_date=${filters.manufacturing_date}`,
			'get',
			null,
			async ({err, result}) => {
				if (err) setData([])
				else if (!err && result) {
					const rows = await formatRows(result.response)
					console.log(rows)
					setData(rows)
					setPagination({ totalPages: result.totalPages, total: result.total, page: page })
					if (page > parseInt(result.totalPages)) 
						setPage(1)

					let columns = formatColumns(result.daysColumn, profileId)
					setColumns(columns)
				}

				setLoading(false)
				hideLoadingBlur()
			},
		)
	}, [dateFilter, filters, hideLoadingBlur, page, profileId, showLoadingBlur])


	useEffect(() => {
		loadData()
	}, [loadData])

	return (
		<MainContentDiv>
			<HeatmapTable 
				columns={columns}
				rows={data}
				name={'MAPA DE CALOR'}
				period={dateFilter}
				pagination={pagination}
				filters={filter}
				loading={loading}
				orderChange={orderChange}
				showHiddenColumn={true}
			/>
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(Heatmap)
