import {CreateContentDiv, CreateFormDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function CreateBatteryModel
 * @file CreateBatteryModel.js
 * @summary Function to create Battery model register view
 * @returns Battery model create crud page
 */
export default function CreateBatteryModel({updatePage}) {
	const [brandsList, setBrandsList] = useState([])
	const [formData, setFormData] = useState({
		name: '',
		estimated_manufactorer_cycles: '',
		capacity: '',
		voltage: '',
		brand_id: '',
	})

	useEffect(() => {
		//get branch list
		createFetchRequest('batteryBrand/getAll', 'get', null, ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}
			let myList = []
			if (result && result.length > 0) {
				for (let brand of result) {
					myList.push({value: brand.id, label: brand.name})
				}
			}

			setBrandsList(myList)
		})
	}, [])

	const handleSaveNewBatteryModel = () => {
		const context = {
			name: formData.name,
			estimated_manufactorer_cycles: formData.estimated_manufactorer_cycles,
			capacity: formData.capacity,
			voltage: formData.voltage,
			brand_id: formData.brand_id,
		}

		createFetchRequest(
			'batteryModel/create',
			'post',
			context,
			({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					updatePage()
				}
			},
		)
	}

	const validButton =
		formData.estimated_manufactorer_cycles &&
		formData.capacity &&
		formData.voltage &&
		formData.brand_id

	return (
		<CreateContentDiv>
			<CreateFormDiv>
				<RowInput
					label={dictionary.Name}
					value={formData.name}
					isObligatory={true}
					fontSize={'16px'}
					autoFocus={true}
					readOnly={true}
				/>
				<RowInput
					value={formData.estimated_manufactorer_cycles}
					label={dictionary.EstimatedCycles}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(estimated_manufactorer_cycles) =>
						setFormData((state) => ({...state, estimated_manufactorer_cycles}))
					}
				/>
				<RowInput
					value={formData.voltage}
					label={dictionary.Voltage}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(voltage) => { 
						setFormData((state) => ({...state, voltage}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(formData.brand_id))
						let brandName = brandIdx === -1 ? '' : brandsList[brandIdx].label
						let voltageValue = voltage === null ? '' : voltage
						let name = `${brandName} ${formData.capacity}A ${voltageValue}V`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<RowInput
					value={formData.capacity}
					label={dictionary.Capacity}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(capacity) => {
						setFormData((state) => ({...state, capacity}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(formData.brand_id))
						let brandName = brandIdx === -1 ? '' : brandsList[brandIdx].label
						let capacityValue = capacity === null ? '' : capacity
						let name = `${brandName} ${capacityValue}A ${formData.voltage}V`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<RowSelect
					value={formData.brand_id}
					label={dictionary.Brand}
					optionsList={brandsList}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(brand_id) => {
						setFormData((state) => ({...state, brand_id}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(brand_id))
						let name = `${brandsList[brandIdx].label} ${formData.capacity}A ${formData.voltage}V`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveNewBatteryModel}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={updatePage}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b> {dictionary.Cancel}</b>
					</Button>
				</div>
			</CreateFormDiv>
		</CreateContentDiv>
	)
}
