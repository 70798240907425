import { ButtonContainer, CancelButton, ConfirmationButton } from './styles'
import {EditModalBackgroundDiv, ViewModalDiv} from 'src/styles/globalStyles'

function ConfirmationModal({onClose, onConfirmation, title, message, children, show}) {
	return(
		<>
			{show ? (
        <EditModalBackgroundDiv>
        	<ViewModalDiv style={{ width: '35%'}}>
        		<h1>{title}</h1>
        		{message && (
        			<p>{message}</p>
        		)}
        		{children}
        		<ButtonContainer>
        			<CancelButton style={{ height: '40px', width: 'auto', minWidth: '90px'}} onClick={onClose}>Cancelar</CancelButton>
        			<ConfirmationButton style={{ height: '40px', width: 'auto', minWidth: '90px'}} onClick={onConfirmation}>Confirmar</ConfirmationButton>
        		</ButtonContainer>
        	</ViewModalDiv>
        </EditModalBackgroundDiv>
      ) : null}
		</>
	)
}

export default ConfirmationModal