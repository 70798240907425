import {
	ButtonsList,
	ListTitle,
	ListTitleIconEnd,
	ListTitleIconStart,
	MainDivOpen,
	PageButton,
	PageButtonCollapsed,
	PagesList,
} from './styles'
import React, { useState } from 'react'
import {
	faChevronCircleLeft,
	faChevronCircleRight,
	faMinusSquare,
	faPlusSquare,
} from '@fortawesome/free-solid-svg-icons'

import ClientSelect from 'src/components/ClientSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Logo } from '../Logo/index.jsx'
import UserCard from './UserCard'
import { getItem } from 'src/utils/localStorage'
import { isUndefinedOrNull } from 'src/genericFunctions'
import pagesList from 'src/pagesAddressList'

/**
 * @author Carlos Padovezi
 * @function Menu
 * @file Menu.jsx
 * @summary Function main layout menu
 * @param {Boolean} isCollapsed - Boolean than indicate if menu is collapsed or not
 * @param {Function} collapseMenuFunction - Function called to callpase menu
 * @returns Menu of pages list
 */
export default function Menu({ isCollapsed, collapseMenuFunction }) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	//Current page
	const [currentPage, changeCurrentPage] = useState(document.location.pathname)

	//Get pages list
	let userProfile = userInfos.profile_id

	let myPageList = pagesList && pagesList.length > 0 ? pagesList : []
	//Mount list
	let list = myPageList.map((page) => {
		let profileToAccess = page.profileToAccess

		if (page.isAvaliable) {
			if (page.type === 'list') {
				return ListMenuButton({
					...page,
					currentPage,
					changeCurrentPage,
					isCollapsed,
					userProfile,
				})
			} else {
				if (
					profileToAccess &&
					Array.isArray(profileToAccess) &&
					profileToAccess.length > 0 &&
					profileToAccess.indexOf(userProfile) === -1
				) {
					return null
				} else {
					let isCurrentPage = currentPage === page.address ? true : false
					return MenuButton({
						...page,
						isCurrentPage,
						changeCurrentPage,
						isCollapsed,
					})
				}
			}
		}
		return null
	})

	return (
		<MainDivOpen>
			<div style={{ width: '100%', height: '90px' }}>
				<Logo width={'100%'} height={'100%'} isLink={false} />
			</div>
			{userProfile === 1 && !isCollapsed && <ClientSelect />}
			<PageButtonCollapsed onClick={collapseMenuFunction}>
				{isCollapsed ? (
					<FontAwesomeIcon icon={faChevronCircleRight} />
				) : (
					<FontAwesomeIcon icon={faChevronCircleLeft} />
				)}
			</PageButtonCollapsed>

			<ButtonsList>
				{list}
				<UserCard isCollapsed={isCollapsed} />
			</ButtonsList>
			{!isCollapsed && (
				<p style={{ textAlign: 'center', color: '#fff' }}>V1.16.1(1)</p>
			)}
		</MainDivOpen>
	)
}

/**
 * @author Carlos Padovezi
 * @function ListMenuButton
 * @file Menu.jsx
 * @summary Function main layout menu
 * @param {Boolean} isCollapsed - Boolean than indicate if menu is collapsed or not
 * @returns List of pages buttons
 */
export function ListMenuButton({
	id,
	name,
	list,
	icon,
	currentPage,
	changeCurrentPage,
	isCollapsed,
	userProfile,
}) {
	//Create state of sublist to check if collapsed or not
	const [showList, toggleList] = useState(
		list.findIndex((page) => page.address === currentPage) > -1,
	)

	const handleToggleList = () => {
		toggleList(!showList)
	}

	//Mount buttons of list
	const buttonsList = list.map((page) => {
		const profileToAccess = page.profileToAccess

		if (
			profileToAccess &&
			Array.isArray(profileToAccess) &&
			profileToAccess.length > 0 &&
			profileToAccess.indexOf(userProfile) === -1
		) {
			return null
		} else {
			const isCurrentPage = currentPage === page.address ? true : false

			return MenuButton({
				...page,
				isCurrentPage,
				changeCurrentPage,
				profileToAccess,
				userProfile,
				isCollapsed,
				listTitle: name,
			})
		}
	})

	return (
		<div key={id + 'List'} style={{ marginTop: '10px' }}>
			{isCollapsed === true ? (
				<></>
			) : (
				<div
					onClick={handleToggleList}
					title={name}
					style={{
						width: '100%',
						cursor: 'pointer',
						display: 'flex',
						justifyContent: 'space-between',
					}}>
					<ListTitle>
						<ListTitleIconStart>{icon}</ListTitleIconStart>
						{name.toUpperCase()}
					</ListTitle>

					<ListTitleIconEnd>
						{showList ? (
							<FontAwesomeIcon icon={faMinusSquare} />
						) : (
							<FontAwesomeIcon icon={faPlusSquare} />
						)}
					</ListTitleIconEnd>
				</div>
			)}
			<PagesList showList={showList || isCollapsed}>{buttonsList}</PagesList>
		</div>
	)
}

/**
 * @author Carlos Padovezi
 * @function MenuButton
 * @file Menu.jsx
 * @summary Construct page button in menu list
 * @returns Menu of pages list
 */
export function MenuButton({
	id,
	name,
	address,
	icon,
	isCurrentPage,
	changeCurrentPage,
	isCollapsed,
	listTitle,
	isAvaliable,
}) {
	//Get address to redirect after user click
	const myAddress = address ? address : '/'

	const handleChangeTabTitle = () => {
		if (isCurrentPage) window.location.reload()

		changeCurrentPage(address)
		document.title = `COBE Control - ${name}`
	}

	const title = listTitle ? `${listTitle} - ` + name : name
	const selectedClient = getItem('selected_client')
	if (
		(id === 'custumers') |
		(id === 'chargersBrandsCrud') |
		(id === 'chargersModelsCrud') |
		(id === 'forkliftBrandsCrud') |
		(id === 'forkliftModelsCrud') |
		(id === 'batteryBrandsCrud') |
		(id === 'batteryModelsCrud') |
		(id === 'checklist') &&
		!isUndefinedOrNull(selectedClient) &&
		!isUndefinedOrNull(selectedClient.selectedClient)
	) {
		return null
	} else if (isAvaliable) {
		return (
			<Link
				to={myAddress}
				key={id + 'Button'}
				onClick={handleChangeTabTitle}
				title={title}>
				{isCollapsed === true ? (
					<PageButtonCollapsed
						isCollapsed={isCollapsed}
						currentPage={isCurrentPage}>
						{icon}
					</PageButtonCollapsed>
				) : (
					<PageButton currentPage={isCurrentPage}>
						<div
							style={{
								display: 'table',
								width: 'calc(100% - 15px)',
								marginLeft:
									title.toUpperCase() === 'HOME' ||
										title.toUpperCase() === 'ARQUIVOS'
										? '5px'
										: '20px',
							}}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									fontSize: '15px',
								}}>
								<div
									style={{
										width: '20px',
										minWidth: '20px',
										maxWidth: '20px',
										display: 'table-cell',
										textAlign: 'center',
									}}>
									{icon ? icon : ''}
								</div>
								<div
									style={{
										width: 'calc(100% - 20px)',
										display: 'table-cell',
										textAlign: 'left',
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										verticalAlign: 'middle',
										marginLeft: '7px',
									}}>
									{name && name === 'Home' ? name.toUpperCase() : name}
								</div>
							</div>
						</div>
					</PageButton>
				)}
			</Link>
		)
	} else {
		return null
	}
}
