import {PURPLE_DARK} from 'src/styles/colors'
import styled from 'styled-components'

export const CardContainer = styled.div`
	background: ${(props) => props.backgroundColor};
	border-radius: 5px;
	-webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
	box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
	border: none;
	margin-bottom: 30px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	padding: 10px;
	color: #000000;
	min-width: 140px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
`

export const CardTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 30px;
`

export const CardTitle = styled.p`
	font-weight: 700;
	color: ${PURPLE_DARK};
	font-size: 14px;
	margin: 5px 0;
`

export const CardDescription = styled(CardTitle)`
	font-weight: 500;
	font-size: 22px;
	line-height: 30px;
`

export const CardIcon = styled.div`
	background-color: ${PURPLE_DARK};
	border-radius: 50%;
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	margin-right: 15px;
`
