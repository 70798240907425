import { CardContent, CardTitle, CardsContainer } from './styles'

export const Card = ({ icon, title, children, width = '97%' }) => {
	return (
		<CardsContainer width={width}>
			<CardTitle>{title}</CardTitle>
			<CardContent>
				{children}
			</CardContent>
		</CardsContainer>
	)
}