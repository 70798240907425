import 'src/styles/index.css'

import App from 'src/pages/app'
import AppProvider from 'src/providers/AppProvider'
import {Provider} from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import {Store} from 'src/store'

function Index() {
	return (
		<Provider store={Store}>
			<AppProvider>
				<App />
			</AppProvider>
		</Provider>
	)
}

ReactDOM.render(
	<React.StrictMode>
		<Index />
	</React.StrictMode>,
	document.getElementById('root'),
)
