import {EditModalBackgroundDiv, EditModalDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function EditBatteryRoom
 * @file EditBatteryRoom.js
 * @summary Function to edit Battery rooms register view
 * @returns Battery rooms edit crud page
 */
function EditBatteryRoom({
	editData,
	handleCancelEdit,
	updatePage,
	hideLoadingBlur,
	showLoadingBlur,
}) {
	const [brandsList, setBrandsList] = useState([])
	const [formData, setFormData] = useState({
		name: editData.name ? editData.name : '',
		estimated_manufactorer_cycles: editData.estimated_manufactorer_cycles
			? editData.estimated_manufactorer_cycles
			: '',
		capacity: editData.capacity ? editData.capacity : '',
		voltage: editData.voltage ? editData.voltage : '',
		brand_id: editData.brand_id ? editData.brand_id : '',
		id: editData.id ? editData.id : '',
	})

	useEffect(() => {
		showLoadingBlur()
		//get branch list
		createFetchRequest('batteryBrand/getAll', 'get', null, ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}

			const myList = []
			if (result && result.length > 0) {
				for (let brand of result) {
					myList.push({value: brand.id, label: brand.name})
				}
			}

			setBrandsList(myList)
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	const handleSaveEditBatteryModel = () => {
		const context = {
			id: formData.id,
			name: formData.name,
			estimated_manufactorer_cycles: formData.estimated_manufactorer_cycles,
			capacity: formData.capacity,
			voltage: formData.voltage,
			brand_id: formData.brand_id,
		}

		createFetchRequest(
			'batteryModel/update',
			'put',
			context,
			({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					updatePage()
				}
			},
		)
	}

	const validButton =
		formData.estimated_manufactorer_cycles &&
		formData.capacity &&
		formData.voltage &&
		formData.brand_id

	return (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					autoFocus={true}
					readOnly={true}
				/>
				<RowInput
					value={formData.estimated_manufactorer_cycles}
					label={dictionary.EstimatedCycles}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(estimated_manufactorer_cycles) =>
						setFormData((state) => ({...state, estimated_manufactorer_cycles}))
					}
				/>
				<RowInput
					value={formData.voltage}
					label={dictionary.Voltage}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(voltage) => { 
						setFormData((state) => ({...state, voltage}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(formData.brand_id))
						let brandName = brandIdx === -1 ? '' : brandsList[brandIdx].label
						let voltageValue = voltage === null ? '' : voltage
						let name = `${brandName} ${formData.capacity}A ${voltageValue}V`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<RowInput
					value={formData.capacity}
					label={dictionary.Capacity}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(capacity) => {
						setFormData((state) => ({...state, capacity}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(formData.brand_id))
						let brandName = brandIdx === -1 ? '' : brandsList[brandIdx].label
						let capacityValue = capacity === null ? '' : capacity
						let name = `${brandName} ${capacityValue}A ${formData.voltage}V`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<RowSelect
					value={formData.brand_id}
					label={dictionary.Brand}
					optionsList={brandsList}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(brand_id) => {
						setFormData((state) => ({...state, brand_id}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(brand_id))
						let name = `${brandsList[brandIdx].label} ${formData.capacity}A ${formData.voltage}V`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveEditBatteryModel}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(EditBatteryRoom)
