import {EditModalBackgroundDiv, EditModalDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {RowSelectBranch} from 'src/components/RowSelectBranch'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

/**
 * @author Carlos Padovezi
 * @function EditBatteryRoom
 * @file EditBatteryRoom.js
 * @summary Function to edit Battery rooms register view
 * @returns Battery rooms edit crud page
 */
function EditBatteryRoom({
	editData,
	handleCancelEdit,
	updatePage,
	hideLoadingBlur,
	showLoadingBlur,
}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [clientsList, setClientsList] = useState([])
	const [formData, setFormData] = useState({
		client_id: editData.client_id ? editData.client_id : userInfos.client_id,
		name: editData.name ? editData.name : '',
		branch_id: editData.branch_id ? editData.branch_id : '',
		id: editData.id ? editData.id : '',
	})
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		showLoadingBlur()
		setLoading(true)
		//get client list
		createFetchRequest('client/getAll', 'get', null, async ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}

			let myList = []
			if (result && result.length > 0) {
				for (let client of result) {
					myList.push({value: client.id, label: client.name})
				}
			}

			setClientsList(myList)
			setLoading(false)
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	async function handleSaveEditBatteryRoom() {
		const context = {
			id: formData.id,
			name: formData.name,
			branch_id: formData.branch_id,
		}

		createFetchRequest(
			'batteryRoom/update',
			'put',
			context,
			async ({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					await updatePage()
				}
			},
		)
	}

	let validButton = formData.name && formData.branch_id

	if (isLoading) validButton = `${dictionary.loading}...`

	return (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={clientsList}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id}))
						}
					/>
				) : null}
				<RowSelectBranch
					clientId={formData.client_id}
					value={formData.branch_id}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(branch_id) =>
						setFormData((state) => ({...state, branch_id}))
					}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveEditBatteryRoom}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(EditBatteryRoom)
