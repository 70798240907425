import {LoadingDiv, WindowDiv} from './styles'
import React, {useEffect, useState} from 'react'

import {connect} from 'react-redux'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function LoadingBlur
 * @file LoadingBlur.jsx
 * @summary Function to use standard input with label
 * @returns Row with Label and input
 */
const LoadingBlur = ({loadingBlurState}) => {
	const [showLoadingBlur, toggleShowState] = useState(loadingBlurState.show)
	const [opactiy, toggleOpacity] = useState(1)

	useEffect(() => {
		if (loadingBlurState.show === true) toggleShowState(true)
		else toggleShowState(false)
	}, [loadingBlurState])

	setTimeout(() => (opactiy === 1 ? toggleOpacity(0.3) : toggleOpacity(1)), 1500)

	return (
		<WindowDiv isShowing={showLoadingBlur}>
			<div>
				<svg
					style={{
						width: '200px',
						height: '200px',
						zIndex: 101,
						opacity: opactiy,
						transition: 'opacity 0.8s ease-in-out',
					}}
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1721.000000 1866.000000"
					preserveAspectRatio="xMidYMid meet">
					<g
						transform="translate(0.000000,1866.000000) scale(0.100000,-0.100000)"
						fill="#FFF"
						stroke="none">
						<path d="M8843 18644 c-23 -9 -59 -32 -80 -53 -30 -29 -587 -1095 -2552 -4882 -2450 -4720 -2515 -4846 -2519 -4907 -5 -78 15 -135 64 -185 78 -78 -34 -71 1310 -77 l1201 -5 49 -30 c85 -53 137 -168 115 -255 -5 -19 -698 -1810 -1541 -3980 -1244 -3200 -1534 -3956 -1538 -4003 -6 -72 15 -131 64 -180 49 -49 98 -70 171 -75 77 -5 133 19 190 81 92 101 9640 12021 9656 12055 13 28 17 56 15 114 -3 66 -7 82 -33 119 -17 24 -50 57 -74 74 l-43 30 -936 5 c-875 5 -940 6 -977 23 -106 48 -169 165 -145 270 5 25 637 1199 1498 2784 819 1508 1494 2756 1501 2773 14 43 14 102 -1 154 -16 55 -99 138 -154 154 -32 9 -623 12 -2620 11 -2255 0 -2584 -2 -2621 -15z" />
						<path d="M6490 17503 c-367 -19 -641 -48 -945 -98 -960 -159 -1857 -507 -2650 -1030 -903 -595 -1604 -1374 -2096 -2329 -465 -905 -706 -1805 -780 -2916 -15 -228 -15 -835 0 -1065 59 -888 232 -1660 541 -2425 641 -1582 1757 -2794 3270 -3549 287 -143 502 -234 512 -216 14 23 881 2260 882 2273 1 8 -33 27 -79 46 -571 232 -1120 623 -1537 1096 -865 979 -1266 2374 -1102 3835 97 869 432 1697 950 2345 137 171 411 450 577 588 649 540 1428 864 2306 960 l153 17 639 1230 c351 677 639 1233 639 1238 0 6 -1154 7 -1280 0z" />
						<path d="M13189 15577 c-332 -611 -605 -1113 -607 -1116 -1 -3 19 -17 45 -31 92 -48 369 -239 503 -347 155 -124 403 -362 529 -508 697 -807 1081 -1904 1081 -3090 0 -1505 -597 -2829 -1655 -3674 -598 -478 -1342 -797 -2100 -900 -305 -42 -356 -44 -1225 -50 l-854 -6 -939 -1170 c-516 -643 -958 -1196 -983 -1228 l-46 -58 1934 4 c2066 4 1985 2 2388 53 1080 136 2075 520 3000 1158 1575 1085 2621 2873 2879 4916 79 626 85 1376 15 2015 -194 1779 -1005 3356 -2268 4408 -301 251 -663 498 -1002 684 l-92 50 -603 -1110z" />
					</g>
				</svg>
			</div>
			<LoadingDiv>
				<p>{dictionary.loading}</p>
			</LoadingDiv>
		</WindowDiv>
	)
}

const mapStateToProps = (store) => {
	return {
		loadingBlurState: store.loadingBlurState,
	}
}

export default connect(mapStateToProps)(LoadingBlur)
