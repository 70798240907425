import { AreaInputContainer, LabelAreaInput, TextAreaInput } from './styles'
import {Button, EditModalBackgroundDiv, EditModalDiv} from 'src/styles/globalStyles'
import React from 'react'
import { RowInput } from '../RowInput'
import { RowSelect } from '../RowSelect'

function Malfunction({ data, malfunction, show, onDimiss, onSuccess, title }) {
	const [equipment, setEquipment] = React.useState(null)
	const [editMalfunction, setEditMalfunction] = React.useState({ notes: '', isRemove: false, id: -1, obs: '' })

	React.useEffect(() => {				
		setEquipment(data)
		setEditMalfunction(malfunction)
	}, [data, malfunction])

	const onFinish = React.useCallback(() => {
		const context = {
			notes: editMalfunction.notes,
			isRemove: editMalfunction.isRemove,
			qrcode: equipment.qrcode,
			id: editMalfunction?.id,
			obs: editMalfunction.obs,
		}
				
		onSuccess(context)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editMalfunction, onSuccess])

	return show ? (
		<EditModalBackgroundDiv>
			<EditModalDiv style={{ width: '70%'}}>
				<h1>{title}</h1>
				<RowInput 
					value={editMalfunction?.notes}
					label={'Descrição'}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(notes) => setEditMalfunction((state) => ({...state, notes}))}
					autoFocus={true}/>
				<RowSelect
					value={editMalfunction?.isRemove}
					label={'Remover da Operação?'}
					optionsList={[
						{value: false, label: 'Não'},
						{value: true, label: 'Sim'},
					]}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(isRemove) => setEditMalfunction((state) => ({...state, isRemove}))}
				/>
				<AreaInputContainer>
					<LabelAreaInput>Observações</LabelAreaInput>
					<TextAreaInput 
						placeholder='Digite aqui informações sobre a avaria, ex: (n° OS, comentários, etc..)'
						defaultValue={editMalfunction?.obs}										
						onChange={(obs) => setEditMalfunction((state) => ({...state, obs: obs.target.value}))}
					/>
				</AreaInputContainer>
				<div style={{ display: 'flex'}}>
					<Button onClick={onDimiss} style={{ margin: '8px'}}>Cancelar</Button>
					<Button onClick={onFinish} style={{ margin: '8px'}}>{title !== 'Editar Avaria' ? 'Cadastrar Avaria' : 'Editar Avaria'}</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	) : null
}

export default Malfunction