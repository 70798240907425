import styled from 'styled-components'

export const InputContainer = styled.div`
	text-align: left;
	display: flex;
	justify-content: center;
	align-items: left;
	flex-direction: column;
	margin-top: 20px;
`

export const InputLabel = styled.label`
	font-size: 18px;
`

export const Input = styled.input`
	width: 300px;
	height: 30px;
	opacity: 1;
	font-size: 16px;
	padding-left: 15px;
	color: rgb(86, 86, 86);
	outline: none;
	border-top: none;
	border-right: none;
	border-left: none;
	border-image: initial;
	border-bottom: 1px solid;
	transition: border-color 1s ease 0s;
	margin-top: 5px;
`

export const Select = styled.select`
	width: 317px;
	height: 30px;
	opacity: 1;
	color: rgb(86, 86, 86);
	outline: none;
	padding-left: 15px;
	border-top: none;
	border-right: none;
	border-left: none;
	border-image: initial;
	border-bottom: 1px solid;
	transition: border-color 1s ease 0s;
	margin-top: 5px;
`

export const Option = styled.option``

export const Form = styled.div`
	min-height: 200px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`
