import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'
import {useCallback, useEffect, useState} from 'react'

import CreateBatteryBrand from './BatteryBrandCreate.jsx'
import {CrudModal} from '../../components/CrudModal/index.jsx'
import EditBatteryBrand from './BatteryBrandEdit.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MainContentDiv} from '../../styles/globalStyles'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

//CRUD list configuration
const columns = [{accessor: 'name', Header: dictionary.Name}]

/**
 * @author Carlos Padovezi
 * @function BatteryBrandsCrud
 * @file BatteryBrandsCrud.js
 * @summary Function to create battery brands crud view
 * @returns Battery brands crud page
 */
function BatteryBrandsCrud({hideLoadingBlur, showLoadingBlur}) {
	const [crudSelected, changeCrudSelected] = useState('list')
	const [crudList, setCrudList] = useState([])
	const [editingBatteryBrand, setEditingBatteryBrand] = useState(null)

	const loadPageData = useCallback(() => {
		showLoadingBlur()

		createFetchRequest('batteryBrand/getAll', 'get', null, ({err, result}) => {
			if (err) setCrudList([])
			else if (!err && result) setCrudList(result)

			setEditingBatteryBrand(null)
			changeCrudSelected('list')
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const handleOpenEditBratteryBrand = (params) => {
		const rowValue = params

		const startValues = {
			name: rowValue.name,
			id: rowValue.id,
		}

		setEditingBatteryBrand(startValues)
	}

	const handleCancelEdit = () => setEditingBatteryBrand(null)

	const handleDeleteBatteryBrand = (params) => {
		const isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			showLoadingBlur()

			const context = {id: params.id}

			createFetchRequest(
				'batteryBrand/delete/' + params.id,
				'delete',
				context,
				({err, result}) => {
					if (err) {
						console.log(err)
					}

					//Reset inputs values
					loadPageData()
				},
			)
		}
	}

	const crudsObjPages = {
		list: {
			title: dictionary.BrandsList,
			list: {
				columns,
				rows: crudList,
			},
			onRowEditClick: handleOpenEditBratteryBrand,
			onRowEditLabel: 'Editar',
			onRowEditIcon: <FontAwesomeIcon icon={faEdit} />,
			onRowDeleteClick: handleDeleteBatteryBrand,
			onRowDeleteLabel: 'Excluir',
			onRowDeleteIcon: <FontAwesomeIcon icon={faTrash} />
		},
		create: {
			title: dictionary.BrandRegister,
			content: <CreateBatteryBrand updatePage={loadPageData} />,
		},
	}

	const editModal = (
		<EditBatteryBrand
			handleCancelEdit={handleCancelEdit}
			updatePage={loadPageData}
			editData={editingBatteryBrand}
		/>
	)

	return (
		<MainContentDiv>
			<CrudModal
				crudsObj={crudsObjPages}
				crudSelected={crudSelected}
				changeCrudSelected={(newCrud) => changeCrudSelected(newCrud)}
			/>
			{editingBatteryBrand === null ? null : editModal}
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(BatteryBrandsCrud)
