import { Container, Option, Select } from './styles'
import { getItem, setItem } from 'src/utils/localStorage'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions/index'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createFetchRequest } from 'src/genericFunctions'

const ClientSelect = () => {
	const [clients, setClients] = React.useState([])
	const [selectedClient, setSelecteClient] = React.useState(() => {
		const selectedCli = getItem('selected_client')
		if (selectedCli !== null)
			return parseInt(selectedCli.selectedClient)
		else
			return undefined
	})
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const loadPageData = React.useCallback(() => {
		showLoadingBlur()

		createFetchRequest(
			'client/listAll',
			'get',
			null,
			async ({ err, result }) => {
				if (err) setClients([])
				else if (!err && result) {
					setClients(result)
				}

				hideLoadingBlur()
			},
		)
	}, [])

	React.useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const changeClient = React.useCallback((client_id) => {
		setSelecteClient(client_id)
		setItem('selected_client', { selectedClient: client_id })
		setItem('selectedClientName', clients.find(e => e.id === parseInt(client_id)).name)
		window.location.reload()
	}, [clients])

	return (
		<Container>
			<Select onChange={(e) => changeClient(e.target.value)} defaultValue={selectedClient} value={selectedClient} defaultChecked={selectedClient}>
				<Option value={undefined} label='Todos os Clientes' />
				{clients.map((client, index) => (
					<Option key={index} value={client.id} label={client.name} />
				))}
			</Select>
		</Container>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(ClientSelect)