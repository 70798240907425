import {PURPLE_LIGHT} from '../../styles/colors'
import styled from 'styled-components'

export const MenuDiv = styled.div`
	width: ${(props) => (props.menuIsCollapsed ? '50px' : '240px')};
	transition: width 1s ease-in-out;
`

export const ContentDiv = styled.div`
	width: 100%;
	height: 100%;
	/* min-width: 1100px; */
	min-height: 550px;
	background-color: ${PURPLE_LIGHT};
	overflow: auto;
`

export const MainDiv = styled.div`
	width: ${(props) =>
		props.menuIsCollapsed ? 'calc(100% - 50px)' : 'calc(100% - 240px)'};
	overflow: hidden;
	padding-bottom: 25px;
	@media (max-width: 1100px) {
		overflow: auto;
	}
`
