
/* eslint-disable semi */
/* eslint-disable indent */
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { Divider } from '@material-ui/core';
import React from 'react';
import { formatFullDateBR } from 'src/genericFunctions';
import styled from 'styled-components';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  padding: 10px;
  margin: 5px 0;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: grab;
`;

const SortableItem = sortableElement(({ value }) => {
	const dt = formatFullDateBR(value.estimated_charged_time).replace(',', '')

	return (
		<ListItem>
			<div style={{ display: 'flex', gap: '10px' }}>
				<label>
					ID: {value.id}
				</label>
				<label>
					Bateria: {value.battery.name}
				</label>
				<Divider orientation='vertical' />
				<label>
					Estimativa de carga: {dt}
				</label>
			</div>
		</ListItem>
	)

});

const SortableList = sortableContainer(({ items }) => {
	return (
		<List>
			{items.map((value, index) => (
				<SortableItem key={`item-${index}`} index={index} value={value} />
			))}
		</List>
	);
});

const ReorderFifoListComponent = ({ data, onSortEnd }) => {
	return (
		<div>
			<SortableList items={data} onSortEnd={onSortEnd} />
		</div>
	);
};

export default ReorderFifoListComponent;
