const initialState = {
	loggedIn: false,
	token: null,
	id: null,
	branch_id: null,
	client_id: null,
	name: null,
	cpf: null,
	email: null,
	profile_id: null,
}

export const userAccessInformations = (state = initialState, action) => {
	switch (action.type) {
		case 'USER_IS_LOGGED_IN':
			return {
				...state,
				loggedIn: true,
				token: action.infos.token,
				id: action.infos.id,
				branch_id: action.infos.branch_id,
				client_id: action.infos.client_id,
				name: action.infos.name,
				cpf: action.infos.cpf,
				email: action.infos.email,
				profile_id: action.infos.profile_id,
			}
		case 'UPDATE_TOKEN':
			return {
				...state,
				loggedIn: true,
				token: action.newToken,
			}
		case 'USER_IS_LOGGED_OUT':
		case 'RESET_STATE':
			return initialState
		default:
			return state
	}
}
