import {createContext, useContext} from 'react'

const AppStateContext = createContext()

const AppStateProvider = ({children}) => {
	return (
		<AppStateContext.Provider value={{}}>{children}</AppStateContext.Provider>
	)
}

const useAppState = () => {
	const context = useContext(AppStateContext)

	if (!context) {
		throw new Error('useAppData must be used within an AppStateProvider.')
	}

	return context
}

export {AppStateProvider, useAppState}
