import { hideLoadingBlur, showLoadingBlur } from 'src/actions'
import EmployeeTable from 'src/components/Table'
import { MainContentDiv } from 'src/styles/globalStyles'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createFetchRequest } from 'src/genericFunctions'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'

const formatColumns = (profileId, orderCallback) => {
	let columns = [
		{
			accessor: 'id',
			Header: dictionary.id,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'name',
			Header: dictionary.Name,
			csvHeader: 'Nome',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'email',
			Header: dictionary.Email,
			csvHeader: 'Email',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'cpf',
			Header: dictionary.cpf,
			csvHeader: 'CPF',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'client',
			Header: dictionary.client,
			csvHeader: 'Cliente',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
			showColumn: profileId === 1,
		},
		{
			accessor: 'branch',
			Header: dictionary.branch,
			csvHeader: 'Sede',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'totalCycles',
			Header: <p>Total de <br /> Ciclos</p>,
			csvHeader: 'Total de Ciclos',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'totalCharge',
			Header: <p>Total de<br /> Cargas</p>,
			csvHeader: 'Total de Cargas',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'efficiency',
			Header: 'Eficiência',
			csvHeader: 'Eficiência',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
			valueFormatter: (row, value) => {
				let newValue = value.replace('.', ',')
				newValue = newValue.concat('%')

				return newValue
			},
			getStyle: (value) => {
				if (value <= 0) {
					return null
				}

				if (value >= 90) {
					return { backgroundColor: '#0a8754' }
				}

				if (value >= 80 && value < 90) {
					return { backgroundColor: '#9ea93f' }
				}

				if (value >= 70 && value < 80) {
					return { backgroundColor: '#f2af29' }
				}

				if (value >= 60 && value < 70) {
					return { backgroundColor: '#ff9b42' }
				}

				return { backgroundColor: '#ad343e' }
			},
		}
	]

	return columns
}

const EmployeeReport = ({ showLoadingBlur, hideLoadingBlur }) => {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()
	const profileId = userInfos.profile_id
	const [order, setOrder] = React.useState({
		field: '',
		value: '',
	})
	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({
		totalPages: 0,
		total: 0,
		page: 1,
	})
	const [dateFilter, setDateFilter] = React.useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})
	const [list, setList] = React.useState([])
	const [columns, setColumns] = React.useState([])
	const limit = 100
	const [filters, setFilters] = React.useState({
		id: '',
		name: '',
		email: '',
		cpf: '',
		client: '',
		branch: ''
	})
	const [selectedIds, setSelectedIds] = React.useState([])
	const [selectedRows, setSelectedRows] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)
	const [deselectIds, setDeselectIds] = React.useState([])

	const loadData = React.useCallback(() => {
		showLoadingBlur()

		let opt = {}
		opt['order'] = order
		opt['startdate'] = dateFilter.initialDate
		opt['finaldate'] = dateFilter.endDate
		opt['filters'] = filters

		createFetchRequest(`/employee/report/${limit}/${page}`, 'post', opt, ({ err, result }) => {
			if (err) {
				setList([])
			}

			if (!err && result) {
				console.log(result)
				setList(result.response)
				setPagination({ totalPages: result.totalPages, total: result.total, page: page })
			}
			hideLoadingBlur()
		})
	}, [dateFilter.endDate, dateFilter.initialDate, hideLoadingBlur, order, page, showLoadingBlur, filters])

	//Filtros
	const filterColumn = (filter, column) => {
		filters[column] = filter
		setFilters({ ...filters })
	}

	const filterValueColumn = React.useCallback((field, value) => {
		setOrder({ field, value })
	}, [])

	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({ initialDate: start, endDate: end })
	}

	const onSelectRow = (row, checked) => {
		if (selectAll) {
			if (!checked) {
				setDeselectIds([...deselectIds, row.id])
				setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
			} else {
				setDeselectIds(deselectIds.filter((r) => r !== row.id))
				setSelectedRows([...selectedRows], row)
			}
		} else {
			if (checked) {
				setSelectedRows([...selectedRows, row])
				setSelectedIds([...selectedIds, row.id])
			} else {
				setSelectedIds(selectedIds.filter((r) => r !== row.id))
				setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
			}
		}
	}

	const onSelectAll = (checked) => {
		setDeselectIds([])
		setSelectedIds([])
		setSelectAll(checked)
	}

	const onGenerateCSV = React.useCallback(() => {
		showLoadingBlur()
		let opt = {}
		opt.selectAll = selectAll
		opt.selectedIds = selectedIds
		opt.deselectIds = deselectIds
		opt['order'] = order
		opt['startdate'] = dateFilter.initialDate
		opt['finaldate'] = dateFilter.endDate
		opt['filters'] = filters

		opt.type = 'csv'

		console.log(filters)

		createFetchRequest(
			'report/exportEmployeeReport',
			'post',
			opt,
			async ({ err, result }) => {
				if (err) console.log(err)
				else if (!err && result) {
					setTimeout(() => {
						window.open(result)
					}, 2000)
				}
				hideLoadingBlur()
			},
		)
	}, [
		dateFilter.endDate,
		dateFilter.initialDate,
		deselectIds,
		filters,
		hideLoadingBlur,
		selectAll,
		selectedIds,
		showLoadingBlur,
	])

	const filter = {
		filterDate,
		filterColumn,
		previous: () => { setPage(page - 1) },
		next: () => { setPage(page + 1) },
		lastPage: () => { setPage(pagination.totalPages) },
		firstPage: () => { setPage(1) },
		selectPage: (value) => { setPage(parseInt(value)) },
		dateFilter,
		searchFilters: filters,
	}

	React.useEffect(() => {
		loadData()
	}, [loadData])

	React.useEffect(() => {
		const columns = formatColumns(profileId, filterValueColumn)
		setColumns(columns)
	}, [filterValueColumn, profileId])

	return (
		<MainContentDiv>
			<EmployeeTable
				rows={list}
				columns={columns}
				filters={filter}
				pagination={pagination}
				name="Relatório de Operadores"
				selectable={true}
				onSelect={onSelectRow}
				onSelectAll={onSelectAll}
				showHiddenColumn={true}
				selectAll={selectAll}
				selectedIds={selectedIds}
				deselectIds={deselectIds}
				onGenerateCSV={onGenerateCSV}
			/>
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(EmployeeReport)