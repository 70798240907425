import React, {useCallback, useEffect, useState} from 'react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'

import CreateEmployee from './EmployeeCreate.jsx'
import {CrudModal} from '../../components/CrudModal/index.jsx'
import EditEmployee from './EmployeeEdit.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MainContentDiv} from '../../styles/globalStyles'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {cpfMask} from '../../genericFunctions'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

const translatedUsers = (usersList) => {
	let newUsersList = []

	for (let user of usersList) {
		newUsersList.push({
			...user,
			enable:
				user.enable === true || user.enable === 'true'
					? dictionary.Enabled
					: dictionary.Disabled,
			enableBoolean:
				user.enable === true || user.enable === 'true' ? true : false,
			branch: user.branch && user.branch.name ? user.branch.name : '',
			branch_id: user.branch && user.branch.id ? user.branch.id : '',
			client_id:
				user.branch && user.branch.client && user.branch.client.id
					? user.branch.client.id
					: '',
			client:
				user.branch && user.branch.client && user.branch.client.name
					? user.branch.client.name
					: '',
			profile: user.profile && user.profile.type ? user.profile.type : '',
			profile_id: user.profile && user.profile.id ? user.profile.id : '',
			receive_email:
				user.receive_email === true ? 'SIM' : 'NÃO',			
		})
	}

	return newUsersList
}

/**
 * @author Carlos Padovezi
 * @function EmployeesCrud
 * @file EmployeesCrud.js
 * @summary Function to create employees crud view
 * @returns Employees crud page
 */
function EmployeesCrud({hideLoadingBlur, showLoadingBlur}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [crudSelected, changeCrudSelected] = useState('list')
	const [crudList, setCrudList] = useState([])
	const [editingEmployee, setEditingEmployee] = useState(null)

	const loadPageData = useCallback(() => {
		showLoadingBlur()

		createFetchRequest('employee/getAll', 'get', null, ({err, result}) => {
			if (err) setCrudList([])
			else if (!err && result) {
				const myResult = translatedUsers(result)
				setCrudList(myResult)
			}

			changeCrudSelected('list')
			setEditingEmployee(null)
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const handleOpenEditEmployee = (params) => {
		const rowValue = params

		const startValues = {
			name: rowValue.name,
			email: rowValue.email,
			cpf: rowValue.cpf,
			enable: rowValue.enableBoolean,
			branch_id: rowValue.branch_id,
			profile_id: rowValue.profile_id,
			client_id: rowValue.client_id,
			editingId: rowValue.id,
		}

		setEditingEmployee(startValues)
	}

	const handleCancelEdit = () => {
		setEditingEmployee(null)
	}

	const handleDeleteButtonClick = (params) => {
		const isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			showLoadingBlur()

			var context = {
				id: params.id,
			}

			createFetchRequest(
				'employee/delete/' + params.id,
				'delete',
				context,
				async ({err, result}) => {
					if (err) {
						console.log(err)
					} else {
						window.alert(dictionary.userDeleted)
					}

					loadPageData()
				},
			)
		}
	}

	//CRUD list configuration
	const columns = [
		{
			accessor: 'cpf',
			Header: dictionary.cpf,
			valueFormatter: cpfMask,
			getStyle: () => ({width: '100px'}),
		},
		{accessor: 'name', Header: dictionary.Name, flex: 1},
		{accessor: 'email', Header: dictionary.Email, flex: 1},
		{
			accessor: 'enable',
			Header: dictionary.Enabled,
			getStyle: () => ({width: '100px'}),
		},
		{
			accessor: 'profile',
			Header: dictionary.Profile,
			getStyle: () => ({width: '100px'}),
		},
		{
			accessor: 'branch',
			Header: dictionary.Branch,
		},
		{
			accessor: 'client',
			Header: dictionary.Customer,
			showColumn: userInfos.profile_id === 1,
		},
		{
			accessor: 'receive_email',
			Header: 'Receber e-mail informativo?'
		},
		{
			accessor: 'app_version',
			Header: 'Versão do App',
			showColumn: userInfos.profile_id === 1,
		},
	]

	const crudsObjPages = {
		list: {
			title: dictionary.EmployeesList,
			list: {
				columns,
				rows: crudList,
			},
			onRowEditClick: handleOpenEditEmployee,
			onRowEditLabel: 'Editar',
			onRowEditIcon: <FontAwesomeIcon icon={faEdit} />,
			onRowDeleteClick: handleDeleteButtonClick,
			onRowDeleteLabel: 'Excluir',
			onRowDeleteIcon: <FontAwesomeIcon icon={faTrash} />
		},
		create: {
			title: dictionary.EmployeeRegister,
			content: <CreateEmployee updatePage={loadPageData} />,
		},
	}

	const editModal = (
		<EditEmployee
			handleCancelEdit={handleCancelEdit}
			updatePage={loadPageData}
			editData={editingEmployee}
		/>
	)

	return (
		<MainContentDiv>
			<CrudModal
				crudsObj={crudsObjPages}
				crudSelected={crudSelected}
				changeCrudSelected={(newCrud) => changeCrudSelected(newCrud)}
			/>
			{editingEmployee === null ? null : editModal}
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(EmployeesCrud)
