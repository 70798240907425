import {CreateContentDiv, CreateFormDiv} from '../../styles/globalStyles'
import React, {useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function CreateBatteryBrand
 * @file CreateBatteryBrand.js
 * @summary Function to create Battery rooms register view
 * @returns Battery rooms create crud page
 */
export default function CreateBatteryBrand({updatePage}) {
	const [name, setName] = useState('')

	function handleSaveNewBatteryBrand() {
		const context = {
			name: name,
		}

		createFetchRequest(
			'chargerBrand/create',
			'post',
			context,
			({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					updatePage()
				}
			},
		)
	}

	const validButton = !!name

	return (
		<CreateContentDiv>
			<CreateFormDiv>
				<RowInput
					value={name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setName(name)}
					autoFocus={true}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveNewBatteryBrand}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={updatePage}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b> {dictionary.Cancel}</b>
					</Button>
				</div>
			</CreateFormDiv>
		</CreateContentDiv>
	)
}
