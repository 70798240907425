import styled from 'styled-components'

export const Content = styled.div`
	max-height: 400px;
	overflow: auto;
	background-color: #fdfdfd;
	margin: 10px 0;
	width: 100%;
`

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`

export const Item = styled.div`
	padding: 10px;
	cursor: pointer;
	border: 1px solid #f2f2f2;
`

export const Title = styled.p`
	font-size: 18px;
	font-weight: bold;
`
export const ItemTitle = styled.span`
	font-size: 14px;
	font-weight: 400;
`

export const HeaderRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	justify-content: space-between;
`
