import axios from 'axios'

const api = axios.create({baseURL: process.env.REACT_APP_DOMAIN})

api.interceptors.request.use((request) => {
	return request
})

api.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		throw error
	},
)

export default api
