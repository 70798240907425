import {GRAY_DARK, PURPLE, PURPLE_DARK} from '../../styles/colors'

import styled from 'styled-components'

export const Row = styled.div`
	height: ${(props) => (props.height ? props.height : 'auto')};
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	margin: 5px;
	position: relative;
`

export const LabelDiv = styled.div`
	color: ${GRAY_DARK};
	font-weight: ${(props) => (props.isObligatory ? 600 : 0)};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
	margin-bottom: 5px;
	margin-left: 15px;
`

export const Input = styled.input`
	width: ${(props) => (props.width ? props.width : '310px')};
	height: ${(props) => (props.height ? props.height : '30px')};
	opacity: ${(props) => (props.disabled ? '0.5' : '1')};
	border: 1px solid #ccc;
	border-radius: 20px;
	cursor: pointer;

	&:hover {
		border: 1px solid ${PURPLE};
	}

	&::-webkit-file-upload-button {
		height: ${(props) => (props.height ? props.height : '30px')};
		display: inline-block;
		color: ${PURPLE};
		background: #fff;
		outline: none;
		white-space: nowrap;
		-webkit-user-select: none;
		font-weight: 700;
		font-size: 10pt;
		border: 1px solid ${PURPLE};
		border-radius: 20px;
		cursor: pointer;
		marginright: 3px;
	}

	&::-webkit-file-upload-button:hover {
		background: ${PURPLE};
		color: #fff;
	}
`

export const ImagePreview = styled.img`
	width: 50px;
	height: 50px;
	object-fit: contain;
	position: absolute;
	right: -65px;
	top: 13px;
	border: 1px solid ${PURPLE};
	border-radius: 5px;
	background-color: ${PURPLE_DARK};
`

export const DeleteImage = styled.span`
	position: absolute;
	right: -58px;
	top: 15px;
	font-size: 12px;
	color: #fff;
	opacity: 0.5;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
		scale: 1.3;
	}
`
