import {PURPLE} from 'src/styles/colors'
import styled from 'styled-components'

export const InputSelectFile = styled.input`
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`
export const Label = styled.label`
	color: ${(props) => (props.color ? props.color : PURPLE)};
`

export const Figure = styled.figure`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : PURPLE};
	display: block;
	padding: 20px;
	cursor: pointer;
	margin: 0 auto 10px;
`
export const Svg = styled.svg``

export const Path = styled.path``
