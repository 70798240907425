import {PURPLE, PURPLE_LIGHT} from 'src/styles/colors'
import styled from 'styled-components'

export const ButtonAdd = styled.button`
	padding: 5px;
	color: ${PURPLE};
	border: 1px solid ${PURPLE};
	background-color: ${PURPLE_LIGHT};
	margin-left: 40px;
	margin-top: 10px;
	cursor: pointer;
	:hover {
		background-color: ${PURPLE};
		color: ${PURPLE_LIGHT};
	}
`
