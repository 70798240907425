import {DeleteImage, ImagePreview, Input, LabelDiv, Row} from './styles'
import React, {useCallback, useEffect, useRef, useState} from 'react'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'

/**
 * @author Carlos Padovezi
 * @function RowFileInput
 * @file RowFileInput.js
 * @param {String} value - Input value
 * @param {String} label - Labek of row above the input
 * @param {Boolean} isObligatory - Boolean than indicates if row is obligatory
 * @param {String} width - Component row's width
 * @param {String} height - Component row's height
 * @param {String} fontSize - Text input font size
 * @param {Function} onChange -	Function called when onChange event occours
 * @param {Boolean} autoFocus -	Boolean than indicates if auto focus input
 * @param {Boolean} readOnly - Boolean to disable input
 * @summary Function to use standard input with label
 * @returns Row with Label and input
 */
export function RowFileInput(props) {
	//RowFileInput configuration START
	const [image, setImage] = useState('')
	const [src, setSrc] = useState('')
	const inputRef = useRef(null)

	const myHeight = props.height ? props.height : null
	const myFontSize = props.fontSize ? props.fontSize : '12px'
	const myAutoFocus = props.autoFocus ? props.autoFocus : false
	const isObligatory = props.isObligatory ? props.isObligatory : false
	const myLabel = props.label ? props.label : ''
	const myFileName = props.value ? props.value : ''
	const filesAccepteds = ['.jpg', '.jpeg', '.png']

	const loadImage = useCallback(() => {
		if (src !== null) {
			const img = new Image(50, 50)

			if (src) {
				img.src = src
			} else {
				const mySrc = process.env.PUBLIC_URL

				img.src = myFileName
					? `${mySrc}/public/logo/${myFileName}`
					: `${process.env.PUBLIC_URL}/images/noimageavailable.png`
			}

			img.onload = () => setImage(img)
		}
	}, [myFileName, src])

	useEffect(() => {
		loadImage()
	}, [loadImage])

	//Middleware between input and parent component
	async function handOnChange(event) {
		event.persist()

		if (event.target.files[0]) {
			const file = event.target.files[0]

			setSrc(URL.createObjectURL(file))

			const data = new FormData()
			data.append('file', file, 'file')

			props.onChange(data)
		} else {
			inputRef.current.value = null

			setSrc(null)
			setImage(null)
			props.onChange(null)
		}
	}

	const HandleDeleteImage = () => {
		inputRef.current.value = null

		setSrc(null)
		setImage(null)
		props.onChange(null)
	}

	return (
		<Row height={myHeight}>
			<LabelDiv
				isObligatory={isObligatory && !props.readOnly}
				fontSize={myFontSize}>
				<label>{`${myLabel}${
					isObligatory && !props.readOnly ? ' *' : ''
				} `}</label>
			</LabelDiv>
			<Input
				ref={inputRef}
				type={'file'}
				onChange={handOnChange}
				autoFocus={myAutoFocus}
				fontSize={myFontSize}
				disabled={props.readOnly}
				accept={filesAccepteds}
				multiple={false}
			/>
			{src === null || image === null ? (
				<ImagePreview
					src={`${process.env.PUBLIC_URL}/images/noimageavailable.png`}
					alt={''}
				/>
			) : (
				<ImagePreview src={image.src} alt={''} />
			)}
			{src === null ? null : (
				<DeleteImage onClick={HandleDeleteImage}>
					<FontAwesomeIcon icon={faTimes} />
				</DeleteImage>
			)}
		</Row>
	)
}
