import { Container, Title } from "./styles"

const formatHour = (idx, minutes = ':00') => {
	const hours = Array.from({ length: 24 }, (_, index) => `${index.toString().padStart(2, '0')}${minutes}`)

	const value = hours[idx]

	return value
}

/**
 * 
 * @param {TooltipProps<ValueType, NameType>} payload 
 */
export const CustomTooltip = ({ active, payload, label }) => {
	return (
		<Container>
			<Title>Trocas no período</Title>
			<label>{formatHour(label)} - {formatHour(label, ":59")}</label>
			<br />
			Trocas: {payload[0]?.value}
		</Container>
	)
}