import {Colors} from 'src/styles'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
`

export const Title = styled.h1`
	font-size: 24px;
	color: #000000;
	padding: 24px;
`

export const ActionRow = styled.div`
	width: 300px;
	display: flex;
	align-items: center;
	justify-content: space-around;
`

export const Button = styled.button`
	border: none;
	border-radius: 2px;
	background-color: ${({active}) => (active ? Colors.PURPLE : Colors.BLACK)};
	padding: 8px;
	color: ${Colors.WHITE};
	cursor: pointer;
	:hover {
		opacity: 0.4;
	}
`

export const ListContainer = styled.div`
	width: 70%;
	border: 1px solid ${Colors.GRAY_LIGHT};
	border-radius: 5px;
	box-shadow: 5px 5px 15px #bebebe, -10px -10px 20px #ffffff;
	padding: 35px;
	margin: 25px 0;
`

export const ListRow = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 30px;
	padding: 5px 0;
	border-bottom: 1px solid;
	border-color: ${Colors.GRAY_LIGHT};
`

export const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`

export const Description = styled.div`
	width: 60%;
	font-size: 16px;
`

export const DescriptionTitle = styled.h1`
	font-size: 20px;
	width: 60%;
	font-weight: bold;
`

export const IsDeletedTitle = styled(DescriptionTitle)`
	width: 20%;
`

export const IsDeleted = styled.div`
	width: 20%;
`

export const ActionTitle = styled(DescriptionTitle)`
	width: 20%;
`

export const Action = styled.div`
	width: 20%;
`

export const ButtonAdd = styled.button`
	padding: 5px;
	color: ${Colors.PURPLE};
	border: 1px solid ${Colors.PURPLE};
	background-color: ${Colors.PURPLE_LIGHT};
	margin-left: 40px;
	margin-top: 10px;
	cursor: pointer;
	:hover {
		background-color: ${Colors.PURPLE};
		color: ${Colors.PURPLE_LIGHT};
	}
`
export const RowAdd = styled.div`
	display: flex;
	width: 70%;
	justify-content: flex-start;
	padding: 15px;
`
export const IsActive = styled.div`
	padding: 5px;
	border: none;
	cursor: pointer;
	background-color: ${({active}) =>
		active ? Colors.GREEN : Colors.GRAY_MEDIUM};
	color: ${Colors.WHITE};
`
export const IsDisabled = styled.div`
	padding: 5px;
	border: none;
	cursor: pointer;
	background-color: ${({active}) => (active ? Colors.RED : Colors.GRAY_MEDIUM)};
	color: ${Colors.WHITE};
`
export const ActionButton = styled.button`
	background-color: ${Colors.PURPLE};
	border-radius: 50%;
	width: 28px;
	height: 28px;
	margin: 0 3px;
	border: none;
	color: ${Colors.WHITE};
	cursor: pointer;
`
