import { ButtonAdd } from './styles'
import ConfirmationModal from 'src/components/ConfirmationModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import JustificationForm from 'src/components/JustificationForms'
import JustificationTable from 'src/components/Table'
import { MainContentDiv } from 'src/styles/globalStyles'
import React from 'react'
import { createFetchRequest } from 'src/genericFunctions'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useInfoModal } from 'src/hooks/useInfoModal'

const formatColumns = () => {
	const columns = [
		{ 
			accessor: 'id',
			Header: <p>ID</p>,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,
			orderType: 'number',
			showFilter: true,
		},
		{ 
			accessor: 'description',
			Header: <p>Descrição</p>,
			csvHeader: 'Descrição',
			width: 'auto',
			priority: 1,
			order: true,
			orderType: 'string',
			showFilter: true,
		},
		{ 
			accessor: 'removeOperation',
			Header: <p>Remover da Operação</p>,
			csvHeader: 'Remover da Operação',
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				if (value) {
					return 'Sim'
				}
        
				return 'Não'
			}
		},
	]

	return columns
}

const initialValueForm = {
	id: -1,
	description: '',
	removeOperation: false,
}

const Justifications = () => {
	const [justifications, setJustifications] = React.useState([])
	const [columns, setColumns] = React.useState([])
	const { showModal } = useInfoModal()
	
	const [justificationModal, setJustificationModal] = React.useState({
		show: false,
		data: initialValueForm,
	})

	const [confirmationModal, setConfirmationModal] = React.useState({
		show: false,
		message: '',
		onConfirm: null,
		title: '',
		data: null
	})

	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({ 
		totalPages: 0,
		total: 0,
		page: 1,
	 })

	const [dateFilter, setDateFilter] = React.useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})

	const [filters, setFilters] = React.useState({ 
		id: '',
		description: '',
	})

	const loadData = React.useCallback(() => {
		createFetchRequest('/justification', 'GET', null, ({ err, result}) => {
			if (err) {
				return 
			}

			setColumns(formatColumns())
			setJustifications(result)
			setPagination({ totalPages: 1, total: result.length, page: 1})
		})
	}, [])

	const onEdit = React.useCallback((params) => {
		console.log(params)
		setJustificationModal({ show: true, data: params })
	}, [])

	const onConfirm = React.useCallback(() => {
		setConfirmationModal({ show: false, message: '', title: '', data: null })
		if (confirmationModal.data) {
			createFetchRequest(`/justification/${confirmationModal.data.id}`, 'DELETE', null, ({ err, result }) => {
				if (err) {
					console.log('erro')
					return
				}

				console.log('aqui')
				loadData()
				showModal({ title: 'Sucesso', message: 'Justificativa deletada com sucesso', children: null, show: true })
			})
		} else {
			showModal({ title: 'Sucesso', message: 'Erro ao processar informações', children: null, show: true })
		}
	}, [confirmationModal.data, loadData, showModal])

	const onDelete = React.useCallback((params) => {
		setConfirmationModal({ show: true, title: 'Atenção!', message: 'Deseja deletar esse item?', data: params })
	}, [])

	const style = {
		'borderRadius': '50%',
		'padding': '2px',
		'width': '28px',
		'height': '28px'
	}

	const actions = [
		{ label: <FontAwesomeIcon icon={faEdit}/>, action: onEdit, title: 'Editar', style: style, view: true },
		{ label: <FontAwesomeIcon icon={faTrash}/>, action: onDelete, title: 'Excluir', style: style, view: true },		
	]

	const filterColumn = (filter, column) => {
		let newFilters = filters
		newFilters[column] = filter
		setFilters({...newFilters})
	}
	
	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({initialDate: start, endDate: end})
	}

	const filter = {
		filterDate,
		filterColumn,
		previous: () => { setPage(page - 1) },
		next: () => { setPage(page + 1) },
		lastPage: () => { setPage(pagination.totalPages) },
		firstPage: () => { setPage(1) },
		selectPage: (value) => { setPage(parseInt(value)) },
		dateFilter,
		searchFilters: filters,
	}

	React.useEffect(() => {
		loadData()
	}, [loadData])

	const onCancel = React.useCallback(() => {
		setJustificationModal({ show: false, data: null })
	}, [])

	const onChange = React.useCallback((key, value) => {
		setJustificationModal((prev) => ({...prev, data: {...prev.data, [key]: value} }))
	}, [])

	const onSubmit = React.useCallback(() => {
		setJustificationModal({ show: false, data: initialValueForm })
		const { data } = justificationModal		
		if (data.id !== -1) {
			createFetchRequest(`/justification/${data.id}`, 'PUT', data, ({ err, result }) => {
				if (err) {
					return
				}

				loadData()
				showModal({ title: 'Sucesso', message: 'Avaria editada com sucesso', children: null, show: true })
			})
		} else {
			createFetchRequest(`/justification`, 'POST', data, ({ err, result }) => {
				if (err) {
					return
				}

				loadData()
				showModal({ title: 'Sucesso', message: 'Avaria criada com sucesso', children: null, show: true })
			})
		}
	}, [justificationModal, loadData, showModal])

	const addJustification = React.useCallback(() => {
		setJustificationModal({ show: true, data: initialValueForm })
	}, [])

	return (
		<MainContentDiv>
			<ButtonAdd onClick={addJustification} >+ Adicionar Justificativa</ButtonAdd>
			<JustificationTable 
				columns={columns}
				rows={justifications}
				actions={actions}
				filters={filter}
				name='LISTA DE JUSTIFICATIVAS'
				pagination={pagination}
				hideSearchPeriod={true}
			/>

			<JustificationForm
				onCancel={onCancel}
				onSubmit={onSubmit}
				justification={justificationModal.data}
				show={justificationModal.show} 
				onChange={onChange}	
			/>
			<ConfirmationModal 
				show={confirmationModal.show}
				message={confirmationModal.message}
				onConfirmation={onConfirm}
				onClose={() => setConfirmationModal({ show: false, message: '', title: '', data: null })}
				title={confirmationModal.title}
			/>
		</MainContentDiv>
	)
}

export default Justifications