import {CreateContentDiv, CreateFormDiv} from '../../styles/globalStyles'
import React, {useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {RowSelectBranch} from 'src/components/RowSelectBranch'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'
import {useEffect} from 'react'

/**
 * @author Carlos Padovezi
 * @function CreateEmployee
 * @file EmployeesCrud.js
 * @summary Function to create Employees register view
 * @returns Employees create crud page
 */
export default function CreateEmployee({updatePage}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [profilesList, setProfilesList] = useState([])
	const [clientsList, setClientsList] = useState([])
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
		profile_id: '',
		cpf: '',
		enable: '',
		client_id: userInfos && userInfos.client_id ? userInfos.client_id : '',
		branch_id: '',
		receive_email: false,
	})

	useEffect(() => {
		//get client list
		createFetchRequest('client/getAll', 'get', null, async ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}

			let myList = []
			if (result && result.length > 0) {
				for (let client of result) {
					myList.push({value: client.id, label: client.name})
				}
			}

			setClientsList(myList)

			//get profile list
			createFetchRequest(
				'profile/getAll',
				'get',
				null,
				async ({err, result}) => {
					if (err) {
						console.log(err)
						return
					}
					let myList = []
					if (result && result.length > 0) {
						for (let profile of result) {
							myList.push({value: profile.id, label: profile.type})
						}
					}
					setProfilesList(myList)
				},
			)
		})
	}, [])

	const handleSaveNewEmployee = () => {
		const context = {
			name: formData.name,
			email: formData.email,
			password: formData.password,
			profile_id: formData.profile,
			cpf: formData.cpf,
			enable: formData.enable,
			branch_id: formData.branch_id,
			receive_email: formData.receive_email
		}

		createFetchRequest(
			'employee/create',
			'post',
			context,
			async ({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					updatePage()
				}
			},
		)
	}

	let validButton =
		formData.name &&
		formData.password &&
		formData.password.length >= 6 &&
		formData.cpf &&
		formData.profile &&
		(formData.enable === 'true' ||
			formData.enable === 'false' ||
			formData.enable === true ||
			formData.enable === false) &&
		formData.branch_id

	return (
		<CreateContentDiv>
			<CreateFormDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				<RowInput
					value={formData.email}
					label={dictionary.Email}
					isObligatory={false}
					fontSize={'16px'}
					type={'email'}
					onChange={(email) => setFormData((state) => ({...state, email}))}
					autoComplete="new-email"
				/>
				<RowInput
					value={formData.password}
					label={dictionary.Password}
					type={'password'}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(password) =>
						setFormData((state) => ({...state, password}))
					}
					maxLength={50}
					autoComplete="new-password"
				/>
				<RowInput
					value={formData.cpf}
					label={dictionary.cpf}
					type={'cpf'}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(cpf) => setFormData((state) => ({...state, cpf}))}
				/>
				<RowSelect
					value={formData.profile}
					label={dictionary.Profile}
					optionsList={profilesList}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(profile) => setFormData((state) => ({...state, profile}))}
				/>
				<RowSelect
					value={formData.enable}
					label={dictionary.Enabled}
					optionsList={[
						{value: false, label: dictionary.Disabled},
						{value: true, label: dictionary.Enabled},
					]}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(enable) => setFormData((state) => ({...state, enable}))}
				/>
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={clientsList}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id: client_id}))
						}
					/>
				) : null}
				<RowSelectBranch
					clientId={formData.client_id}
					value={formData.branch_id}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(branch_id) =>
						setFormData((state) => ({...state, branch_id}))
					}
				/>
				<RowSelect
					value={formData.receive_email}
					label={'Receber e-mail informativo?'}
					optionsList={[
						{value: false, label: 'Não'},
						{value: true, label: 'Sim'},
					]}
					isObligatory={false}
					fontSize={'16px'}
					onChange={(receive_email) => setFormData((state) => ({...state, receive_email}))}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveNewEmployee}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={updatePage}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b> {dictionary.Cancel}</b>
					</Button>
				</div>
			</CreateFormDiv>
		</CreateContentDiv>
	)
}
