import {PURPLE, PURPLE_LIGHT, WHITE} from 'src/styles/colors'
import styled from 'styled-components'

export const CreateContentDiv = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`

export const CreateFormDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
`

export const ChargerTagContainer = styled.div`
	width: 170px;
	height: 238px;
	border: 1px solid #000;
	background-color: #fff;
`

export const ChargerTagHeader = styled.div`
	width: 100%;
	height: 50px;

	border-bottom: 1px solid #000;
	background-color: #fff;

	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const ChargerTagContent = styled.div`
	width: 100%;
	height: calc(100% - 51px);
	display: flex;
	flex-direction: column;
`

export const ChargerTagQrDataContent = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`

export const ChargerTagQrCodeContent = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`

export const TitleHeader = styled.div`
	cursor: pointer;
	text-align: center;
	font-size: 16px;

	line-height: 40px;
	color: ${(props) => (props.isSelected ? `${WHITE}` : `${PURPLE}`)};

	background-image: ${(props) =>
		props.isSelected
			? `linear-gradient(to top, ${PURPLE}, ${PURPLE})`
			: `linear-gradient(to top, ${PURPLE_LIGHT}, #fff)`};

	border-radius: 10px 10px 0px 0px;
	box-shadow: -0px -4px 1em #776e6e;

	z-index: ${(props) => (props.isSelected ? 2 : 1)};

	padding: 0px 10px;
	width: fit-content;

	&:hover {
		color: #fff !important;
		background-image: linear-gradient(to top, ${PURPLE}, ${PURPLE});
	}
`

export const Container = styled.div`
	position: relative;
	max-width: 100%;
	max-height: calc(100% - 80px);
	overflow: hidden;
	border-radius: 5px;
	overflow: auto;
`

export const ContentInfo = styled.div`
	display: flex;
	justify-content: space-between;
	height: 40px;
	align-items: center;
`
export const DisableInfo = styled.span`
	background-color: ${(props) => props.color};
	color: #fff;
	padding: 7px 13px;
	border-radius: 4px;
	font-size: 10px;
`
