import {LabelDiv, Row, Textarea} from './styles'

import React from 'react'

/**
 * @author Carlos Padovezi
 * @function RowTextArea
 * @file RowTextArea.js
 * @param {String} value - Input value
 * @param {String} label - Labek of row above the input
 * @param {Boolean} isObligatory - Boolean than indicates if row is obligatory
 * @param {String} width - Component row's width
 * @param {String} height - Component row's height
 * @param {String} fontSize - Text input font size
 * @param {Function} onChange -	Function called when onChange event occours
 * @param {String} type - Type of input value options: phone, cnpj, cpf, email, number, password
 * @param {Boolean} autoFocus -	Boolean than indicates if auto focus input
 * @param {Number} maxLength - Input value max length
 * @param {Number} minLength - Input value min length
 * @param {Boolean} centerText - Boolean than indicates if should center input text
 * @param {Boolean} readOnly - Boolean to disable input
 * @summary Function to use standard input with label
 * @returns Row with Label and input
 */
export function RowTextArea(props) {
	//RowTextArea configuration START
	let myHeight = props.height ? props.height : null

	let myFontSize = props.fontSize ? props.fontSize : '12px'
	let myAutoFocus = props.autoFocus ? props.autoFocus : false
	let centerText = props.centerText ? props.centerText : false
	let placeholder = props.placeholder ? props.placeholder : ''
	let isObligatory = props.isObligatory ? props.isObligatory : false
	let textAreaMaxLength = props.maxLength ? props.maxLength : null
	let textAreaMinLength = props.minLength ? props.minLength : 0
	let textAreaResize = props.resizeEnabled ? props.resizeEnabled : 0

	let myLabel = props.label ? props.label : ''
	let inputType = props.type ? props.type : ''

	//Middleware between input and parent component
	function handOnChange(event) {
		event.persist()

		props.onChange(event.target.value)
	}

	return (
		<Row height={myHeight}>
			<LabelDiv
				isObligatory={isObligatory && !props.readOnly}
				fontSize={myFontSize}>
				<label>{`${myLabel}${
					isObligatory && !props.readOnly ? ' *' : ''
				} `}</label>
			</LabelDiv>
			<Textarea
				value={
					props.value === null || props.value === 'null' ? '' : props.value
				}
				onChange={handOnChange}
				height={myHeight}
				type={inputType}
				min={inputType === 'number' ? 0 : null}
				autoFocus={myAutoFocus}
				placeholder={placeholder}
				maxLength={textAreaMaxLength}
				minLength={textAreaMinLength}
				fontSize={myFontSize}
				centerText={centerText ? true : false}
				disabled={props.readOnly}
				style={{
					resize: textAreaResize ? 'auto' : 'none',
				}}
			/>
		</Row>
	)
}
