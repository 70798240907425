const { Select, Option } = require("./styles")

const InconsistenceFilter = ({ onChange, value }) => {
	return (
		<Select onChange={onChange} value={value}>
			<Option value="undefined">Todos</Option>
			<Option value="A carga não foi finalizada corretamente.">A carga não foi finalizada corretamente.</Option>
			<Option value="O carregador foi utilizado em outra bateria.">O carregador foi utilizado em outra bateria.</Option>
		</Select>
	)
}

export default InconsistenceFilter