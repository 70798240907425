import { LabelDiv, Row, Select } from './styles'

import React from 'react'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function CustomersCrud
 * @file CustomersCrud.js
 * @param {String} value -
 * @param {Array} optionsList -
 * @param {String} label -
 * @param {Boolean} isObligatory -
 * @param {String} width -
 * @param {String} height -
 * @param {String} fontSize -
 * @param {Function} onChange -
 * @param {String} type -
 * @param {Boolean} autoFocus -
 * @param {Boolean} readOnly - Boolean to disable input
 * @summary Function to use standard select with label
 * @returns Row with Label and select
 */
export function RowSelect(props) {
	if (!props || Object.keys(props).length === 0) {
		return <div></div>
	}

	if (!props.onChange || typeof props.onChange !== 'function') {
		return <div></div>
	}

	//Middleware between select and parent component
	function handOnChange(event) {
		event.persist()

		let value = event.target.value
		props.onChange(value)
	}

	//RowSelect configuration
	//RowInput configuration START
	let myLabel = props.label ? props.label : ''
	let isObligatory = props.isObligatory ? props.isObligatory : false
	let myAutoFocus = props.autoFocus ? props.autoFocus : false
	let myHeight = props.height ? props.height : null
	let myFontSize = props.fontSize ? props.fontSize : '12px'
	let placeholder = props.placeholder ? props.placeholder : ''

	//Create select options
	let options = [
		<option value={''} key={'SelectionOption'}>
			{dictionary.SelectOption}
		</option>,
	]

	if (props.optionsList && props.optionsList.length > 0) {
		for (let i = 0; i < props.optionsList.length; i++) {
			let myValue = props.optionsList[i].value
			let myLabel = props.optionsList[i].label

			options.push(
				<option value={myValue} key={'SelectionOption' + i}>
					{myLabel}
				</option>,
			)
		}
	}

	return (
		<Row height={myHeight}>
			<LabelDiv
				isObligatory={isObligatory && !props.readOnly}
				fontSize={myFontSize}>
				<label>{`${myLabel}${isObligatory && !props.readOnly ? ' *' : ''} `}</label>
			</LabelDiv>
			<Select
				value={
					props.value === null || props.value === 'null' ? '' : props.value
				}
				onChange={handOnChange}
				autoFocus={myAutoFocus}
				placeholder={placeholder}
				disabled={props.readOnly}>
				{options}
			</Select>
		</Row>
	)
}
