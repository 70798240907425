import {
	ButtonsContentDiv,
	CopyrightDiv,
	InputPass,
	InputsContentDiv,
	LabelPass,
	LoginContentDiv,
	LoginErrorDiv,
	LoginPhrase,
	PageContentDiv,
	PassContainer,
	ViewPassButton
} from './styles'
import {PURPLE, PURPLE_DARK} from '../../styles/colors'
import {faCog, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import {useHistory, useLocation} from 'react-router-dom'

import {Button} from '../../styles/globalStyles'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Logo} from '../../components/Logo/index.jsx'
import React from 'react'
import {RowInput} from '../../components/RowInput/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {setItem} from 'src/utils/localStorage'
import {useSession} from 'src/hooks/useSession'
import {useState} from 'react'

/**
 * @author Carlos Padovezi
 * @function Login
 * @file Login.js
 * @summary Function of login page
 * @returns Login page
 */
export default function Login() {
	const history = useHistory()
	const location = useLocation()
	const {login} = useSession()
	const [erroMessage, setErrorMessage] = useState('Erro ao realizar login.')

	//Credentials function
	const [credentials, setCredentials] = useState({
		login: '',
		password: '',
	})

	const [viewPass, setViewPass] = useState(false)
	const changeViewPass = () => {
		setViewPass(!viewPass)
	}

	const handleUpdateLogin = (login) => {
		let newLogin = login
		setCredentials({...credentials, login: newLogin})
	}

	const handleUpdatePassword = (password) => {
		let newPassword = password
		setCredentials({...credentials, password: newPassword})
	}

	//Button loading function
	const [isLoading, setLoading] = useState(false)
	const handleToggleLoading = () => setLoading(!isLoading)

	//Login function
	const [loginError, setLoginError] = useState(false)
	const handleToggleLoginError = (message) => {
		//Clear loading
		setLoading(false)
		//Show login error
		setLoginError(true)
		setErrorMessage(message)
		setTimeout(() => {
			//Clear error
			setLoginError(false)
		}, 10000)
	}

	//Connect handle function
	const handleConnectButtonOnClick = () => {
		if (isLoading) return

		//Update loading state
		handleToggleLoading()

		const callback = ({err, result, status}) => {
			console.log(status)
			if (err || !result) {
				let message
				if (status === 401)
					message = 'Credenciais inválidas.'	
				
				if (status === 666)
					message = 'A comunicação com o servidor falhou.'

				if (status === 404)
					message = 'Usuário não encontrado.'	

				if (status === 500)
					message = 'Erro interno no servidor.'	
				handleToggleLoginError(message)
				return
			}

			if (result) {
				const userInfosObj = {
					id: result.id,
					branch_id: result.branch_id,
					client_id: result.branch.client.id,
					name: result.name,
					cpf: result.cpf,
					email: result.email,
					profile_id: result.profile_id,					
				}

				setItem('session', userInfosObj)

				const {from} = location.state || {from: {pathname: '/'}}
				history.replace(from)
				login()
			} else {
				handleToggleLoginError()
			}
		}

		createFetchRequest('auth/login', 'post', credentials, callback, true, true)
	}

	//Handle open Cobel Control in new tab
	const handleOpenCobeControlTab = () => {
		window.open('http://cobetecnologia.com.br/')
	}

	return (
		<PageContentDiv>
			<div style={{width: '100%', height: '100%', backgroundColor: PURPLE}}>
				<Logo width={'400px'} height={'200px'} isLink={false} />
			</div>

			<LoginContentDiv>
				<LoginPhrase>
					<p>
						<b>{dictionary.LoginOnSystem}</b>
					</p>
				</LoginPhrase>
				<InputsContentDiv>
					<RowInput
						value={credentials.login}
						label={dictionary.Login}
						width={'400px'}
						height={'100px'}
						onChange={handleUpdateLogin}
						isObligatory={true}
						fontSize={'16px'}
						centerText={true}
					/>
					<LabelPass >
						<p>Senha *</p>
					</LabelPass>								
					<PassContainer>						
						<InputPass
							value={credentials.password}
							onChange={(e) => handleUpdatePassword(e.target.value)}
							isObligatory={true}
							fontSize={'16px'}
							centerText={true}
							viewPass={viewPass}
						/>
						<ViewPassButton onClick={changeViewPass}>
							<FontAwesomeIcon icon={viewPass ? faEye : faEyeSlash} />
						</ViewPassButton>
					</PassContainer>
				</InputsContentDiv>

				<ButtonsContentDiv>
					<LoginErrorDiv>
						{loginError ? (
							<b
								style={{
									color: '#C70000',
									fontSize: '12px',
									textAlign: 'center',
								}}>
								{erroMessage}
							</b>
						) : null}
					</LoginErrorDiv>
					<Button
						style={{width: '150px', height: '40px'}}
						onClick={handleConnectButtonOnClick}
						disabled={!credentials.login || !credentials.password}>
						{isLoading ? (
							<FontAwesomeIcon icon={faCog} spin />
						) : (
							<b>{dictionary.Connect.toUpperCase()}</b>
						)}
					</Button>
				</ButtonsContentDiv>
				<CopyrightDiv>
					<p>
						<span style={{color: PURPLE_DARK, fontSize: '16px'}}>
							{`${dictionary.copyright}: `}
						</span>
						<b
							style={{color: PURPLE_DARK, fontSize: '16px', cursor: 'pointer'}}
							onClick={handleOpenCobeControlTab}>
							{dictionary.cobeControl}
						</b>
						.
					</p>
				</CopyrightDiv>
			</LoginContentDiv>
		</PageContentDiv>
	)
}
