import {BLACK, PURPLE, PURPLE_MEDIUM, WHITE} from 'src/styles/colors'
import styled from 'styled-components'

export const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 99%;
	height: 100%;
	flex-direction: column;
`

export const Title = styled.h2`
	margin-top: 25px;
`

export const ContentForm = styled(Content)`
	min-width: 70%;
	min-height: 70%;
	max-width: 70%;
	max-height: 70%;
	background-color: ${WHITE};
	border-radius: 9px;
	justify-content: flex-start;
	padding: 15px;
	flex-direction: column;
`

export const LabelForm = styled.label`
	font-size: 16px;
	color: ${BLACK};
`

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	flex: 1;
	padding: 15px;
`

export const InputContent = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
`

export const InputForm = styled.input`
	flex: 1;
	height: 30px;
	font-size: 25px;
	width: 100%;
	border-radius: 5px;
	border-color: #fefefe;
`

export const TitlePage = styled.div`
	display: flex;
	min-width: 70%;
	max-width: 70%;
	padding: 0 25px 0 0;
	height: 40px;
	justify-content: flex-start;
`

export const TypesCrudButton = styled.div`
	text-align: left;
	font-size: 16px;

	line-height: 40px;
	color: ${PURPLE};

	background-color: ${(props) => (props.isSelected ? '#FFF' : PURPLE_MEDIUM)};
	opacity: ${(props) => (props.isSelected ? 1 : 0.7)};

	border-radius: 10px 10px 0px 0px;
	box-shadow: -0px -4px 1em #776e6e;

	z-index: ${(props) => (props.isSelected ? 2 : 1)};

	padding: 0px 10px;

	&:hover {
		color: #fff;
		background-color: ${PURPLE};
	}
`
export const LabelError = styled.span`
	color: red;
	min-height: 30px;
`
