import styled from 'styled-components'

export const TextAreaInput = styled.textarea`
	border: 1px solid #333333;
	border-radius: 8px;
	min-height: 200px;
	min-width: 400px;
`
export const LabelAreaInput = styled.label`
	color: #565656;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 5px;
	margin-left: 15px;
`

export const AreaInputContainer = styled.div`
	display: flex;
	flex-direction: column;
`
