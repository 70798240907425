import {
	faAlignJustify,
	faArrowCircleRight,
	faBatteryFull,
	faBolt,
	faBuilding,
	faCalendar,
	faCarBattery,
	// faChartArea,
	faChartLine,
	faCheck,
	faCheckCircle,
	faFile,
	faFileImport,
	faGhost,
	faHome,
	faLaptopHouse,
	faListUl,
	faPlug,
	faRegistered,
	faTh,
	faTools,
	faUndoAlt,
	faUser,
	faUserCircle,
	faBars,
} from '@fortawesome/free-solid-svg-icons'

import BatteriesChargeReport from 'src/pages/batteriesChargeReport'
import BatteriesCrud from 'src/pages/batteriesCrud'
import BatteryBrandsCrud from 'src/pages/batteryBrandsCrud'
import BatteryModelsCrud from 'src/pages/batteryModelsCrud'
// import BatteryReport from 'src/pages/batteryReport'
import BatteryRoomsCrud from 'src/pages/batteryRoomsCrud'
import BranchCrud from 'src/pages/branchCrud'
import ChargerBrandsCrud from 'src/pages/chargerBrandsCrud'
import ChargerModelCrud from 'src/pages/chargerModelsCrud'
import ChargersCrud from 'src/pages/chargersCrud'
import Checklist from './pages/checklist'
import ChecklistClient from 'src/components/ChecklistClient'
import ChecklistReport from './pages/checklistReport'
import CustomersCrud from 'src/pages/customersCrud'
import CycleReport from 'src/pages/cycleReport'
import Dashboard from 'src/pages/newDashboard'
import EmployeeReport from './pages/employeeReport'
import EmployeesCrud from 'src/pages/employeesCrud'
import Files from 'src/pages/files'
import FloatingBatteries from './pages/floatingBatteriesReport'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ForkLiftIcon} from 'src/components/ForkliftIcon/index.jsx'
import ForklifstCrud from 'src/pages/forkliftsCrud'
import ForkliftBrandsCrud from 'src/pages/forkliftBrandsCrud'
import ForkliftModelCrud from 'src/pages/forkliftModelsCrud'
import Heatmap from 'src/pages/heatMapReport'
import ImportCustomer from 'src/pages/importCustomer'
import Justifications from 'src/pages/justifications'
import Malfunction from 'src/pages/malfunctionReport'
import MonthReport from './pages/monthReport'
import RecurringInconsistencyReport from './pages/recurringInconsistencyReport'
import ReorderFifo from 'src/pages/reorderFifo'
import dictionary from 'src/utils/dictionary'

const pagesAddressList = [
	{
		id: 'home',
		address: '/',
		isAvaliable: true,
		name: dictionary.Home,
		icon: <FontAwesomeIcon icon={faHome} />,
		type: 'link',
		content: Dashboard,
	},
	{
		id: 'company',
		isAvaliable: true,
		name: dictionary.Company,
		type: 'list',
		icon: <FontAwesomeIcon icon={faBuilding} />,
		list: [
			{
				id: 'custumers',
				address: '/custumers',
				isAvaliable: true,
				name: dictionary.Customers,
				icon: <FontAwesomeIcon icon={faUser} />,
				type: 'link',
				profileToAccess: [1],
				content: CustomersCrud,
			},
			{
				id: 'branchs',
				address: '/branchs',
				isAvaliable: true,
				name: dictionary.Branchs,
				icon: <FontAwesomeIcon icon={faLaptopHouse} />,
				type: 'link',
				profileToAccess: [1, 2],
				content: BranchCrud,
			},
			{
				id: 'employees',
				address: '/employees',
				isAvaliable: true,
				name: dictionary.Employees,
				icon: <FontAwesomeIcon icon={faUserCircle} />,
				type: 'link',
				content: EmployeesCrud,
			},
			{
				id: 'batteryRooms',
				address: '/batteryRooms',
				isAvaliable: true,
				name: dictionary.BatteryRooms,
				icon: <FontAwesomeIcon icon={faBolt} />,
				type: 'link',
				content: BatteryRoomsCrud,
			},
			{
				id: 'importCustomer',
				address: '/importCustomer',
				isAvaliable: true,
				name: 'Importar',
				icon: <FontAwesomeIcon icon={faFileImport} />,
				type: 'link',
				content: ImportCustomer,
				profileToAccess: [1],
			},
			{
				id: 'justifications',
				address: '/justifications',
				isAvaliable: true,
				name: 'Justificativas',
				icon: <FontAwesomeIcon icon={faHome} />,
				type: 'link',
				content: Justifications,
				profileToAccess: [1],
			},
			{
				id: 'checklist',
				address: '/checklist',
				isAvaliable: true,
				name: 'Cadastro checklist',
				icon: <FontAwesomeIcon icon={faCheckCircle} />,
				type: 'link',
				profileToAccess: [1],
				content: Checklist,
			},
			{
				id: 'checklist-client',
				address: '/checklist-client',
				isAvaliable: true,
				name: 'Checklist',
				icon: <FontAwesomeIcon icon={faCheck} />,
				type: 'link',
				content: ChecklistClient,
			},
			{
				id: 'reorder-fifo',
				address: '/reorder-fifo',
				isAvaliable: true,
				name: 'Reordenar Fifo',
				icon: <FontAwesomeIcon icon={faBars} />,
				profileToAccess: [1],
				type: 'link',
				content: ReorderFifo,
			},
		],
	},
	{
		id: 'chargers',
		isAvaliable: true,
		name: dictionary.Chargers,
		type: 'list',
		icon: <FontAwesomeIcon icon={faPlug} />,
		list: [
			{
				id: 'chargersBrandsCrud',
				address: '/chargerBrands',
				isAvaliable: true,
				name: dictionary.Brands,
				icon: <FontAwesomeIcon icon={faRegistered} />,
				type: 'link',
				profileToAccess: [1],
				content: ChargerBrandsCrud,
			},
			{
				id: 'chargersModelsCrud',
				address: '/chargerModels',
				isAvaliable: true,
				name: dictionary.Models,
				icon: <FontAwesomeIcon icon={faAlignJustify} />,
				type: 'link',
				profileToAccess: [1],
				content: ChargerModelCrud,
			},
			{
				id: 'chargersCrud',
				address: '/chargers',
				isAvaliable: true,
				name: dictionary.ChargersList,
				icon: <FontAwesomeIcon icon={faListUl} />,
				type: 'link',
				content: ChargersCrud,
			},
		],
	},
	{
		id: 'forklift',
		isAvaliable: true,
		name: dictionary.Forklifts,
		type: 'list',
		icon: ForkLiftIcon({opacity: 1}),
		list: [
			{
				id: 'forkliftBrandsCrud',
				address: '/forkliftBrands',
				isAvaliable: true,
				name: dictionary.Brands,
				icon: <FontAwesomeIcon icon={faRegistered} />,
				type: 'link',
				profileToAccess: [1],
				content: ForkliftBrandsCrud,
			},
			{
				id: 'forkliftModelsCrud',
				address: '/forkliftModels',
				isAvaliable: true,
				name: dictionary.Models,
				icon: <FontAwesomeIcon icon={faAlignJustify} />,
				type: 'link',
				profileToAccess: [1],
				content: ForkliftModelCrud,
			},
			{
				id: 'forkliftCrud',
				address: '/forklifts',
				isAvaliable: true,
				name: dictionary.ForkliftsList,
				icon: <FontAwesomeIcon icon={faListUl} />,
				type: 'link',
				content: ForklifstCrud,
			},
		],
	},
	{
		id: 'batteies',
		isAvaliable: true,
		name: dictionary.Batteries,
		type: 'list',
		icon: <FontAwesomeIcon icon={faCarBattery} />,
		list: [
			{
				id: 'batteryBrandsCrud',
				address: '/batteryBrands',
				isAvaliable: true,
				name: dictionary.Brands,
				icon: <FontAwesomeIcon icon={faRegistered} />,
				type: 'link',
				profileToAccess: [1],
				content: BatteryBrandsCrud,
			},
			{
				id: 'batteryModelsCrud',
				address: '/batteryModels',
				isAvaliable: true,
				name: dictionary.Models,
				icon: <FontAwesomeIcon icon={faAlignJustify} />,
				type: 'link',
				profileToAccess: [1],
				content: BatteryModelsCrud,
			},
			{
				id: 'batteriesCrud',
				address: '/batteries',
				isAvaliable: true,
				name: dictionary.BatteriesList,
				icon: <FontAwesomeIcon icon={faListUl} />,
				type: 'link',
				content: BatteriesCrud,
			},
		],
	},
	{
		id: 'reports',
		isAvaliable: true,
		name: dictionary.Reports,
		type: 'list',
		icon: <FontAwesomeIcon icon={faChartLine} />,
		list: [
			// {
			// 	id: 'batteryReport',
			// 	address: '/batteryReport',
			// 	isAvaliable: true,
			// 	name: dictionary.BatteryReport,
			// 	icon: <FontAwesomeIcon icon={faChartArea} />,
			// 	type: 'link',
			// 	content: BatteryReport,
			// },
			{
				id: 'batteriesChargeReport',
				address: '/batteriesChargeReport',
				isAvaliable: true,
				name: dictionary.BatteriesChargeReport,
				icon: <FontAwesomeIcon icon={faBatteryFull} />,
				type: 'link',
				content: BatteriesChargeReport,
			},
			{
				id: 'cycleReport',
				address: '/cycleReport',
				isAvaliable: true,
				name: dictionary.CycleReport,
				icon: <FontAwesomeIcon icon={faUndoAlt} />,
				type: 'link',
				content: CycleReport,
			},
			{
				id: 'heatmapReport',
				address: '/heatmapReport',
				isAvaliable: true,
				name: dictionary.Heatmap,
				icon: <FontAwesomeIcon icon={faTh} />,
				type: 'link',
				content: Heatmap,
			},
			{
				id: 'malfunctionReport',
				address: '/malfunctionReport',
				isAvaliable: true,
				name: dictionary.MalfunctionReport,
				icon: <FontAwesomeIcon icon={faTools} />,
				type: 'link',
				content: Malfunction,
			},
			{
				id: 'floatingBatteries',
				address: '/floatingBatteriesReport',
				isAvaliable: true,
				name: 'Baterias Flutuantes',
				icon: <FontAwesomeIcon icon={faGhost} />,
				type: 'link',
				profileToAccess: [1],
				content: FloatingBatteries,
			},
			{
				id: 'employeeReport',
				address: '/employeeReport',
				isAvaliable: true,
				name: 'Operadores',
				icon: <FontAwesomeIcon icon={faUser} />,
				type: 'link',
				content: EmployeeReport,
			},
			{
				id: 'recurringInconsitencyReport',
				address: '/recurringInconsistencyReport',
				isAvaliable: true,
				name: 'Inconsistências recorrentes',
				icon: <FontAwesomeIcon icon={faArrowCircleRight} />,
				type: 'link',
				profileToAccess: [1],
				content: RecurringInconsistencyReport,
			},
			{
				id: 'checklistReport',
				address: '/checklistReport',
				isAvaliable: true,
				name: 'Relatório de Checklist',
				icon: <FontAwesomeIcon icon={faCheck} />,
				type: 'link',
				content: ChecklistReport,
			},
			{
				id: 'monthReport',
				address: '/monthReport',
				isAvaliable: true,
				name: 'Relatório Mensal',
				icon: <FontAwesomeIcon icon={faCalendar} />,
				type: 'link',
				profileToAccess: [1],
				content: MonthReport,
			},
		],
	},
	{
		id: 'files',
		address: '/files',
		isAvaliable: true,
		name: 'ARQUIVOS',
		icon: <FontAwesomeIcon icon={faFile} />,
		type: 'link',
		content: Files,
		profileToAccess: [1],
	},
]

export default pagesAddressList
