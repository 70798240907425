import {EditModalBackgroundDiv, EditModalDiv} from '../../styles/globalStyles'
import React, {useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function EditBatteryRoom
 * @file EditBatteryRoom.js
 * @summary Function to edit Battery rooms register view
 * @returns Battery rooms edit crud page
 */
export default function EditBatteryRoom({
	editData,
	handleCancelEdit,
	updatePage,
}) {
	const [name, setName] = useState(editData.name ? editData.name : '')

	function handleSaveEditBatteryBrand() {
		const context = {
			id: editData.id,
			name,
		}

		createFetchRequest(
			'chargerBrand/update',
			'put',
			context,
			({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					updatePage()
				}
			},
		)
	}

	const validButton = !!editData.name

	return (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput
					value={name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setName(name)}
					autoFocus={true}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveEditBatteryBrand}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						onClick={handleCancelEdit}
						disabled={!validButton}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	)
}
