/* eslint-disable semi */
/* eslint-disable indent */
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Divider, Snackbar } from '@material-ui/core';
import { Colors } from 'src/styles';
import React from 'react';
import ReorderFifoListComponent from 'src/components/ReorderListFifo';
import { Select } from '../importCustomer/styles';
import Skeleton from 'src/components/Skeleton';
import { arrayMove } from 'react-sortable-hoc';
import { fetchData } from 'src/hooks/useFetch';
import { putData } from 'src/hooks/usePut';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.h1`
  font-size: 25px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  color: #8A05BE;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: calc(100% - 100px);
  width: 100%;
  max-height: calc(100% - 35px);
  height: 100%;
  padding: 35px;
`;

export const CardCustom = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #f8f8f8;
  height: 200px;
  margin: 10px;
  background-color: ${Colors.GRAY_MEDIUM};
  background: #e0e0e0;
  box-shadow: 10px 10px 30px #bebebe, -10px -10px 20px #ffffff;
  min-width: 324px;
`;

export const Name = styled.h1`
  padding: 15px;
  font-size: 20px;
  color: ${Colors.WHITE};
  background-color: ${Colors.PURPLE_DARK};
  margin: 0;
  height: 40px;
  border-radius: 10px;
`;

export const Value = styled.p`
  width: 100%;
  text-align: center;
  font-size: 30px;
  color: ${Colors.BLUE_DARK};
`;

const ReorderFifo = () => {
  const [items, setItems] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [branchs, setBranchs] = React.useState([])
  const [clients, setClients] = React.useState([])
  const [batteryRooms, setBatteryRooms] = React.useState([])
  const [models, setModels] = React.useState([])
  const [message, setMessage] = React.useState({
    show: false,
    title: ""
  })

  const [params, setParams] = React.useState({
    client: null,
    branch: null,
    batteryRoom: null,
    model: null,
  })

  const [step, setStep] = React.useState(1)

  React.useEffect(() => {
    const loadData = async () => {
      const profileData = await fetchData('employee/profileInfo')

      setClients(profileData.clients)
      setModels(profileData?.batteriesModels ?? [])
      if (profileData.clients.length === 1) {
        const clientId = profileData.clients[0].id
        setStep(2)
        setParams((prev) => ({ ...prev, client: parseInt(clientId) }))
        const branchs = profileData.clients[0].branchs
        setBranchs(branchs)
        if (profileData.clients[0].branchs.length === 1) {
          const branchId = profileData.clients[0].branchs[0].id
          setParams(prev => ({ ...prev, branch: parseInt(branchId) }))
        }
      }
      setLoading(false)
    }

    loadData()
  }, [])

  React.useEffect(() => {
    if (message.show) {
      setTimeout(() => { setMessage({ show: false, title: '' }) }, 6000)
    }
  }, [message])


  const onChangeClient = (event) => {
    if (event.target.value) {
      const clientId = parseInt(event.target.value)
      const branchsFiltered = clients.find(c => c.id == clientId)?.branchs;
      setBranchs(branchsFiltered)

      if (branchsFiltered && branchsFiltered.length == 1) {
        setParams((prev) => ({ ...prev, client: clientId, branch: branchsFiltered[0].id }))
      } else {
        setParams((prev) => ({ ...prev, client: clientId }))
      }
    }
  }

  const onChangeBranch = (event) => {
    if (event.target.value) {
      const branchId = parseInt(event.target.value)
      const batteryRoomsFiltered = branchs.find(b => b.id == branchId)?.battery_rooms;
      console.log(batteryRoomsFiltered)
      setBatteryRooms(batteryRoomsFiltered)

      if (batteryRoomsFiltered && batteryRoomsFiltered.length == 1) {
        setParams((prev) => ({ ...prev, branch: branchId, batteryRoom: batteryRoomsFiltered[0].id }))
      } else {
        setParams((prev) => ({ ...prev, branch: branchId }))
      }
    }
  }

  const fetchFifo = React.useCallback(async (batteryRoomId, modelId) => {
    const fifoResponse = await fetchData(`chargeBattery/fifo/${batteryRoomId}/${modelId}`)
    setItems(fifoResponse)
  }, [])

  const onChangeBatteryRoom = (event) => {
    if (event.target.value) {
      const batteryRoomId = parseInt(event.target.value)
      setParams((prev) => ({ ...prev, batteryRoom: batteryRoomId }))
    }
  }

  const onChangeModel = (event) => {
    if (event.target.value) {
      const modelId = parseInt(event.target.value)
      setParams((prev) => ({ ...prev, model: modelId }))
      fetchFifo(params.batteryRoom, modelId)
    }
  }

  const nextStep = () => {
    if (step == 5) {
      return
    }

    if (step === 1 && params.client) {
      setStep(step + 1)
      return
    }

    if (step === 2 && params.branch) {
      setStep(step + 1)
      return
    }
    if (step === 3 && params.batteryRoom) {
      setStep(step + 1)
      return
    }
    if (step === 4 && params.model) {
      setStep(step + 1)
      return
    }
  }

  const previousStep = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  const handleGetOrderedItems = async () => {
    try {
      const newOrder = items.map((e, index) => ({ id: e.id, order: index }))
      await putData('chargeBattery/reorder', newOrder)
      setMessage({ show: true, title: 'FIFO reordenado com sucesso!' })
    } catch (error) {
      setMessage({ show: true, title: 'Erro ao reordenar FIFO!' })
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };

  const SelectClientComponent = () => (
    <Select style={{ width: '60%' }} onChange={onChangeClient} value={params.client}>
      <option>Selecionar</option>
      {clients.map(e => (
        <option value={e.id}>
          {e.name}
        </option>
      ))}
    </Select>
  )

  const SelectBranchComponent = () => (
    <Select style={{ width: '60%' }} placeholder='Selecionar' onChange={onChangeBranch} value={params.branch}>
      <option value={undefined}>Selecionar</option>
      {branchs.map(branch => (
        <option value={branch.id}>{branch.name}</option>
      ))}
    </Select>
  )

  const SelectBatteryRoom = () => (
    <Select style={{ width: '60%' }} onChange={onChangeBatteryRoom} value={params.batteryRoom}>
      <option>Selecionar</option>
      {batteryRooms.map(br => (
        <option value={br.id}>
          {br.name}
        </option>
      ))}
    </Select>
  )

  const SelectModel = () => (
    <Select style={{ width: '60%' }} onChange={onChangeModel} value={params.model}>
      <option>Selecione</option>
      {models.map(e => (
        <option value={e.id}>{e.name}</option>
      ))}
    </Select>
  )

  const steps = [
    {
      stepCount: 1,
      component: <SelectClientComponent />,
      title: "Selecionar Cliente"
    },
    {
      stepCount: 2,
      component: <SelectBranchComponent />,
      title: "Selecionar Sede"
    },
    {
      stepCount: 3,
      component: <SelectBatteryRoom />,
      title: "Selecionar Sala de Bateria"
    },
    {
      stepCount: 4,
      component: <SelectModel />,
      title: "Selecionar modelo"
    },
    {
      stepCount: 5,
      component: <ReorderFifoListComponent onSortEnd={onSortEnd} data={items ?? []} />,
      title: "Reodenar FIFO (arraste e solte itens para reordenar o fifo)"
    }
  ]

  return (
    <Container>
      <Title>Reordenar FIFO</Title>
      {items.length === 0 && loading ? (
        <Skeleton height='300' width='300' />
      ) : (
        <>
          {
            steps.filter(e => e.stepCount === step).map(item => (
              <Card style={{ width: '60%', padding: '20px', maxHeight: '80%', overflowY: 'scroll' }}>
                <CardHeader title={item.title} avatar={<Avatar>{item.stepCount}</Avatar>}>
                </CardHeader>
                <Divider />
                <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
                  {item.component}
                </CardContent>
                <CardActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Button disabled={step === 1} onClick={previousStep} > Anterior</Button>
                  {item.stepCount !== 5 ? (
                    <Button disabled={step === 5} onClick={nextStep} > Próximo</Button>
                  ) : (
                    <Button onClick={handleGetOrderedItems} > Finalizar</Button>
                  )}
                </CardActions>
              </Card>
            ))
          }
        </>
      )}
      <Snackbar
        open={message.show}
        autoHideDuration={6000}
        message={message.title}
      />
    </Container>
  );
};

export default ReorderFifo;
