import 'moment/locale/pt-br'

import {LabelDiv, Row} from './styles'
import React, {useCallback, useEffect, useState} from 'react'

import MomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'

moment.locale('pt-br')

/**
 * @author Carlos Padovezi
 * @function RowCalendarDateHour
 * @file RowCalendarDateHour.js
 * @param {String} value - Input value
 * @param {String} label - Labek of row above the input
 * @param {Boolean} isObligatory - Boolean than indicates if row is obligatory
 * @param {String} width - Component row's width
 * @param {String} height - Component row's height
 * @param {String} fontSize - Text input font size
 * @param {Function} onChange -	Function called when onChange event occours
 * @param {Boolean} autoFocus -	Boolean than indicates if auto focus input
 * @summary Function to use standard input with label
 * @summary Function to use standard select with label
 * @returns Row with Label and select
 */
export function RowCalendarDateHour(props) {
	const [date, setDate] = useState('')

	const mountDateVar = useCallback(() => {
		let myDate = props.value ? props.value : null
		let myFormat

		if (props.type === 'date') {
			myFormat = 'YYYY-MM-DD'
		} else {
			myFormat = 'YYYY-MM-DDTHH:mm:ss'
		}

		if (myDate !== null) myDate = moment(myDate).format(myFormat)

		setDate(myDate)
	}, [props.type, props.value])

	useEffect(() => mountDateVar(), [mountDateVar])

	if (!props || Object.keys(props).length === 0) {
		return <div></div>
	}

	if (!props.onChange || typeof props.onChange !== 'function') {
		return <div></div>
	}

	//Middleware between select and parent component
	function handleOnChange(event) {
		if (event.target.value) {
			const newDate = new Date(event.target.value)
			const year = newDate.getUTCFullYear()
			let month = newDate.getUTCMonth() + 1
			let day = newDate.getUTCDate()
			let hour = newDate.getHours()
			let minutes = newDate.getMinutes()
			let seconds = newDate.getSeconds()

			if (month < 10) month = '0' + month
			if (day < 10) day = '0' + day
			if (hour < 10) hour = '0' + hour
			if (minutes < 10) minutes = '0' + minutes
			if (seconds < 10) seconds = '0' + seconds

			let date

			if (props.type === 'date') {
				date = `${year}-${month}-${day}`
			} else {
				date = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
			}

			props.onChange(date)
		} else {
			props.onChange(null)
		}
	}

	//RowCalendarDateHour configuration
	let myLabel = props.label ? props.label : ''
	let isObligatory = props.isObligatory ? props.isObligatory : false
	let myWidth = props.width ? props.width : '317px'
	let myHeight = props.height ? props.height : null
	let myFontSize = props.fontSize ? props.fontSize : '12px'

	return (
		<MuiPickersUtilsProvider
			libInstance={moment}
			utils={MomentUtils}
			locale={'pt-br'}>
			<Row height={myHeight}>
				{!myLabel ? null : (
					<LabelDiv
						isObligatory={isObligatory && !props.readOnly}
						fontSize={myFontSize}>
						<label>{`${myLabel}${
							isObligatory && !props.readOnly ? ' *' : ''
						} `}</label>
					</LabelDiv>
				)}
				<TextField
					value={date}
					type={props.type ? props.type : 'datetime-local'}
					onChange={handleOnChange}
					disabled={props.readOnly}
					style={{
						width: myWidth,
					}}
				/>
			</Row>
		</MuiPickersUtilsProvider>
	)
}
