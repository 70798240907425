import {EditModalBackgroundDiv, EditModalDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

/**
 * @author Carlos Padovezi
 * @function EditBranch
 * @file BranchCrud.js
 * @summary Function to create customers
 * @returns Customers create crud page
 */
export default function EditBranch({editData, handleCancelEdit, updatePage}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [customersList, setCustomersList] = useState([])
	const [formData, setFormData] = useState({
		name: editData.name ? editData.name : '',
		address: editData.address ? editData.address : '',
		phone: editData.phone ? editData.phone : '',
		email: editData.email ? editData.email : '',
		client_id: editData.client_id ? editData.client_id : userInfos.client_id,
		editingId: editData.editingId ? editData.editingId : '',
	})

	useEffect(() => {
		//get branch list
		createFetchRequest('client/getAll', 'get', null, async ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}
			let myList = []
			if (result && result.length > 0) {
				for (let customer of result) {
					myList.push({value: customer.id, label: customer.name})
				}
			}

			setCustomersList(myList)
		})
	}, [])

	async function handleSaveEditBranch() {
		var context = {
			id: formData.editingId,
			name: formData.name,
			email: formData.email,
			address: formData.address,
			phone: formData.phone,
			client_id: formData.client_id,
		}

		await createFetchRequest(
			'branch/update',
			'put',
			context,
			({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					updatePage()
				}
			},
		)
	}

	let validButton =
		formData.name &&
		formData.address &&
		formData.phone &&
		formData.email &&
		formData.client_id

	return (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				<RowInput
					value={formData.address}
					label={dictionary.Address}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(address) => setFormData((state) => ({...state, address}))}
				/>
				<RowInput
					value={formData.email}
					label={dictionary.Email}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(email) => setFormData((state) => ({...state, email}))}
					type={'email'}
				/>
				<RowInput
					value={formData.phone}
					label={dictionary.Phone}
					isObligatory={true}
					type={'phone'}
					fontSize={'16px'}
					onChange={(phone) => setFormData((state) => ({...state, phone}))}
				/>
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={customersList}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id}))
						}
					/>
				) : null}
				<div>
					<Button
						name={'buttonConfirm'}
						onClick={handleSaveEditBranch}
						disabled={!validButton}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	)
}
