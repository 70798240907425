import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'
import {useCallback, useEffect, useState} from 'react'

import CreateChargerBrand from './ChargerBrandCreate.jsx'
import {CrudModal} from '../../components/CrudModal/index.jsx'
import EditChargerBrand from './ChargerBrandEdit.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MainContentDiv} from '../../styles/globalStyles'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

//CRUD list configuration
const columns = [{accessor: 'name', Header: dictionary.Name}]

/**
 * @author Carlos Padovezi
 * @function ChargerBrandsCrud
 * @file ChargerBrandsCrud.js
 * @summary Function to create forklift brands crud view
 * @returns Charger brands crud page
 */
function ChargerBrandsCrud({hideLoadingBlur, showLoadingBlur}) {
	const [crudSelected, changeCrudSelected] = useState('list')
	const [crudList, setCrudList] = useState([])
	const [editingChargerBrand, setEditingChargerBrand] = useState(null)

	const loadPageData = useCallback(() => {
		showLoadingBlur()

		createFetchRequest('chargerBrand/getAll', 'get', null, ({err, result}) => {
			if (err) setCrudList([])
			else if (!err && result) setCrudList(result)

			changeCrudSelected('list')
			setEditingChargerBrand(null)
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(loadPageData, [loadPageData])

	const handleOpenEditChargerBrand = (params) => {
		let rowValue = params

		const startValues = {
			name: rowValue.name,
			id: rowValue.id,
		}

		setEditingChargerBrand(startValues)
	}

	const handleCancelEdit = () => setEditingChargerBrand(null)

	const handleDeleteChargerBrand = async (params) => {
		const isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			showLoadingBlur()

			const context = {id: params.id}

			createFetchRequest(
				'chargerBrand/delete/' + params.id,
				'delete',
				context,
				({err, result}) => {
					if (err) console.log(err)

					loadPageData()
				},
			)
		}
	}

	const crudsObjPages = {
		list: {
			title: dictionary.BrandsList,
			list: {
				columns,
				rows: crudList,
			},
			onRowEditClick: handleOpenEditChargerBrand,
			onRowEditLabel: 'Editar',
			onRowEditIcon: <FontAwesomeIcon icon={faEdit} />,
			onRowDeleteClick: handleDeleteChargerBrand,
			onRowDeleteLabel: 'Excluir',
			onRowDeleteIcon: <FontAwesomeIcon icon={faTrash} />
		},
		create: {
			title: dictionary.BrandRegister,
			content: <CreateChargerBrand updatePage={loadPageData} />,
		},
	}

	const editModal = (
		<EditChargerBrand
			handleCancelEdit={handleCancelEdit}
			updatePage={loadPageData}
			editData={editingChargerBrand}
		/>
	)

	return (
		<MainContentDiv>
			<CrudModal
				crudsObj={crudsObjPages}
				crudSelected={crudSelected}
				changeCrudSelected={(newCrud) => changeCrudSelected(newCrud)}
			/>
			{editingChargerBrand === null ? null : editModal}
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(ChargerBrandsCrud)
