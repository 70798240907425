import {Button, EditModalBackgroundDiv, ViewModalDiv} from 'src/styles/globalStyles'

function InfoModal({ modal, closeModal }) {	
	return(
		<>
			{modal.show ? (
				<EditModalBackgroundDiv>
					<ViewModalDiv style={{ width: '35%'}}>
						<h1>{modal.title}</h1>
						<p>{modal.message}</p>
						{modal.component && (
							<modal.component />
						)}
						<Button style={{ height: '40px', width: 'auto', minWidth: '90px'}} onClick={closeModal}>OK</Button>
					</ViewModalDiv>
				</EditModalBackgroundDiv>
		): null}
		</>
	)
}

export default InfoModal