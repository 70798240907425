import { Container, ContentInfo, TitleHeader } from './styles'
import { CreateContentDiv, CreateFormDiv } from 'src/styles/globalStyles'
import React, { useEffect, useState } from 'react'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions/index'

import { Button } from 'src/styles/globalStyles'
import { ModalContainer } from 'src/components/CrudModal/styles'
import { RowCalendar } from 'src/components/RowCalendar/index.jsx'
import { RowInput } from 'src/components/RowInput/index.jsx'
import { RowSelect } from 'src/components/RowSelect/index.jsx'
import { RowSelectBatteryRoom } from 'src/components/RowSelectBatteryRoom'
import { RowSelectBranch } from 'src/components/RowSelectBranch'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createFetchRequest } from 'src/genericFunctions'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'
import moment from 'moment'

/**
 * @author Carlos Padovezi
 * @function CreateCharger
 * @file CreateCharger.js
 * @summary Function to create charger rooms register view
 * @returns charger rooms create create page
 */
function CreateCharger({ updatePage, showLoadingBlur, hideLoadingBlur, onChangeView, view, name, registerTitle, preview }) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [modelsList, setModelsList] = useState([])
	const [clientsList, setClientsList] = useState([])
	const [formData, setFormData] = useState({
		name: '',
		serial: '',
		manufacturing_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
		model_id: '',
		battery_room_id: '',
		client_id:
			userInfos && userInfos.profile_id === 1 ? '' : userInfos.client_id,
	})

	useEffect(() => {
		if (preview) {
			console.log(preview)
			setFormData((prev) => ({
				...prev,
				manufacturing_date: moment(new Date(preview.manufacturing_date)).format('YYYY-MM-DD HH:mm:ss'),
				model_id: preview.model_id,
				battery_room_id: preview.battery_room_id,
				client_id: preview.client_id,
				branch_id: preview.branch_id
			}))
		}
	}, [preview])

	useEffect(() => {
		showLoadingBlur()

		//get models list
		createFetchRequest('chargerModel/getAll', 'get', null, ({ err, result }) => {
			if (err) {
				console.log(err)
				return
			}

			let myList = []
			if (result && result.length > 0) {
				for (let model of result) {
					myList.push({ value: model.id, label: model.name })
				}
			}

			setModelsList(myList)

			//get client list
			createFetchRequest('client/getAll', 'get', null, ({ err, result }) => {
				if (err) {
					console.log(err)
					return
				}

				let myList = []
				if (result && result.length > 0) {
					for (let client of result) {
						myList.push({ value: client.id, label: client.name })
					}
				}

				setClientsList(myList)
				hideLoadingBlur()
			})
		})
	}, [hideLoadingBlur, showLoadingBlur])

	function handleSaveNewCharger() {
		const context = {
			name: formData.name,
			serial: formData.serial,
			manufacturing_date: formData.manufacturing_date,
			model_id: formData.model_id,
			battery_room_id: formData.battery_room_id,
		}

		createFetchRequest('charger/create', 'post', context, ({ err, result }) => {
			if (err) {
				console.log(err)
			} else {
				//Reset inputs values
				updatePage()
			}
		})
	}

	const validButton =
		formData.name &&
		formData.serial &&
		formData.manufacturing_date &&
		formData.model_id &&
		formData.battery_room_id

	return (
		<CreateContentDiv>
			<ModalContainer>
				{(onChangeView && typeof (onChangeView) === 'function') && (
					<Container>
						<ContentInfo>
							<div style={{ display: 'flex' }}>
								{(onChangeView && typeof (onChangeView) === 'function') && (
									<>
										<TitleHeader isSelected={view && view === 'table'} onClick={() => onChangeView('table')}>{name}</TitleHeader>
										<TitleHeader isSelected={view && view === 'register'} onClick={() => onChangeView('register')}>{registerTitle}</TitleHeader>
									</>
								)}

							</div>
						</ContentInfo>
					</Container>
				)}
				{clientsList.length > 0 && modelsList.length > 0 && (
					<CreateFormDiv>
						<RowInput
							value={formData.name}
							label={dictionary.Name}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(name) => setFormData((state) => ({ ...state, name }))}
							autoFocus={true}
						/>
						<RowInput
							value={formData.serial}
							label={dictionary.Serial}
							maxLength={15}
							minLength={10}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(serial) => setFormData((state) => ({ ...state, serial }))}
						/>
						<RowCalendar
							value={formData.manufacturing_date}
							label={dictionary.ManufacturingDate}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(manufacturing_date) =>
								setFormData((state) => ({ ...state, manufacturing_date }))
							}
							stringAutoComplite={`00:00:00`}
						/>
						<RowSelect
							value={formData.model_id}
							optionsList={modelsList}
							label={dictionary.Model}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(model_id) =>
								setFormData((state) => ({ ...state, model_id }))
							}
						/>
						{userInfos && userInfos.profile_id === 1 ? (
							<RowSelect
								value={formData.client_id}
								label={dictionary.Customer}
								optionsList={clientsList}
								isObligatory={true}
								fontSize={'16px'}
								onChange={(client_id) =>
									setFormData((state) => ({ ...state, client_id }))
								}
							/>
						) : null}
						<RowSelectBranch
							value={formData.branch_id}
							clientId={formData.client_id}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(branch_id) =>
								setFormData((state) => ({ ...state, branch_id }))
							}
						/>
						<RowSelectBatteryRoom
							value={formData.battery_room_id}
							branchId={formData.branch_id}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(battery_room_id) =>
								setFormData((state) => ({ ...state, battery_room_id }))
							}
						/>
						<div>
							<Button
								name={'buttonConfirm'}
								disabled={!validButton}
								onClick={handleSaveNewCharger}
								style={{ marginTop: '10px', marginRight: '3px', width: '100px' }}>
								<b>{dictionary.Save}</b>
							</Button>
							<Button
								name={'buttonCancel'}
								disabled={false}
								onClick={updatePage}
								style={{ marginTop: '10px', marginLeft: '3px', width: '100px' }}>
								<b> {dictionary.Cancel}</b>
							</Button>
						</div>
					</CreateFormDiv>
				)}
			</ModalContainer>

		</CreateContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(CreateCharger)
