import styled from 'styled-components'

export const WindowDiv = styled.div`
	display: ${(props) => (props.isShowing === true ? 'flex' : 'none')};

	height: 100%;
	width: 100%;

	position: absolute;
	z-index: 900;

	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: rgb(0, 0, 0, 0.75);
`

export const LoadingDiv = styled.div`
	width: 100%;
	line-height: 50px;
	font-size: 40px;
	color: #fff;

	z-index: 301;

	display: flex;
	justify-content: center;
	align-items: center;
`
