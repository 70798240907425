import {PURPLE, PURPLE_MEDIUM} from 'src/styles/colors'
import styled from 'styled-components'

export const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
export const Container = styled(Content)`
	text-align: center;
	flex-direction: column;
	height: 100%;
	overflow: none;
	width: 100%;
	overflow-x: auto;
`

export const Table = styled.table`
	white-space: nowrap;
	border: none;
	border-collapse: separate;
	border-spacing: 1px;
	border-color: #fff;
	table-layout: fixed;
	border-radius: 8px;
	width: auto;
`

export const THeader = styled.thead``

export const TBody = styled.tbody`
	/* max-height: 80vh; */
	overflow: auto;
`

export const THead = styled.th`
	z-index: 150;
	position: sticky;
	top: 0;
	padding: 8px;
	font-size: 14px;
	background-color: ${(props) => (props.color ? props.color : '#f9f9f9')};
	text-align: center;
	height: 40px;
`

export const TRow = styled.tr`
	padding: 0px;
`

export const TCell = styled.td`
	background-color: ${(props) => (props.color ? props.color : '#fdfdfd')};
	font-size: 12px;
	font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
	padding: 8px;
	margin: 0;
	text-align: center;
`

export const Input = styled.input`
	padding: 5px;
	margin: 8px;
	border: 1px solid ${(props) => (props.color ? props.color : PURPLE)};
	color: ${(props) => (props.color ? props.color : PURPLE)};
	border-radius: 8px;
	min-width: 100px;
`
export const Select = styled.select`
	padding: 5px;
	margin: 8px;
	border: 1px solid ${(props) => (props.color ? props.color : PURPLE)};
	color: ${(props) => (props.color ? props.color : PURPLE)};
	border-radius: 8px;
	min-width: 100px;
`

export const Options = styled.option`
	padding: 5px;
	margin: 8px;
	border: 1px solid ${PURPLE};
	color: ${PURPLE};
	border-radius: 8px;
	min-width: 100px;
`
export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: top;
	width: 100%;
`

export const Line = styled.div`
	/* max-width: calc((100% / 5) - 160px); */
	background-color: ${(props) => (props.isTrue ? PURPLE : '#3d3d3d')};
	height: 4px;
	width: 80%;
	min-width: 100px;
	margin-right: 3px;
	margin-left: 3px;
`

export const Step = styled.div`
	color: ${(props) => (props.isTrue ? PURPLE : '#3d3d3d')};
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 1px solid ${(props) => (props.isTrue ? PURPLE : '#3d3d3d')};
	font-weight: bold;
	justify-content: center;
	display: flex;
	align-items: center;
`
export const ButtonStep = styled.button`
	background-color: ${(props) => (props.disabled ? '#3d3d3d' : PURPLE)};
	padding: 10px;
	color: #fff;
	border: none;
	border-radius: 8px;
	margin-right: 10px;
	margin-left: 10px;
	cursor: pointer;
	:hover {
		background-color: ${(props) => (props.disabled ? '#3d3d3d' : '#4d036a')};
	}
`
export const ColumnStep = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const Title = styled.h1`
	font-weight: bold;
	font-size: 22px;
	line-height: 20px;
`
export const Flex = styled.div`
	display: flex;
`

export const TableContent = styled.div`
	height: calc(100vh - 180px);
	overflow-y: scroll;
	margin: 20px;
	border-radius: 8px;
	border: 1px solid #dbdbdb;
	box-shadow: 8px 8px 4px ${PURPLE_MEDIUM};
	width: calc(100% - 435px);
	min-width: 800px;
	overflow: auto;
	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px grey;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb {
		background-color: ${PURPLE};
		border-radius: 10px;
	}
`
