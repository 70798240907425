import {EditModalBackgroundDiv, EditModalDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'

import {Button} from '../../styles/globalStyles'
import {RowCalendar} from '../../components/RowCalendar/index.jsx'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {RowSelectBranch} from 'src/components/RowSelectBranch'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

/**
 * @author Carlos Padovezi
 * @function EditBattery
 * @file BatteriesCreate.js
 * @summary Function to create Battery rooms register view
 * @returns Battery rooms create create page
 */
function EditBattery({
	editData,
	handleCancelEdit,
	updatePage,
	showLoadingBlur,
	hideLoadingBlur,
	show,
}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [formData, setFormData] = useState(null)
	const [clientsList, setClientList] = useState([])
	const [modelsList, setModelsList] = useState([])
	const [modelsListOriginal, setModelListOriginal] = useState([])
	const [forkligtModelsList, setForkliftModelsList] = useState([])

	useEffect(() => {
		if(!show) {
			return
		}
		setFormData({
			name: editData?.name,
			serial: editData?.serial,
			manufacturing_date: editData?.manufacturing_date_text,
			registered_cycles: editData?.registered_cycles,
			estimated_cycles: editData?.estimated_cycles,
			remaining_cycles: editData?.remaining_cycles,
			forklift_model_id: editData?.forklift_model_id,
			model_id: editData?.model_id,
			client_id: editData?.client_id ? editData?.client_id : userInfos.client_id,
			branch_id: editData?.branch_id,
			id: editData?.id,
		})

		showLoadingBlur()

		//get branch list
		createFetchRequest('client/getAll', 'get', null, async ({err, result}) => {
			if (err) {
				console.log(err)
				hideLoadingBlur()
				return
			}

			let myList = []
			if (result && result.length > 0) {
				for (let client of result) {
					myList.push({value: client.id, label: client.name})
				}
			}

			setClientList(myList)

			//get battery model list
			createFetchRequest(
				'batteryModel/getAll',
				'get',
				null,
				async ({err, result}) => {
					if (err) {
						console.log(err)
						hideLoadingBlur()
						return
					}
					setModelListOriginal(result)

					let myList = []
					if (result && result.length > 0) {
						for (let model of result) {
							myList.push({value: model.id, label: model.name})
						}
					}

					setModelsList(myList)

					//get forklifts models list
					createFetchRequest(
						'forkliftModel/getAll',
						'get',
						null,
						async ({err, result}) => {
							if (err) {
								console.log(err)
								hideLoadingBlur()
								return
							}

							let myList = []
							if (result && result.length > 0) {
								for (let forklift of result) {
									myList.push({value: forklift.id, label: forklift.name})
								}
							}

							setForkliftModelsList(myList)
							hideLoadingBlur()
						},
					)
				},
			)
		})
	}, [editData, hideLoadingBlur, show, showLoadingBlur, userInfos.client_id])

	function changeBatteryEstimatedCycles(value) {
		var model = modelsListOriginal.find((e) => e.id === parseInt(value))
		setFormData((state) => ({
			...state,
			model_id: value,
			estimated_cycles: model.estimated_manufactorer_cycles,
		}))
	}

	async function handleSaveEditBattery() {
		let context = {
			id: formData.id,
			name: formData.name,
			serial: formData.serial,
			model_id: formData.model_id,
			manufacturing_date: formData.manufacturing_date,
			registered_cycles: formData.registered_cycles,
			estimated_cycles: formData.estimated_cycles,
			remaining_cycles: formData.remaining_cycles,
			forklift_model_id: formData.forklift_model_id,
			branch_id: formData.branch_id,
		}

		await createFetchRequest(
			'battery/update',
			'put',
			context,
			async ({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					await updatePage()
				}
			},
		)
	}

	let validButton =
		formData &&
		formData.name &&
		formData.serial &&
		formData.manufacturing_date &&
		formData.forklift_model_id &&
		formData.model_id &&
		formData.branch_id

	return (show && formData) ? (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				<RowInput
					value={formData.serial}
					label={dictionary.Serial}
					maxLength={10}
					minLength={10}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(serial) => setFormData((state) => ({...state, serial}))}
				/>
				<RowCalendar
					value={formData.manufacturing_date}
					label={dictionary.ManufacturingDate}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(manufacturing_date) =>
						setFormData((state) => ({...state, manufacturing_date}))
					}
				/>
				<RowInput
					value={formData.registered_cycles}
					label={dictionary.RegistredCycles}
					type={'number'}
					readOnly={true}
					fontSize={'16px'}
					onChange={(registered_cycles) =>
						setFormData((state) => ({...state, registered_cycles}))
					}
				/>
				<RowInput
					value={formData.estimated_cycles}
					label={dictionary.EstimatedCycles}
					type={'number'}
					readOnly={true}
					fontSize={'16px'}
					onChange={(estimated_cycles) =>
						setFormData((state) => ({...state, estimated_cycles}))
					}
				/>
				<RowInput
					value={formData.remaining_cycles}
					label={dictionary.RemainingCycles}
					type={'number'}
					readOnly={true}
					fontSize={'16px'}
					onChange={(remaining_cycles) =>
						setFormData((state) => ({...state, remaining_cycles}))
					}
				/>
				<RowSelect
					value={formData.model_id}
					optionsList={modelsList}
					label={dictionary.Model}
					isObligatory={true}
					fontSize={'16px'}
					onChange={changeBatteryEstimatedCycles}
				/>
				<RowSelect
					value={formData.forklift_model_id}
					optionsList={forkligtModelsList}
					label={dictionary.ForkliftModel}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(forklift_model_id) =>
						setFormData((state) => ({...state, forklift_model_id}))
					}
				/>
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={clientsList}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id}))
						}
					/>
				) : null}
				{formData.branch_id && (
					<RowSelectBranch
						clientId={formData.client_id}
						value={formData.branch_id}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(branch_id) =>
							setFormData((state) => ({...state, branch_id}))
						}
					/>
				)}
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveEditBattery}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	) : null
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(EditBattery)
