import { Column, TableContent, Title } from '../styles'
import React from 'react'
import Spinner from 'src/components/Spinner'

const ImportInfo = ({ customer }) => {
	const [data, setData] = React.useState()

	React.useEffect(() => {
		setData(customer)
	}, [customer])

	return  (
		<Column>

			{!data ? (
        <TableContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        	<Spinner />
        </TableContent>
      ) : (
				<>
					<Title>Informações da Importação</Title>
					<TableContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
						<Column>
							<h2>Total de máquinas: {data.forklifts.length}</h2>
							<h2>Total de baterias: {data.batteries.length}</h2>						
							<h2>Total de carregadores: {data.chargers.length}</h2>
							<h2>Total de colaboradores: {data.employees.length}</h2>
						</Column>
					</TableContent>
				</>
      )}
		</Column>
	)
}

export default ImportInfo