import {Button, CreateFormDiv} from 'src/styles/globalStyles'
import React, {useState} from 'react'
import {RowFileInput} from '../../components/RowFileInput/index.jsx'
import {RowInput} from '../../components/RowInput/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function CreateCustomer
 * @file CustomersCrud.js
 * @summary Function to create customers
 * @returns Customers create crud page
 */
export default function CreateCustomer({updatePage, onChangeView}) {
	const [formData, setformData] = useState({
		name: '',
		cnpj: '',
		address: '',
		phone: '',
		email: '',
		image: '',
	})

	function handleSaveNewCustomer() {
		const context = {
			name: formData.name,
			cnpj: formData.cnpj,
			address: formData.address,
			phone: formData.phone,
			email: formData.email,
		}

		createFetchRequest('client/create', 'post', context, ({err, result}) => {
			if (err) {
				console.log(err)
			} else {
				if (formData.image || formData.image === null) {
					//Send image
					createFetchRequest(
						`/client/image/${result.id}`,
						'put',
						formData.image,
						({err, result}) => {
							if (err) {
								console.log(err)
							} else {
								//Reset inputs values
								updatePage()
								onChangeView('list')
							}
						},
					)
				} else {
					//Reset inputs values
					updatePage()
					onChangeView('list')
				}
			}
		})
	}

	const validButton =
		formData.name &&
		formData.cnpj &&
		formData.address &&
		formData.phone &&
		formData.email

	return (
		<>
			<CreateFormDiv>				
				<RowInput
					value={formData.cnpj}
					label={dictionary.CNPJ}
					type={'cnpj'}
					fontSize={'16px'}
					onChange={(cnpj) => setformData((state) => ({...state, cnpj}))}
					isObligatory={true}
					autoFocus={true}
				/>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setformData((state) => ({...state, name}))}
				/>
				<RowInput
					value={formData.address}
					label={dictionary.Address}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(address) => setformData((state) => ({...state, address}))}
				/>
				<RowInput
					value={formData.email}
					label={dictionary.Email}
					type={'email'}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(email) => setformData((state) => ({...state, email}))}
				/>
				<RowInput
					value={formData.phone}
					label={dictionary.Phone}
					type={'phone'}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(phone) => setformData((state) => ({...state, phone}))}
				/>
				<RowFileInput
					value={formData.image}
					label={dictionary.clientImage}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(image) => setformData((state) => ({...state, image}))}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveNewCustomer}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b> {dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={() => onChangeView('list')}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b> {dictionary.Cancel}</b>
					</Button>
				</div>
			</CreateFormDiv>
		</>
	)
}
