import {
	ButtonClose,
	CheckCard,
	Column,
	Content,
	Description,
	DescriptionTitle,
	Header,
	IconCard,
	Line,
	NameEquipment,
	NokIcon,
	RowIcon,
	TBody,
	Table,
} from './styles'
import {EditModalBackgroundDiv, ViewModalDiv} from 'src/styles/globalStyles'
import {createFetchRequest, formatFullDateBR} from 'src/genericFunctions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

const ViewLastChecklistModal = ({
	show,
	equipmentId,
	equipmentType,
	onClose,
}) => {
	const [checklist, setChecklist] = React.useState(null)

	React.useEffect(() => {
		const getChecklist = async () => {
			createFetchRequest(
				`/checklist/lastChecklist/${equipmentType}/${equipmentId}`,
				'get',
				null,
				({err, result}) => {
					if (!err && result) {
						console.log(result)
						setChecklist(result)
					}
				},
			)
		}

		if (equipmentId > 0 && equipmentType.length > 0) {
			getChecklist()
		}
	}, [equipmentId, equipmentType])

	const close = React.useCallback(() => {
		setChecklist(null)
		onClose()
	}, [onClose])

	return (
		<>
			{show ? (
				<EditModalBackgroundDiv>
					<ViewModalDiv
						style={{justifyContent: 'flex-start', padding: 0, width: 'auto'}}>
						{checklist ? (
							<Column>
								<Header>
									Último Checklist realizado {formatFullDateBR(checklist.date)}
								</Header>
								<Content>
									<NameEquipment>
										{checklist.equipmentType} {checklist.equipmentName}
									</NameEquipment>
									<Line />
									<Table>
										<TBody>
											<RowIcon>
												<IconCard color="#25a18e">
													<FontAwesomeIcon icon={faCheck} />
												</IconCard>
												<IconCard color="#c1292e">
													<NokIcon>X</NokIcon>
												</IconCard>
												<IconCard>
													<NokIcon>NA</NokIcon>
												</IconCard>
												<DescriptionTitle>Descrição</DescriptionTitle>
											</RowIcon>
											{checklist.inspection.map((check) => (
												<RowIcon>
													<CheckCard
														color={check.value === true ? '#25a18e' : ''}
													/>
													<CheckCard
														color={check.value === false ? '#25a18e' : ''}
													/>
													<CheckCard
														color={check.value === null ? '#25a18e' : ''}
													/>
													<Description>{check.label}</Description>
												</RowIcon>
											))}
										</TBody>
									</Table>
									<ButtonClose onClick={close}>Fechar</ButtonClose>
								</Content>
							</Column>
						) : (
							<>
								<Header>Não há informações sobre o equipamento</Header>
								<Content>
									<NameEquipment>
										Não foram encontrados registros de checklist deste
										equipamento.
									</NameEquipment>
									<ButtonClose onClick={close}>Fechar</ButtonClose>
								</Content>
							</>
						)}
					</ViewModalDiv>
				</EditModalBackgroundDiv>
			) : null}
		</>
	)
}

export default ViewLastChecklistModal
