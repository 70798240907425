import { Container, ContentInfo, TitleHeader } from './styles'
import { CreateContentDiv, CreateFormDiv } from '../../styles/globalStyles'
import React, { useEffect, useState } from 'react'

import { Button } from '../../styles/globalStyles'
import { ModalContainer } from 'src/components/CrudModal/styles'
import { RowCalendar } from '../../components/RowCalendar/index.jsx'
import { RowInput } from '../../components/RowInput/index.jsx'
import { RowSelect } from '../../components/RowSelect/index.jsx'
import { RowSelectBranch } from 'src/components/RowSelectBranch'
import { createFetchRequest } from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'
import moment from 'moment'

/**
 * @author Carlos Padovezi
 * @function CreateForklift
 * @file CreateForklift.js
 * @summary Function to create forklift rooms register view
 * @returns forklift rooms create create page
 */
export default function CreateForklift({
	updatePage,
	view,
	name,
	registerTitle,
	onChangeView,
	preview
}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [forkliftModel, setforkliftModelList] = useState([])
	const [clientsList, setClientList] = useState([])
	const [formData, setFormData] = useState({
		name: '',
		serial: '',
		manufacturing_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
		model_id: '',
		hour_meter: '',
		branch_id: '',
		client_id:
			userInfos && userInfos.profile_id === 1 ? '' : userInfos.client_id,
	})

	useEffect(() => {
		if (preview) {
			setFormData((prev => ({
				...prev,
				manufacturing_date: moment(new Date(preview.manufacturing_date)).format('YYYY-MM-DD HH:mm:ss'),
				branch_id: preview.branch_id,
				client_id: preview.client_id,
				model_id: preview.model_id,
				hour_meter: preview.hour_meter
			})))
		}
	}, [preview])

	useEffect(() => {
		//get branch list
		createFetchRequest('client/getAll', 'get', null, async ({ err, result }) => {
			if (err) {
				console.log(err)
				return
			}
			let myList = []
			if (result && result.length > 0) {
				for (let client of result) {
					myList.push({ value: client.id, label: client.name })
				}
			}

			setClientList(myList)

			//get forklift list
			createFetchRequest(
				'forkliftModel/getAll',
				'get',
				null,
				async ({ err, result }) => {
					if (err) {
						console.log(err)
						return
					}
					let myList = []
					if (result && result.length > 0) {
						for (let forklift of result) {
							myList.push({ value: forklift.id, label: forklift.name })
						}
					}

					setforkliftModelList(myList)
				},
			)
		})
	}, [])

	async function handleSaveNewForklift() {
		const context = {
			name: formData.name,
			serial: formData.serial,
			manufacturing_date: formData.manufacturing_date,
			model_id: formData.model_id,
			hour_meter: formData.hour_meter,
			branch_id: formData.branch_id,
		}

		createFetchRequest(
			'forklift/create',
			'post',
			context,
			async ({ err, result }) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					await updatePage()
				}
			},
		)
	}

	let validButton =
		formData.name &&
		formData.serial &&
		formData.manufacturing_date &&
		formData.model_id &&
		formData.hour_meter &&
		formData.branch_id

	return (
		<CreateContentDiv>
			<ModalContainer>
				{onChangeView && typeof onChangeView === 'function' && (
					<Container>
						<ContentInfo>
							<div style={{ display: 'flex' }}>
								{onChangeView && typeof onChangeView === 'function' && (
									<>
										<TitleHeader
											isSelected={view && view === 'table'}
											onClick={() => onChangeView('table')}>
											{name}
										</TitleHeader>
										<TitleHeader
											isSelected={view && view === 'register'}
											onClick={() => onChangeView('register')}>
											{registerTitle}
										</TitleHeader>
									</>
								)}
							</div>
						</ContentInfo>
					</Container>
				)}
				{clientsList.length > 0 && forkliftModel.length > 0 && (
					<CreateFormDiv>
						<RowInput
							value={formData.name}
							label={dictionary.Name}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(name) => setFormData((state) => ({ ...state, name }))}
							autoFocus={true}
						/>
						<RowInput
							value={formData.serial}
							label={dictionary.Serial}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(serial) => setFormData((state) => ({ ...state, serial }))}
							maxLength={15}
							minLength={10}
						/>
						<RowCalendar
							value={formData.manufacturing_date}
							label={dictionary.ManufacturingDate}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(manufacturing_date) =>
								setFormData((state) => ({ ...state, manufacturing_date }))
							}
							stringAutoComplite={`00:00:00`}
						/>
						<RowSelect
							value={formData.model_id}
							optionsList={forkliftModel}
							label={dictionary.Model}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(model_id) =>
								setFormData((state) => ({ ...state, model_id }))
							}
						/>
						<RowInput
							value={formData.hour_meter}
							label={dictionary.HourMeter}
							isObligatory={true}
							type={'number'}
							fontSize={'16px'}
							onChange={(hour_meter) =>
								setFormData((state) => ({ ...state, hour_meter }))
							}
							maxLength={9}
							minLength={9}
						/>
						{userInfos && userInfos.profile_id === 1 ? (
							<RowSelect
								value={formData.client_id}
								label={dictionary.Customer}
								optionsList={clientsList}
								isObligatory={true}
								fontSize={'16px'}
								onChange={(client_id) =>
									setFormData((state) => ({ ...state, client_id }))
								}
							/>
						) : null}
						<RowSelectBranch
							clientId={formData.client_id}
							value={formData.branch_id}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(branch_id) =>
								setFormData((state) => ({ ...state, branch_id }))
							}
						/>
						<div>
							<Button
								name={'buttonConfirm'}
								disabled={!validButton}
								onClick={handleSaveNewForklift}
								style={{ marginTop: '10px', marginRight: '3px', width: '100px' }}>
								<b>{dictionary.Save}</b>
							</Button>
							<Button
								name={'buttonCancel'}
								disabled={false}
								onClick={updatePage}
								style={{ marginTop: '10px', marginLeft: '3px', width: '100px' }}>
								<b> {dictionary.Cancel}</b>
							</Button>
						</div>
					</CreateFormDiv>
				)}
			</ModalContainer>
		</CreateContentDiv>
	)
}
