import {FlexRow} from 'src/styles/globalStyles'
import {PURPLE} from 'src/styles/colors'
import styled from 'styled-components'

export const Row = styled(FlexRow)`
	border-bottom: 1px solid #333;
	padding: 6px;
`
export const TableContainer = styled.div`
	margin: 10px 70px 70px;
	box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
`

export const Table = styled.table`
	border-radius: 5px;
	font-size: 12px;
	font-weight: normal;
	border: none;
	border-collapse: collapse;
	width: 80%;
	max-width: 100%;
	white-space: nowrap;
`

export const THeader = styled.thead`
	background-color: ${PURPLE};
`

export const THead = styled.th`
	z-index: 150;
	position: sticky;
	top: 0;
	padding: 8px;
	font-size: 14px;
	background-color: ${PURPLE};
	color: #fff;
	text-align: center;
`
export const TBody = styled.tbody``

export const TRow = styled.tr`
	padding: 0px;
`

export const TCell = styled.td`
	background-color: ${(props) => (props.color ? props.color : '#fdfdfd')};
	font-size: 12px;
	font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
	padding: 8px;
	margin: 0;
	text-align: center;
`
