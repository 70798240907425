import { Option, Select } from './styles'
import React from 'react'

const AlertFilter = ({ value, onChange }) => {
	return (
		<Select onChange={onChange} value={value}>
			<Option value="undefined">Todos</Option>
			<Option value="damaged">Avaria</Option>
			<Option value="maintenance">Manutenção</Option>
		</Select>
	)
}

export default AlertFilter