import 'moment/locale/pt-br'

import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import {LabelDiv, Row} from './styles'
import React, {useCallback, useEffect, useState} from 'react'

import {GRAY_DARK} from '../../styles/colors'
import MomentUtils from '@date-io/moment'
import dictionary from 'src/utils/dictionary'
import moment from 'moment'

moment.locale('pt-br')

/**
 * @author Carlos Padovezi
 * @function CustomersCrud
 * @file CustomersCrud.js
 * @param {String} value - Input value
 * @param {String} label - Labek of row above the input
 * @param {Boolean} isObligatory - Boolean than indicates if row is obligatory
 * @param {String} width - Component row's width
 * @param {String} height - Component row's height
 * @param {String} fontSize - Text input font size
 * @param {Function} onChange -	Function called when onChange event occours
 * @param {Boolean} autoFocus -	Boolean than indicates if auto focus input
 * @summary Function to use standard input with label
 * @summary Function to use standard select with label
 * @returns Row with Label and select
 */
export function RowCalendar(props) {
	const [date, setDate] = useState('')

	const mountDateVar = useCallback(() => {
		let myDate = props.value ? props.value : new Date()

		myDate = moment(myDate).format('YYYY-MM-DD HH:mm:ss')

		setDate(myDate)
	}, [props.value])

	useEffect(() => mountDateVar(), [mountDateVar])

	if (!props || Object.keys(props).length === 0) {
		return <div></div>
	}

	if (!props.onChange || typeof props.onChange !== 'function') {
		return <div></div>
	}

	//Middleware between select and parent component
	function handleOnChange(event) {
		let newDate = new Date(event._d)

		let newYear = newDate.getFullYear()
		let newMonth = newDate.getMonth() + 1

		let year = newYear
		let month = parseInt(newMonth) < 10 ? `0${newMonth}` : newMonth
		let day = '01'

		let date = `${year}-${month}-${day} 00:00:00`

		setDate(date)
		props.onChange(date)
	}

	//RowCalendar configuration
	let myLabel = props.label ? props.label : ''
	let isObligatory = props.isObligatory ? props.isObligatory : false
	let myWidth = props.width ? props.width : '317px'
	let myHeight = props.height ? props.height : null
	let myFontSize = props.fontSize ? props.fontSize : '12px'

	return (
		<MuiPickersUtilsProvider
			libInstance={moment}
			utils={MomentUtils}
			locale={'pt-br'}>
			<Row height={myHeight}>
				<LabelDiv isObligatory={isObligatory} fontSize={myFontSize}>
					<label>{`${myLabel}${isObligatory ? ' *' : ''} `}</label>
				</LabelDiv>
				<DatePicker
					value={date}
					onChange={handleOnChange}
					//picker type
					views={['year', 'month']}
					minDate={new Date('1900-01-01')}
					//controllers
					invalidLabel={''}
					invalidDateMessage={dictionary.invalidDate}
					disableFuture={true}
					autoOk={true}
					//Styles
					style={{
						width: props.width ? props.width : myWidth,
						height: props.height ? props.height : '30px',
						color: GRAY_DARK,
						borderColor: GRAY_DARK,
						outline: 'none',
					}}
				/>
			</Row>
		</MuiPickersUtilsProvider>
	)
}
