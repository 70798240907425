import {GRAY_DARK} from '../../styles/colors'
import styled from 'styled-components'

export const Row = styled.div`
	height: ${(props) => (props.height ? props.height : '100px')};
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	margin: 5px;
`

export const LabelDiv = styled.div`
	color: ${GRAY_DARK};
	font-weight: ${(props) => (props.isObligatory ? 600 : 0)};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
	margin-bottom: 5px;
	margin-left: 15px;
`