import { Button, CreateFormDiv } from 'src/styles/globalStyles'
import {
	ButtonChecklist,
	FormContent,
	InputContainer,
	InputLabel,
	InputLabelError,
	InputValueToken,
	IsActive,
	IsDisabled,
	Label,
	Row,
	Select,
} from './styles'
import InfoModal from 'src/components/InfoModal'
import React from 'react'
import Spinner from 'src/components/Spinner'
import { createFetchRequest } from 'src/genericFunctions'

const CustomerConfig = ({ _client, _cancel, updatePage, onChangeView }) => {
	const [client, setClient] = React.useState(null)
	const [checklist, setChecklist] = React.useState([])
	const [typeEquipment, setTypeEquipment] = React.useState('battery')
	const [clientChecklist, setClientChecklist] = React.useState([])
	const [infoModal, setInfoModal] = React.useState({
		show: false,
		message: '',
		title: '',
	})

	const [errors, setErrors] = React.useState({
		mobile_token_value: '',
		web_token_value: '',
	})

	const getChecklist = React.useCallback(() => {
		const url = `/checklist/client/${typeEquipment}/${_client.id}`

		createFetchRequest(url, 'get', null, ({ err, result }) => {
			if (!err) {
				setChecklist(result.checklist)
				setClientChecklist(result.clientChecklist)
			}
		})
	}, [_client.id, typeEquipment])

	const onUpdateConfig = React.useCallback(() => {
		const context = {
			web_token: client.web_token_value.concat(client.web_token_type),
			mobile_token: client.mobile_token_value.concat(client.mobile_token_type),
		}

		createFetchRequest(
			`client/updateTokenConfig/${client.id}`,
			'put',
			context,
			({ err, result }) => {
				if (err) {
					console.log(err)
				} else {
					createFetchRequest(
						`checklist/client/${typeEquipment}/${client.id}`,
						'put',
						clientChecklist,
						({ err, result }) => {
							if (!err) {
								setInfoModal({
									message: 'Configuração editada com sucesso!',
									title: 'OK',
									show: true,
								})
							}
						},
					)
				}
			},
		)
	}, [client, typeEquipment, clientChecklist])

	const onChange = (key, value) => {
		if ((key === 'mobile_token_value') | 'web_token_value') {
			if (!Number.isInteger(parseFloat(value))) {
				setErrors({ ...errors, [key]: 'O valor precisa ser inteiro' })
			} else {
				setErrors({ ...errors, [key]: '' })
			}
		}
		setClient({ ...client, [key]: value })
	}

	const onCloseModal = React.useCallback(() => {
		updatePage()
		setInfoModal({
			message: '',
			title: '',
			show: false,
		})
		onChangeView('list')
	}, [onChangeView, updatePage])

	const onChangeChecklist = React.useCallback(
		(id, value) => {
			if (value === true) {
				const exist = clientChecklist.find((e) => e.check_id === id)

				if (!exist) {
					setClientChecklist((prev) =>
						prev.concat([{ check_id: id, client_id: _client.id }]),
					)
				}
			} else {
				setClientChecklist((prev) => prev.filter((e) => e.check_id !== id))
			}
		},
		[_client.id, clientChecklist],
	)

	const onChangeTypeEquipment = (value) => {
		setTypeEquipment(value)
	}

	React.useEffect(() => {
		let cli = _client
		let configWebToken = cli.web_token_expiration
		let webValueToken = configWebToken.replace(/\D/gim, '')
		let webValueType = configWebToken.replace(webValueToken, '')

		let configMobileToken = cli.mobile_token_expiration
		let mobileValueToken = configMobileToken.replace(/\D/gim, '')
		let mobileValueType = configMobileToken.replace(mobileValueToken, '')

		cli.web_token_value = webValueToken
		cli.web_token_type = webValueType
		cli.mobile_token_value = mobileValueToken
		cli.mobile_token_type = mobileValueType
		setClient(cli)
		getChecklist()
	}, [_client, getChecklist])

	return (
		<CreateFormDiv>
			{!client ? (
				<Spinner />
			) : (
				<>
					<FormContent>
						<h1>Configuração Cliente - {client.name}</h1>
						<Row>
							<InputContainer>
								<InputLabel>Configuração Token Aplicativo</InputLabel>
								<Row>
									<InputValueToken
										defaultValue={client.mobile_token_value}
										onChange={(event) =>
											onChange('mobile_token_value', event.target.value)
										}
									/>
									<Select
										defaultValue={client.mobile_token_type}
										onChange={(event) =>
											onChange('mobile_token_type', event.target.value)
										}>
										<option value="h">Hora(s)</option>
										<option value="d">Dia(s)</option>
									</Select>
								</Row>
								<InputLabelError>{errors.mobile_token_value}</InputLabelError>
							</InputContainer>
							<InputContainer>
								<InputLabel>Configuração Token Web</InputLabel>
								<Row>
									<InputValueToken
										defaultValue={client.web_token_value}
										onChange={(event) =>
											onChange('web_token_value', event.target.value)
										}
									/>
									<Select
										defaultValue={client.web_token_type}
										onChange={(event) =>
											onChange('web_token_type', event.target.value)
										}>
										<option value="h">Hora(s)</option>
										<option value="d">Dia(s)</option>
									</Select>
								</Row>
							</InputContainer>
						</Row>
						<InputContainer>
							<h3>Checklist</h3>
							<Row>
								<ButtonChecklist
									active={typeEquipment === 'battery'}
									onClick={() => onChangeTypeEquipment('battery')}>
									Bateria
								</ButtonChecklist>
								<ButtonChecklist
									active={typeEquipment === 'forklift'}
									onClick={() => onChangeTypeEquipment('forklift')}>
									Máquina
								</ButtonChecklist>
								<ButtonChecklist
									active={typeEquipment === 'charger'}
									onClick={() => onChangeTypeEquipment('charger')}>
									Carregador
								</ButtonChecklist>
							</Row>
							{checklist.map((row, index) => (
								<Row key={index}>
									<Label>{row.description}</Label>
									<IsActive
										active={
											clientChecklist.findIndex((e) => e.check_id === row.id) >=
											0
										}
										onClick={() => onChangeChecklist(row.id, true)}>
										Sim
									</IsActive>
									<IsDisabled
										active={
											clientChecklist.findIndex((e) => e.check_id === row.id) <
											0
										}
										onClick={() => onChangeChecklist(row.id, false)}>
										Não
									</IsDisabled>
								</Row>
							))}
						</InputContainer>
						<Row>
							<Button
								style={{
									margin: '4px',
									padding: '10px 15px',
									height: 'auto',
									width: 'auto',
									borderRadius: '4px',
								}}
								onClick={onUpdateConfig}
								disabled={
									errors.mobile_token_value.length > 0 ||
									errors.web_token_value.length > 0
								}>
								Editar
							</Button>
							<Button
								style={{
									margin: '4px',
									padding: '10px 15px',
									height: 'auto',
									width: 'auto',
									borderRadius: '4px',
								}}
								onClick={_cancel}>
								Cancelar
							</Button>
						</Row>
					</FormContent>
				</>
			)}
			<InfoModal modal={infoModal} closeModal={onCloseModal} />
		</CreateFormDiv>
	)
}

export default CustomerConfig
