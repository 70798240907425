import {
	BatteryTagContainer,
	BatteryTagContent,
	BatteryTagHeader,
	BatteryTagQrCodeContent,
	BatteryTagQrDataContent,
	Title,
} from './styles'

import {Logo} from '../../components/Logo/index.jsx'
import {QrCode} from '../../components/QrCode/index.jsx'

/**
 * @author Carlos Padovezi
 * @function BatteryTag
 * @file BatteryTag.js
 * @summary Function to create battery tag
 * @returns Batteries battery tag
 */
export default function BatteryTag({code, name, forkliftModel, branch, image}) {
	const mySrc = process.env.PUBLIC_URL

	const img = new Image(50, 50)
	img.src = `${mySrc}/public/logo/${image}`

	return (
		<BatteryTagContainer>
			<BatteryTagHeader>
				<Logo
					width={100}
					height={50}
					backgroundColor={'#FFF'}
					isLink={false}
					type={'svg'}
				/>
				{image ? (
					<img
						src={img.src}
						alt={name + 'image'}
						style={{
							width: '50px',
							height: '50px',
							marginRight: 10,
							objectFit: 'contain',
						}}
					/>
				) : null}
			</BatteryTagHeader>
			<BatteryTagContent>
				<BatteryTagQrDataContent>
					<div style={{display: 'table', height: '100%', width: '100%'}}>
						<div style={{display: 'table-row'}}>
							<div
								style={{
									height: '80%',
									width: '100%',
									display: 'table-cell',
									fontSize: '3em',
									fontWeight: 700,
								}}>
								<div
									style={{
										height: '100%',
										width: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'start',
										paddingLeft: '3px',
									}}>
									<Title>{name}</Title>
								</div>
							</div>
						</div>
						<div style={{display: 'table-row'}}>
							<div
								style={{
									height: '20%',
									width: '100%',
									display: 'table-cell',
									fontSize: '1.2em',
									fontWeight: 700,
								}}>
								{`${forkliftModel}`}
							</div>
						</div>
					</div>
				</BatteryTagQrDataContent>
				<BatteryTagQrCodeContent>
					<QrCode
						code={code}
						size={100}
						width={100}
						height={100}
						bgColor={'#FFF'}
						fgColor={'#000'}
						includeMargin={false}
					/>
				</BatteryTagQrCodeContent>
			</BatteryTagContent>
		</BatteryTagContainer>
	)
}
