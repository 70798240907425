import {EditModalBackgroundDiv, EditModalDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'

import {Button} from '../../styles/globalStyles'
import {RowCalendar} from '../../components/RowCalendar/index.jsx'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {RowSelectBranch} from 'src/components/RowSelectBranch'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

/**
 * @author Carlos Padovezi
 * @function EditForklift
 * @file EditForklift.js
 * @summary Function to create Battery rooms register view
 * @returns Battery rooms create create page
 */
function EditForklift({
	editData,
	handleCancelEdit,
	updatePage,
	hideLoadingBlur,
	showLoadingBlur,
}) {
	console.log(editData)
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [forkliftModel, setModelsList] = useState([])
	const [clientsList, setClientList] = useState([])
	const [formData, setFormData] = useState({
		name: editData.name ? editData.name : '',
		serial: editData.serial ? editData.serial : '',
		manufacturing_date: editData.manufacturing_date
			? editData.manufacturing_date
			: '',
		model_id: editData.model_id ? editData.model_id : '',
		hour_meter: editData.hour_meter ? editData.hour_meter : '',
		branch_id: editData.branch_id ? editData.branch_id : '',
		client_id: editData.client_id ? editData.client_id : userInfos.client_id,
		id: editData.id ? editData.id : '',
	})

	useEffect(() => {
		showLoadingBlur()
		//get branch list
		createFetchRequest('client/getAll', 'get', null, async ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}
			let myList = []
			if (result && result.length > 0) {
				for (let client of result) {
					myList.push({value: client.id, label: client.name})
				}
			}

			setClientList(myList)

			//get forklift model list
			createFetchRequest(
				'forkliftModel/getAll',
				'get',
				null,
				async ({err, result}) => {
					if (err) {
						console.log(err)
						return
					}
					let myList = []
					if (result && result.length > 0) {
						for (let model of result) {
							myList.push({value: model.id, label: model.name})
						}
					}

					setModelsList(myList)
					hideLoadingBlur()
				},
			)
		})
	}, [hideLoadingBlur, showLoadingBlur])

	function handleSaveEditForklift() {
		const context = {
			id: formData.id,
			name: formData.name,
			serial: formData.serial,
			manufacturing_date: formData.manufacturing_date,
			model_id: formData.model_id,
			hour_meter: formData.hour_meter,
			branch_id: formData.branch_id,
		}

		createFetchRequest('forklift/update', 'put', context, ({err, result}) => {
			if (err) {
				console.log(err)
			} else {
				//Reset inputs values
				updatePage()
			}
		})
	}

	let validButton =
		formData.name &&
		formData.serial &&
		formData.manufacturing_date &&
		formData.model_id &&
		formData.hour_meter &&
		formData.branch_id

	return (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				<RowInput
					value={formData.serial}
					label={dictionary.Serial}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(serial) => setFormData((state) => ({...state, serial}))}
					maxLength={10}
					minLength={10}
				/>
				<RowCalendar
					value={formData.manufacturing_date}
					label={dictionary.ManufacturingDate}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(manufacturing_date) =>
						setFormData((state) => ({...state, manufacturing_date}))
					}
					stringAutoComplite={`00:00:00`}
				/>
				<RowSelect
					value={formData.model_id}
					optionsList={forkliftModel}
					label={dictionary.Model}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(model_id) =>
						setFormData((state) => ({...state, model_id}))
					}
				/>
				<RowInput
					value={formData.hour_meter}
					label={dictionary.HourMeter}
					isObligatory={true}
					type={'number'}
					fontSize={'16px'}
					onChange={(hour_meter) =>
						setFormData((state) => ({...state, hour_meter}))
					}
					maxLength={9}
					minLength={9}
					readOnly={true}
				/>
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={clientsList}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id}))
						}
					/>
				) : null}
				<RowSelectBranch
					clientId={formData.client_id}
					value={formData.branch_id}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(branch_id) =>
						setFormData((state) => ({...state, branch_id}))
					}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveEditForklift}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(EditForklift)
