/**
 * @author Carlos Padovezi
 * @function ForkLift
 * @file ForkliftIcon.js
 * @summary Mount forklift img
 * @param {Object}
 * @param {string} width - Set icon width
 * @param {string} height - Set icon height
 * @param {string} opacity - Set icon opacity
 * @returns {image} Forklift icon in img tag format
 */
export function ForkLiftIcon(props) {
	//Get icon dimentions
	let myWidth = props && props.width ? props.width : '16px'
	let myHeight = props && props.height ? props.height : '16px'
	let myOpacity = props && props.opacity ? props.opacity : '0.6'
	let color = props && props.color ? props.color : '#fff'

	return (
		<div title={props.title}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 16.594 16.594"
				style={{
					width: myWidth,
					height: myHeight,
					opacity: myOpacity,
				}}
			>
				<g fill={color}>
					<path d="M9.35 9.147s-.717-6.853-3.416-6.853H1.599v6.303H.492c-1.107 0 0 3.918 0 3.918h.687c.047-.973.852-1.751 1.838-1.751.985 0 1.79.778 1.837 1.751h2.241c-.001-.009-.002-.017-.002-.026 0-1.079.88-1.958 1.96-1.958 1.079 0 1.958.879 1.958 1.958 0 .126-.013.248-.035.367h.693l-1.154-3.624c-1.059.597-1.165-.085-1.165-.085zm-3.491.217L4.837 7.575H2.622V3.317h3.129c.914 0 1.419 1.273 1.683 2.342.168.683.685 3.164.731 3.706H5.859v-.001z" />
					<path d="M9.062 10.758c-.939 0-1.7.762-1.7 1.7 0 .939.761 1.7 1.7 1.7.938 0 1.7-.761 1.7-1.7 0-.938-.762-1.7-1.7-1.7zm0 2.686c-.543 0-.985-.442-.985-.985s.442-.984.985-.984.984.441.984.984-.441.985-.984.985zM2.969 11.069c-.893 0-1.616.725-1.616 1.616 0 .891.723 1.615 1.616 1.615.891 0 1.615-.725 1.615-1.615-.001-.891-.724-1.616-1.615-1.616zm0 2.551c-.516 0-.935-.419-.935-.935s.419-.937.935-.937c.515 0 .935.421.935.937s-.421.935-.935.935zM16.575 10.841c-.063-.192-.271-.298-.461-.233l-3.544 1.167-3.063-9.202c-.064-.192-.271-.294-.464-.231-.192.064-.296.271-.231.463l3.18 9.548c.052.153.193.25.348.25.038 0 .076-.006.114-.018l3.89-1.282c.191-.064.296-.27.231-.462z" />
				</g>
			</svg>
		</div>
	)
}
