import React, {useCallback, useEffect, useState} from 'react'
import {createFetchRequest, phoneMask} from '../../genericFunctions'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'

import CreateBranch from './BranchCreate.jsx'
import {CrudModal} from '../../components/CrudModal/index.jsx'
import EditBranch from './BranchEdit.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MainContentDiv} from '../../styles/globalStyles'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

const translatedBranchsList = (branchsList) => {
	let newBranchsList = []

	for (let branch of branchsList) {
		newBranchsList.push({
			...branch,
			client: branch.client && branch.client.name ? branch.client.name : '',
			client_id: branch.client && branch.client.id ? branch.client.id : '',
		})
	}

	return newBranchsList
}

/**
 * @author Carlos Padovezi
 * @function BranchCrud
 * @file BranchCrud.js
 * @summary Function to create branchs crud view
 * @returns Branchs crud page
 */
function BranchCrud({hideLoadingBlur, showLoadingBlur}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [crudSelected, changeCrudSelected] = useState('list')
	const [crudList, setCrudList] = useState([])
	const [editingBranch, setEditingBranch] = useState(null)

	const loadPageData = useCallback(() => {
		showLoadingBlur()

		createFetchRequest('branch/getAll', 'get', null, async ({err, result}) => {
			if (err) setCrudList([])
			else if (!err && result) {
				let myResult = translatedBranchsList(result)
				await setCrudList(myResult)
			}

			await changeCrudSelected('list')
			await setEditingBranch(null)
			await hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const handleOpenEditBranch = (params) => {
		let rowValue = params

		let startValues = {
			name: rowValue.name,
			email: rowValue.email,
			address: rowValue.address,
			phone: rowValue.phone,
			client_id: rowValue.client_id,
			editingId: rowValue.id,
		}

		setEditingBranch(startValues)
	}

	const handleCancelEdit = () => {
		setEditingBranch(null)
	}

	const handleOpenDeleteBranch = async (params) => {
		const isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			var context = {
				id: params.id,
			}

			await createFetchRequest(
				'branch/delete/' + params.id,
				'delete',
				context,
				async ({err, result}) => {
					if (err) {
						console.log(err)
					}

					//Reset inputs values
					await loadPageData()
				},
			)
		}
	}

	//CRUD list configuration
	const columns = [
		{accessor: 'name', Header: dictionary.Name},
		{accessor: 'address', Header: dictionary.Address},
		{accessor: 'email', Header: dictionary.Email},
		{
			accessor: 'phone',
			Header: dictionary.Phone,
			valueFormatter: phoneMask,
			getStyle: () => ({width: '110px'}),
		},
		{
			accessor: 'client',
			Header: dictionary.Customer,
			showColumn: userInfos.profile_id === 1,
		},
	]

	let crudsObjPages = {
		list: {
			title: dictionary.BranchsList,
			list: {
				columns,
				rows: crudList,
			},
			onRowEditClick: handleOpenEditBranch,
			onRowEditLabel: 'Editar',
			onRowEditIcon: <FontAwesomeIcon icon={faEdit} />,
			onRowDeleteClick: handleOpenDeleteBranch,
			onRowDeleteLabel: 'Excluir',
			onRowDeleteIcon: <FontAwesomeIcon icon={faTrash} />
		},
		create: {
			title: dictionary.BranchsRegister,
			content: <CreateBranch updatePage={loadPageData} />,
		},
	}

	const editModal = (
		<EditBranch
			handleCancelEdit={handleCancelEdit}
			updatePage={loadPageData}
			editData={editingBranch}
		/>
	)

	return (
		<MainContentDiv>
			<CrudModal
				crudsObj={crudsObjPages}
				crudSelected={crudSelected}
				changeCrudSelected={(newCrud) => changeCrudSelected(newCrud)}
			/>
			{editingBranch === null ? null : editModal}
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(BranchCrud)
