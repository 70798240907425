import {Button} from '@material-ui/core'
import {Colors} from 'src/styles'
import styled from 'styled-components'

export const Row = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
	color: ${({color}) => color ?? '#000000'};
	font-size: 14px;
	font-weight: 600;
`

export const Content = styled.div`
	width: 100%;
	padding: 10px;
	background: ${Colors.WHITE};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

export const Header = styled.div`
	background-color: ${Colors.PURPLE};
	text-align: center;
	padding: 10px;
	color: ${Colors.WHITE};
	width: 100%;
	font-size: 20px;
	font-weight: bold;
	border-radius: 5px;
`

export const Items = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

export const NameEquipment = styled.h2``

export const Line = styled.div`
	height: 1px;
	background-color: #000000;
	width: 80%;
`
export const Table = styled.table``

export const TBody = styled.tbody``

export const RowIcon = styled.tr`
	display: flex;
	align-items: center;
`

export const ChecklistContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
`

export const NokIcon = styled.label`
	font-weight: bold;
	font-size: 12px;
`

export const IconCard = styled.div`
	background-color: ${({color}) => color ?? ''};
	padding: 5px;
	border-radius: 5px;
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px;
	border: 1px dashed #000000;
`

export const DescriptionTitle = styled(NokIcon)``

export const Description = styled(NokIcon)`
	font-weight: 200;
`

export const CheckCard = styled.div`
	width: 15px;
	padding: 5px;
	height: 15px;
	margin: 10px;
	border-radius: 5px;
	border: 1px dashed #000000;
	background-color: ${({color}) => color ?? ''};
`

export const ButtonClose = styled(Button)`
	background-color: ${Colors.PURPLE} !important;
	color: ${Colors.WHITE} !important;
`

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	overflow: auto;
`
