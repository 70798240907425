import React, {useCallback, useEffect, useState} from 'react'

import {RowSelect} from '../RowSelect'
import {createFetchRequest} from 'src/genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function RowSelectBatteryRoom
 * @file RowSelectBatteryRoom.js
 * @param {String} value -
 * @param {Number} branchId -
 * @param {Boolean} isObligatory -
 * @param {String} width -
 * @param {String} height -
 * @param {String} fontSize -
 * @param {Function} onChange -
 * @param {String} type -
 * @param {Boolean} autoFocus -
 * @summary Function to use standard select with label
 * @returns Row with Label and select
 */
export function RowSelectBatteryRoom({
	branchId,
	value,
	isObligatory,
	autoFocus,
	height,
	fontSize,
	placeholder,
	onChange,
	readOnly,
}) {
	const [optionsList, setOptionsList] = useState([])
	const [batteryRoomSelected, setBatteryRoom] = useState(
		value === null ? '' : value,
	)

	const loadBatteryRoomList = useCallback(() => {
		if (branchId) {
			//get branch list
			createFetchRequest(
				`batteryRoom/getAll/?branch=${branchId}`,
				'get',
				null,
				async ({err, result}) => {
					if (err) {
						console.log(err)
						return
					}

					const myList = []
					if (result && result.length > 0) {
						for (const batteryRoom of result) {
							myList.push({value: batteryRoom.id, label: batteryRoom.name})
						}
					}

					setOptionsList(myList)
				},
			)
		} else {
			setOptionsList([])
		}
	}, [branchId])

	useEffect(() => {
		loadBatteryRoomList()
	}, [loadBatteryRoomList])

	const onChangeFunction = (batteryRoom) => {
		setBatteryRoom(batteryRoom)
		onChange(batteryRoom)
	}

	return (
		<RowSelect
			value={batteryRoomSelected}
			optionsList={optionsList}
			label={dictionary.BatteryRooms}
			isObligatory={isObligatory}
			autoFocus={autoFocus}
			height={height}
			fontSize={fontSize}
			placeholder={placeholder}
			onChange={onChangeFunction}
			readOnly={readOnly}
		/>
	)
}
