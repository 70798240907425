import {EditModalBackgroundDiv, EditModalDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function EditChargerModel
 * @file EditChargerModel.js
 * @summary Function to edit Battery rooms register view
 * @returns Battery rooms edit crud page
 */
export default function EditChargerModel({
	editData,
	handleCancelEdit,
	updatePage,
}) {
	const [brandsList, setBrandsList] = useState([])
	const [formData, setFormData] = useState({
		name: editData.name ? editData.name : '',
		consumption_kwh: editData.consumption_kwh ? editData.consumption_kwh : '',
		voltage: editData.voltage ? editData.voltage : '',
		capacity: editData.capacity ? editData.capacity : '',
		charging_voltage: editData.charging_voltage
			? editData.charging_voltage
			: '',
		amps_hour_charging_time: editData.amps_hour_charging_time
			? editData.amps_hour_charging_time
			: '',
		brand_id: editData.brand_id ? editData.brand_id : '',
		id: editData.id ? editData.id : '',
	})

	useEffect(() => {
		//get branch list
		createFetchRequest(
			'chargerBrand/getAll',
			'get',
			null,
			async ({err, result}) => {
				if (err) {
					console.log(err)
					return
				}
				let myList = []
				if (result && result.length > 0) {
					for (let brand of result) {
						myList.push({value: brand.id, label: brand.name})
					}
				}

				setBrandsList(myList)
			},
		)
	}, [])

	async function handleSaveEditChargerModel() {
		const context = {
			id: formData.id,
			name: formData.name,
			consumption_kwh: formData.consumption_kwh,
			voltage: formData.voltage,
			capacity: formData.capacity,
			charging_voltage: formData.charging_voltage,
			amps_hour_charging_time: formData.amps_hour_charging_time,
			brand_id: formData.brand_id,
		}

		createFetchRequest(
			'chargerModel/update',
			'put',
			context,
			async ({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					await updatePage()
				}
			},
		)
	}

	let validButton =
		formData.consumption_kwh &&
		formData.voltage &&
		formData.capacity &&
		formData.charging_voltage &&
		formData.amps_hour_charging_time &&
		formData.brand_id

	return (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					autoFocus={true}
					maxLength={50}
					readOnly={true}
				/>
				<RowInput
					value={formData.voltage}
					label={dictionary.Voltage}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(voltage) => { 
						setFormData((state) => ({...state, voltage}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(formData.brand_id))
						let brandName = brandIdx === -1 ? '' : brandsList[brandIdx].label
						let voltageValue = voltage === null ? '' : voltage
						let name = `${brandName} ${voltageValue}V ${formData.capacity}A`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<RowInput
					value={formData.consumption_kwh}
					label={dictionary.ConsumptionKwh}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(consumption_kwh) =>
						setFormData((state) => ({...state, consumption_kwh}))
					}
				/>
				<RowInput
					value={formData.capacity}
					label={dictionary.Capacity}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(capacity) => {
						setFormData((state) => ({...state, capacity}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(formData.brand_id))
						let brandName = brandIdx === -1 ? '' : brandsList[brandIdx].label
						let capacityValue = capacity === null ? '' : capacity
						let name = `${brandName} ${formData.voltage}V ${capacityValue}A`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<RowInput
					value={formData.charging_voltage}
					label={dictionary.ChargingVoltage}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(charging_voltage) =>
						setFormData((state) => ({...state, charging_voltage}))
					}
				/>
				<RowInput
					value={formData.amps_hour_charging_time}
					label={dictionary.AmpsHourChargingTime}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(amps_hour_charging_time) =>
						setFormData((state) => ({...state, amps_hour_charging_time}))
					}
				/>
				<RowSelect
					value={formData.brand_id}
					label={dictionary.Brand}
					optionsList={brandsList}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(brand_id) => {
						setFormData((state) => ({...state, brand_id}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(brand_id))
						let name = `${brandsList[brandIdx].label} ${formData.voltage}V ${formData.capacity}A`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						onClick={handleSaveEditChargerModel}
						disabled={!validButton}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	)
}
