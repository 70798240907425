import { Button, Chip,  } from '@material-ui/core'
import { FlexCenter, MainContentDiv } from 'src/styles/globalStyles'
import { TBody, TCell, THead, THeader, TRow, Table } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Spinner from 'src/components/Spinner'
import { createFetchRequest } from 'src/genericFunctions'
import { faInbox } from '@fortawesome/free-solid-svg-icons'

const StatusStyle = (status) => {
	
	if (status === 'error') {
		return (
			<Chip style={{ backgroundColor: 'rgba(117, 33, 30, 0.7)'}} label='Erro' variant='outlined' />
		)
	}

	if (status === 'pending') {
		return (
			<Chip style={{ backgroundColor: 'rgba(170, 140, 8, 0.7)'}} label='Pendente' variant='outlined' />
		)
	}

	if (status === 'success') {
		return (
			<Chip style={{ backgroundColor: 'rgba(59, 117, 30, 0.7)'}} label='Sucesso' variant='outlined' />
		)
	}
}


const formatDate = (date) => {
	const dt = date.split('T')
	return new Date(dt).toLocaleDateString('pt-BR', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	})
}

const onView = (filename) => {
	let url = process.env.REACT_APP_DOMAIN
	url = url.substring(0, url.length - 5)
	url = url.concat('/public/report/').concat(filename)

	window.open(url)
}

const File = () => {
	const [files, setFiles] = React.useState([])
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		createFetchRequest('/files', 'get', null, ({ err, result}) => {
			if(err) {
				return
			}
      
			setFiles(result)
			setLoading(false)
		})
	}, [])

	return (
		<MainContentDiv>
			<h1 style={{ textAlign: 'center' }}>GERENCIADOR DE ARQUIVOS</h1>
			<FlexCenter style={{ width: '100%', height: 'auto', alignItems: 'start',  marginTop: 30}}>
				{files.length > 0 && !loading && (
					<Table>
						<THeader>
							<TRow>
								<THead>
								Nome
								</THead>
								<THead>
								Descrição
								</THead>
								<THead>
								Status
								</THead>
								<THead>
								Data
								</THead>
								<THead>
								Data Exclusão
								</THead>
								<THead>
								Ação
								</THead>
							</TRow>
						</THeader>
						<TBody>		
							{files.map(file => (
								<TRow key={file.id}>
									<TCell>{file.name}</TCell>
									<TCell>{file.description}</TCell>
									<TCell>{StatusStyle(file.status)}</TCell>
									<TCell>{formatDate(file.createdAt)}</TCell>
									<TCell>{formatDate(file.deletion_date)}</TCell>
									<TCell>
										{file.status === 'success' && (
											<Button onClick={() => onView(file.filename)} style={{ backgroundColor: 'rgba(30, 72, 117, 0.7)'}} variant="outlined">Visualizar</Button>
										)}
									</TCell>
								</TRow>
							))}
						</TBody>			
					</Table>
				)} 

				{!loading && files.length === 0 && (
					<FlexCenter style={{ flexDirection: 'column'}}>
						<FontAwesomeIcon icon={faInbox}  style={{ width: 250, height: 250}}/>
						<p style={{ fontWeight:'bold' }}>Sem resultados encontrados</p>
					</FlexCenter>
				)}

				{loading && (
					<FlexCenter>
						<Spinner />
					</FlexCenter>
				)}
			</FlexCenter>			
		</MainContentDiv>
	)
}

export default File