import { Option, Select } from './styles'
import React from 'react'

const options = [
	"Bateria recomendada não está na sala de cargas",
	"Bateria recomendada está descarregada",
	"Bateria recomendada está com meia carga",
	"Bateria recomendada está avariada / danificada",
	"Veículo não reconhece a bateria recomendada",
	"Bateria está em carga de equalização.",
	"Bateria está em Manutenção Externa.",
]

const JustifyDifferentBattery = ({ value, onChange }) => {
	return (
		<Select onChange={onChange} value={value}>
			<Option value="undefined">Todos</Option>
			{options.map(option => (
				<Option key={option} value={option} >{option}</Option>
			))}
		</Select>
	)
}

export default JustifyDifferentBattery