import {PURPLE, PURPLE_DARK, PURPLE_MEDIUM} from '../../styles/colors'

import styled from 'styled-components'

export const ModalContainer = styled.div`
	width: calc(100% - 80px);
	min-width: 300px;
	height: calc(100% - 120px);
	min-height: 400px;
	margin: 40px;
`

export const ContentDiv = styled.div`
	width: 100%;
	height: calc(100% - 40px);
	background-color: #fff;

	border-radius: 0px 10px 10px 10px;
	box-shadow: 0px 0px 1em #776e6e;

	padding-bottom: 20px;
	overflow: auto;
`

export const TypesCrudButton = styled.div`
	cursor: pointer;
	text-align: center;
	font-size: 16px;

	line-height: 40px;
	color: ${PURPLE};

	background-color: ${(props) => (props.isSelected ? '#FFF' : PURPLE_MEDIUM)};
	opacity: ${(props) => (props.isSelected ? 1 : 0.7)};

	border-radius: 10px 10px 0px 0px;
	box-shadow: -0px -4px 1em #776e6e;

	z-index: ${(props) => (props.isSelected ? 2 : 1)};

	padding: 0px 10px;

	&:hover {
		color: #fff;
		background-color: ${PURPLE};
	}
`

export const SearchPeriodDiv = styled.div`
	text-align: center;
	font-size: 16px;

	line-height: 40px;
	color: ${PURPLE};

	background-color: #fff;
	border-radius: 10px 10px 0px 0px;
	box-shadow: -0px -4px 1em #776e6e;
	padding: 0px 10px;
	right: 0px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const TypesCrudButtonsRow = styled.div`
	height: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: start;
	position: relative;
`

export const CrudActionsButtonsDiv = styled.div`
	height: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

export const ActionsButtonLabel = styled.p`
	font-weight: 700;
	font-size: 12px;
	color: ${PURPLE};
	padding: 0 10px;
	cursor: pointer;
	line-height: 30px;

	&:hover {
		color: ${PURPLE_DARK};
	}
`
