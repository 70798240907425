import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CustomTooltip } from './customTooltip'
import React from "react"
import Skeleton from "src/components/Skeleton"
import { fetchData } from "src/hooks/useFetch"


export const ExchangePerHour = ({ date }) => {
	const [data, setData] = React.useState()
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const loadData = async () => {
			const response = await fetchData(`monthReport/exchangePerHour?startDate=${date.startDate}&finalDate=${date.finalDate}`)
			setData(response)

			setLoading(false)
		}

		loadData()
	}, [date.startDate, date.finalDate])

	return (
		<>
			{loading ? (
				<Skeleton height="400px" width="100%" />
			) : (
				<div style={{ width: '100%', minWidth: '860px', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<ResponsiveContainer width={860} height={300}>
						<AreaChart
							width={860}
							height={300}
							data={data}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<defs>
								<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
									<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
								</linearGradient>
								<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
									<stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
								</linearGradient>
							</defs>
							{/* <CartesianGrid strokeDasharray="1" /> */}
							<XAxis dataKey="label" name='Por hora' format={(e) => { console.log(e) }} />
							<YAxis />
							<Tooltip content={<CustomTooltip />} />
							{/* <Legend name='Por hora' dataKey='label' /> */}
							<Area type="monotone" name='Por hora' dataKey="value" stroke="#38348c" fillOpacity={0.4} fill="#433ccd" />
						</AreaChart>
					</ResponsiveContainer>
				</div>
			)}
		</>
	)
}