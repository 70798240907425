import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'
import {useCallback, useEffect, useState} from 'react'

import CreateChargerModel from './ChargerModelCreate.jsx'
import {CrudModal} from '../../components/CrudModal/index.jsx'
import EditChargerModel from './ChargerModelEdit.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MainContentDiv} from '../../styles/globalStyles'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

const translatedModelsList = (modelsList) => {
	const newModelsList = []

	for (let model of modelsList) {
		newModelsList.push({
			...model,
			charger_brand:
				model.charger_brand && model.charger_brand.name
					? model.charger_brand.name
					: '',
			brand_id:
				model.charger_brand && model.charger_brand.id
					? model.charger_brand.id
					: '',
		})
	}

	return newModelsList
}

//CRUD list configuration
const columns = [
	{accessor: 'name', Header: dictionary.Name},
	{
		accessor: 'consumption_kwh',
		Header: dictionary.ConsumptionKwh,
		valueFormatter: (value) => `${value} Kw/h`,
		getStyle: () => ({width: '75px'}),
	},
	{
		accessor: 'voltage',
		Header: dictionary.Voltage,
		valueFormatter: (value) => `${value}V`,
		getStyle: () => ({width: '75px'}),
	},
	{
		accessor: 'capacity',
		Header: dictionary.Capacity,
		valueFormatter: (value) => `${value}A`,
		getStyle: () => ({width: '75px'}),
	},
	{
		accessor: 'amps_hour_charging_time',
		Header: dictionary.AmpsHourChargingTime,
		valueFormatter: (value) => `${value} H`,
		getStyle: () => ({width: '75px'}),
	},
	{
		accessor: 'charging_voltage',
		Header: dictionary.ChargingVoltage,
		valueFormatter: (value) => `${value}V`,
		getStyle: () => ({width: '75px'}),
	},
	{
		accessor: 'charger_brand',
		Header: dictionary.Brand,
	},
]

/**
 * @author Carlos Padovezi
 * @function ChargerModelsCrud
 * @file ChargerModelsCrud.js
 * @summary Function to create charger Models crud view
 * @returns Charger Models crud page
 */
function ChargerModelsCrud({hideLoadingBlur, showLoadingBlur}) {
	const [crudSelected, changeCrudSelected] = useState('list')
	const [crudList, setCrudList] = useState([])
	const [editingFokliftModel, setEditingFokliftModel] = useState(null)

	const loadPageData = useCallback(() => {
		showLoadingBlur()

		createFetchRequest('chargerModel/getAll', 'get', null, ({err, result}) => {
			if (err) setCrudList([])
			else if (!err && result) {
				let myResult = translatedModelsList(result)
				setCrudList(myResult)
			}

			changeCrudSelected('list')
			setEditingFokliftModel(null)
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const handleOpenEditChargerModel = (params) => {
		let rowValue = params

		const startValues = {
			name: rowValue.name,
			consumption_kwh: rowValue.consumption_kwh,
			voltage: rowValue.voltage,
			capacity: rowValue.capacity,
			charging_voltage: rowValue.charging_voltage,
			amps_hour_charging_time: rowValue.amps_hour_charging_time,
			brand_id: rowValue.brand_id,
			id: rowValue.id,
		}

		setEditingFokliftModel(startValues)
	}

	const handleCancelEdit = () => setEditingFokliftModel(null)

	const handleDeleteChargerModel = (params) => {
		const isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			showLoadingBlur()

			const context = {id: params.id}

			createFetchRequest(
				'chargerModel/delete/' + params.id,
				'delete',
				context,
				({err, result}) => {
					if (err) {
						console.log(err)
					}

					//Reset inputs values
					loadPageData()
				},
			)
		}
	}

	const crudsObjPages = {
		list: {
			title: dictionary.ModelsList,
			list: {
				columns,
				rows: crudList,
			},
			onRowEditClick: handleOpenEditChargerModel,
			onRowEditLabel: 'Editar',
			onRowEditIcon: <FontAwesomeIcon icon={faEdit} />,
			onRowDeleteClick: handleDeleteChargerModel,
			onRowDeleteLabel: 'Excluir',
			onRowDeleteIcon: <FontAwesomeIcon icon={faTrash} />
		},
		create: {
			title: dictionary.ModelRegister,
			content: <CreateChargerModel updatePage={loadPageData} />,
		},
	}

	const editModal = (
		<EditChargerModel
			handleCancelEdit={handleCancelEdit}
			updatePage={loadPageData}
			editData={editingFokliftModel}
		/>
	)

	return (
		<MainContentDiv>
			<CrudModal
				crudsObj={crudsObjPages}
				crudSelected={crudSelected}
				changeCrudSelected={(newCrud) => changeCrudSelected(newCrud)}
			/>
			{editingFokliftModel === null ? null : editModal}
		</MainContentDiv>
	)
}
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(ChargerModelsCrud)
