import {
	Input,
	LabelDiv,
	Row,
	RowInputWithError,
	ShowInvalidInput,
} from './styles'
import React, {useCallback, useEffect, useState} from 'react'
import {
	cnpjMask,
	cpfMask,
	phoneMask,
	validateEmail,
} from '../../genericFunctions'

import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function RowInput
 * @file RowInput.js
 * @param {String} value - Input value
 * @param {String} label - Labek of row above the input
 * @param {Boolean} isObligatory - Boolean than indicates if row is obligatory
 * @param {String} width - Component row's width
 * @param {String} height - Component row's height
 * @param {String} fontSize - Text input font size
 * @param {Function} onChange -	Function called when onChange event occours
 * @param {String} type - Type of input value options: phone, cnpj, cpf, email, number, password
 * @param {Boolean} autoFocus -	Boolean than indicates if auto focus input
 * @param {Number} maxLength - Input value max length
 * @param {Number} minLength - Input value min length
 * @param {Boolean} centerText - Boolean than indicates if should center input text
 * @param {Boolean} readOnly - Boolean to disable input
 * @summary Function to use standard input with label
 * @returns Row with Label and input
 */
export function RowInput(props) {	
	//Get props value
	const [inputValue, setInputValue] = useState({
		validValue: null,
		fakeValue: props.value === null ? '' : props.value,
		isInvalidInput: false,
	})

	const applyMask = useCallback(() => {
		let myValue = props.value === null ? '' : props.value

		if (inputValue.fakeValue !== '' && props.value === null) {
			myValue = inputValue.fakeValue
		} else if (inputValue.fakeValue !== props.value) {
			myValue = props.value
		}

		let inputValueTranslated = inputTypeApplication(myValue, props.type)
		setInputValue(inputValueTranslated)
	}, [inputValue.fakeValue, props.type, props.value])

	useEffect(() => {
		applyMask()
	}, [applyMask])

	//RowInput configuration START
	let myHeight = props.height ? props.height : null

	let myFontSize = props.fontSize ? props.fontSize : '12px'
	let myAutoFocus = props.autoFocus ? props.autoFocus : false
	let centerText = props.centerText ? props.centerText : false
	let placeholder = props.placeholder ? props.placeholder : ''
	let isObligatory = props.isObligatory ? props.isObligatory : false
	let inputMaxLength = props.maxLength ? props.maxLength : null
	let inputMinLength = props.minLength ? props.minLength : 0

	let myLabel = props.label ? props.label : ''
	let inputType = props.type ? props.type : ''

	switch (inputType) {
		case 'phone':
			placeholder = '** *****-****'
			inputMaxLength = placeholder.length
			inputMinLength = placeholder.length
			break
		case 'cnpj':
			placeholder = '**.***.***/****-**'
			inputMaxLength = placeholder.length
			inputMinLength = placeholder.length
			break
		case 'cpf':
			placeholder = '***.***.***-**'
			inputMaxLength = placeholder.length
			inputMinLength = placeholder.length
			break
		case 'email':
			placeholder = '****@****.***'
			break
		default:
			break
	}
	//RowInput configuration END

	//Middleware between input and parent component
	function handOnChange(event) {
		event.persist()

		let value = event.target.value
		let inputValueTranslated = inputTypeApplication(value, inputType)

		setInputValue(inputValueTranslated)
		props.onChange(inputValueTranslated.validValue)
	}

	return (
		<Row height={myHeight}>
			<LabelDiv
				isObligatory={isObligatory && !props.readOnly}
				fontSize={myFontSize}>
				<label>{`${myLabel}${
					isObligatory && !props.readOnly ? ' *' : ''
				} `}</label>
			</LabelDiv>
			<RowInputWithError>
				<Input
					value={inputValue.fakeValue === null ? '' : inputValue.fakeValue}
					onChange={handOnChange}
					type={inputType}
					min={inputType === 'number' ? 0 : null}
					autoFocus={myAutoFocus}
					placeholder={placeholder}
					maxLength={inputMaxLength}
					minLength={inputMinLength}
					fontSize={myFontSize}
					centerText={centerText ? true : false}
					isInvalid={inputValue.isInvalidInput}
					disabled={props.readOnly}
					autoComplete={props.autoComplete}
					name={props.name}
					id={props.name}					
				/>
				{inputValue.isInvalidInput ? (
					<ShowInvalidInput>{dictionary.invalidInput}</ShowInvalidInput>
				) : null}
			</RowInputWithError>
		</Row>
	)
}

export function inputTypeApplication(string, type) {
	let validValue = null
	let fakeValue = string
	let isInvalidInput = false

	//Checks types START
	if (string)
		switch (type) {
			case 'phone':
				let validatePhoneString = string
					.replace(/[^0-9]/gm, '')
					.match(/[0-9]{10,11}/gm)

				fakeValue = phoneMask(string)
				validValue = validatePhoneString ? string.replace(/[^0-9]/g, '') : null
				isInvalidInput = !validatePhoneString
				break
			case 'cnpj':
				let validateCnpjString = string
					.replace(/[^0-9]/gm, '')
					.match(/[0-9]{14}/gm)

				fakeValue = cnpjMask(string)
				validValue = validateCnpjString ? string.replace(/[^0-9]/g, '') : null
				isInvalidInput = !validateCnpjString
				break
			case 'cpf':
				let validateCpfString = string
					.replace(/[^0-9]/gm, '')
					.match(/[0-9]{11}/gm)

				fakeValue = cpfMask(string)
				validValue = validateCpfString ? string.replace(/[^0-9]/g, '') : null
				isInvalidInput = !validateCpfString
				break
			case 'email':
				let validateEmailString = validateEmail(string)

				fakeValue = string
				validValue = validateEmailString ? string : null
				isInvalidInput = !validateEmailString
				break
			default:
				fakeValue = string
				validValue = string
				break
		}
	//Checks types END

	return {
		validValue,
		fakeValue,
		isInvalidInput,
	}
}
