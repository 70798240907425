import { Column, Input, Options, Select, TBody, TCell, THead, THeader, TRow, Table, TableContent, Title } from '../styles'
import React from 'react'
import Spinner from 'src/components/Spinner'
import { debounce } from 'lodash'
import { isUndefinedOrNull } from 'src/genericFunctions'

const ImportEmployeesTable = ({employees, clients, profiles, onChangeValue}) => {		
	const [data, setData] = React.useState([])

	React.useEffect(() => {		
		setData(employees)
	}, [employees])
 
	const onChangeInput = debounce((value, index, field) => {
		onChangeValue({ value, index, field, state: 'employees'})
	}, 300)	

	return (		
		<Column>
			{data.length === 0 ? (
				<TableContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<Spinner />
				</TableContent>
			): (
			<>
				<Title>Máquinas</Title>
				<TableContent>
					<Table>
						<THeader>
							<TRow>
								<THead bold={true}>Nome</THead>
								<THead bold={true}>CPF</THead>
								<THead bold={true}>E-mail</THead>
								<THead bold={true}>Função</THead>
								<THead bold={true}>Cliente</THead>
								<THead bold={true}>Sede</THead>
							</TRow>
						</THeader>
						<TBody>
							{data.map((r, index) => (
								<TRow key={index}>
									<TCell>
										<Input
											type='text'
											defaultValue={r.name}																			
											onChange={(event) => onChangeInput(event.target.value, index, 'name')}
											color={isUndefinedOrNull(r.name) ? 'red' : null}
										/>
									</TCell>
									<TCell>
										<Input 
											type='text'
											defaultValue={r.cpf} 											
											onChange={(event) => onChangeInput(event.target.value, index, 'cpf')}
											color={isUndefinedOrNull(r.cpf) ? 'red' : null}
										/>
									</TCell>
									<TCell>
										<Input 
											type='text'
											defaultValue={r.email} 											
											onChange={(event) => onChangeInput(event.target.value, index, 'email')}											
										/>
									</TCell>
									<TCell>										
										<Select 										
											defaultValue={r.profile_id} 											
											onChange={(event) => onChangeInput(event.target.value, index, 'profile_id')}
											color={isUndefinedOrNull(r.profile_id) ? 'red' : null}
										>
											<Options value={-1}>Selecionar Função</Options>
											{profiles.map((profile) => (
												<Options key={r.profile_id} value={profile.id}>
													{profile.type}
												</Options>
											))}
										</Select>
									</TCell>
									<TCell>
										<Select defaultValue={r.client_id} 
											onChange={(event) => onChangeInput(event.target.value, index, 'client_id')}
											color={r.client_id === -1 ? 'red' : null}
										>
											<Options value={-1}>Selecionar Cliente</Options>
											{clients.map(c => (
												<Options key={c.id} value={c.id} >
													{c.name}
												</Options>
											))}
										</Select>
									</TCell>
									<TCell>
										<Select defaultValue={r.branch_id}
											onChange={(event) => onChangeInput(event.target.value, index, 'branch_id')}
											color={r.branch_id === -1 ? 'red' : null}
										>
											<Options value={-1}>Selecionar Sede</Options>
											{clients.find(client => client.id === parseInt(r.client_id))?.branchs.map(branch => (
												<Options value={branch.id}>{branch.name}</Options>
											))}
										</Select>
									</TCell>
								</TRow>
							))}
						</TBody>
					</Table>
				</TableContent>		
			</>
			)}
		</Column>		
	)
}

export default ImportEmployeesTable