import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'
import {useCallback, useEffect, useState} from 'react'

import CreateBatteryModel from './BatteryModelCreate.jsx'
import {CrudModal} from '../../components/CrudModal/index.jsx'
import EditBatteryModel from './BatteryModelEdit.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MainContentDiv} from '../../styles/globalStyles'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

const translatedModelsList = (modelsList) => {
	const newModelsList = []

	for (let model of modelsList) {
		newModelsList.push({
			...model,
			battery_brand:
				model.battery_brand && model.battery_brand.name
					? model.battery_brand.name
					: '',
			brand_id:
				model.battery_brand && model.battery_brand.id
					? model.battery_brand.id
					: '',
		})
	}

	return newModelsList
}

//CRUD list configuration
const columns = [
	{accessor: 'name', Header: dictionary.Model},
	{
		accessor: 'estimated_manufactorer_cycles',
		Header: dictionary.EstimatedCycles,
		getStyle: () => ({width: '75px'}),
	},
	{
		accessor: 'capacity',
		Header: dictionary.Capacity,
		valueFormatter: (value) => `${value}A`,
		getStyle: () => ({width: '75px'}),
	},
	{
		accessor: 'voltage',
		Header: dictionary.Voltage,
		valueFormatter: (value) => `${value}V`,
		getStyle: () => ({width: '75px'}),
	},
	{
		accessor: 'battery_brand',
		Header: dictionary.Brand,
	},
]

/**
 * @author Carlos Padovezi
 * @function BatteryModelsCrud
 * @file BatteryModelsCrud.js
 * @summary Function to create battery Models crud view
 * @returns Battery Models crud page
 */
function BatteryModelsCrud({hideLoadingBlur, showLoadingBlur}) {
	const [crudSelected, changeCrudSelected] = useState('list')
	const [crudList, setCrudList] = useState([])
	const [editingBatteryModel, setEditingBatteryModel] = useState(null)

	const loadPageData = useCallback(() => {
		showLoadingBlur()

		createFetchRequest('batteryModel/getAll', 'get', null, ({err, result}) => {
			if (err) setCrudList([])
			else if (!err && result) {
				const myResult = translatedModelsList(result)
				setCrudList(myResult)
			}

			changeCrudSelected('list')
			setEditingBatteryModel(null)
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const handleOpenEditBratteryModel = (params) => {
		const rowValue = params

		const startValues = {
			name: rowValue.name,
			estimated_manufactorer_cycles: rowValue.estimated_manufactorer_cycles,
			capacity: rowValue.capacity,
			voltage: rowValue.voltage,
			brand_id: rowValue.brand_id,
			id: rowValue.id,
		}

		setEditingBatteryModel(startValues)
	}

	const handleCancelEdit = () => setEditingBatteryModel(null)

	const handleDeleteBatteryModel = (params) => {
		const isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			showLoadingBlur()

			const context = {id: params.id}

			createFetchRequest(
				'batteryModel/delete/' + params.id,
				'delete',
				context,
				({err, result}) => {
					if (err) {
						console.log(err)
					}

					//Reset inputs values
					loadPageData()
				},
			)
		}
	}

	const crudsObjPages = {
		list: {
			title: dictionary.ModelsList,
			list: {
				columns,
				rows: crudList,
			},
			onRowEditClick: handleOpenEditBratteryModel,
			onRowEditLabel: 'Editar',
			onRowEditIcon: <FontAwesomeIcon icon={faEdit} />,
			onRowDeleteClick: handleDeleteBatteryModel,
			onRowDeleteLabel: 'Excluir',
			onRowDeleteIcon: <FontAwesomeIcon icon={faTrash} />
		},
		create: {
			title: dictionary.ModelRegister,
			content: <CreateBatteryModel updatePage={loadPageData} />,
		},
	}

	const editModal = (
		<EditBatteryModel
			handleCancelEdit={handleCancelEdit}
			updatePage={loadPageData}
			editData={editingBatteryModel}
		/>
	)

	return (
		<MainContentDiv>
			<CrudModal
				crudsObj={crudsObjPages}
				crudSelected={crudSelected}
				changeCrudSelected={(newCrud) => changeCrudSelected(newCrud)}
			/>
			{editingBatteryModel === null ? null : editModal}
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(BatteryModelsCrud)
