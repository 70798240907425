import styled from 'styled-components'

const Container = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background: linear-gradient(-45deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 400% 400%;
    animation: gradient 1.5s ease infinite;
    border-radius: 8px;
    @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
`

const Skeleton = ({ width = '100px', height = '35px' }) => {
	return <Container width={width} height={height} />
}

export default Skeleton