import {EditModalBackgroundDiv, ViewModalDiv} from 'src/styles/globalStyles'
import {Colors} from 'src/styles'
import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
	padding: 5px;
	border-radius: 4px;
	border: 1px solid;
	border-color: ${({error}) => (error ? Colors.RED : Colors.GRAY_LIGHT)};
	:focus {
		border: 1px solid;
		border-color: ${Colors.PURPLE};
	}
`

const Button = styled.button`
	margin: 10px;
	border: none;
	border-radius: 2px;
	background-color: ${({active}) => (active ? Colors.PURPLE : Colors.BLACK)};
	padding: 8px;
	color: ${Colors.WHITE};
	cursor: pointer;
	:hover {
		opacity: 0.4;
	}
`

const SpanError = styled.span`
	color: ${Colors.RED};
	font-size: 10px;
`

const IsActive = styled.div`
	padding: 5px;
	border: none;
	cursor: pointer;
	background-color: ${({active}) =>
		active ? Colors.GREEN : Colors.GRAY_MEDIUM};
	color: ${Colors.WHITE};
`
const IsDisabled = styled.div`
	padding: 5px;
	border: none;
	cursor: pointer;
	background-color: ${({active}) => (active ? Colors.RED : Colors.GRAY_MEDIUM)};
	color: ${Colors.WHITE};
`
const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`
const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10px 0;
`

const FormCheklist = ({
	onChange,
	onSubmit,
	onCancel,
	checklist,
	show,
	typeEquipment,
}) => {
	const [error, setError] = React.useState(null)
	const checkOnSubmit = () => {
		if (checklist.description.length === 0) {
			setError('O Campo é obrigatório')
			return
		}

		onSubmit()
	}

	return (
		<>
			{show ? (
				<EditModalBackgroundDiv>
					<ViewModalDiv style={{width: '25%'}}>
						<h3>
							{checklist.id ? 'Editar ' : 'Criar '} checklist{' '}
							{typeEquipment === 'battery'
								? 'Bateria'
								: typeEquipment === 'forklift'
								? 'Máquina'
								: 'Carregador'}
						</h3>
						<Input
							placeholder="Descrição"
							defaultValue={checklist.description}
							onChange={(e) => {
								setError(null)
								onChange(e.target.value, 'description')
							}}
						/>
						{error && <SpanError>{error}</SpanError>}
						<Column>
							<span>Habilitado</span>
							<Row>
								<IsActive
									active={checklist.is_deleted === false}
									onClick={() => onChange(false, 'is_deleted')}>
									Sim
								</IsActive>
								<IsDisabled
									active={checklist.is_deleted === true}
									onClick={() => onChange(true, 'is_deleted')}>
									Não
								</IsDisabled>
							</Row>
						</Column>
						<Row>
							<Button onClick={onCancel}>Cancelar</Button>
							<Button onClick={checkOnSubmit}>Enviar</Button>
						</Row>
					</ViewModalDiv>
				</EditModalBackgroundDiv>
			) : null}
		</>
	)
}

export default FormCheklist
