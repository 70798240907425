import { Card, RowCards } from './styles'
import { faChargingStation, faChartLine, faClock, faFileSignature } from '@fortawesome/free-solid-svg-icons'
import IndicatorCard from '../IndicatorCard'
import React from 'react'
import { fetchData } from 'src/hooks/useFetch'

const OperationCard = ({ date }) => {
	const [data, setData] = React.useState()
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const loadData = async () => {
			try {
				const response = await fetchData(`dashboard/monthlyOperation/?startDate=${date.startDate}&finalDate=${date.finalDate}`)
				setData(response)
				setLoading(false)
			} catch {
				setLoading(false)
			}

		}

		loadData()
	}, [date.finalDate, date.startDate])

	return (
		<RowCards>
			<Card>
				<IndicatorCard icon={faClock} loading={loading} title={"Horas Operacionais Totais"} value={data?.total_operating_hours} />
			</Card>
			<Card>
				<IndicatorCard icon={faChartLine} loading={loading} title={"Autonomia média da Operação x Ciclo"} value={data?.autonomy} />
			</Card>
			<Card>
				<IndicatorCard icon={faFileSignature} loading={loading} title={"Ciclos Registrados"} value={data?.total_cycles_registered} />
			</Card>
			<Card>
				<IndicatorCard icon={faChargingStation} loading={loading} title={"Quantidade de Cargas"} value={data?.total_chargers} />
			</Card>
		</RowCards>
	)
}

export default OperationCard