import {
	ChargerTagContainer,
	ChargerTagContent,
	ChargerTagHeader,
	ChargerTagQrCodeContent,
	ChargerTagQrDataContent,
} from './styles'

import {Logo} from '../../components/Logo/index.jsx'
import {QrCode} from '../../components/QrCode/index.jsx'

/**
 * @author Carlos Padovezi
 * @function ChargerTag
 * @file ChargerTag.js
 * @summary Function to create battery tag
 * @returns Batteries battery tag
 */
export default function ChargerTag({code, name, image}) {
	const mySrc = process.env.PUBLIC_URL

	const img = new Image(50, 50)
	img.src = `${mySrc}/public/logo/${image}`

	return (
		<ChargerTagContainer>
			<ChargerTagHeader>
				<Logo
					width={100}
					height={50}
					backgroundColor={'#FFF'}
					isLink={false}
					type={'svg'}
				/>
				{image ? (
					<img
						src={img.src}
						alt={name + 'image'}
						style={{
							width: '50px',
							height: '50px',
							marginRight: 10,
							objectFit: 'contain',
						}}
					/>
				) : null}
			</ChargerTagHeader>
			<ChargerTagContent>
				<ChargerTagQrCodeContent>
					<QrCode
						code={code}
						size={100}
						width={100}
						height={100}
						bgColor={'#FFF'}
						fgColor={'#000'}
						includeMargin={false}
					/>
				</ChargerTagQrCodeContent>
				<ChargerTagQrDataContent>
					<div style={{display: 'table'}}>
						<div style={{display: 'table-row'}}>
							<div
								style={{
									display: 'table-cell',
									height: '20px',
									fontSize: '2.2em',
									fontWeight: 700,
								}}>
								{name}
							</div>
						</div>
					</div>
				</ChargerTagQrDataContent>
			</ChargerTagContent>
		</ChargerTagContainer>
	)
}
