import {
	convertManufactoryDate,
	createFetchRequest,
	formatManufacturingDate,
	getAutonomyStyle,
} from 'src/genericFunctions'
import {
	faBan,
	faCopy,
	faEdit,
	faExclamationTriangle,
	faTools,
	faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions/index'
import AlertFilter from 'src/components/Filter/AlertFilter'
import AlertsModal from 'src/components/AlertsModal'
import ChargerCreate from './ChargerCreate'
import ChargerTable from 'src/components/Table'
import ChargerTag from './ChargerTag'
import { Colors } from 'src/styles'
import ConfirmationModal from 'src/components/ConfirmationModal'
import { DisableInfo } from './styles'
import EditCharger from './ChargerEdit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfoModal from 'src/components/InfoModal'
import { MainContentDiv } from 'src/styles/globalStyles'
import Malfunction from 'src/components/MalfunctionModal'
import MonthYearFilter from 'src/components/Filter/MonthYearFilter'
import { PURPLE } from 'src/styles/colors'
import React from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

async function formatColumns(profileId, orderCallback, statusFilter, lastChecklistFilter, onViewMalfunction) {
	let columns = [
		{
			accessor: 'id',
			Header: <p>ID</p>,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback,
		},
		{
			accessor: 'name',
			Header: dictionary.Name,
			csvHeader: 'Nome',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback,
		},
		{
			accessor: 'serial',
			Header: dictionary.Serial,
			csvHeader: 'Serial',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback,
		},
		{
			accessor: 'manufacturing_date',
			Header: (
				<p>
					Data <br />
					de
					<br />
					Fabricação
				</p>
			),
			csvHeader: 'Data de Fabricação',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			filterType: 'dateYear',
			orderCallback,
		},
		{
			accessor: 'model',
			Header: dictionary.Model,
			csvHeader: 'Modelo',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback,
		},
		{
			accessor: 'battery_room',
			Header: (
				<p>
					Sala de
					<br />
					Baterias
				</p>
			),
			csvHeader: 'Sala de Baterias',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback,
		},
		{
			accessor: 'client',
			Header: dictionary.client,
			csvHeader: 'Cliente',
			showColumn: profileId === 1,
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback,
		},
		{
			accessor: 'branch',
			Header: dictionary.branch,
			csvHeader: 'Sede',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			orderCallback,
		},
		{
			accessor: 'amount_of_use',
			Header: (
				<p>
					Quantidade <br /> de <br /> Cargas
				</p>
			),
			csvHeader: 'Quantidade de Cargas',
			width: 'auto',
			priority: 1,
			order: true,
			orderType: 'number',
			orderCallback,
			showFilter: true,
		},
		{
			accessor: 'autonomy',
			Header: dictionary.Autonomy,
			csvHeader: 'Autonomia',
			width: 'auto',
			priority: 1,
			order: true,
			orderType: 'number',
			getStyle: (values) => getAutonomyStyle(values),
			valueFormatter: (row, value) => {
				return parseFloat(value).toFixed(1).replace('.', ',')
			},
			showFilter: true,
			orderCallback,
		},
		{
			accessor: 'status',
			Header: <p>Alertas</p>,
			csvHeader: 'Alertas',
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				if (value === 'maintenance') {
					return <FontAwesomeIcon title="Manutenção" icon={faTools} onClick={() => onViewMalfunction(row)}
						style={{
							color: PURPLE,
							cursor: 'pointer',
						}} />
				}

				if (value === 'damaged') {
					return <FontAwesomeIcon title="Avaria" icon={faExclamationTriangle} onClick={() => onViewMalfunction(row)}
						style={{
							color: PURPLE,
							cursor: 'pointer',
						}} />
				}
			},
			orderCallback,
			showFilter: true,
			customFilter: <AlertFilter onChange={statusFilter.callback} value={statusFilter.value} />
		},
		{
			accessor: 'disable',
			Header: 'Ativada',
			csvHeader: 'Ativada',
			width: 'auto',
			priority: 1,
			showFilter: true,
			filterType: 'yesOrNo',
			valueFormatter: (row, value) => {
				if (value) {
					return <DisableInfo color="#e63b2e">Não</DisableInfo>
				} else {
					return <DisableInfo color="#40987B">Sim</DisableInfo>
				}
			},
		},
		{
			accessor: 'last_checklist',
			Header: (
				<p>
					Data último <br /> Checklist
				</p>
			),
			csvHeader: 'Data último checklist',
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				if (value) {
					const date = new Date(value)
					const priorDate = new Date(new Date().setDate(date.getDate() - 30))

					if (date >= priorDate) {
						return new Date(value).toLocaleDateString('pt-BR', {
							day: '2-digit',
							month: '2-digit',
							year: 'numeric',
							hour: '2-digit',
							minute: '2-digit',
						})
					} else {
						return (
							<div
								title="O último checklist foi feito há mais de 30 dias"
								style={{
									width: 'auto',
									display: 'flex',
									justifyContent: 'space-around',
									alignItems: 'center',
								}}>
								<label style={{ marginRight: '5px', color: Colors.RED }}>
									{new Date(value).toLocaleDateString('pt-BR', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
									})}
								</label>
								<FontAwesomeIcon
									color={Colors.RED}
									icon={faExclamationTriangle}
								/>
							</div>
						)
					}
				}

				return (
					<div title="Nenhum checklist realizado para esse equipamento">
						<FontAwesomeIcon color={Colors.RED} icon={faExclamationTriangle} />
					</div>
				)
			},
			showFilter: true,
			customFilter: <MonthYearFilter onChange={lastChecklistFilter.callback} value={lastChecklistFilter.value} />
		},
		{
			accessor: 'mtbf',
			Header: <p>Medium Time <br /> Between <br /> Fails</p>,
			csvHeader: 'Medium Time Betwen Fails',
			width: 'auto',
			priority: 1,
			showFilter: true,
			order: true,
			orderType: 'number',
			orderCallback,
		},
		{
			accessor: 'mttr',
			Header: <p>Medium Time <br /> To <br /> Repair</p>,
			csvHeader: 'Medium Time To Repair',
			width: 'auto',
			priority: 1,
			showFilter: true,
			order: true,
			orderType: 'number',
			orderCallback,
		},
	]

	return columns
}

async function formatRows(rows) {
	const rowsFormat = rows.map((row) => {
		let formatDate = convertManufactoryDate(row.manufacturing_date)
		let manufacturingDate = formatManufacturingDate(row.manufacturing_date)

		return {
			...row,
			manufacturing_date_text: manufacturingDate,
			manufacturing_date: formatDate,
		}
	})

	return rowsFormat
}

function Chargers({ hideLoadingBlur, showLoadingBlur }) {
	const userInfos = getItem('session')
	const profileId = userInfos.profile_id
	const limit = 100

	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({
		totalPages: 0,
		total: 0,
		page: 1,
	})
	const [showModalEdit, setShowModalEdit] = React.useState({
		show: false,
		charger: {},
	})
	const [loading, setLoading] = React.useState(true)
	const [columns, setColumns] = React.useState([])
	const [list, setList] = React.useState([])
	const [dateFilter, setDateFilter] = React.useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})
	const [selectedIds, setSelectedIds] = React.useState([])
	const [selectedRows, setSelectedRows] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)
	const [deselectIds, setDeselectIds] = React.useState([])
	const [view, setView] = React.useState('table')
	const [registerMalfunction, setRegisterMalfunction] = React.useState({
		show: false,
		charger: {},
	})
	const [alertsModal, setAlertModal] = React.useState(null)

	const [infoModal, setInfoModal] = React.useState({
		title: '',
		message: '',
		show: false,
	})

	const [confirmationModal, setConfirmationModal] = React.useState({
		title: '',
		message: '',
		show: false,
		data: null,
	})

	const [filters, setFilters] = React.useState({
		name: '',
		serial: '',
	})

	const [duplicateCharger, setDuplicateCharger] = React.useState()

	const loadPageData = React.useCallback(() => {
		showLoadingBlur()
		setLoading(true)

		const opt = filters
		opt.startdate = dateFilter.initialDate
		opt.finaldate = dateFilter.endDate
		opt.page = page
		opt.limit = limit

		createFetchRequest('charger/getAll', 'post', opt, async ({ err, result }) => {
			if (err) setList([])
			else if (!err && result) {
				const rows = await formatRows(result.rows)
				setList(rows)
				setPagination({
					totalPages: result.totalPages,
					total: result.total,
					page: result.page,
				})
				let columns = await formatColumns(profileId, (orderField, order) => {
					setFilters((prev) => ({ ...prev, orderField, order }))
				}, { callback: filterStatus, value: filters.status }, { callback: filterLastChecklist, value: filters.last_checklist }, onViewMalfunction)
				setColumns(columns)
				setSelectedRows([])
				setDeselectIds([])
				setSelectedIds([])
				setLoading(false)
			}
		})

		setTimeout(() => {
			hideLoadingBlur()
		}, 3000)
	}, [dateFilter, filters, hideLoadingBlur, page, profileId, showLoadingBlur])

	const onSaveMalfunction = React.useCallback(
		(context) => {
			showLoadingBlur()
			createFetchRequest('/malfunction', 'post', context, ({ err, result }) => {
				if (err) console.log(err)
				else {
					console.log(result)
				}

				setRegisterMalfunction({ show: false, charger: {} })
				hideLoadingBlur()
				loadPageData()
			})
		},
		[hideLoadingBlur, loadPageData, showLoadingBlur],
	)

	const onViewMalfunction = (charger) => {
		setAlertModal({ type: 'charger', device: charger })
	}

	const onChangeView = (value) => {
		setView(value)
	}

	const handleDeleteCharger = async (params) => {
		let isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			await showLoadingBlur()

			var context = {
				id: params.id,
			}

			await createFetchRequest(
				'charger/delete/' + params.id,
				'delete',
				context,
				async ({ err, result }) => {
					if (err) {
						console.log(err)
					}

					await loadPageData()
				},
			)
		}
	}

	const onEdit = (params) => {
		setShowModalEdit({
			show: true,
			charger: params,
		})
	}

	const onSelectRow = (row, checked) => {
		if (selectAll) {
			if (!checked) {
				setDeselectIds([...deselectIds, row.id])
				setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
			} else {
				setDeselectIds(deselectIds.filter((r) => r !== row.id))
				setSelectedRows([...selectedRows], row)
			}
		} else {
			if (checked) {
				setSelectedRows([...selectedRows, row])
				setSelectedIds([...selectedIds, row.id])
			} else {
				setSelectedIds(selectedIds.filter((r) => r !== row.id))
				setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
			}
		}
	}

	const onSelectAll = (checked) => {
		setDeselectIds([])
		setSelectedIds([])
		setSelectAll(checked)
	}

	const onRegisterMalfunction = (charger) => {
		setRegisterMalfunction({ show: true, charger: charger })
	}

	const onDimissMalfunction = () => {
		setRegisterMalfunction({ show: false, charger: {} })
	}

	const filterColumn = (filter, column) => {
		let newFilters = filters
		newFilters[column] = filter
		setFilters({ ...newFilters })
	}

	const filterStatus = (event) => {
		setFilters((prev) => ({ ...prev, status: event.target.value }))
	}

	const filterLastChecklist = (event) => {
		setFilters((prev) => ({ ...prev, last_checklist: event.target.value }))
	}

	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({ initialDate: start, endDate: end })
	}

	const filter = {
		filterDate,
		filterColumn,
		previous: () => {
			setPage(page - 1)
		},
		next: () => {
			setPage(page + 1)
		},
		lastPage: () => {
			setPage(pagination.totalPages)
		},
		firstPage: () => {
			setPage(1)
		},
		selectPage: (value) => {
			setPage(parseInt(value))
		},
		dateFilter,
		searchFilters: filters,
	}

	const onDisableChargerConfirm = React.useCallback((row) => {
		let message = row.disable
			? 'Deseja ativar este carregador?'
			: 'Deseja desativar este carregador?'

		setConfirmationModal({
			title: 'Atenção',
			message: message,
			show: true,
			data: row,
		})
	}, [])

	const onDisableBattery = React.useCallback(() => {
		showLoadingBlur()
		createFetchRequest(
			`charger/disable/${confirmationModal.data.id}`,
			'put',
			{},
			({ err, result }) => {
				setConfirmationModal({ title: '', message: '', show: false })
				hideLoadingBlur()
				if (err) {
					setInfoModal({
						show: true,
						message: 'Erro ao atualizar carregador.',
						title: 'ERRO!',
					})
					return
				} else {
					setInfoModal({
						show: true,
						message: 'Carregador atualizada com sucesso.',
						title: 'OK!',
					})
					loadPageData()
					return
				}
			},
		)
	}, [confirmationModal, hideLoadingBlur, loadPageData, showLoadingBlur])

	const generatePDFDocument = (newSelectedRows) => {
		const oldRatio = window.devicePixelRatio
		window.devicePixelRatio = 1
		let id = 'cobe-charger-tags'

		const maxTagsPerPage = 16
		let tagsCounter = 0

		let tagsList = []
		const elementsArray = []

		for (let chargerId in newSelectedRows) {
			let charger = newSelectedRows[chargerId]

			tagsList.push(
				<ChargerTag
					image={charger.client_image}
					code={charger.qrcode}
					name={charger.name}
					id={charger.id}
					key={charger.id}
				/>,
			)

			tagsCounter++
		}

		for (let i = 0; i < tagsCounter; i = i + maxTagsPerPage) {
			console.log(i, i + maxTagsPerPage)
			let myArray = [...tagsList]

			myArray = myArray.slice(i, i + maxTagsPerPage)

			elementsArray.push(
				React.createElement(
					'div',
					{
						style: {
							width: '730px',
							padding: '40px',
							display: 'flex',
							flexWrap: 'wrap',
						},
						key: 'element' + i,
					},
					myArray,
				),
			)
		}

		let controller = 0
		const pdf = new jsPDF()

		const mountPdf = () => {
			const element = elementsArray[controller]

			let parentDiv = document.createElement('div')
			parentDiv.id = 'parentNode'

			document.body.appendChild(parentDiv)
			ReactDOM.render(element, parentDiv)

			html2canvas(parentDiv, { scale: 1, allowTaint: true, useCORS: true }).then(
				(canvas) => {
					const imgData = canvas.toDataURL('image/png')

					pdf.addImage(imgData, 'PNG', 0, 0)
					document.body.removeChild(parentDiv)

					controller++
					if (controller === elementsArray.length) {
						pdf.save(id + '.pdf')
						hideLoadingBlur()
						window.devicePixelRatio = oldRatio
					} else {
						pdf.addPage()
						mountPdf()
					}
				},
			)
		}

		mountPdf()
	}

	const contructPDFDocument = () => {
		if (Object.keys(selectedRows).length < 1 && !selectAll) {
			alert(dictionary.noRowsSelected)
			return
		}

		showLoadingBlur()

		let newSelectedRows = selectAll ? list : selectedRows

		window.alert(
			`Será gerado o PDF apenas dos dados em tela. com um total de ${newSelectedRows.length} linha(s) selecionada(s).`,
		)

		generatePDFDocument(newSelectedRows)
	}

	React.useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const updatePage = React.useCallback(() => {
		showLoadingBlur()
		loadPageData()
		setFilters({
			name: '',
			serial: '',
		})
		setView('table')

		setTimeout(() => {
			setShowModalEdit({
				show: false,
				charger: {},
			})
			hideLoadingBlur()
		}, 1000)
	}, [hideLoadingBlur, loadPageData, showLoadingBlur])

	const style = {
		borderRadius: '50%',
		padding: '2px',
		width: '28px',
		height: '28px',
	}

	const onDuplicate = React.useCallback((item) => {
		setDuplicateCharger(item)
	}, [])

	React.useEffect(() => {
		if (duplicateCharger) {
			setView('register')
		}
	}, [duplicateCharger])

	const actions = [
		{
			label: <FontAwesomeIcon icon={faEdit} />,
			action: onEdit,
			title: 'Editar',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faTrash} />,
			action: handleDeleteCharger,
			title: 'Excluir',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faExclamationTriangle} />,
			action: onRegisterMalfunction,
			title: 'Avaria',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faBan} />,
			action: onDisableChargerConfirm,
			title: 'Desativar/Ativar',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faCopy} />,
			action: onDuplicate,
			title: 'Duplicar',
			style: style,
		},
	]

	const onGenerateCSV = React.useCallback(() => {
		showLoadingBlur()
		let opt = filters
		opt.selectAll = selectAll
		opt.selectedIds = selectedIds
		opt.deselectIds = deselectIds
		opt.startdate = dateFilter.initialDate
		opt.finaldate = dateFilter.endDate
		opt.type = 'csv'

		createFetchRequest(
			'report/exportChargerReport',
			'post',
			opt,
			async ({ err, result }) => {
				if (err) console.log(err)
				else if (!err && result) {
					setTimeout(() => {
						window.open(result)
					}, 2000)
				}

				setLoading(false)
				hideLoadingBlur()
			},
		)
	}, [
		dateFilter.endDate,
		dateFilter.initialDate,
		deselectIds,
		filters,
		hideLoadingBlur,
		selectAll,
		selectedIds,
		showLoadingBlur,
	])

	const alertModal =
		alertsModal === null ? null : (
			<AlertsModal
				type={alertsModal.type}
				device={alertsModal.device}
				last={true}
				closeModal={() => setAlertModal(null)}
			/>
		)


	return (
		<MainContentDiv>
			{view === 'table' && (
				<ChargerTable
					actions={actions}
					loading={loading}
					columns={columns}
					rows={list}
					period={dateFilter}
					filters={filter}
					name="LISTA DE CARREGADORES"
					selectAll={selectAll}
					selectedIds={selectedIds}
					selectable
					view={view}
					deselectIds={deselectIds}
					registerTitle="CADASTRAR"
					onChangeView={onChangeView}
					onSelect={onSelectRow}
					onSelectAll={onSelectAll}
					pagination={pagination}
					onGenerateQRCODE={contructPDFDocument}
					onGenerateCSV={onGenerateCSV}
				/>
			)}
			{view === 'register' && (
				<ChargerCreate
					view={view}
					name="LISTA DE CARREGADORES"
					onChangeView={onChangeView}
					registerTitle="CADASTRAR"
					updatePage={updatePage}
					preview={duplicateCharger}
				/>
			)}
			{alertsModal === null ? null : alertModal}
			<Malfunction
				data={registerMalfunction.charger}
				show={registerMalfunction.show}
				onDimiss={onDimissMalfunction}
				onSuccess={onSaveMalfunction}
				title={`Registrar Avaria - Carregador ${registerMalfunction?.charger?.name}`}
			/>
			<EditCharger
				editData={showModalEdit.charger}
				show={showModalEdit.show}
				updatePage={updatePage}
				handleCancelEdit={() => {
					setShowModalEdit({ show: false, charger: {} })
				}}
			/>
			<ConfirmationModal
				title={confirmationModal.title}
				message={confirmationModal.message}
				onClose={() => {
					setConfirmationModal({ title: '', message: '', show: false })
				}}
				onConfirmation={onDisableBattery}
				show={confirmationModal.show}
			/>
			<InfoModal
				modal={infoModal.message}
				onClose={() => {
					setInfoModal({ title: '', message: '', show: false })
				}}
			/>
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(Chargers)
