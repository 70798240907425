import React, { useCallback, useEffect, useState } from 'react'
import {
	faBatteryEmpty,
	faBatteryFull,
	faBatteryQuarter,
	faBatteryThreeQuarters,
} from '@fortawesome/free-solid-svg-icons'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions/index'
import ChargeStatusFilter from 'src/components/Filter/ChargeStatusFilter'
import ChargerTable from 'src/components/Table'
import DateFilter from 'src/components/Filter/DateFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { GetStatusFile } from 'src/services/file'
import InconsistenceFilter from 'src/components/Filter/InconsistenceFilter'
import { MainContentDiv } from 'src/styles/globalStyles'
import { PURPLE } from 'src/styles/colors'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createFetchRequest } from 'src/genericFunctions'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'

const formatDate = (timestamp) => {
	let date = new Date(timestamp)
	let format = date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
	return format
}

async function formatRows(data) {
	let rows = []

	for (let i = 0; i < data.length; i++) {
		data[i].batteryname = data[i].battery_empty.name
		data[i].client = data[i].branch.client.name
		data[i].application = data[i].battery_empty.forklift_model.name
		data[i].chargername = data[i].charger.name
		data[i].employee_starter = data[i].collaborator_started.name
		data[i].employee_ended = data[i].collaborator_ended ? data[i].collaborator_ended.name : null
		data[i].batteryroom = data[i].charger.battery_room.name
		data[i].start = formatDate(data[i].start_date)
		data[i].end = data[i].end_date ? formatDate(data[i].end_date) : null

		rows.push(data[i])
	}

	return rows
}

function formatColumns(profileId, orderCallback, filterDateStart, filterDateEnd, filterInconsistence) {

	let columns = [
		{
			accessor: 'id',
			Header: dictionary.id,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'client',
			Header: dictionary.client,
			csvHeader: 'Cliente',
			showColumn: profileId === 1,
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
		},
		{
			accessor: 'batteryname',
			Header: dictionary.BatteryEmpty,
			csvHeader: 'Descarregada',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
		},
		{
			accessor: 'application',
			Header: dictionary.Application,
			csvHeader: 'Aplicação',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
		},
		{
			accessor: 'chargername',
			Header: dictionary.charger,
			csvHeader: 'Carregador',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
		},
		{
			accessor: 'batteryroom',
			Header: dictionary.BatteryRooms,
			csvHeader: 'Sala de Baterias',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
		},
		{
			accessor: 'start',
			Header: dictionary.Start,
			csvHeader: 'Inicio',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			customFilter: <DateFilter onChange={filterDateStart.callback} value={filterDateStart.value} />
		},
		{
			accessor: 'employee_starter',
			Header: dictionary.employeeStarter,
			csvHeader: 'Colaborador Iniciou',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
		},
		{
			accessor: 'end',
			Header: dictionary.End,
			csvHeader: 'Final',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			customFilter: <DateFilter onChange={filterDateEnd.callback} value={filterDateEnd.value} />
		},
		{
			accessor: 'employee_ended',
			Header: dictionary.employeeCloser,
			csvHeader: 'Colaborador Finalizou',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
			valueFormatter: (row, value) => {
				if (row.end != null && row.employee_ended == null) {
					return '--------'
				} else {
					return row.employee_ended
				}
			}
		},
		{
			accessor: 'time_available_for_charging',
			Header: <p>Tempo disponível <br /> para carga</p>,
			csvHeader: 'Tempo de Carga',
			width: 'auto',
			priority: 1,
			order: true,
			showFilter: true,
		},
		{
			accessor: 'inconsistences',
			Header: <p>Inconsistências</p>,
			csvHeader: 'Inconsistências',
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				let newValue = ''

				value.forEach((element, index) => {
					if (index !== 0) {
						newValue = newValue.concat('/')
					}
					newValue = newValue.concat(element.description)
				})

				return newValue
			},
			showFilter: true,
			customFilter: <InconsistenceFilter onChange={filterInconsistence.callback} value={filterInconsistence.value} />
		},
		{
			accessor: 'status',
			Header: dictionary.Status,
			csvHeader: 'Status',
			width: 'auto',
			priority: 1,
			valueFormatter: (row, value) => {
				// Vermelho - 00:00 a 01:59 (descarregada)
				// Amarelo - 2:00 a 5:59 (meia carga)
				// Verde - 6:00 a 7:59 (carregada)
				// Roxo - 8:00 em diante (carregada/descansada)

				const myTime = row.time_available_for_charging
				if (parseInt(myTime) >= 10 || myTime >= '08:00') {
					return (
						<FontAwesomeIcon
							icon={faBatteryFull}
							style={{ fontSize: '20px', color: PURPLE }}
						/>
					)
				} else if (myTime >= '06:00') {
					return (
						<FontAwesomeIcon
							icon={faBatteryThreeQuarters}
							style={{ fontSize: '20px', color: '#138A36' }}
						/>
					)
				} else if (myTime >= '02:00') {
					return (
						<FontAwesomeIcon
							icon={faBatteryQuarter}
							style={{ fontSize: '20px', color: '#F5B700' }}
						/>
					)
				} else {
					return (
						<FontAwesomeIcon
							icon={faBatteryEmpty}
							style={{ fontSize: '20px', color: '#F00' }}
						/>
					)
				}
			},
			// showFilter: true,
			// customFilter: <ChargeStatusFilter />
		}
	]

	return columns
}


function BatteriesChargeReport({ hideLoadingBlur, showLoadingBlur }) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()
	const profileId = userInfos.profile_id
	const limit = 100

	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({
		totalPages: 0,
		total: 0,
		page: 1,
	})
	const [loading, setLoading] = React.useState(true)
	const [filters, setFilters] = React.useState({
		client: '',
		batteryname: '',
		application: '',
		chargername: '',
		batteryroom: '',
		employee_starter: '',
		employee_ended: '',
		id: '',
	})

	const [orderFilter, setOrderFilter] = React.useState({
		orderField: '',
		order: '',
	})


	const [columns, setColumns] = useState([])
	const [list, setList] = useState([])
	const [dateFilter, setDateFilter] = useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})
	const [selectedIds, setSelectedIds] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)
	const [deselectIds, setDeselectIds] = React.useState([])

	const filterValueColumn = React.useCallback((orderField, order) => {
		setOrderFilter({ orderField, order })
	}, [])

	const loadPageData = useCallback(() => {
		showLoadingBlur()

		createFetchRequest(
			'report/charge/'
				.concat(`${limit}/${page}/`)
				.concat(`?startdate=${dateFilter.initialDate}&finaldate=${dateFilter.endDate}`)
				.concat(`&client=${filters.client}`).concat(`&batteryname=${filters.batteryname}`)
				.concat(`&application=${filters.application}`).concat(`&charger=${filters.chargername}`)
				.concat(`&batteryroom=${filters.batteryroom}`)
				.concat(`&employee_starter=${filters.employee_starter}`).concat(`&employee_ended=${filters.employee_ended}`)
				.concat(`&orderField=${orderFilter.orderField}`).concat(`&order=${orderFilter.order}`)
				.concat(`&id=${filters.id}`).concat(`&start=${filters.start}&end=${filters.end}`).concat(`&time_available_for_charging=${filters.time_available_for_charging}`).concat(`&inconsistences=${filters.inconsistences}`),
			'get',
			null,
			async ({ err, result }) => {
				if (err) setList([])
				else if (!err && result) {
					const rows = await formatRows(result.response)
					setList(rows)
					setPagination({ totalPages: result.totalPages, total: result.total, page: result.page })
					if (page > parseInt(result.totalPages))
						setPage(1)
				}
				let columns = formatColumns(profileId, filterValueColumn, { callback: filterDateStart, value: filters.start }, { callback: filterDateEnd, value: filters.end }, { callback: filterInconsistence, value: filters.inconsistences })
				setColumns(columns)
				setLoading(false)
				hideLoadingBlur()
			},
		)
	}, [showLoadingBlur, page, dateFilter.initialDate, dateFilter.endDate, filters, orderFilter.orderField, orderFilter.order, profileId, filterValueColumn, hideLoadingBlur])


	const filterColumn = (filter, column) => {
		filters[column] = filter
		setFilters({ ...filters })
	}

	const filterDateStart = (event) => {
		setFilters((prev) => ({ ...prev, start: event.target.value }))
	}

	const filterDateEnd = (event) => {
		setFilters((prev) => ({ ...prev, end: event.target.value }))
	}

	const filterInconsistence = (event) => {
		setFilters((prev) => ({ ...prev, inconsistences: event.target.value }))
	}

	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({ initialDate: start, endDate: end })
	}

	const onSelectRow = (row, checked) => {
		if (selectAll) {
			if (!checked)
				setDeselectIds([...deselectIds, row.id])
			else
				setDeselectIds(deselectIds.filter((r) => r !== row.id))
		} else {
			if (checked)
				setSelectedIds([...selectedIds, row.id])
			else
				setSelectedIds(selectedIds.filter((r) => r !== row.id))
		}
	}

	const onSelectAll = (checked) => {
		setDeselectIds([])
		setSelectedIds([])
		setSelectAll(checked)
	}

	const onGenerateCSV = React.useCallback(() => {
		showLoadingBlur()
		let opt = filters
		opt.selectAll = selectAll
		opt.selectedIds = selectedIds
		opt.deselectIds = deselectIds
		opt.startdate = dateFilter.initialDate
		opt.finaldate = dateFilter.endDate
		opt.type = 'csv'

		createFetchRequest(
			'report/exportBatteryChargeReport',
			'post',
			opt,
			async ({ err, result }) => {
				if (err)
					console.log(err)
				else if (!err && result) {
					setTimeout(() => {
						window.open(result)
					}, 2000)
				}

				setLoading(false)
				hideLoadingBlur()
			},
		)
	}, [dateFilter.endDate, dateFilter.initialDate, deselectIds, filters, hideLoadingBlur, selectAll, selectedIds, showLoadingBlur])

	// const onGeneratePDF = React.useCallback(() => {
	// 	showLoadingBlur()
	// 	let opt = filters
	// 	opt.selectAll = selectAll
	// 	opt.selectedIds = selectedIds
	// 	opt.deselectIds = deselectIds
	// 	opt.startdate = dateFilter.initialDate
	// 	opt.finaldate = dateFilter.endDate
	// 	opt.type = 'pdf'

	// 	createFetchRequest(
	// 		'report/generateCSVChargeReport',
	// 		'post',
	// 		opt,			
	// 		async ({err, result}) => {
	// 			if (err)
	// 				console.log(err)
	// 			else if (!err && result) {					
	// 				setShowModal(true)
	// 				GetStatusFile(result)
	// 			}

	// 			setLoading(false)
	// 			hideLoadingBlur()
	// 		},
	// 	)
	// },[dateFilter.endDate, dateFilter.initialDate, deselectIds, filters, hideLoadingBlur, selectAll, selectedIds, showLoadingBlur])

	const filter = {
		filterDate,
		filterColumn,
		previous: () => { setPage(page - 1) },
		next: () => { setPage(page + 1) },
		lastPage: () => { setPage(pagination.totalPages) },
		firstPage: () => { setPage(1) },
		selectPage: (value) => { setPage(parseInt(value)) },
		dateFilter,
		searchFilters: filters,
	}

	useEffect(() => {
		loadPageData()
	}, [loadPageData])

	return (
		<MainContentDiv>
			<ChargerTable
				columns={columns}
				rows={list}
				name={'RELATÓRIO DE CARGA'}
				period={dateFilter}
				pagination={pagination}
				filters={filter}
				loading={loading}
				onGenerateCSV={onGenerateCSV}
				// onGeneratePDF={onGeneratePDF}
				selectable
				onSelect={onSelectRow}
				onSelectAll={onSelectAll}
				selectAll={selectAll}
				selectedIds={selectedIds}
				deselectIds={deselectIds}
				showHiddenColumn={true}
			/>

		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(BatteriesChargeReport)
