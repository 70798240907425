import {PURPLE_DARK} from '../../styles/colors'
import React from 'react'

const QRCode = require('qrcode.react')

/**
 * @author Carlos Padovezi
 * @function QrCode
 * @file QrCode.js
 * @summary Function to create QrCode div
 * @host https://github.com/zpao/qrcode.react
 * @param {string} code - hash of qrcode
 * @param {number} size - qrcode size
 * @param {number} width - qrcode width
 * @param {number} heigth - qrcode height
 * @param {string} bgColor - background qrcode color. Default: #FFFFFF.
 * @param {string} fgColor - Foward qrcode color. Default: PURPLE_DARK.
 * @param {boolean} includeMargin - Indicates if img shoul have margin. Default: false.
 * @param {string} renderAs - Chose between SVG or canvas. Default: 'canvas'.
 * @returns QrCode div
 */
export function QrCode({
	code,
	size,
	width,
	height,
	bgColor,
	fgColor,
	includeMargin,
	renderAs,
}) {
	if (
		!code ||
		code === undefined ||
		typeof code !== 'string' ||
		code.length === 0
	)
		return <div></div>

	const mySize = size ? size : 200
	const myFgColor = fgColor ? fgColor : '#FFFFFF'
	const myBgColor = bgColor ? bgColor : PURPLE_DARK
	const shouldIncludeMargin = includeMargin === true ? true : false

	const imageSettings = {
		width: width ? width : 'auto',
		height: height ? height : 'auto',
		x: width ? width : 'auto',
		y: height ? height : 'auto',
		src: code ? code : '',
	}

	return (
		<div>
			<QRCode
				value={code}
				level="M"
				size={mySize}
				fgColor={myFgColor}
				bgColor={myBgColor}
				renderAs={'svg'}
				includeMargin={shouldIncludeMargin}
				imageSettings={imageSettings}
			/>
		</div>
	)
}
