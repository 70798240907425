import axios from 'axios'

const apiPdf = axios.create({
	baseURL: process.env.REACT_APP_SERVICE_PDF,
})

apiPdf.interceptors.request.use((request) => {
	return request
})

apiPdf.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		throw error
	},
)

export function updateApiHeader(keys) {
	apiPdf.defaults.headers = {
		...apiPdf.defaults.headers,
		...keys,
	}
}

export default apiPdf
