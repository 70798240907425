import { AlignmentType, Document, HeadingLevel, ImageRun, Packer, Paragraph, Table, TableCell, TableRow, TextRun, WidthType } from 'docx'
import CobeLogo from './assets/cobe.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MonthReportImage from './assets/month-report.png'
import React from 'react'
import dictionary from 'src/utils/dictionary'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { getColorHexAutonomy } from 'src/genericFunctions'
import { renderAsync } from 'docx-preview'
import { saveAs } from 'file-saver'

const margins = {
	bottom: 10,
	left: 10,
	right: 10,
	top: 10,
}

const spacing = {
	after: 100,
	before: 100,
}

function efficiencyStyle(value) {
	if (value <= 0) {
		return undefined
	}

	if (value >= 90) {
		return '#0a8754'
	}

	if (value >= 80 && value < 90) {
		return '#9ea93f'
	}

	if (value >= 70 && value < 80) {
		return '#f2af29'
	}

	if (value >= 60 && value < 70) {
		return '#ff9b42'
	}

	if (value < 60 && value > 0) {
		return '#ad343e'
	}
}

const MonthReportView = ({ lastAutonomies, month, year, client, branch, charts, averageBatteryRooms, autonomyOperation, averageAutonomyBatteryModel, countCyclesByEmployee, diffBetweenExchangeAndCharge, date, data }) => {
	const [blob, setBlob] = React.useState()

	const line = (color = '#000000', size = 12) => {
		const l = new Paragraph({
			border: {
				top: {
					color,
					space: 1,
					value: 'single',
					size,
				}
			},
			spacing: {
				after: 100,
				before: 100,
			},

		})

		return l
	}

	const download = async () => {
		const properties = {
			page: {
				margin: {
					top: "94.4881889775 px",
					bottom: "94.4881889775 px",
				},
			},
		}

		const paragraphOptions = {
			indent: {
				firstLine: '30px'
			},
			spacing: {
				before: 400,
			},
			alignment: AlignmentType.JUSTIFIED,
		}

		const breakline = new Paragraph("")

		const margin = '	'

		const blobVal = await loadData(properties, paragraphOptions, margin, breakline, 620)
		saveAs(blobVal, `RelatorioMensal-${client}.docx`)
	}

	const loadData = React.useCallback(async (properties, paragraphOptions, margin, breakline, widthReportImage = 700) => {
		const cobeLogo = await fetch(CobeLogo)
		const monthReportImage = await fetch(MonthReportImage)

		const logo = new Paragraph({
			children: [
				new ImageRun({
					data: await cobeLogo.blob(),
					transformation: {
						width: 200,
						height: 80,
					}
				})
			],
			alignment: AlignmentType.RIGHT,
		})

		const tableAverageAutonomy = new Table({
			width: {
				size: 100,
				type: WidthType.PERCENTAGE,
			},
			rows: [
				new TableRow({
					children: lastAutonomies.map((e, index) => (
						new TableCell({
							children: [
								new Paragraph({
									children: [new TextRun({ text: e.label, color: "#000000", bold: true, })],
									heading: HeadingLevel.HEADING_2,
									spacing,
									alignment: AlignmentType.CENTER,
									indent: {
										start: 300,
										end: 300,
									},

								})
							],
							margins,
							verticalAlign: 'center',
							shading: {
								fill: "#b3b3b3",
							},
							width: {
								size: index === -0 ? 100 / lastAutonomies - 1 : 100 / lastAutonomies.length + 1,
								type: WidthType.PERCENTAGE,
							},
						})
					)),
					tableHeader: true,
				}),
				new TableRow({
					children: lastAutonomies.map((e, index) => (
						new TableCell({
							children: [
								new Paragraph({
									text: parseFloat(e.value).toFixed(1),
									spacing,
									alignment: AlignmentType.CENTER
								})
							],
							margins,
							shading: {
								fill: getColorHexAutonomy(e.value),
							},
							width: {
								size: index === -0 ? 100 / lastAutonomies - 1 : 100 / lastAutonomies.length + 1,
								type: WidthType.PERCENTAGE,
							},
						})
					))
				})
			],
		})

		const generateTable = (rows, columns, label, styleCell = getColorHexAutonomy) => {
			let labelVal

			if (columns[0] !== 'label') {
				labelVal = new TableCell({
					children: [
						new Paragraph({
							text: label,
							spacing,
							alignment: AlignmentType.CENTER,
							indent: {
								start: 300,
								end: 300,
							},
						})
					],
					shading: {
						fill: "#b3b3b3",
					},
					margins,
					width: {
						size: 100 / (columns.length + 2),
						type: WidthType.PERCENTAGE,
					}
				})
			}

			return (
				new Table({
					width: {
						size: 100,
						type: WidthType.PERCENTAGE,
					},
					rows: [
						new TableRow({
							children: [
								labelVal,
								...columns.map((column) => (
									new TableCell({
										children: [
											new Paragraph({
												text: column === 'label' ? label : column,
												spacing,
												alignment: AlignmentType.CENTER,
												indent: {
													start: 300,
													end: 300,
												},
											})
										],
										margins,
										width: {
											size: 100 / columns.length,
											type: WidthType.PERCENTAGE,
										},
										shading: {
											fill: "#b3b3b3",
										}
									})
								))
							],
							tableHeader: true,
						}),
						...rows.map(row => (
							new TableRow({
								children: [
									new TableCell({
										children: [
											new Paragraph({
												text: row.label,
												spacing,
												alignment: AlignmentType.CENTER,
												indent: {
													start: 300,
													end: 300,
												},
											})
										],
										margins,
										width: {
											size: 100 / (columns.length + 1),
											type: WidthType.PERCENTAGE,
										},
									}),
									...columns.map(col => {
										if (col !== 'label') {
											return (
												new TableCell({
													children: [
														new Paragraph({
															text: `${row[col]}` !== 'undefined' ? `${row[col]}` : row[col],
															spacing,
															alignment: AlignmentType.CENTER,
															indent: {
																start: 300,
																end: 300,
															},
															shading: {
																fill: styleCell(parseFloat(row[col]).toFixed(1)),
															},
														})
													],
													shading: {
														fill: styleCell(parseFloat(row[col]).toFixed(1))
													},
													margins,
													width: {
														size: 100 / (columns.length + 1),
														type: WidthType.PERCENTAGE,
													},
												})
											)
										}

										return undefined
									})
								],
							})
						)),
					],
				})
			)
		}

		const getBestEmployee = () => {
			const { columns, rows } = countCyclesByEmployee

			if (!rows || rows.length === 0) {
				return null
			}

			const key = columns[columns.length - 1]
			const arrOrder = rows.sort((a, b) => {
				if (a[key] < b[key]) {
					return 1
				}

				if (b[key] < a[key]) {
					return -1
				}

				return -1
			})

			return { employee: arrOrder[0].label, count: arrOrder[0][key] }
		}


		const page1 = {
			properties,
			children: [
				logo,
				new Paragraph({
					children: [
						new TextRun({
							text: "RELATÓRIO MENSAL",
							color: '#000000',
							bold: true,
						})
					],
					alignment: AlignmentType.CENTER,
					heading: HeadingLevel.HEADING_1,
					contextualSpacing: true,
					spacing: {
						before: 400,
					}
				}),
				line(),
				new Paragraph({
					alignment: AlignmentType.RIGHT,
					children: [
						new TextRun({
							text: dictionary.arrayNamesMonths[month] + ' ' + year + margin,
							color: '#8A05BE',
							bold: true,
						})
					],
				}),
				breakline,
				new Paragraph({
					alignment: AlignmentType.LEFT,
					children: [
						new TextRun({
							text: margin + "OPERAÇÃO: " + client,
							bold: true,
						}),
					],
				}),
				breakline,
				new Paragraph({
					alignment: AlignmentType.LEFT,
					children: [
						new TextRun({
							text: margin + "UNIDADE: " + branch,
							bold: true,
						}),
					],
				}),
				breakline,
				breakline,
				breakline,
				new Paragraph({
					children: [
						new ImageRun({
							data: await monthReportImage.blob(),
							transformation: {
								width: widthReportImage,
								height: 300,
							},
						}),
					],
					alignment: AlignmentType.LEFT,

				}),
				breakline,
				// pageNumber("1"),
			]
		}

		const page2 = {
			properties,
			children: [
				logo,
				line('#8A05BE', 20),
				new Paragraph({
					children: [new TextRun({ text: "Introdução", bold: true, color: "#000000", size: 30 })]
				}),
				new Paragraph({
					children: [new TextRun({ text: "Prezado cliente,", color: "#000000", size: 30 })],
					...paragraphOptions,
				}),
				new Paragraph({
					children: [new TextRun({ text: "Este relatório busca sintetizar os números obtidos dentro da sua operação, através da utilização do aplicativo Cobe.", color: "#000000", size: 30 })],
					...paragraphOptions,
					spacing: {
						before: 500,
					},
				}),
				new Paragraph({
					children: [new TextRun({ text: "Todos os números foram extraídos de dentro da plataforma e organizados a fim de gerar indicadores personalizados para auxiliá-los no entendimento da operação e de tal forma auxiliar na tomada de decisão através de indicadores reais.", color: "#000000", size: 30 })],
					...paragraphOptions
				}),
				new Paragraph({
					children: [new TextRun({ text: "Outros indicadores poderão ser encontrados dentro da nossa plataforma online ou solicitados através do nosso e-mail contato@cobeapp.com.br.", color: "#000000", size: 30 })],
					...paragraphOptions
				}),
				new Paragraph({
					children: [new TextRun({ text: "Atenciosamente,", color: "#000000", size: 30 })],
					...paragraphOptions,
					alignment: AlignmentType.RIGHT,
				}),
				new Paragraph({
					children: [new TextRun({ text: "Equipe COBE", color: "#000000", size: 30 })],
					...paragraphOptions,
					alignment: AlignmentType.RIGHT,
					spacing: {
						before: 800,
					}
				}),
				new Paragraph({
					children: [new TextRun({ text: "Gestão de Empilhadeiras e Sala de Baterias Inteligente", color: "#000000", size: 30 })],
					...paragraphOptions,
					alignment: AlignmentType.RIGHT,
					spacing: {
						before: 100,
					}
				}),
				// pageNumber("2"),
			]
		}

		const page3 = {
			properties,
			children: [
				logo,
				line('#8A05BE', 20),
				new Paragraph({
					children: [new TextRun({ text: "AUTONOMIA MÉDIA DA OPERAÇÃO", bold: true, color: "#000000", size: 30 })],
					spacing: {
						after: 500
					}
				}),
				tableAverageAutonomy,
				new Paragraph({
					children: [new TextRun({
						text: `A operação rodou com uma autonomia média de ${parseFloat(lastAutonomies[lastAutonomies.length - 1].value).toFixed(1)} horas para cada ciclo. Ou seja, esse é o tempo médio trabalhado cada vez que uma máquina vai para operação (horas operacionais), independente de marca, modelo ou operação.`,
						size: 23,
					})],
					...paragraphOptions,
				}),
				new Paragraph({
					children: [
						new ImageRun({
							data: charts[0],
							transformation: {
								width: 600,
								height: 350,
							},
						}),
					],
					...paragraphOptions,
					alignment: AlignmentType.CENTER,
				}),
				new Paragraph({
					children: [
						new TextRun({
							text: "A performance pode ter relação com diversos fatores, dentre eles: idade das baterias, intensidade da operação no período, aumento de turnos de trabalho, envelhecimento da frota, entre outros.",
							size: 23
						})],
					...paragraphOptions
				}),
				// pageNumber("3")
			]
		}

		const page4 = {
			properties,
			children: [
				logo,
				line('#8A05BE', 20),
				new Paragraph({
					children: [new TextRun({ text: "AUTONOMIA MÉDIA POR SALA DE BATERIAS", bold: true, color: "#000000", size: 30 })],
					spacing: {
						after: 500
					}
				}),
				generateTable(averageBatteryRooms.rows, averageBatteryRooms.columns, "Sede"),
				new Paragraph({
					children: [new TextRun({
						text: `A operação rodou com uma autonomia média de ${parseFloat(lastAutonomies[lastAutonomies.length - 1].value).toFixed(1)} horas para cada ciclo. Ou seja, esse é o tempo médio trabalhado cada vez que uma máquina vai para operação (horas operacionais), independente de marca, modelo ou operação.`,
						size: 23,
					})],
					...paragraphOptions,
				}),
				new Paragraph({
					children: [
						new ImageRun({
							data: charts[1],
							transformation: {
								width: 600,
								height: 350,
							},
						}),
					],
					...paragraphOptions,
					alignment: AlignmentType.CENTER,
				}),
				new Paragraph({
					children: [
						new TextRun({
							text: "A performance pode ter relação com diversos fatores, dentre eles: idade das baterias, intensidade da operação no período, aumento de turnos de trabalho, envelhecimento da frota, entre outros.",
							size: 23
						})],
					...paragraphOptions
				}),
				// pageNumber("4")
			]
		}

		const page5 = {
			properties,
			children: [
				logo,
				line('#8A05BE', 20),
				new Paragraph({
					children: [new TextRun({ text: "AUTONOMIA MÉDIA POR SUB-OPERAÇÃO (Sala x modelo de máquina)", bold: true, color: "#000000", size: 30 })],
					spacing: {
						after: 500
					}
				}),
				generateTable(autonomyOperation.rows, autonomyOperation.columns, "Operação"),
				new Paragraph({
					children: [new TextRun({
						text: `Se realizarmos a mesma análise, mas desta vez separando por sub-operação (ou seja, 	por modelo x sala) observamos que alguns modelos têm melhor performance que outras.`,
						size: 23,
					})],
					...paragraphOptions,
				}),
				// pageNumber("5")
			]
		}

		const page6 = {
			properties,
			children: [
				logo,
				line('#8A05BE', 20),
				new Paragraph({
					children: [new TextRun({ text: "AUTONOMIA MÉDIA POR MODELO DE MÁQUINA", bold: true, color: "#000000", size: 30 })],
					spacing: {
						after: 500
					}
				}),
				generateTable(averageAutonomyBatteryModel.rows, averageAutonomyBatteryModel.columns, "Modelo"),
				// pageNumber("6")
			]
		}

		const page7 = {
			properties,
			children: [
				logo,
				line('#8A05BE', 20),
				new Paragraph({
					children: [new TextRun({ text: "QUANTIDADE DE CICLOS REGISTRADOS POR OPERADOR", bold: true, color: "#000000", size: 30 })],
					spacing: {
						after: 500
					}
				}),
				generateTable(countCyclesByEmployee.rows, countCyclesByEmployee.columns, "Operador"),
				new Paragraph({
					children: [new TextRun({
						text: `Com relação a quantidade de ciclos iniciados por cada operador, há bastante diferença por conta das salas e turnos onde os operadores atuam. Mas o destaque do mês vai para o operador ${getBestEmployee()?.employee} que neste mês iniciou ${getBestEmployee()?.count} ciclos respectivamente.`,
						size: 23
					})],
					...paragraphOptions
				}),
				// pageNumber("7")
			]
		}

		const page8 = {
			properties,
			children: [
				logo,
				line('#8A05BE', 20),
				new Paragraph({
					children: [new TextRun({ text: "DIFERENÇA DE TROCAS INICIADAS X CARGAS INICIADAS", bold: true, color: "#000000", size: 30 })],
					spacing: {
						after: 500
					}
				}),
				generateTable(diffBetweenExchangeAndCharge.rows, diffBetweenExchangeAndCharge.columns, "Colaborador", efficiencyStyle),
				// pageNumber("8")
			]
		}

		const page9 = {
			properties,
			children: [
				logo,
				line('#8A05BE', 20),
				new Paragraph({
					children: [new TextRun({ text: "HORÁRIOS DE PICO DE TROCAS DE BATERIAS", bold: true, color: "#000000", size: 30 })],
					spacing: {
						after: 500
					}
				}),
				new Paragraph({
					children: [new TextRun({
						text: `Abaixo segue o gráfico que mostra o horário de pico de trocas de baterias no mês atual.`,
						size: 23,
					})],
					...paragraphOptions,
				}),
				new Paragraph({
					children: [
						new ImageRun({
							data: charts[2],
							transformation: {
								width: 600,
								height: 350,
							},
						}),
					],
					...paragraphOptions,
					alignment: AlignmentType.CENTER,
				}),
				new Paragraph({
					children: [new TextRun({
						text: `No gráfico azul analisamo o período dos últimos 6 meses`,
						size: 23,
					})],
					...paragraphOptions,
				}),
				new Paragraph({
					children: [
						new ImageRun({
							data: charts[3],
							transformation: {
								width: 600,
								height: 350,
							},
						}),
					],
					...paragraphOptions,
					alignment: AlignmentType.CENTER,

				}),
				// pageNumber("9")
			]
		}

		const page10 = {
			properties,
			children: [
				logo,
				line('#8A05BE', 20),
				new Paragraph({
					children: [new TextRun({ text: "NÚMEROS GERAIS DA OPERAÇÃO", bold: true, color: "#000000", size: 30 })],
					spacing: {
						after: 500
					}
				}),
				new Paragraph({
					children: [new TextRun({ text: "Sua Operação em", bold: true, color: "#000000", size: 25 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
				new Paragraph({
					children: [new TextRun({ text: date, bold: true, color: "#8A05BE", size: 45 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
				new Paragraph({
					children: [new TextRun({ text: "Autonomia Média da Operação x Ciclo", bold: true, color: "#000000", size: 25 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
				new Paragraph({
					children: [new TextRun({ text: data.autonomy, bold: true, color: "#8A05BE", size: 55 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
				new Paragraph({
					children: [new TextRun({ text: "Horas Operacionais Totais", bold: true, color: "#000000", size: 25 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
				new Paragraph({
					children: [new TextRun({ text: data.total_operating_hours.toString(), bold: true, color: "#8A05BE", size: 55 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
				new Paragraph({
					children: [new TextRun({ text: "Ciclos Iniciados", bold: true, color: "#000000", size: 25 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
				new Paragraph({
					children: [new TextRun({ text: data.total_cycles_registered.toString(), bold: true, color: "#8A05BE", size: 55 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
				new Paragraph({
					children: [new TextRun({ text: "Cargas Iniciadas", bold: true, color: "#000000", size: 25 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
				new Paragraph({
					children: [new TextRun({ text: data.total_chargers.toString(), bold: true, color: "#8A05BE", size: 55 })],
					alignment: AlignmentType.CENTER,
					spacing,
				}),
			]
		}

		const doc = new Document({
			sections: [
				page1,
				page2,
				page3,
				page4,
				page5,
				page6,
				page7,
				page8,
				page9,
				page10,
			],
			compatabilityModeVersion: 1,
			compatibility: 1,
			title: "Relatório Mensal",
			styles: {
				paragraphStyles: [{ uiPriority: 1 }]
			}
		})


		const blobVal = await Packer.toBlob(doc)

		return blobVal
	}, [autonomyOperation.columns, autonomyOperation.rows, averageAutonomyBatteryModel.columns, averageAutonomyBatteryModel.rows, averageBatteryRooms.columns, averageBatteryRooms.rows, charts, countCyclesByEmployee, diffBetweenExchangeAndCharge.columns, diffBetweenExchangeAndCharge.rows, lastAutonomies, month, year, date, data])

	React.useEffect(() => {
		const properties = {
			page: {
				margin: {
					top: "30px",
					right: "40px",
					bottom: "30px",
					left: "40px",
				},
			},
		}

		const paragraphOptions = {
			indent: {
				firstLine: '30px'
			},
			spacing: {
				before: 400,
			},
			alignment: AlignmentType.JUSTIFIED,
		}

		const breakline = new Paragraph("")

		const margin = '	'

		const load = async () => {
			const blobVal = await loadData(properties, paragraphOptions, margin, breakline)
			setBlob(blobVal)
		}


		const renderFile = async () => {
			try {
				console.log(blob)
				await renderAsync(blob, document.getElementById('view'))
			} catch (error) {
				console.log(error)
			}
		}

		if (blob === undefined) {
			load()
		} else {
			renderFile()
		}


	}, [blob, loadData])

	return (
		<>
			<div
				onClick={download}
				style={{
					padding: '5px',
					cursor: 'pointer',
					position: 'fixed',
					right: '10px',
					top: '10px',
					display: 'flex',
					alignItems: 'center',
					backgroundColor: '#8A05BE',
					borderRadius: '5px',
				}}>
				<p style={{ color: '#ffffff', paddingRight: '5px' }}>
					Baixar DOCX
				</p>
				<FontAwesomeIcon icon={faFileDownload} color="#ffffff" />
			</div>
			<div id="view">

			</div>
		</>
	)
}

export default MonthReportView