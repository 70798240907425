import React, {useCallback, useEffect, useState} from 'react'

import {RowSelect} from '../RowSelect'
import {createFetchRequest} from 'src/genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function RowSelectBranch
 * @file RowSelectBranch.js
 * @param {String} value -
 * @param {Number} clientId -
 * @param {Boolean} isObligatory -
 * @param {String} width -
 * @param {String} height -
 * @param {String} fontSize -
 * @param {Function} onChange -
 * @param {String} type -
 * @param {Boolean} autoFocus -
 * @summary Function to use standard select with label
 * @returns Row with Label and select
 */
export function RowSelectBranch({
	clientId,
	value,
	isObligatory,
	autoFocus,
	height,
	fontSize,
	placeholder,
	onChange,
	readOnly,
}) {
	const [branchSelected, setBranch] = useState(value === null ? '' : value)
	const [optionsList, setOptionsList] = useState([])

	const loadBranchsList = useCallback(() => {
		if (clientId) {
			//get branch list
			createFetchRequest(
				`branch/getAll/?clientId=${clientId}`,
				'get',
				null,
				async ({err, result}) => {
					if (err) {
						console.log(err)
						return
					}
					let myList = []
					if (result && result.length > 0) {
						for (let branch of result) {
							myList.push({value: branch.id, label: branch.name})
						}
					}

					setOptionsList(myList)
				},
			)
		} else {
			setOptionsList([])
		}
	}, [clientId])

	useEffect(() => {
		loadBranchsList()
	}, [loadBranchsList])

	const onChangeFunction = (branch) => {
		setBranch(branch)
		onChange(branch)
	}

	return (
		<RowSelect
			value={branchSelected}
			optionsList={optionsList}
			label={dictionary.Branch}
			isObligatory={isObligatory}
			autoFocus={autoFocus}
			height={height}
			fontSize={fontSize}
			placeholder={placeholder}
			onChange={onChangeFunction}
			readOnly={readOnly}
		/>
	)
}
