import {EditModalBackgroundDiv, EditModalDiv} from '../../styles/globalStyles'
import React, {useState} from 'react'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'

import {Button} from '../../styles/globalStyles'
import {RowCalendar} from '../../components/RowCalendar/index.jsx'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {RowSelectBatteryRoom} from 'src/components/RowSelectBatteryRoom'
import {RowSelectBranch} from 'src/components/RowSelectBranch'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'
import {useEffect} from 'react'

/**
 * @author Carlos Padovezi
 * @function EditCharger
 * @file EditCharger.js
 * @summary Function to create Battery rooms register view
 * @returns Battery rooms create create page
 */
function EditCharger({
	editData,
	updatePage,
	handleCancelEdit,
	hideLoadingBlur,
	showLoadingBlur,
	show,
}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [clientsList, setClientsList] = useState([])
	const [modelsList, setModelsList] = useState([])
	const [formData, setFormData] = useState(null)

	useEffect(() => {
		if (!show) {
			return
		}
		
		showLoadingBlur()
		setFormData({
			name: editData.name ? editData.name : '',
			serial: editData.serial ? editData.serial : '',
			manufacturing_date: editData.manufacturing_date_text
				? editData.manufacturing_date_text
				: '',
			model_id: editData.model_id ? editData.model_id : '',
			battery_room_id: editData.battery_room_id ? editData.battery_room_id : '',
			branch_id: editData.branch_id ? editData.branch_id : '',
			client_id: editData.client_id ? editData.client_id : '',
			id: editData.id ? editData.id : '',
		})		
		//get models list
		createFetchRequest('chargerModel/getAll', 'get', null, ({err, result}) => {
			if (err) {
				console.log(err)
				hideLoadingBlur()
				return
			}
			let myList = []
			if (result && result.length > 0) {
				for (let model of result) {
					myList.push({value: model.id, label: model.name})
				}
			}

			setModelsList(myList)

			//get client list
			createFetchRequest('client/getAll', 'get', null, ({err, result}) => {
				if (err) {
					console.log(err)
					hideLoadingBlur()
					return
				}

				let myList = []
				if (result && result.length > 0) {
					for (let client of result) {
						myList.push({value: client.id, label: client.name})
					}
				}

				setClientsList(myList)
				hideLoadingBlur()
			})
		})
	}, [editData, hideLoadingBlur, showLoadingBlur, show])

	function handleSaveEditCharger() {
		var context = {
			id: formData.id,
			name: formData.name,
			serial: formData.serial,
			manufacturing_date: formData.manufacturing_date,
			model_id: formData.model_id,
			battery_room_id: formData.battery_room_id,
		}

		createFetchRequest('charger/update', 'put', context, ({err, result}) => {
			if (err) {
				console.log(err)
			} else {
				//Reset inputs values
				updatePage()
			}
		})
	}

	let validButton =
		formData &&
		formData.name &&
		formData.serial &&
		formData.manufacturing_date &&
		formData.model_id &&
		formData.battery_room_id

	return (show && formData) ? (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				<RowInput
					value={formData.serial}
					label={dictionary.Serial}
					maxLength={10}
					minLength={10}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(serial) => setFormData((state) => ({...state, serial}))}
				/>
				<RowCalendar
					value={formData.manufacturing_date}
					label={dictionary.ManufacturingDate}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(manufacturing_date) =>
						setFormData((state) => ({...state, manufacturing_date}))
					}
					stringAutoComplite={`00:00:00`}
				/>
				<RowSelect
					value={formData.model_id}
					optionsList={modelsList}
					label={dictionary.Model}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(model_id) =>
						setFormData((state) => ({...state, model_id}))
					}
				/>
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={clientsList}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id}))
						}
					/>
				) : null}
				{formData.branch_id && (
					<RowSelectBranch
						value={formData.branch_id}
						clientId={formData.client_id}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(branch_id) =>
							setFormData((state) => ({...state, branch_id}))
						}
					/>
				)}
				{formData.battery_room_id && (
					<RowSelectBatteryRoom
						value={formData.battery_room_id}
						branchId={formData.branch_id}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(battery_room_id) =>
							setFormData((state) => ({...state, battery_room_id}))
						}
					/>
				)}
				<div>
					<Button
						name={'buttonConfirm'}
						onClick={handleSaveEditCharger}
						disabled={!validButton}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	) : null
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(EditCharger)
