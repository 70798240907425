import {
	ButtonAction,
	Container,
	FilterInput,
	PaginationDiv,
	TableBody,
	TableCell,
	TableGrid,
	TableHeaderCell,
	TableHeaderContainer,
	TableHeaderFilter,
	TableHeaderRow,
	TableRow,
} from './styles'
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import {
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAngleLeft,
	faAngleRight,
	faArrowDown,
	faArrowUp,
	faExclamationTriangle,
	faFileCsv,
} from '@fortawesome/free-solid-svg-icons'
import {
	useAsyncDebounce,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'

import {Button} from 'src/styles/globalStyles'
import {CSVLink} from 'react-csv'
import Constants from 'src/constants'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PURPLE} from 'src/styles/colors'
import dictionary from 'src/utils/dictionary'
import {matchSorter} from 'match-sorter'

function getCell(cell, row) {
	if (row.original?.error_ids?.length === 0) return cell.render('Cell')

	if (
		cell.column.id === 'battery_empty' &&
		row.original.error_ids.includes(Constants.CYCLE_ERROR.MORE_THAN_ONE_RECORD)
	)
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: '#af3232',
				}}>
				<FontAwesomeIcon icon={faExclamationTriangle} />
				{cell.render('Cell')}
			</div>
		)
	if (
		cell.column.id === 'forklift' &&
		row.original.error_ids.includes(Constants.CYCLE_ERROR.NO_LOADING_RECORDS)
	) {
	}
	if (
		cell.column.id === 'forklift' &&
		row.original.error_ids.includes(Constants.CYCLE_ERROR.UNKNOWN_LOCATION)
	) {
	}
	if (
		cell.column.id === 'battery_full' &&
		row.original.error_ids.includes(
			Constants.CYCLE_ERROR.STARTING_A_CYCLE_WITHOUT_ENDING_PREVIOUS,
		)
	)
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: '#af3232',
				}}>
				<FontAwesomeIcon icon={faExclamationTriangle} />
				{cell.render('Cell')}
			</div>
		)
	if (
		cell.column.id === 'battery_empty' &&
		row.original.error_ids.includes(Constants.CYCLE_ERROR.NO_OPEN_RECORDS_FOUND)
	)
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: '#af3232',
				}}>
				<FontAwesomeIcon icon={faExclamationTriangle} />
				{cell.render('Cell')}
			</div>
		)
	if (
		cell.column.id === 'battery_empty' &&
		row.original.error_ids.includes(
			Constants.CYCLE_ERROR.NO_PREVIOUS_RECORDS_FOR_BATTERY,
		)
	)
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: '#af3232',
				}}>
				<FontAwesomeIcon icon={faExclamationTriangle} />
				{cell.render('Cell')}
			</div>
		)
	if (
		cell.column.id === 'battery_full' &&
		row.original.error_ids.includes(
			Constants.CYCLE_ERROR.FORKLIFT_HAS_OPEN_CYCLE,
		)
	)
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: '#af3232',
				}}>
				<FontAwesomeIcon icon={faExclamationTriangle} />
				{cell.render('Cell')}
			</div>
		)
	return cell.render('Cell')
}

const IndeterminateCheckbox = forwardRef(({indeterminate, ...rest}, ref) => {
	const defaultRef = useRef()
	const resolvedRef = ref || defaultRef

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate
	}, [resolvedRef, indeterminate])
	return (
		<div style={{textAlign: 'center'}}>
			<input type="checkbox" ref={resolvedRef} {...rest} />
		</div>
	)
})

// Define a default UI for filtering
function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter}) {
	const count = preGlobalFilteredRows.length
	const [value, setValue] = useState(globalFilter)
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined)
	}, 200)

	return (
		<span>
			{dictionary.Search}:{' '}
			<input
				value={value || ''}
				onChange={(e) => {
					setValue(e.target.value)
					onChange(e.target.value)
				}}
				placeholder={dictionary.searchInResults(count)}
				style={{
					fontSize: '1.1rem',
					border: '0',
				}}
			/>
		</span>
	)
}

// Define a default UI for filtering
function DefaultColumnFilter({
	column: {filterValue, preFilteredRows, setFilter},
}) {
	const count = preFilteredRows.length

	function doNotSort(event) {
		event.stopPropagation()
	}

	return (
		<FilterInput
			value={filterValue || ''}
			onChange={(e) => {
				setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
			}}
			onClick={doNotSort}
			placeholder={dictionary.searchInResults(count)}
		/>
	)
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
	column: {filterValue, setFilter, preFilteredRows, id},
}) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = useMemo(() => {
		const options = new Set()
		preFilteredRows.forEach((row) => {
			options.add(row.values[id])
		})
		return [...options.values()]
	}, [id, preFilteredRows])

	// Render a multi-select box
	return (
		<select
			value={filterValue}
			onChange={(e) => {
				setFilter(e.target.value || undefined)
			}}>
			<option value="">All</option>
			{options.map((option, i) => (
				<option key={i} value={option}>
					{option}
				</option>
			))}
		</select>
	)
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
	column: {filterValue, setFilter, preFilteredRows, id},
}) {
	// Calculate the min and max
	// using the preFilteredRows

	const [min, max] = useMemo(() => {
		let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
		let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
		preFilteredRows.forEach((row) => {
			min = Math.min(row.values[id], min)
			max = Math.max(row.values[id], max)
		})
		return [min, max]
	}, [id, preFilteredRows])

	return (
		<>
			<input
				type="range"
				min={min}
				max={max}
				value={filterValue || min}
				onChange={(e) => {
					setFilter(parseInt(e.target.value, 10))
				}}
			/>
			<button onClick={() => setFilter(undefined)}>Off</button>
		</>
	)
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
	column: {filterValue = [], preFilteredRows, setFilter, id},
}) {
	const [min, max] = useMemo(() => {
		let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
		let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
		preFilteredRows.forEach((row) => {
			min = Math.min(row.values[id], min)
			max = Math.max(row.values[id], max)
		})
		return [min, max]
	}, [id, preFilteredRows])

	return (
		<div
			style={{
				display: 'flex',
			}}>
			<input
				value={filterValue[0] || ''}
				type="number"
				onChange={(e) => {
					const val = e.target.value
					setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
				}}
				placeholder={`Min (${min})`}
				style={{
					width: '70px',
					marginRight: '0.5rem',
				}}
			/>
			to
			<input
				value={filterValue[1] || ''}
				type="number"
				onChange={(e) => {
					const val = e.target.value
					setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
				}}
				placeholder={`Max (${max})`}
				style={{
					width: '70px',
					marginLeft: '0.5rem',
				}}
			/>
		</div>
	)
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, {keys: [(row) => row.values[id]]})
}

fuzzyTextFilterFn.autoRemove = (val) => !val

function Table({
	columns,
	data,
	onRowEditClick,
	onRowEditLabel,
	onRowEditIcon,
	onRowDeleteClick,
	onRowDeleteLabel,
	onRowDeleteIcon,
	onRowCreateClick,
	onRowCreateLabel,
	onRowCreateIcon,
	deleteButtonDisabledFunction,
	onRowSelected,
	actionsIcons,
	getTableStyle,
}) {
		
	const [actions, setActions] = useState(null)
	const [headerPosition, setHeaderPosition] = useState(0)
	const [containerRefference, setConainterReffence] = useState(null)	

	const filterTypes = useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id]

					const string = String(rowValue)
						.toLowerCase()
						.startsWith(String(filterValue).toLowerCase())

					return rowValue !== undefined ? string : true
				})
			},
		}),
		[],
	)

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[],
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		selectedFlatRows,
		state: {pageIndex, pageSize},
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			initialState: {pageIndex: 0, pageSize: 100},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				// Let's make a column for selection
				{
					id: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({getToggleAllPageRowsSelectedProps}) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({row}) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				...columns,
			])
		},
	)

	const createHeaderPositionListener = useCallback(() => {
		if (containerRefference) {
			containerRefference.addEventListener('scroll', (e) => {
				const currentScrollTop = containerRefference.scrollTop

				setHeaderPosition(currentScrollTop)
			})
		}
	}, [containerRefference])

	useEffect(() => {
		createHeaderPositionListener()
	}, [createHeaderPositionListener])

	const mountActions = useCallback(() => {
		if (
			actionsIcons &&
			Array.isArray(actionsIcons) &&
			actionsIcons.length > 0
		) {
			setActions(
				actionsIcons.map((action, index) => (
					<FontAwesomeIcon
						key={`icon-action-${index}`}
						title={action.title}
						icon={action.icon}
						onClick={action.onClick}
						style={{
							cursor: 'pointer',
							marginRight: '5px',
							color: PURPLE,
						}}
					/>
				)),
			)
		}
	}, [actionsIcons])

	useEffect(() => {
		mountActions()
	}, [mountActions])

	if (onRowSelected) onRowSelected(selectedFlatRows.map((d) => d.original))

	const printSelectedRows = (event) => {
		event.stopPropagation()

		if (!selectedFlatRows || selectedFlatRows.length < 1) {
			alert(dictionary.noRowsSelected)
			return false
		}

		return true
	}

	const loadRowsData = () => {
		return selectedFlatRows.map((myRow) => {
			const row = myRow.original

			for (let key in row) {
				row[key] = `${row[key]}`
			}

			return row
		})
	}

	const loadHeadersLabel = () => {
		return columns.map((column) => ({
			label: column.Header,
			key: column.accessor,
		}))
	}

	//auxiliar vars
	let columnsCounter = 0

	const tableStyles =
		getTableStyle && typeof getTableStyle === 'function' ? getTableStyle() : {}

	return (
		<Container ref={(ref) => setConainterReffence(ref)}>
			<TableGrid {...getTableProps()} style={{...tableStyles}}>
				<TableHeaderContainer>
					{headerGroups.map((headerGroup) => (
						<TableHeaderRow
							key={headerGroup.id}
							style={{transform: `translateY(${headerPosition}px)`}}
							{...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								if (
									column.showColumn === undefined ||
									column.showColumn === true
								) {
									columnsCounter++

									const headerTitle = column.description
										? column.description
										: column.Header

									const headerSortProps = column.getSortByToggleProps({
										title: headerTitle,
									})

									const columnProperties = {
										...(column.sortable === false
											? column.getHeaderProps()
											: column.getHeaderProps(headerSortProps)),
									}

									const {colSpan, key, onClick, role, style, title} =
										columnProperties

									return (
										<TableHeaderCell
											isCheckbox={column.id === 'selection'}
											colSpan={colSpan ? colSpan : 1}
											key={key ? key : 'columnHeader'}
											onClick={onClick ? onClick : () => {}}
											role={role ? role : null}
											style={style ? style : null}
											title={title && typeof title === 'string' ? title : null}
											filterable={column.filterable}>
											{column.render('Header')}
											{column.sortable === false ? null : (
												<span>
													{/* Add a sort direction indicator */}
													{column.isSorted ? (
														column.isSortedDesc ? (
															<FontAwesomeIcon
																icon={faArrowDown}
																style={{
																	float: 'right',
																	marginRight: '3px',
																	marginTop: '7px',
																}}
															/>
														) : (
															<FontAwesomeIcon
																icon={faArrowUp}
																style={{
																	float: 'right',
																	marginRight: '3px',
																	marginTop: '7px',
																}}
															/>
														)
													) : (
														''
													)}
												</span>
											)}
											{/* Render the columns filter UI */}
											{column.filterable === false ? null : (
												<div style={{textAlign: 'center'}}>
													{column.canFilter ? column.render('Filter') : null}
												</div>
											)}
										</TableHeaderCell>
									)
								} else {
									return null
								}
							})}
							{onRowEditClick || onRowDeleteClick ? (
								<TableHeaderCell
									filterable={false}
									width={
										onRowEditClick && onRowDeleteClick
											? '160px'
											: onRowEditClick || onRowDeleteClick
											? '80px'
											: 'auto'
									}>
									{dictionary.Actions}
								</TableHeaderCell>
							) : null}
						</TableHeaderRow>
					))}
					<TableHeaderFilter>
						<th
							colSpan={
								onRowEditClick || onRowDeleteClick
									? columnsCounter
									: columnsCounter - 1
							}
							style={{
								textAlign: 'left',
							}}>
							<GlobalFilter
								preGlobalFilteredRows={preGlobalFilteredRows}
								globalFilter={state.globalFilter}
								setGlobalFilter={setGlobalFilter}
							/>
						</th>
						<th
							title={dictionary.exportTableToCSV}
							style={{
								textAlign: 'right',
								fontSize: '21px',
								color: PURPLE,
							}}>
							{actions}
							<CSVLink
								onClick={printSelectedRows}
								data={loadRowsData()}
								headers={loadHeadersLabel()}
								filename={'cobe-table.csv'}>
								<FontAwesomeIcon
									icon={faFileCsv}
									style={{
										cursor: 'pointer',
										marginRight: '5px',
										color: PURPLE,
									}}
								/>
							</CSVLink>
						</th>
					</TableHeaderFilter>
				</TableHeaderContainer>
				<TableBody {...getTableBodyProps()}>
					{page.map((row, indexRow) => {
						prepareRow(row)
						return (
							<TableRow key={indexRow} {...row.getRowProps()} index={indexRow}>
								{row.cells.map((cell, index) => {
									if (
										cell.column.showColumn === undefined ||
										cell.column.showColumn === true
									) {
										const cellValue =
											cell.column.valueFormatter &&
											typeof cell.column.valueFormatter === 'function'
												? cell.column.valueFormatter(cell.value, row.original)
												: null

										const styleProps =
											cell.column.getStyle &&
											typeof cell.column.getStyle === 'function'
												? cell.column.getStyle(row.original)
												: {}

										const cellTootip =
											cell.column.getTooltip &&
											typeof cell.column.getTooltip === 'function'
												? cell.column.getTooltip(row.original)
												: ''

										return (
											<TableCell
												key={index}
												title={cellTootip}
												style={styleProps}
												{...cell.getCellProps()}>
												{cellValue ? cellValue : getCell(cell, row)}
											</TableCell>
										)
									} else {
										return null
									}
								})}
								{onRowEditClick || onRowDeleteClick || onRowCreateClick ? (
									<TableCell
										style={{
											display: 'flex',
											flexDirection: 'row',
											width: 'auto',
											justifyContent: 'center',
											alignItems: 'center',
										}}>
										{onRowEditClick ? (
											<div
												style={{
													margin: '3px',
													textAlign: 'center',
												}}>
												<ButtonAction
													style={onRowEditIcon ? null : { borderRadius: '5px', width: 'auto'} }
													title={onRowEditLabel}
													onClick={() => onRowEditClick(row.original)}>
													<b>{onRowEditIcon ? onRowEditIcon : onRowEditLabel ? onRowEditLabel : dictionary.Edit}</b>
												</ButtonAction>
											</div>
										) : null}
										{onRowDeleteClick ? (
											<div
												style={{
													margin: '3px',
													textAlign: 'center',
												}}>
												<ButtonAction
													style={onRowDeleteIcon ? null : { borderRadius: '5px', width: 'auto' } }
													title={onRowDeleteLabel}
													onClick={() => onRowDeleteClick(row.original)}
													disabled={
														deleteButtonDisabledFunction &&
														typeof deleteButtonDisabledFunction === 'function'
															? deleteButtonDisabledFunction(row.original)
															: false
													}>
													<b>{onRowDeleteIcon ? onRowDeleteIcon : onRowDeleteLabel ? onRowDeleteLabel : dictionary.Delete}</b>													
												</ButtonAction>
											</div>
										) : null}
										{onRowCreateClick ? (
											<div
												style={{
													margin: '3px',
													textAlign: 'center',
												}}>
												<ButtonAction
													title={onRowCreateLabel}
													style={onRowCreateIcon ? null : { borderRadius: '5px', width: 'auto'} }
													onClick={() => onRowCreateClick(row.original)}
													disabled={
														deleteButtonDisabledFunction &&
														typeof deleteButtonDisabledFunction === 'function'
															? deleteButtonDisabledFunction(row.original)
															: false
													}>
													<b>{onRowCreateIcon ? onRowCreateIcon : onRowCreateLabel ? onRowCreateLabel : dictionary.Create}</b>
												</ButtonAction>
											</div>
										) : null}
									</TableCell>
								) : null}
							</TableRow>
						)
					})}
				</TableBody>
			</TableGrid>

			<PaginationDiv className="pagination">
				<Button
					width={'40px'}
					height={'20px'}
					onClick={() => gotoPage(0)}
					disabled={!canPreviousPage}>
					<b>
						<FontAwesomeIcon icon={faAngleDoubleLeft} />
					</b>
				</Button>
				<Button
					width={'40px'}
					height={'20px'}
					onClick={() => previousPage()}
					disabled={!canPreviousPage}>
					<b>
						<FontAwesomeIcon icon={faAngleLeft} />
					</b>
				</Button>
				<span>
					{dictionary.PageXofX(
						pageIndex + 1,
						pageOptions.length === 0 ? 1 : pageOptions.length,
					)}
				</span>
				<Button
					width={'40px'}
					height={'20px'}
					onClick={() => nextPage()}
					disabled={!canNextPage}>
					<b>
						<FontAwesomeIcon icon={faAngleRight} />
					</b>
				</Button>
				<Button
					width={'40px'}
					height={'20px'}
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}>
					<b>
						<FontAwesomeIcon icon={faAngleDoubleRight} />
					</b>
				</Button>
				|
				<span>
					{dictionary.goToPage}:
					<input
						type="number"
						min={1}
						max={Math.ceil(data.length / pageSize)}
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
						}}
						style={{width: '100px'}}
					/>
				</span>
			</PaginationDiv>
		</Container>
	)
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
	return rows.filter((row) => {
		const rowValue = row.values[id]
		return rowValue >= filterValue
	})
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

/**
 * @author Carlos Padovezi
 * @function List
 * @file List.jsx
 * @summary Function to create customers crud view
 * @param {Array} columns - Arry list of columns object - Example: [{accessor:'name', Header: 'Nome', width: 70}, {accessor:'birthDate', Header: 'Data de aniversário', width: 70}]
 * @param {Array} rows - Arry list of rows object with columns keys - Example: [{name: 'Carlos Alberto', birthDate: '18/04/1997' }]
 * @param {Boolean=} onRowSelected - If true, the grid get a first column with a checkbox that allows to select rows
 * @param {Function=} onRowSelected - If true, the grid get a first column with a checkbox that allows to select rows
 * @param {Function=} onRowEditSubmit - If true, the column menu is disabled
 * @param {Object=} actionsIcons - Object with icons to put in actions position
 * @returns Return list table
 */
export function List(props) {
	const columnsList = props.columns ? props.columns : []
	const rowsList = props.rows ? props.rows : []

	for (let column of columnsList) {
		switch (column.filter) {
			case 'selectColumnFilter':
				column.Filter = SelectColumnFilter
				break
			case 'sliderColumnFilter':
				column.Filter = SliderColumnFilter
				break
			case 'numberRangeColumnFilter':
				column.Filter = NumberRangeColumnFilter
				break
			default:
				break
		}
	}

	return (
		<Table
			columns={columnsList}
			data={rowsList}
			onRowEditClick={props.onRowEditClick}
			onRowEditLabel={props.onRowEditLabel}
			onRowEditIcon={props.onRowEditIcon}
			onRowDeleteClick={props.onRowDeleteClick}
			onRowDeleteLabel={props.onRowDeleteLabel}
			onRowDeleteIcon={props.onRowDeleteIcon}
			onRowCreateClick={props.onRowCreateClick}
			onRowCreateLabel={props.onRowCreateLabel}
			onRowCreateIcon={props.onRowCreateIcon}
			deleteButtonDisabledFunction={props.deleteButtonDisabledFunction}
			onRowSelected={props.onRowSelected}
			actionsIcons={props.actionsIcons}
			getTableStyle={props.getTableStyle}
		/>
	)
}
