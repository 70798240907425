import {CreateContentDiv, CreateFormDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function CreateForkliftModel
 * @file CreateForkliftModel.js
 * @summary Function to create Fokrlift model register view
 * @returns Fokrlift model create crud page
 */
export default function CreateForkliftModel({updatePage}) {
	const [brandsList, setBrandsList] = useState([])
	const [formData, setFormData] = useState({
		name: '',
		consumption_kwh: '',
		voltage: '',
		capacity: '',
		charging_voltage: '',
		amps_hour_charging_time: '',
		brand_id: '',
	})

	useEffect(() => {
		//get branch list
		createFetchRequest('chargerBrand/getAll', 'get', null, ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}
			let myList = []
			if (result && result.length > 0) {
				for (let brand of result) {
					myList.push({value: brand.id, label: brand.name})
				}
			}

			setBrandsList(myList)
		})
	}, [])

	function handleSaveNewFokrliftModel() {
		const context = {
			name: formData.name,
			consumption_kwh: formData.consumption_kwh,
			voltage: formData.voltage,
			capacity: formData.capacity,
			charging_voltage: formData.charging_voltage,
			amps_hour_charging_time: formData.amps_hour_charging_time,
			brand_id: formData.brand_id,
		}

		createFetchRequest(
			'chargerModel/create',
			'post',
			context,
			({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					updatePage()
				}
			},
		)
	}

	const validButton =
		formData.consumption_kwh &&
		formData.voltage &&
		formData.capacity &&
		formData.charging_voltage &&
		formData.amps_hour_charging_time &&
		formData.brand_id

	return (
		<CreateContentDiv>
			<CreateFormDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					autoFocus={true}
					maxLength={50}
					readOnly={true}
				/>
				<RowInput
					value={formData.voltage}
					label={dictionary.Voltage}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(voltage) => { 
						setFormData((state) => ({...state, voltage}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(formData.brand_id))
						let brandName = brandIdx === -1 ? '' : brandsList[brandIdx].label
						let voltageValue = voltage === null ? '' : voltage
						let name = `${brandName} ${voltageValue}V ${formData.capacity}A`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<RowInput
					value={formData.consumption_kwh}
					label={dictionary.ConsumptionKwh}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(consumption_kwh) =>
						setFormData((state) => ({...state, consumption_kwh}))
					}
				/>
				<RowInput
					value={formData.capacity}
					label={dictionary.Capacity}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(capacity) => {
						setFormData((state) => ({...state, capacity}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(formData.brand_id))
						let brandName = brandIdx === -1 ? '' : brandsList[brandIdx].label
						let capacityValue = capacity === null ? '' : capacity
						let name = `${brandName} ${formData.voltage}V ${capacityValue}A`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<RowInput
					value={formData.charging_voltage}
					label={dictionary.ChargingVoltage}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(charging_voltage) =>
						setFormData((state) => ({...state, charging_voltage}))
					}
				/>
				<RowInput
					value={formData.amps_hour_charging_time}
					label={dictionary.AmpsHourChargingTime}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(amps_hour_charging_time) =>
						setFormData((state) => ({...state, amps_hour_charging_time}))
					}
				/>
				<RowSelect
					value={formData.brand_id}
					label={dictionary.Brand}
					optionsList={brandsList}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(brand_id) => {
						setFormData((state) => ({...state, brand_id}))
						let brandIdx = brandsList.findIndex(x => x.value === parseInt(brand_id))
						let name = `${brandsList[brandIdx].label} ${formData.voltage}V ${formData.capacity}A`
						setFormData((state) => ({...state, name}))
					}}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveNewFokrliftModel}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={updatePage}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b> {dictionary.Cancel}</b>
					</Button>
				</div>
			</CreateFormDiv>
		</CreateContentDiv>
	)
}
