import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from 'src/actions'
import AlertsModal from 'src/components/AlertsModal'
import ConfirmationModal from 'src/components/ConfirmationModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MainContentDiv } from 'src/styles/globalStyles'
import MalfunctionModal from 'src/components/MalfunctionModal'
import MalfunctionTable from 'src/components/Table'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import { createFetchRequest } from 'src/genericFunctions'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'
import { useInfoModal } from 'src/hooks/useInfoModal'

const formatColumns = (profileId, orderCallback) => {
	let columns = [
		{ 
			accessor: 'id',
			Header: dictionary.id,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{ 
			accessor: 'client_name',
			Header: <p>Cliente</p>,
			csvHeader: 'Cliente',
			width: 'auto',
			showColumn: profileId === 1,
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
		{ 
			accessor: 'branch_name',
			Header: <p>Sede</p>,
			csvHeader: 'Sede',
			width: 'auto',			
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
		{ 
			accessor: 'equipment_name',
			Header: <p>Equipamento</p>,
			csvHeader: 'Equipamento',
			width: 'auto',			
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
		{ 
			accessor: 'type_equipment',
			Header: <p>Tipo do <br/>Equipamento</p>,
			csvHeader: 'Tipo do equipamento',
			width: 'auto',			
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
		{ 
			accessor: 'notes',
			Header: <p>Descrição</p>,
			csvHeader: 'Descrição',
			width: 'auto',			
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
		{ 
			accessor: 'start_date',
			Header: <p>Inicio</p>,
			csvHeader: 'Inicio',
			width: 'auto',			
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
		{ 
			accessor: 'employee_started',
			Header: <p>Colaborador <br/> Iniciou</p>,
			csvHeader: 'Colaborador Iniciou',
			width: 'auto',			
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
		{ 
			accessor: 'end_date',
			Header: <p>Final</p>,
			csvHeader: 'Final',
			width: 'auto',			
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
		{ 
			accessor: 'employee_ended',
			Header: <p>Colaborador <br/> Finalizou</p>,
			csvHeader: 'Colaborador Finalizou',
			width: 'auto',			
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
		{ 
			accessor: 'type',
			Header: <p>Tipo de <br/> Falha</p>,
			csvHeader: 'Tipo de Falha',
			width: 'auto',			
			priority: 1,
			showFilter: true,
			orderServer: true,
			orderCallback: orderCallback,
			order: true,
		},
	]

	return columns
}

const MalfunctionReport = ({ hideLoadingBlur, showLoadingBlur }) => {
	const { showModal } = useInfoModal()
	const userInfos = getItem('session')	
	if (userInfos === null) window.location.reload()
	const profileId = userInfos.profile_id
	const [list, setList] = React.useState([])
	const [columns, setColumns] = React.useState([])
	const [alertsModal, setAlertModal] = React.useState(null)
	const [editMalfunction, setEditMalfunction] = React.useState({ show: false, equipment: {}, malfunction: {}, id: -1 })
	const [confirmationModal, setConfirmationModal] = React.useState({
		show: false,
		title: '',
		message: '',
		children: null,
		confirmationAction: () => {}
	})
	const [dateFilter, setDateFilter] = React.useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})
	const [filters, setFilters] = React.useState({
		id: '',
		client_name: '', 
		branch_name: '', 
		equipment_name: '', 
		type_equipment: '', 
		notes: '',
		start_date: '', 
		employee_started: '',
		end_date: '',
		employee_ended: '',
		type: ''
	})
	const [order, setOrder] = React.useState({
		field: '',
		value: '',
	})
	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({ 
		totalPages: 0,
		total: 0,
		page: 1,
	 })
	const [selectedIds, setSelectedIds] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)
	const [deselectIds, setDeselectIds] = React.useState([])
	const limit = 100

	const loadData = React.useCallback(() => {		
		showLoadingBlur()
		let opt = {}
		opt['filters'] = filters
		opt['startdate'] = dateFilter.initialDate
		opt['finaldate'] = dateFilter.endDate
		opt['order'] = order


		createFetchRequest(
			`report/malfunction/${page}/${limit}`,
			'post',
			opt,
			({err, result}) => {				
				if (err) setList([])
				else if (!err && result) {
					// const rows = formatRows(result.response)
					setList(result.response)				
					setPagination({ totalPages: result.totalPages, total: result.total, page: page })
				}

				hideLoadingBlur()
			},
		)
	}, [dateFilter.endDate, dateFilter.initialDate, filters, hideLoadingBlur, order, page, showLoadingBlur])

	React.useEffect(() => { loadData()}, [loadData])

	const filterValueColumn = React.useCallback((orderField, order) => {
		setOrder({orderField, order})
	}, [])

	React.useEffect(() => {
		const columns = formatColumns(profileId, filterValueColumn)
		setColumns(columns)	
	}, [filterValueColumn, profileId])

	//Ações
	const onCloseConfirmatioModal = () => {
		setConfirmationModal({ show: false, children: null, message: '', title: '', confirmationAction: () => {} })
	}

	const openModalEdit = React.useCallback((params) => {		
		const context = {
			qrcode: params.qrcode,
			notes: params.notes,
			obs: params.obs,
			isRemove: params.type === 'Manutenção',
			id: params.id,
		}

		setEditMalfunction({ show: true, malfunction: context, equipment: params, id: params.id })
	}, [])

	const handleOpenViewMalfunction = (params) => {
		setAlertModal({ type: params.type_equipment, device: params, last: false })
	}

	const onDimiss = () => {
		setEditMalfunction({ show: false, equipment: {}, malfunction: {}})
	}

	const onSaveMalfunction = React.useCallback((context) => {
		showLoadingBlur()
		createFetchRequest(
			`/malfunction/update/${context.qrcode}/${context.id}`, 
			'put', 
			context, 
			({ err }) => {
				if(err)	{				
					hideLoadingBlur()
					return
				}

				setEditMalfunction({ show: false, equipment: {}, malfunction: {}})
				hideLoadingBlur()
				loadData()
				showModal({ title: 'Sucesso', message: 'Avaria editada com sucesso', children: null, show: true })
			})
	}, [hideLoadingBlur, loadData, showLoadingBlur, showModal])

	const onConfirmDelete = React.useCallback((context) => {
		showLoadingBlur()
		onCloseConfirmatioModal()

		createFetchRequest(
			`/malfunction/${context.qrcode}/${context.id}`,
			'delete',
			undefined,
			({ err }) => {
				if (err) {
					hideLoadingBlur()
					return
				}
				hideLoadingBlur()
				loadData()
				showModal({ title: 'Sucesso', message: 'Avaria excluída com sucesso', children: null, show: true })
			}
		)
	}, [hideLoadingBlur, loadData, showLoadingBlur, showModal])

	const onDeleteMalfunction = React.useCallback((context) => {
		setConfirmationModal({ show: true, message: 'Deseja remover esse registro?', title: 'ATENÇÃO', confirmationAction: () => { onConfirmDelete(context) }})
	}, [onConfirmDelete])

	const style = {
		'borderRadius': '50%',
		'padding': '2px',
		'width': '28px',
		'height': '28px'
	}

	const actions = [
		{ label: <FontAwesomeIcon icon={faTrash}/>, action: onDeleteMalfunction, title: 'Excluir', style: style, view: true },
		{ label: <FontAwesomeIcon icon={faEdit}/>, action: openModalEdit, title: 'Editar', style: style, view: true },
		{ label: <FontAwesomeIcon icon={faEye}/>, action: handleOpenViewMalfunction, title: 'Detalhes', style: style, view: true },
	]

	//Filtros tabela
	const filterColumn = (filter, column) => {
		filters[column] = filter
		setFilters({...filters})
	}

	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({initialDate: start, endDate: end})
	}

	const filter = {
		filterDate,
		filterColumn,
		previous: () => { setPage(page - 1) },
		next: () => { setPage(page + 1) },
		lastPage: () => { setPage(pagination.totalPages) },
		firstPage: () => { setPage(1) },
		selectPage: (value) => { setPage(parseInt(value)) },
		dateFilter,
		searchFilters: filters,
	}

	const onSelectRow = (row, checked) => {		
		if (selectAll) {
			if (!checked) 
				setDeselectIds([...deselectIds, { id: row.id, type_equipment: row.type_equipment }])
			else
				setDeselectIds(deselectIds.filter((r) => r.id !== row.id && r.type_equipment !== row.type_equipment))
		} else {
			if (checked)
				setSelectedIds([...selectedIds, {id: row.id, type_equipment: row.type_equipment }])
			else
				setSelectedIds(selectedIds.filter((r) => r.id !== row.id && r.type_equipment !== row.type_equipment ))
		}		
	}

	const onSelectAll = (checked) => {
		setDeselectIds([])
		setSelectedIds([])
		setSelectAll(checked)
	}

	const close = () => {
		setAlertModal(null)
		loadData()
	}

	const alertModalInfo =
		alertsModal === null ? null : (
			<AlertsModal
				type={alertsModal.type}
				device={alertsModal.device}
				last={false}
				closeModal={close}
			/>
		)

	const onGenerateCSV = React.useCallback(() => {showLoadingBlur()
		let opt = {}
		opt['filters'] = filters
		opt['startdate'] = dateFilter.initialDate
		opt['finaldate'] = dateFilter.endDate
		opt['order'] = order
		opt['select'] = { selectAll, selectedIds, deselectIds }


		createFetchRequest(
			`report/exportMalfunctionReport`,
			'post',
			opt,
			({err, result}) => {					
				if (err) {}
				else if (!err && result) {
					setTimeout(() => {
						window.open(result)
					}, 2000)
				}

				hideLoadingBlur()
			},
		)
	}, [dateFilter.endDate, dateFilter.initialDate, deselectIds, filters, hideLoadingBlur, order, selectAll, selectedIds, showLoadingBlur])

	return(
		<MainContentDiv>
			<MalfunctionTable 
				columns={columns}
				rows={list}
				name="RELATÓRIO DE FALHAS"
				filters={filter}
				pagination={pagination}
				selectable
				actions={actions}
				onGenerateCSV={onGenerateCSV}
				onSelect={onSelectRow}
				onSelectAll={onSelectAll}
				selectAll={selectAll}
				selectedIds={selectedIds.map(x => { return x.id })}
				deselectIds={deselectIds.map(x => { return x.id })}
				showHiddenColumn={true}
			/>
			<ConfirmationModal 
				title={confirmationModal.title}
				children={confirmationModal.children}
				message={confirmationModal.message}
				show={confirmationModal.show}
				onClose={() => { onCloseConfirmatioModal() }}
				onConfirmation={confirmationModal.confirmationAction}
			/>
			<MalfunctionModal
				show={editMalfunction.show}
				data={editMalfunction.equipment}
				malfunction={editMalfunction.malfunction}
				title={`Editar Avaria`}
				onDimiss={onDimiss}
				onSuccess={onSaveMalfunction}
			/>
			{alertsModal === null ? null : alertModalInfo}
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(MalfunctionReport)