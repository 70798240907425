import {Content, Header, Items, NameEquipment, Row} from './styles'
import {EditModalBackgroundDiv, ViewModalDiv} from 'src/styles/globalStyles'
import {Button} from '@material-ui/core'
import {Colors} from 'src/styles'
import React from 'react'
import {formatFullDateBR} from 'src/genericFunctions'

const ViewHistoryChecklist = ({history, show, equipment, onClose}) => {
	return (
		<>
			{show ? (
				<EditModalBackgroundDiv>
					<ViewModalDiv
						style={{justifyContent: 'flex-start', padding: 0, width: 'auto'}}>
						<Header>Histórico de checklists</Header>
						<Content>
							<NameEquipment>{equipment}</NameEquipment>
							<Items>
								{history.map((row) => (
									<Row
										key={row.id}
										color={row.is_error ? Colors.RED : Colors.BLACK}>
										{formatFullDateBR(row.createdAt)} - {row.employee.name} -{' '}
										{row.is_error ? 'NOK' : 'OK'}
									</Row>
								))}
								{history.length === 0 && (
									<Row>Não há dados a serem mostrados</Row>
								)}
							</Items>
							<Button onClick={onClose}>Fechar</Button>
						</Content>
					</ViewModalDiv>
				</EditModalBackgroundDiv>
			) : null}
		</>
	)
}

export default ViewHistoryChecklist
