import { Column, Input, Options, Select, TBody, TCell, THead, THeader, TRow, Table, TableContent, Title } from '../styles'
import React from 'react'
import Spinner from 'src/components/Spinner'
import { debounce } from 'lodash'
import { isUndefinedOrNull } from 'src/genericFunctions'

const ImportForklift = ({forklifts, brands, clients, onChangeValue}) => {		
	const [data, setData] = React.useState([])

	React.useEffect(() => {		
		setData(forklifts)
	}, [forklifts])
 
	const onChangeInput = debounce((value, index, field) => {
		onChangeValue({ value, index, field, state: 'forklifts'})
	}, 300)	

	return (		
		<Column>
			{data.length === 0 ? (
				<TableContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<Spinner />
				</TableContent>
			) : (
			<>
				<Title>Máquinas</Title>
				<TableContent>
					<Table>
						<THeader>
							<TRow>
								<THead bold={true}>Nome</THead>
								<THead bold={true}>Serial</THead>
								<THead bold={true}>Marca</THead>
								<THead bold={true}>Modelo</THead>
								<THead bold={true}>Data de Fabricação</THead>
								<THead bold={true}>Cliente</THead>
								<THead bold={true}>Sede</THead>
							</TRow>
						</THeader>
						<TBody>
							{data.map((r, index) => (
								<TRow key={index}>
									<TCell>
										<Input
											type='text'
											defaultValue={r.name}																			
											onChange={(event) => onChangeInput(event.target.value, index, 'name')}
											color={isUndefinedOrNull(r.name) ? 'red' : null}
										/>
									</TCell>
									<TCell>
										<Input 
											type='text'
											defaultValue={r.serial} 											
											onChange={(event) => onChangeInput(event.target.value, index, 'serial')}
											color={isUndefinedOrNull(r.serial) ? 'red' : null}
										/>
									</TCell>
									<TCell>
										<Select defaultValue={r.brand_id}
											onChange={(event) => onChangeInput(event.target.value, index, 'brand_id')}
											color={r.brand_id === -1 ? 'red' : null}
										>
											<Options value={-1}>Selecionar Marca</Options>
											{brands.map(b => (
												<Options key={b.id} value={b.id} >
													{b.name}
												</Options>
											))}
										</Select>							
									</TCell>
									<TCell>
										<Select defaultValue={r.model_id}
											onChange={(event) => onChangeInput(event.target.value, index, 'model_id')}
											color={r.model_id === -1 ? 'red' : null}
										>
											<Options value={-1}>Selecionar Modelo</Options>
											{brands.find(brand => brand.id === parseInt(r.brand_id))?.models.map(model => {		 										
												return <Options key={model.id} value={model.id}>{model.name}</Options>
											})}											
										</Select>
									</TCell>
									<TCell>
										{console.log(r.manufacturing_date)}
										<Input
											type='date'
											defaultValue={r.manufacturing_date}
											onChange={(event) => onChangeInput(event.target.value, index, 'manufacturing_date')}
											color={r.manufacturing_date === null || r.manufacturing_date === '' ? 'red' : null}

										/>
									</TCell>
									<TCell>
										<Select defaultValue={r.client_id} 
											onChange={(event) => onChangeInput(event.target.value, index, 'client_id')}
											color={r.client_id === -1 ? 'red' : null}
										>
											<Options value={-1}>Selecionar Cliente</Options>
											{clients.map(c => (
												<Options key={c.id} value={c.id} >
													{c.name}
												</Options>
											))}
										</Select>
									</TCell>
									<TCell>
										<Select defaultValue={r.branch_id}
											onChange={(event) => onChangeInput(event.target.value, index, 'branch_id')}
											color={r.branch_id === -1 ? 'red' : null}
										>
											<Options value={-1}>Selecionar Sede</Options>
											{clients.find(client => client.id === parseInt(r.client_id))?.branchs.map(branch => (
												<Options key={branch.id} value={branch.id}>{branch.name}</Options>
											))}
										</Select>
									</TCell>
								</TRow>
							))}
						</TBody>
					</Table>
				</TableContent>		
			</>
			)}
		</Column>		
	)
}

export default ImportForklift