import { ActiveCircle, CardContent, Container, Content, Details, DetailsLabel, DetailsRow, OfflineCircle } from "./styles"
import { faBatteryFull, faPersonBooth, faPlug, faUser } from "@fortawesome/free-solid-svg-icons"
import { Card } from "./card"
import ChargerStatusCard from "src/components/Dashboard/ChargerStatusCard"
import DashboardHeader from "src/components/Dashboard/Header"
import { ExchangePerHour } from "src/components/Dashboard/ExchangePerHour"
import { ForkLiftIcon } from "src/components/ForkliftIcon"
import IndicatorCard from "src/components/Dashboard/IndicatorCard"
import ListFifoBatteries from "src/components/ListFifoBatteries"
import OperationCard from 'src/components/Dashboard/OperationCard'
import React from "react"
import TableChartsDashboard from "src/components/TableChartsDashboard"
import dayjs from "dayjs"
import { fetchData } from "src/hooks/useFetch"

function Dashboard() {
	const [data, setData] = React.useState()
	const [loading, setLoading] = React.useState(true)
	// const [date, setDate] = React.useState({
	// 	month: new Date().getMonth() + 1,
	// 	year: new Date().getFullYear(),
	// })

	const [dateFilter, setDateFilter] = React.useState({
		startDate: dayjs().subtract(30, 'days').format("YYYY-MM-DD"),
		finalDate: dayjs().format("YYYY-MM-DD")
	})

	console.log(dateFilter.startDate)

	const faForklift = ForkLiftIcon({ height: '25px', width: '25px', opacity: 1 })

	React.useEffect(() => {
		const loadData = async () => {
			const response = await fetchData(`dashboard?startDate=${dateFilter.startDate}&finalDate=${dateFilter.finalDate}`)
			setData(response)
			setLoading(false)
		}

		loadData()
	}, [])


	const changeDate = React.useCallback((key, value) => {
		setDateFilter((prev) => ({ ...prev, [key]: value }))
	}, [])

	return (
		<div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
			<DashboardHeader date={dateFilter} onChangeDate={changeDate} />
			<Container>
				<Card title="Indicadores de operação" width="60%">
					<CardContent>
						<Content>
							<IndicatorCard title={"Operadores"} value={data?.operator?.total} icon={faUser} loading={loading}>
								<Details>
									<DetailsRow>
										<ActiveCircle />
										<DetailsLabel>Todal habilitado: {data?.operator?.total_enabled}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<OfflineCircle />
										<DetailsLabel>Todal desabilitado: {data?.operator?.total_disabled}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<ActiveCircle />
										<DetailsLabel>Todal online: {data?.operator?.total_logged}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<OfflineCircle />
										<DetailsLabel>Todal offline: {data?.operator?.total_off}</DetailsLabel>
									</DetailsRow>
								</Details>
							</IndicatorCard>
						</Content>
						<Content>
							<IndicatorCard title={"Colaboradores"} value={data?.employees?.total} icon={faPersonBooth} loading={loading} >
								<Details>
									<DetailsRow>
										<ActiveCircle />
										<DetailsLabel>Habilitados: {data?.employees?.total_enabled}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<OfflineCircle />
										<DetailsLabel>Desabilitados: {data?.employees?.total_disabled}</DetailsLabel>
									</DetailsRow>
								</Details>
							</IndicatorCard>
						</Content>
						<Content>
							<IndicatorCard title={"Carregadores"} value={data?.chargers?.total} icon={faPlug} loading={loading} >
								<Details>
									<DetailsRow>
										<ActiveCircle />
										<DetailsLabel>Em uso: {data?.chargers?.total_chargers_in_use}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<OfflineCircle />
										<DetailsLabel>Ocioso: {data?.chargers?.total_unused_chargers}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<OfflineCircle />
										<DetailsLabel>Em manutenção: {data?.chargers?.total_in_maintenance
										}</DetailsLabel>
									</DetailsRow>
								</Details>
							</IndicatorCard>
						</Content>
						<Content>
							<IndicatorCard title={"Baterias"} value={data?.batteries?.total} icon={faBatteryFull} loading={loading} >
								<Details>
									<DetailsRow>
										<ActiveCircle />
										<DetailsLabel>Em carga: {data?.batteries?.total_battery_in_charge}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<ActiveCircle />
										<DetailsLabel>Em uso: {data?.batteries?.total_battery_in_use}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<OfflineCircle />
										<DetailsLabel>Ocioso: {data?.batteries?.total_unused_battery}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<OfflineCircle />
										<DetailsLabel>Em manutenção: {data?.batteries?.total_in_maintenance
										}</DetailsLabel>
									</DetailsRow>
								</Details>
							</IndicatorCard>
						</Content>
						<Content>
							<IndicatorCard title={"Máquinas"} value={data?.forklifts?.total} icon={faForklift} loading={loading} isCustomIcon={true}>
								<Details>
									<DetailsRow>
										<ActiveCircle />
										<DetailsLabel>Em uso: {data?.forklifts?.total_forklift_in_use}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<OfflineCircle />
										<DetailsLabel>Ocioso: {data?.forklifts?.total_unused_forklift}</DetailsLabel>
									</DetailsRow>
									<DetailsRow>
										<OfflineCircle />
										<DetailsLabel>Em manutenção: {data?.forklifts?.total_in_maintenance
										}</DetailsLabel>
									</DetailsRow>
								</Details>
							</IndicatorCard>
						</Content>
						<OperationCard date={dateFilter} />
					</CardContent>
				</Card>
				<Card title={"Próximas baterias do FIFO"} width="35%">
					<CardContent>
						<ListFifoBatteries />
					</CardContent>
				</Card>
				<Card title={"Horário de Pico trocas de bateria"} width="60%">
					<ExchangePerHour date={dateFilter} />
				</Card>
				<Card title={"Média de carga operação (H)"} width="35%">
					<ChargerStatusCard />
				</Card>
				<Card title={"Autonomia"} width="100%">
					<TableChartsDashboard
						url={'dashboard/forkliftModelsChart/'.concat(
							`?startDate=${dateFilter.startDate}&finalDate=${dateFilter.finalDate}`,
						)}
						name={'Sub-Operação'}
						title={'Autonomia Média por Sub-Operação'}
					/>

					<TableChartsDashboard
						url={'dashboard/roomModelChart/'.concat(
							`?startDate=${dateFilter.startDate}&finalDate=${dateFilter.finalDate}`,
						)}
						title={'Autonomia Média por Modelo de Máquina'}
						name={'Modelo'}
					/>
					<TableChartsDashboard
						url={'dashboard/batteryRoomChart/'.concat(
							`?startDate=${dateFilter.startDate}&finalDate=${dateFilter.finalDate}`,
						)}
						title={'Autonomia Média por Sala de Bateria'}
						name={'Sala de Bateria'}
						showClient={true}
					/>
				</Card>
			</Container>
		</div>
	)
}

export default Dashboard
