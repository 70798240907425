import {
	GRAY_MEDIUM3,
	PURPLE,
	PURPLE_LIGHT,
	YELLOW_LIGHT,
} from 'src/styles/colors'
import {Button} from 'src/styles/globalStyles'

import styled from 'styled-components'

export const TableGrid = styled.table`
	width: 100%;
	overflow: hidden;
	padding: 0;
	border-spacing: 1px;
	border-collapse: separate;
`

export const TableHeaderContainer = styled.thead``

export const TableHeaderRow = styled.tr`
	height: 60px;
`

export const TableHeaderCell = styled.th`
	height: 30px;
	width: ${(props) =>
		props.isCheckbox ? '30px' : props.width ? props.width : 'auto'};
	font-size: 15px;
	line-height: 30px;
	background: #fff;
	color: ${PURPLE};
	text-align: left;
	vertical-align: ${(props) =>
		props.filterable === false ? 'middle' : 'bottom'};
	text-align: center;
	background-image: linear-gradient(to top, ${PURPLE_LIGHT}, #fff);
	&:hover {
		background-color: ${PURPLE_LIGHT};
		background-image: unset;
	}
`

export const TableHeaderFilter = styled.tr`
	height: 30px;
`

export const TableBody = styled.tbody`
	height: 100%;
	overflow: auto;
`

export const TableRow = styled.tr`
	background-color: ${(props) =>
		props.index % 2 === 0 ? GRAY_MEDIUM3 : '#FFF'};
	&:hover {
		background-color: ${YELLOW_LIGHT};
	}
`

export const TableCell = styled.td`
	min-height: 35px;
	height: 35px;
	padding: 3px;
`

export const FilterInput = styled.input`
	width: calc(100% - 10px);
	max-width: 100px;
	min-width: 20px;
	border-radius: 25px;
	border: 1px solid ${PURPLE};
`

export const PaginationDiv = styled.div`
	width: 100%;
	height: 40px;
	min-height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	transform: translateY(100% - 40px);
`

export const Container = styled.div`
	max-height: 100%;
	overflow: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
`

export const ButtonAction = styled(Button)`
	border-radius: 50%;
	padding: 2px;
	width: 28px;
	height: 28px;
`
