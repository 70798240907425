import {EditModalBackgroundDiv, EditModalDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function EditBatteryRoom
 * @file EditBatteryRoom.js
 * @summary Function to edit Battery rooms register view
 * @returns Battery rooms edit crud page
 */
export default function EditBatteryRoom({
	editData,
	handleCancelEdit,
	updatePage,
}) {
	const [brandsList, setBrandsList] = useState([])
	const [formData, setFormData] = useState({
		name: editData.name ? editData.name : '',
		description: editData.description ? editData.description : '',
		voltage: editData.voltage ? editData.voltage : '',
		brand_id: editData.brand_id ? editData.brand_id : '',
		id: editData.id ? editData.id : '',
	})

	useEffect(() => {
		//get branch list
		createFetchRequest('forkliftBrand/getAll', 'get', null, ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}

			const myList = []
			if (result && result.length > 0) {
				for (const brand of result) {
					myList.push({value: brand.id, label: brand.name})
				}
			}

			setBrandsList(myList)
		})
	}, [])

	function handleSaveEditForkliftModel() {
		const context = {
			id: formData.id,
			name: formData.name,
			description: formData.description,
			voltage: formData.voltage,
			brand_id: formData.brand_id,
		}

		createFetchRequest(
			'forkliftModel/update',
			'put',
			context,
			({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					updatePage()
				}
			},
		)
	}

	const validButton = formData.name && formData.voltage && formData.brand_id

	return (
		<EditModalBackgroundDiv>
			<EditModalDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				<RowInput
					value={formData.voltage}
					label={dictionary.Voltage}
					isObligatory={true}
					fontSize={'16px'}
					type={'number'}
					onChange={(voltage) => setFormData((state) => ({...state, voltage}))}
				/>
				<RowSelect
					value={formData.brand_id}
					label={dictionary.Brand}
					optionsList={brandsList}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(brand_id) =>
						setFormData((state) => ({...state, brand_id}))
					}
				/>
				<RowInput
					value={formData.description}
					label={dictionary.Description}
					fontSize={'16px'}
					onChange={(description) =>
						setFormData((state) => ({...state, description}))
					}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveEditForkliftModel}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>
			</EditModalDiv>
		</EditModalBackgroundDiv>
	)
}
