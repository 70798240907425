import {
	Action,
	ActionButton,
	ActionRow,
	ActionTitle,
	Button,
	ButtonAdd,
	Container,
	Description,
	DescriptionTitle,
	IsActive,
	IsDeleted,
	IsDeletedTitle,
	IsDisabled,
	ListContainer,
	ListRow,
	Row,
	RowAdd,
	Title,
} from './styles'
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import FormCheklist from 'src/components/FormChecklist'
import React from 'react'
import {createFetchRequest} from 'src/genericFunctions'

const Checklist = () => {
	const [data, setData] = React.useState([])
	const [typeEquipment, setTypeEquipment] = React.useState('battery')
	const [checklist, setChecklist] = React.useState({
		description: '',
		is_deleted: false,
	})
	const [show, setShow] = React.useState(false)

	const getChecklist = React.useCallback(() => {
		const url = `/checklist/findAll/${typeEquipment}`

		createFetchRequest(url, 'get', null, ({err, result}) => {
			if (!err) {
				setData(result)
			}
		})
	}, [typeEquipment])

	const onChangeTypeEquipment = (value) => {
		setTypeEquipment(value)
	}

	const createChecklist = React.useCallback(() => {
		setShow(false)
		setChecklist({description: '', is_deleted: false})

		if (!checklist.id) {
			const url = `/checklist/create/${typeEquipment}`

			createFetchRequest(url, 'post', checklist, ({err, result}) => {
				if (!err) {
					getChecklist()
				}
			})
		} else {
			const url = `/checklist/update/${typeEquipment}/${checklist.id}`

			createFetchRequest(url, 'put', checklist, ({err, result}) => {
				if (!err) {
					getChecklist()
				}
			})
		}
	}, [checklist, getChecklist, typeEquipment])

	const disableChecklist = React.useCallback(
		(id, is_deleted) => {
			const url = `/checklist/disable/${typeEquipment}/${id}`

			createFetchRequest(url, 'put', {is_deleted}, ({err, result}) => {
				if (!err) {
					getChecklist()
				}
			})
		},
		[typeEquipment, getChecklist],
	)

	const removeChecklist = React.useCallback(
		(id) => {
			const url = `/checklist/remove/${typeEquipment}/${id}`

			createFetchRequest(url, 'delete', null, ({err, result}) => {
				if (!err) {
					getChecklist()
				}
			})
		},
		[typeEquipment, getChecklist],
	)

	const onChange = React.useCallback((value, key) => {
		setChecklist((prev) => ({...prev, [key]: value}))
	}, [])

	const edit = (checklist) => {
		setChecklist(checklist)
		setShow(true)
	}

	React.useEffect(() => {
		getChecklist()
	}, [getChecklist])

	return (
		<Container>
			<FormCheklist
				show={show}
				onCancel={() => {
					setShow(false)
					setChecklist({description: '', is_deleted: false})
				}}
				onSubmit={createChecklist}
				checklist={checklist}
				onChange={onChange}
				typeEquipment={typeEquipment}
			/>
			<Title>Cadastro checklist</Title>
			<ActionRow>
				<Button
					active={typeEquipment === 'battery'}
					onClick={() => onChangeTypeEquipment('battery')}>
					Bateria
				</Button>
				<Button
					active={typeEquipment === 'forklift'}
					onClick={() => onChangeTypeEquipment('forklift')}>
					Máquina
				</Button>
				<Button
					active={typeEquipment === 'charger'}
					onClick={() => onChangeTypeEquipment('charger')}>
					Carregador
				</Button>
			</ActionRow>
			<RowAdd>
				<ButtonAdd onClick={() => setShow(true)}>+ Adicionar</ButtonAdd>
			</RowAdd>
			<ListContainer>
				<Row>
					<DescriptionTitle>Descrição</DescriptionTitle>
					<IsDeletedTitle>Ativo</IsDeletedTitle>
					<ActionTitle>Ações</ActionTitle>
				</Row>
				{data.map((row) => (
					<ListRow key={row.id}>
						<Description>{row.description}</Description>
						<IsDeleted>
							<Row>
								<IsActive
									active={row.is_deleted === false}
									onClick={() => disableChecklist(row.id, false)}>
									Sim
								</IsActive>
								<IsDisabled
									active={row.is_deleted === true}
									onClick={() => disableChecklist(row.id, true)}>
									Não
								</IsDisabled>
							</Row>
						</IsDeleted>
						<Action>
							<Row>
								<ActionButton
									title="Excluir"
									onClick={() => removeChecklist(row.id)}>
									<FontAwesomeIcon icon={faTrash} />
								</ActionButton>
								<ActionButton
									title="Editar"
									onClick={() => {
										edit(row)
									}}>
									<FontAwesomeIcon icon={faEdit} />
								</ActionButton>
							</Row>
						</Action>
					</ListRow>
				))}
			</ListContainer>
		</Container>
	)
}

export default Checklist
