import {
	ImgDiv,
	UserCardArrowFunctionDiv,
	UserCardDiv,
	UserCardFunctionButtonDiv,
	UserCardFunctionsListDiv,
	UserCardInfosDiv,
} from './styles'
import React, {useState} from 'react'
import {clear, getItem} from 'src/utils/localStorage'
import {
	faArrowAltCircleDown,
	faArrowAltCircleUp,
	faSignOutAlt,
	faUser,
} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import dictionary from 'src/utils/dictionary'
import { useHistory } from 'react-router-dom'
import {useSession} from 'src/hooks/useSession'

/**
 * @author Carlos Padovezi
 * @function UserCard
 * @file UserCard.jsx
 * @summary Function main layout UserCard
 * @returns UserCard of pages list
 */
export default function UserCard({isCollapsed}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const {logout} = useSession()
	const [showFunctionsList, toggleFunctionsList] = useState(false)
	const history = useHistory()

	const userName = userInfos.name ? userInfos.name : ''
	const userEmail = userInfos.email ? userInfos.email : ''
	const userFirsLetterName =
		userInfos && userInfos.name && userInfos.name[0]
			? userInfos.name[0].toUpperCase()
			: ''

	const handleSignOut = () => {
		logout()
		clear('session')
		clear('token')
		document.location.reload()
	}

	const myProfile = () => {
		history.push("/MyProfile")	
	}

	const functionsArray = [
		{label: 'Meu Perfil', icon: faUser, func: myProfile},
		{label: dictionary.Logout, icon: faSignOutAlt, func: handleSignOut},
	]
	const functionsListDiv = FunctionsList(functionsArray)

	return (
		<div style={{width: '100%', flexGrow: 1, marginTop: '10px'}}>
			<div
				style={{
					width: '100%',
					position: 'relative',
					height: '100%',
					display: 'flex',
					alignItems: 'flex-end',
					justifyContent: 'center',
				}}>
				{isCollapsed ? (
					<ImgDiv isCollapsed={isCollapsed}>
						<b>{userFirsLetterName}</b>
					</ImgDiv>
				) : (
					<>
						{showFunctionsList ? functionsListDiv : null}
						<UserCardDiv
							onClick={() => toggleFunctionsList(!showFunctionsList)}>
							<ImgDiv>
								<b>{userFirsLetterName}</b>
							</ImgDiv>
							<UserCardInfosDiv>
								<div style={{display: 'table', width: '100%'}}>
									<div
										title={`${dictionary.Name}: ${userName}`}
										style={{display: 'table-row', width: '100%'}}>
										<div style={{display: 'table-cell', width: '70px'}}>
											<b>{dictionary.Name}:</b>
										</div>
										<div
											style={{
												display: 'table-cell',
												verticalAlign: 'middle',
												width: 'calc(100% - 70px)',
											}}>
											<b
												style={{
													width: '150px',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													overflow: 'hidden',
													display: 'block',
												}}>
												{userName}
											</b>
										</div>
									</div>
									<div
										title={`${dictionary.Email}: ${userEmail}`}
										style={{display: 'table-row', width: '100%'}}>
										<div style={{display: 'table-cell', width: '70px'}}>
											<b style={{ fontSize: '11px'}}>{dictionary.Email}:</b>
										</div>
										<div
											style={{
												display: 'table-cell',
												verticalAlign: 'middle',
												width: 'calc(100% - 70px)',
											}}>
											<b
												style={{
													width: '150px',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													overflow: 'hidden',
													display: 'block',
													fontSize: '11px'
												}}>
												{userEmail}
											</b>
										</div>
									</div>
								</div>
							</UserCardInfosDiv>
							<UserCardArrowFunctionDiv>
								<FontAwesomeIcon
									icon={
										showFunctionsList
											? faArrowAltCircleUp
											: faArrowAltCircleDown
									}
								/>
							</UserCardArrowFunctionDiv>
						</UserCardDiv>
					</>
				)}
			</div>
		</div>
	)
}

function FunctionsList(list) {
	let functionArray = []
	for (let i = 0; i < list.length; i++) {
		let myFunction = list[i]

		let functionButton = (
			<UserCardFunctionButtonDiv
				key={'userCardFunction' + myFunction.label}
				onClick={myFunction.func}>
				<b style={{marginRight: '5px'}}>{myFunction.label}</b>
				<b style={{marginRight: '5px'}}>
					<FontAwesomeIcon icon={myFunction.icon} />
				</b>
			</UserCardFunctionButtonDiv>
		)

		functionArray.push(functionButton)
	}

	return <UserCardFunctionsListDiv>{functionArray}</UserCardFunctionsListDiv>
}
