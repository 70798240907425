import { hideLoadingBlur, showLoadingBlur } from 'src/actions'
import { AutonomyColumnChart } from './autonomyColumnChart'
import { AverageOperationLine } from './averageOperationLine/index.jsx'
import ClientSelect from 'src/components/ClientSelect'
import MonthReportView from 'src/components/MonthReportView'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createFetchRequest } from 'src/genericFunctions'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'
import html2canvas from 'html2canvas'

const styles = {
	container: {
		width: '100%',
		minHeight: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		padding: '10px 0',
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	subTitle: {
		fontWeight: '700',
		marginTop: '15px',
		color: '#000000',
		fontSize: '16px',
	},
	flex: {
		display: 'flex'
	},
	select: {
		borderRadius: '5px',
		border: '1px solid #8A05BE',
		color: '#8A05BE',
		padding: '7px',
		margin: '7px',
	}
}

function efficiencyStyle(value) {
	if (value <= 0) {
		return null
	}

	if (value >= 90) {
		return { backgroundColor: '#0a8754' }
	}

	if (value >= 80 && value < 90) {
		return { backgroundColor: '#9ea93f' }
	}

	if (value >= 70 && value < 80) {
		return { backgroundColor: '#f2af29' }
	}

	if (value >= 60 && value < 70) {
		return { backgroundColor: '#ff9b42' }
	}

	if (value < 60 && value > 0) {
		return { backgroundColor: '#ad343e' }
	}
}

/**
 * 
 * @param {HTMLCanvasElement} data 
 * @returns {Promise<Blob>}
 */
const toBlobPromise = async (data) => {
	return new Promise((resolve, reject) => {
		if (data) {
			try {
				data.toBlob((blob) => {
					resolve(blob)
				}, "image/jpeg", 1)
			} catch (error) {
				reject(error)
			}
		} else {
			reject("Invalid argument")
		}
	})
}

const MonthReport = ({ showLoadingBlur, hideLoadingBlur }) => {
	const [selectedClient, setSelectedClient] = React.useState(null)
	const [selectedBranch, setSelectedBranch] = React.useState(null)
	const [lastAutonomies, setLastAutonomies] = React.useState([])
	const [averageBatteryRooms, setAverageBatteryRooms] = React.useState([])
	const [averageAutonomyBatteryModel, setAverageAutonomyBatteryModel] = React.useState()
	const [countCyclesByEmployee, setCountCyclesByEmployee] = React.useState()
	const [diffBetweenExchangeAndCharge, setDiffBetweenExchangeAndCharge] = React.useState()
	const [exchangePerHour, setExchangePerHour] = React.useState()
	const [autonomyOperation, setAutonomyOperation] = React.useState()
	const [years, setYears] = React.useState([])
	const [months, setMonths] = React.useState([])
	const [period, setPeriod] = React.useState({
		year: undefined,
		month: undefined,
	})
	const [data, setData] = React.useState()
	const [builded, setBuilded] = React.useState(false)
	const [pageRefs, setPageRefs] = React.useState([])
	const [charts, setCharts] = React.useState([])


	const formatDateMMYYYY = () => {
		return (period.month + 1) < 10 ? '0'.concat(period.month + 1).concat('/').concat(period.year) : `${period.month + 1}/${period.year}`
	}

	const loadClientInfo = React.useCallback(() => {
		let client = getItem('selected_client')

		if (client !== null) {
			createFetchRequest(
				'client/getOne/' + client.selectedClient,
				'get',
				null,
				async ({ err, result }) => {
					if (err) setSelectedClient(null)
					else if (!err && result) {
						setSelectedClient(result)

						if (result.branchs.length === 1) {
							setSelectedBranch(result.branchs[0])
						}


					}
				},
			)
		}
	}, [])

	React.useEffect(() => {
		loadClientInfo()
	}, [loadClientInfo])

	React.useEffect(() => {
		const dt = new Date()
		const firstYear = 2020
		const yrs = []
		const mths = []

		for (let i = firstYear; i <= dt.getFullYear(); i++) {
			yrs.push(i)
		}

		yrs.unshift(undefined)

		setYears(yrs)

		for (let i = 0; i <= 11; i++) {
			mths.push(i)
		}

		mths.unshift(undefined)
		setMonths(mths)
	}, [])

	React.useEffect(() => {
		const loadPageData = () => {
			try {
				showLoadingBlur()
				if (selectedClient && selectedBranch) {
					createFetchRequest(`monthReport/?clientId=${selectedClient.id}&month=${period.month}&year=${period.year}`, 'get', null, async ({ err, result }) => {
						if (err) {
							throw new Error()
						}

						const lstAutonomies = result.map(e => ({ value: e.autonomy, label: dictionary.arrayNamesMonthsAbbr[e.month] }))
						setLastAutonomies(lstAutonomies.reverse())

						createFetchRequest(`dashboard/monthlyOperation/?year=${period.year}&month=${period.month + 1}`, 'get', null, async ({ err, result: monthlyOperation }) => {
							if (err) {
								throw new Error()
							}

							setData(monthlyOperation)

							createFetchRequest(`dashboard/batteryRoomChart/?year=${period.year}&month=${period.month + 1}&totalMonths=6`, 'get', null, async ({ err, result: averageBatteryRoomVal }) => {
								if (err) {
									throw new Error()
								}

								const lastColumn = averageBatteryRoomVal.columns[averageBatteryRoomVal.columns.length - 1]
								const values = averageBatteryRoomVal.rows.map(e => ({ label: e.label, value: e[lastColumn] }))

								setAverageBatteryRooms(averageBatteryRoomVal)

								createFetchRequest(
									`monthReport/averageAutonomyByBatteryModel/${selectedClient.id}/${selectedBranch.id}/?year=${period.year}&month=${period.month}`,
									'get',
									null,
									async ({ err, result: averageAutonomyBatteryModelVal }) => {
										if (err) {
											throw new Error()
										}

										setAverageAutonomyBatteryModel(averageAutonomyBatteryModelVal)

										createFetchRequest(
											`monthReport/countCyclesByEmployee/${selectedClient.id}/${selectedBranch.id}/?year=${period.year}&month=${period.month}`,
											'get',
											null,
											async ({ err, result: countCyclesByEmployeeVal }) => {
												if (err) {
													throw new Error()
												}

												setCountCyclesByEmployee(countCyclesByEmployeeVal)
												createFetchRequest(
													`monthReport/diffBetweenExchangeAndCharge/${selectedClient.id}/${selectedBranch.id}/?year=${period.year}&month=${period.month}`,
													'get',
													null,
													async ({ err, result: diffBetweenExchangeAndChargeVal }) => {
														if (err) {
															throw new Error()
														}

														setDiffBetweenExchangeAndCharge(diffBetweenExchangeAndChargeVal)

														createFetchRequest(
															`monthReport/exchangePerHour/${selectedClient.id}/${selectedBranch.id}/?year=${period.year}&month=${period.month}`,
															'get',
															null,
															async ({ err, result: exchangePerHourVal }) => {
																if (err) {
																	throw new Error()
																}

																console.log(exchangePerHourVal)
																setExchangePerHour(exchangePerHourVal)

																createFetchRequest(
																	'dashboard/forkliftModelsChart/'.concat(`?year=${period.year}&month=${period.month + 1}&totalMonths=6`),
																	'get',
																	null,
																	async ({ err, result: autonomyOperationVal }) => {
																		if (err) {
																			throw new Error()
																		}

																		const rows = autonomyOperationVal.rows
																		const columns = autonomyOperationVal.columns

																		columns.unshift("label")

																		setAutonomyOperation({ rows, columns })

																		const charts = [
																			<AverageOperationLine values={lstAutonomies.reverse()} />,
																			<AutonomyColumnChart
																				values={values}
																				title={`Autonomia média por sala em ${period.month + 1 < 10 ? '0'.concat(period.month + 1) : period.month + 1}/${period.year}`} />,
																			<AutonomyColumnChart backgroundColor='#8A05BE' datalabelsDisplay={false} barThickness={8} values={exchangePerHourVal.currentMonth} />,
																			<AutonomyColumnChart datalabelsDisplay={false} barThickness={8} values={exchangePerHourVal.lastMonths} />
																		]

																		const pgsRefs = []

																		for (let i = 0; i < charts.length; i++) {
																			pgsRefs.push({
																				content: charts[i],
																				ref: React.createRef()
																			})
																		}
																		setPageRefs(pgsRefs)

																		const loadCharts = async () => {
																			const widthCanva = 600
																			const heightCanva = 400

																			let buildCharts = []
																			for (let i = 0; i < pgsRefs.length; i++) {
																				const canvaChart = await html2canvas(pgsRefs[i].ref.current, { scale: 1, allowTaint: true, useCORS: true, height: heightCanva, width: widthCanva })
																				const blobChart = await toBlobPromise(canvaChart)
																				buildCharts.push(blobChart)
																			}

																			setCharts(buildCharts)
																			setBuilded(true)
																			hideLoadingBlur()
																		}

																		setTimeout(() => loadCharts(), 3000)
																	}
																)

															}
														)
													}
												)
											}
										)
									})
							})
						})

					})
				} else {
					hideLoadingBlur()
				}
			}
			catch (error) {
				window.alert("Erro ao gerar o relatório mensal")
				hideLoadingBlur()
			}
		}

		if (period.month && period.year) {
			loadPageData()
		} else {
			hideLoadingBlur()
		}
	}, [hideLoadingBlur, period.month, period.year, selectedBranch, selectedClient, showLoadingBlur])

	React.useEffect(() => {
		const loadCharts = async () => {
			const widthCanva = 600
			const heightCanva = 300

			let buildCharts = []
			for (let i = 0; i < pageRefs.length; i++) {
				const canvaChart = await html2canvas(pageRefs[i].ref.current, { scale: 3, allowTaint: true, useCORS: true, height: heightCanva, width: widthCanva })
				const blobChart = await toBlobPromise(canvaChart)
				buildCharts.push(blobChart)
			}

			setCharts(buildCharts)
		}

		if (builded) {
			loadCharts()
		}
	}, [builded, pageRefs])


	const onChangePeriod = React.useCallback((key, value) => {
		value = parseInt(value)

		const currentDate = new Date()
		if (key === 'month' && value > currentDate.getMonth() - 1 && period.year >= currentDate.getFullYear()) {
			setPeriod(prev => ({ ...prev, [key]: currentDate.getMonth() - 1 }))
			return
		}

		if (key === 'year' && value === currentDate.getFullYear() && period.month >= currentDate.getMonth()) {
			console.log('aqui')
			setPeriod({ year: value, month: currentDate.getMonth() - 1 })
			return
		}


		setPeriod(prev => ({ ...prev, [key]: value }))


	}, [period.month, period.year])

	return (
		<>
			{!selectedClient && !selectedBranch ? (
				<div style={styles.container}>
					<h1>Selecione um cliente</h1>
					<ClientSelect />
				</div>
			) : !selectedBranch ? (
				<div>
					<h1>Selecione a sede</h1>
					<select style={styles.select} onChange={(e) => {
						const branch = selectedClient.branchs.find(cli => cli.id === e)

						if (branch) {
							setSelectedBranch(branch)
						}
					}}>
						{selectedClient.branchs.map(e => (
							<option value={e.id}>{e.name}</option>
						))}
					</select>
				</div>
			) : period.month === undefined || period.year === undefined ? (
				<div style={styles.container}>
					<h1>Selecionar período</h1>
					<div style={styles.flex}>
						{months && (
							<div style={styles.titleContainer}>
								<label>Mês</label>
								<select style={styles.select} defaultValue={period.month} onChange={e => onChangePeriod('month', e.target.value)}>
									{months.map(e => (
										<option key={e} value={e}>
											{e !== undefined ? dictionary.arrayNamesMonths[e] : 'Selecione'}
										</option>
									))}
								</select>
							</div>
						)}

						{years && (
							<div style={styles.titleContainer}>
								<label>Ano</label>
								<select style={styles.select} defaultValue={period.year} onChange={e => onChangePeriod('year', e.target.value)}>
									{years.map(e => (
										<option key={e} value={e}>
											{e !== undefined ? e : 'Selecione'}
										</option>
									))}
								</select>
							</div>
						)}
					</div>
				</div>
			) : (
				<div style={styles.container}>
					<div>
						<h1>Período: {dictionary.arrayNamesMonths[period.month]} - {period.year}</h1>
					</div>
					{data && period && lastAutonomies && averageBatteryRooms && (
						<>
							<div style={{ position: 'fixed', top: '20%', zIndex: 1, opacity: 0, width: '600px' }}>
								{pageRefs.map(pag => (
									<div ref={pag.ref}>
										{pag.content}
									</div>
								))}
							</div>

							{charts.length === 4 && builded && charts[0] !== undefined && (
								<MonthReportView
									autonomyOperation={autonomyOperation}
									lastAutonomies={lastAutonomies}
									client={selectedClient?.name}
									branch={selectedBranch?.name}
									month={period.month}
									year={period.year}
									charts={charts}
									averageBatteryRooms={averageBatteryRooms}
									averageAutonomyBatteryModel={averageAutonomyBatteryModel}
									countCyclesByEmployee={countCyclesByEmployee}
									diffBetweenExchangeAndCharge={diffBetweenExchangeAndCharge}
									date={formatDateMMYYYY()}
									data={data}
								/>
							)}

						</>
					)}
				</div >
			)}
		</>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(MonthReport)
