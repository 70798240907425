// Link color
export const PURPLE = '#8A05BE'
export const PURPLE_DARK = '#101d33'
export const PURPLE_LIGHT = '#EBEBEA'
export const PURPLE_MEDIUM = '#DDDDDD'

// Colors pallet
export const RED = '#FF2C2C'
export const RED_DARK = '#D32C44'
export const RED_MEDIUM = '#E92C38'
export const RED_LIGHT = '#FFC6C6'
export const RED_OPACITY_9 = 'rgba(255, 44, 44, 0.9)'
export const RED_OPACITY_6 = 'rgba(255, 44, 44, 0.60)'
export const RED_OPACITY_3 = 'rgba(255, 44, 44, 0.30)'

export const YELLOW_LIGHT = '#F7F79E'
export const YELLOW_MEDIUM = '#F5C33D'

export const CYAN = '#00C2FF'
export const CYAN_OPACITY_3 = 'rgba(0, 194, 255, 0.30)'

export const BLUE_LIGHT = '#5DADF7'
export const BLUE_MEDIUM = '#376FFF'
export const BLUE_DARK = '#273856'
export const BLUE_DARK_2 = '#050D1B'

export const GREEN = '#00C041'
export const GREEN_DARK = '#007026'
export const GREEN_OPACITY_3 = 'rgba(0, 162, 65, 0.30)'

// Severities
export const RED_HIGH_SEVERITY = '#FF2C2C'
export const RED_MEDIUM_SEVERITY = '#FF7B7B'
export const RED_LOW_SEVERITY = '#FFC6C6'

export const BLACK = '#000000'
export const BLACK_OPACITY_3 = 'rgba(0, 0, 0, 0.3)'
export const BLACK_OPACITY_5 = 'rgba(0, 0, 0, 0.5)'
export const BLACK_OPACITY_7 = 'rgba(0, 0, 0, 0.7)'

export const WHITE = '#FFFFFF'
export const WHITE_OPACITY_2 = 'rgba(255, 255, 255, 0.2)'
export const WHITE_OPACITY_5 = 'rgba(255, 255, 255, 0.5)'
export const WHITE_OPACITY_8 = 'rgba(255, 255, 255, 0.8)'

// GRAYSCALE
export const GRAY_LIGHT = '#F6F6F6'
export const GRAY_MEDIUM3 = '#E3E3E3'
export const GRAY_MEDIUM2 = '#E6E6E6'
export const GRAY_MEDIUM = '#C4C4C4'
export const GRAY_DARK = '#565656'
