import {Button} from 'src/styles/globalStyles'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80%;
`

export const Content = styled.div``

export const Title = styled.h1`
	font-size: 25px;
`
export const LabelContent = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
`

export const Label = styled.p`
	font-weight: bold;
	margin-right: 10px;
	font-size: 16px;
`
export const Description = styled.p`
	color: #333;
	font-size: 16px;
`
export const Image = styled.img`
	height: 300px;
	width: 300px;
	border-radius: 5px;
	/* transform: rotate(90deg); */
`

export const ImageLink = styled.a`
	text-decoration: none;
`
export const ContainerButtons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80%;
`
export const ButtonModal = styled(Button)`
	margin: 10px;
`
