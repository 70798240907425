import {PURPLE_DARK} from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	border-radius: 8px;
	background-color: ${PURPLE_DARK};
	width: 120px;
	height: 120px;
	color: #ffffff;
	gap: 4;
`

export const Title = styled.h1`
	font-weight: bold;
	font-size: 14px;
	border-bottom: 1px solid #ffffff;
	text-align: center;
	padding: 4px;
`
