import {
	ForkliftTagContainer,
	ForkliftTagContent,
	ForkliftTagHeader,
	ForkliftTagQrCodeContent,
	ForkliftTagQrDataContent,
} from './styles'

import {Logo} from 'src/components/Logo'
import {QrCode} from 'src/components/QrCode'

/**
 * @author Carlos Padovezi
 * @function ForkliftTag
 * @file ForkliftTag.js
 * @summary Function to create battery tag
 * @returns Batteries battery tag
 */
export default function ForkliftTag({code, name, image}) {
	const mySrc = process.env.PUBLIC_URL

	const img = new Image(50, 50)
	img.src = `${mySrc}/public/logo/${image}`

	return (
		<ForkliftTagContainer>
			<ForkliftTagHeader>
				<Logo
					width={100}
					height={50}
					backgroundColor={'#FFF'}
					isLink={false}
					type={'svg'}
				/>
				{image ? (
					<img
						src={img.src}
						alt={name + 'image'}
						style={{
							width: '50px',
							height: '50px',
							marginRight: 10,
							objectFit: 'contain',
						}}
					/>
				) : null}
			</ForkliftTagHeader>
			<ForkliftTagContent>
				<ForkliftTagQrCodeContent>
					<QrCode
						code={code}
						size={100}
						width={100}
						height={100}
						bgColor={'#FFF'}
						fgColor={'#000'}
						includeMargin={false}
					/>
				</ForkliftTagQrCodeContent>
				<ForkliftTagQrDataContent>
					<div style={{display: 'table'}}>
						<div style={{display: 'table-row'}}>
							<div
								style={{
									display: 'table-cell',
									height: '20px',
									fontSize: '2.2em',
									fontWeight: 700,
								}}>
								{name}
							</div>
						</div>
					</div>
				</ForkliftTagQrDataContent>
			</ForkliftTagContent>
		</ForkliftTagContainer>
	)
}
