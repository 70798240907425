import {Button, PageParentDiv} from '../../styles/globalStyles'
import {
	ContentDiv,
	ModalContainer,
	SearchPeriodDiv,
	TypesCrudButton,
	TypesCrudButtonsRow,
} from './styles'

import {List} from '../List/index.jsx'
import React from 'react'
import {RowCalendarDateHour} from '../RowCalendarDateHour'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function CrudModal
 * @file CrudModal.jsx
 * @summary Function to create customers crud view
 * @param {Object} crudsObj - Object with cruds settings
 * LIST
 * @param {Object} crudsObj.list - Crud list configuration
 * @param {Array} crudsObj.list.columns - Arry list of columns object - Example: [{accessor:'name', Header: 'Nome', width: 70}, {accessor:'birthDate', Header: 'Data de aniversário', width: 70}]
 * @param {Array} crudsObj.list.rows - Arry list of rows object with columns keys - Example: [{name: 'Carlos Alberto', birthDate: '18/04/1997' }]
 * @param {Boolean=} crudsObj.list.checkboxSelection - If true, the grid get a first column with a checkbox that allows to select rows
 * @param {Function=} crudsObj.list.onRowSelected - If true, the grid get a first column with a checkbox that allows to select rows
 * @param {Function=} crudsObj.list.deleteButtonDisabledFunction - If true, the grid get a first column with a checkbox that allows to select rows
 * @param {Function=} crudsObj.list.getSearchPeriod -
 * CREATE
 * @param {Object} crudsObj.create - Crud create configuration
 * @param {Array} crudsObj.create.content - Create content
 * Buttons actions
 * @param {Array} actionsIcons - Create custom actions buttons
 * @returns Customers crud page
 */
export function CrudModal({
	crudsObj,
	crudSelected,
	changeCrudSelected,
	actionsIcons,
}) {
	function handleCrudTypeChange(crudType) {
		if (crudSelected === crudType) return
		changeCrudSelected(crudType)
	}

	//CRUD buttons type
	let buttonsHeader = []
	for (let crud in crudsObj) {
		let crudObj = crudsObj[crud]
		let isSelected = crudSelected === crud

		let crudButtons = createTypesCrudButtons(
			crudObj.title,
			crud,
			handleCrudTypeChange,
			isSelected,
		)

		buttonsHeader.push(crudButtons)
	}	

	//Construct CRUDs page
	let currentComponent = <div></div>

	switch (crudSelected) {
		case 'list':
			let listObj = crudsObj['list']
			currentComponent = (
				<List
					columns={listObj.list.columns}
					rows={listObj.list.rows}
					onRowEditClick={crudsObj.list.onRowEditClick}
					onRowEditLabel={crudsObj.list.onRowEditLabel}
					onRowEditIcon={crudsObj.list.onRowEditIcon}
					onRowDeleteClick={crudsObj.list.onRowDeleteClick}
					onRowDeleteLabel={crudsObj.list.onRowDeleteLabel}
					onRowDeleteIcon={crudsObj.list.onRowDeleteIcon}
					onRowCreateClick={crudsObj.list.onRowCreateClick}
					onRowCreateLabel={crudsObj.list.onRowCreateLabel}
					onRowCreateIcon={crudsObj.list.onRowCreateIcon}
					deleteButtonDisabledFunction={
						crudsObj.list.deleteButtonDisabledFunction
					}
					autoPageSize={true}
					checkboxSelection={crudsObj.list.checkboxSelection}
					onRowSelected={crudsObj.list.onRowSelected}
					actionsIcons={actionsIcons}
					getTableStyle={crudsObj.list.getTableStyle}
				/>
			)
			break
		case 'create':
			currentComponent = crudsObj['create'].content
			break
		default:
			break
	}

	//Search period component
	let searchPeriodComponent = null
	if (
		crudSelected === 'list' &&
		crudsObj &&
		crudsObj.list &&
		crudsObj.list.searchPeriod
	) {
		let {
			startDate,
			onStartDateChange,
			endDate,
			onEndDateChange,
			onReportUpdate,
		} = crudsObj.list.searchPeriod

		searchPeriodComponent = (
			<SearchPeriodDiv>
				<b style={{fontSize: '14px'}}>{dictionary.Start}: </b>
				<RowCalendarDateHour
					type={'date'}
					value={startDate}
					label={dictionary.start}
					onChange={onStartDateChange}
					height={'40px'}
					width={'150px'}
					fontSize={'8px'}
				/>
				<b style={{fontSize: '14px'}}>{dictionary.End}: </b>
				<RowCalendarDateHour
					type={'date'}
					value={endDate}
					label={dictionary.end}
					onChange={onEndDateChange}
					height={'40px'}
					width={'150px'}
					fontSize={'8px'}
				/>
				<Button width={'70px'} height={'30px'} onClick={onReportUpdate}>
					<b>{dictionary.update}</b>
				</Button>
			</SearchPeriodDiv>
		)
	}

	return (
		<PageParentDiv>
			<ModalContainer>
				<TypesCrudButtonsRow>
					{buttonsHeader}
					{searchPeriodComponent}
				</TypesCrudButtonsRow>
				<ContentDiv>{currentComponent}</ContentDiv>
			</ModalContainer>
		</PageParentDiv>
	)
}

function createTypesCrudButtons(title, type, onClickFunction, typeSelected) {
	return (
		<TypesCrudButton
			key={'button' + type}
			isSelected={typeSelected}
			onClick={() => onClickFunction(type)}>
			<b>{title}</b>
		</TypesCrudButton>
	)
}
