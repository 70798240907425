import {GRAY_DARK, GRAY_MEDIUM, PURPLE} from '../../styles/colors'

import styled from 'styled-components'

export const Row = styled.div`
	height: ${(props) => (props.height ? props.height : 'auto')};
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	margin: 5px;
`

export const LabelDiv = styled.div`
	color: ${GRAY_DARK};
	font-weight: ${(props) => (props.isObligatory ? 600 : 0)};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
	margin-bottom: 5px;
	margin-left: 15px;
`

export const Input = styled.input`
	width: ${(props) => (props.width ? props.width : '300px')};
	height: ${(props) => (props.height ? props.height : '30px')};
	opacity: ${(props) => (props.disabled ? '0.5' : '1')};

	font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
	text-align: ${(props) => (props.centerText ? 'center' : 'left')};

	padding-left: ${(props) => (props.centerText ? '0px' : '15px')};

	color: ${GRAY_DARK};
	outline: none;
	border: none;
	border-bottom: 1px solid;
	boder-color: ${(props) => (props.isInvalidInput ? '#F00' : GRAY_MEDIUM)};

	transition: border-color 1s;

	&:hover {
		border-bottom: 2px solid;
		border-color: ${GRAY_DARK};
	}

	&:focus {
		border-bottom: 2px solid;
		border-color: ${PURPLE};
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}
`
export const ReadOnly = styled.p`
	width: ${(props) => (props.width ? props.width : '300px')};
	height: ${(props) => (props.height ? props.height : '30px')};

	font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
	text-align: ${(props) => (props.centerText ? 'center' : 'left')};
	color: ${GRAY_DARK};

	padding-left: ${(props) => (props.centerText ? '0px' : '15px')};

	outline: none;
	border: none;
	border-bottom: 1px solid;
	boder-color: ${GRAY_MEDIUM};
`

export const ShowInvalidInput = styled.p`
	font-size: 12px;
	text-align: left;
	font-weight: 700;
	color: #f00;
	position: absolute;
	transform: translateX(330px);
`

export const RowInputWithError = styled.div`
	display: flex;
	position: relative;
`
