import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React from 'react'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)


export function AutonomyColumnChart({ values, title, datalabelsDisplay = true, barThickness = 35, backgroundColor = '#2f61f8' }) {
	const options = {
		responsive: true,
		scales: {
			xAxes: {
				barPercentage: 0.5,
				gridLines: {
					display: false,
				},
				grid: {
					display: 'false',
				},
				scaleLabel: {
					display: false
				},
				ticks: {
					display: true, // it should work
					autoSkip: false,
					font: {
						size: 10
					},
					maxRotation: 0,
					minRotation: 0
				}
			},
			yAxes: {
				barPercentage: 0.5,
				gridLines: {
					display: false,
				},
				grid: {
					display: false,
				},
				scaleLabel: {
					display: false
				},
				ticks: {
					display: true, // it should work
					autoSkip: false,
					font: {
						size: 10
					},
				}
			},
		},
		plugins: {
			legend: {
				position: 'top',
				display: false,
			},
			title: {
				display: true,
				text: title,
			},
			datalabels: {
				display: datalabelsDisplay,
				anchor: 'start',
				align: 'top',
				font: {
					weight: 'bold',
					color: '#ffffff',
				},
				color: '#ffffff'
			}
		},
	}

	const data = {
		labels: values.map((e) => e.label),
		datasets: [
			{
				label: '',
				data: values.map((e) => e.value),
				backgroundColor: backgroundColor,
				borderColor: "#7e7e7e",
				borderWidth: 1,
				barThickness: barThickness
			},
		],
	}

	return <Bar options={options} data={data} />
}
