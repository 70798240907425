import {Button, CreateFormDiv} from 'src/styles/globalStyles'
import React, {useState} from 'react'

import {RowFileInput} from '../../components/RowFileInput/index.jsx'
import {RowInput} from '../../components/RowInput/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

/**
 * @author Carlos Padovezi
 * @function EditCustomer
 * @file CustomerCrud.js
 * @summary Function to edit Customers register view
 * @returns Customers edit crud page
 */
export default function EditCustomer({editData, handleCancelEdit, updatePage, onChangeView }) {
	const [formData, setformData] = useState({
		editingId: '',
		name: '',
		cnpj: '',
		address: '',
		phone: '',
		email: '',
		image: ''
	})

	React.useState(() => {
		setformData({
			editingId: editData.id ?? null,
			name: editData.name ?? '',
			cnpj: editData.cnpj ?? '',
			address: editData.address ?? '',
			phone: editData.phone ?? '',
			email: editData.email ?? '',
			image: editData.image ? new File([], editData.image) : '',
		})
	}, [editData])

	function handleSaveNewCustomer() {
		const context = {
			id: formData.editingId,
			name: formData.name,
			cnpj: formData.cnpj,
			address: formData.address,
			phone: formData.phone,
			email: formData.email,
		}

		createFetchRequest('client/update', 'put', context, ({err, result}) => {
			if (err) {
				console.log(err)
			} else {
				if (formData.image || formData.image === null) {
					//Send image
					createFetchRequest(
						`/client/image/${formData.editingId}`,
						'put',
						formData.image,
						({err, result}) => {
							if (err) {
								console.log(err)
							} else {
								//Reset inputs values
								updatePage()
								onChangeView('list')
							}
						},
					)
				} else {
					//Reset inputs values
					updatePage()
					onChangeView('list')
				}
			}
		})
	}

	let validButton =
		formData.name &&
		formData.cnpj &&
		formData.address &&
		formData.phone &&
		formData.email

	return (
		<>
			<CreateFormDiv>				
				<RowInput
					value={formData.cnpj}
					label={dictionary.CNPJ}
					type={'cnpj'}
					fontSize={'16px'}
					onChange={(value) =>
						setformData((state) => ({...state, cnpj: value}))
					}
					readOnly={true}
				/>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(value) =>
						setformData((state) => ({...state, name: value}))
					}
					autoFocus={true}
				/>
				<RowInput
					value={formData.address}
					label={dictionary.Address}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(value) =>
						setformData((state) => ({...state, address: value}))
					}
				/>
				<RowInput
					value={formData.email}
					label={dictionary.Email}
					isObligatory={true}
					type={'email'}
					fontSize={'16px'}
					onChange={(value) =>
						setformData((state) => ({...state, email: value}))
					}
				/>
				<RowInput
					value={formData.phone}
					label={dictionary.Phone}
					type={'phone'}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(value) =>
						setformData((state) => ({...state, phone: value}))
					}
				/>
				<RowFileInput
					value={editData.image}
					label={dictionary.clientImage}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(image) => setformData((state) => ({...state, image}))}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveNewCustomer}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={handleCancelEdit}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b>{dictionary.Cancel}</b>
					</Button>
				</div>				
			</CreateFormDiv>
		</>
	)
}
