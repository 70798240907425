import React, { useCallback, useState } from 'react'
import InputMask from 'react-input-mask'
import { PURPLE } from 'src/styles/colors'
import { debounce } from 'lodash'

function MonthYearFilter({ value, onChange }) {
	const [val, setVal] = useState(value)

	// Use useCallback para criar uma versão memorizada de debounceOnChange
	// que só muda se `onChange` mudar.
	const debounceOnChange = useCallback(
		// Envolve onChange com debounce
		debounce((event) => onChange(event), 1000),
		// Depende de `onChange` para ser recriado.
		[onChange]
	)

	const onChangeValue = (event) => {
		const { value: currentValue } = event.target
		setVal(currentValue)
		// Aqui você chama a versão debounced de onChange
		debounceOnChange(event)
	}

	return (
		<InputMask
			style={{
				width: '60px',
				borderRadius: '8px',
				border: `1px solid ${PURPLE}`,
				color: PURPLE,
			}}
			mask="99/9999"
			placeholder="MM/YYYY"
			onChange={onChangeValue}
			value={val}
		/>
	)
}

export default MonthYearFilter
