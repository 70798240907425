import styled from 'styled-components'

export const Table = styled.table`
	white-space: nowrap;
	border: none;
	border-collapse: separate;
	border-spacing: 1px;
	border-color: #fff;
	table-layout: fixed;
`

export const THeader = styled.thead``

export const TBody = styled.tbody`
	max-height: 350px;
	overflow: auto;
`

export const THead = styled.th`
	z-index: 150;
	position: sticky;
	top: 0;
	padding: 8px;
	font-size: 14px;
	background-color: ${(props) => (props.color ? props.color : '#fdfdfd')};
	text-align: center;
`

export const TRow = styled.tr`
	padding: 0px;
`

export const TCell = styled.td`
	background-color: ${(props) => (props.color ? props.color : '#fdfdfd')};
	font-size: 12px;
	font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
	padding: 8px;
	margin: 0;
	text-align: center;
`
