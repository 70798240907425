import { Button, MainContentDiv } from 'src/styles/globalStyles'
import { 
	Content, 
	ContentForm, 
	InputContainer,
	InputContent,
	LabelError,
	Title,
	TitlePage,
	TypesCrudButton,
} from './styles'
import {createFetchRequest, validateEmail} from 'src/genericFunctions'
import React from 'react'
import { RowInput } from 'src/components/RowInput'
import { RowSelect } from 'src/components/RowSelect'
import dictionary from 'src/utils/dictionary'
import { setItem } from 'src/utils/localStorage'

const MyProfile = () => {
	const [formData, setFormData] = React.useState({
		name: '',
		email: '',
		cpf: '',
		receive_email: false,
	})

	const [formPassword, setFormPassword] = React.useState({
		password: '',
		newPassword: '',
		confirmNewPassword: '',
	})

	const [errors, setErrors] = React.useState({
		password: '',
		newPassword: '',
		confirmNewPassword: '',
		name: '',
		cpf: '',
		email: '',
		showError: false,
	})

	const loadData = React.useCallback(() => {
		createFetchRequest('/employee/myProfile', 'get', null, ({ err, result }) => {
			if (!err && result) {
				console.log(result)

				setFormData({
					name: result.name,
					email: result.email ? result.email : '',
					cpf: result.cpf ? result.cpf : '',
					receive_email: result.receive_email ? result.receive_email : false,
				})
			}
		})
	}, [])

	React.useEffect(() => {
		loadData()
	}, [loadData])

	const validFormPassword = React.useCallback(() => {
		let valid = true
		let newErrors = errors
		
		if (formPassword.newPassword && formPassword.newPassword !== '') {
			if (!formPassword.password || formPassword.password.length === 0) {
				console.log('Senha Atual é obrigatório')
				newErrors.password = 'Senha Atual é obrigatório'
				valid = false
			} 
			
			if (formPassword.newPassword !== formPassword.confirmNewPassword) {
				console.log('Nova senha e Confirmar nova senha são diferentes.')
				newErrors.newPassword = 'Nova senha e Confirmar nova senha são diferentes.'
				valid = false
			}

			if (formPassword.newPassword.length < 6) {
				newErrors.newPassword = 'Nova senha precisa ter no mínimo 6 dígitos'
				valid = false
			}
		}

		return valid
	}, [errors, formPassword])

	const validFormData = React.useCallback(() => {		
		let valid = true

		let newErrors = errors

		if (formData.name == null || formData.name.length === 0) {		
			newErrors.name = 'Nome é obrigatório'
			valid = false			
		}
		
		if ((formData.email !== null && formData.email !== '') && !validateEmail(formData.email)) {			
			newErrors.email = 'Email é inválido'
			valid = false
		}

		if (formData.cpf === null || formData.cpf.length < 11) {
			newErrors.cpf = 'CPF é inválido'
			valid = false
		}
		
		setErrors(newErrors)
		return valid
	}, [errors, formData])

	const onSubmit = React.useCallback(() => {		
		const isValidNewPassword = validFormPassword()
		const isValidFormData = validFormData()		

		console.log(isValidFormData, isValidNewPassword)

		if(!isValidNewPassword) {
			setErrors({...errors, showError: true, })
			return
		}

		if(!isValidFormData) {
			setErrors({...errors, showError: true, })
			return
		}

		setErrors({...errors, showError: false })

		const context = Object.assign(formData, formPassword)

		context.receive_email = context.receive_email === true || context.receive_email === 'true'

		console.log(context)

		createFetchRequest('/employee/myProfile', 'put', context, ({ err, result }) => {
			if(err) {
				window.alert('Erro ao editar dados')
				return
			}
			else if(!err && result) {
				console.log('RESULT', result)
				window.alert('Dados editados com sucesso')				
				setFormPassword({ password: '', newPassword: '', confirmNewPassword: ''})				

				try {
					const userInfosObj = {
						id: result.id,
						branch_id: result.branch_id,
						client_id: result.branch.client_id,
						name: result.name,
						cpf: result.cpf,
						email: result.email,
						profile_id: result.profile_id,					
					}
				
					setItem('session', userInfosObj)

					window.location.reload()
				} catch(err) {
					console.log(err)
				}
				return
			}
			return
		})

	}, [errors, formData, formPassword, validFormData, validFormPassword])

	return (
		<MainContentDiv>
			<Content>
				<TitlePage>
					<TypesCrudButton disabled>Editar Perfil</TypesCrudButton>
				</TitlePage>
				<ContentForm>
					<InputContent>
						<InputContainer>
							<Title>Dados Pessoais</Title>
							<RowInput  
								value={formData.name}
								label={dictionary.Name}
								fontSize={'16px'}
								isObligatory={true}
								onChange={(name) => { 
									setErrors({...errors, name: ''})
									setFormData((state) => ({...state, name}))									
								}}
								autoFocus={true}
							/>
							<LabelError>
								{(errors.name.length !== 0 && errors.showError) && (
									<>{errors.name}</>
								)}
							</LabelError>
							<RowInput  
								value={formData.email}
								label={dictionary.Email}
								isObligatory={false}
								fontSize={'16px'}
								onChange={(email) => { 
									setFormData((state) => ({...state, email}))																	
								}}
								autoFocus={true}
								disabled={true}
							/>
							<LabelError>
								{(errors.email.length !== 0 && errors.showError) && (
									<>{errors.email}</>
								)}
							</LabelError>
							<RowInput
								value={formData.cpf}
								label={dictionary.cpf}
								type={'cpf'}
								isObligatory={true}
								fontSize={'16px'}
								onChange={(cpf) => {
									setFormData((state) => ({...state, cpf}))									
								}}
							/>
							<LabelError>
								{(errors.cpf.length !== 0 && errors.showError) && (
									<>{errors.cpf}</>
								)}
							</LabelError>	
							<RowSelect
								value={formData.receive_email}
								label={'Receber e-mail informativo?'}
								optionsList={[
									{value: false, label: 'Não'},
									{value: true, label: 'Sim'},
								]}
								isObligatory={false}
								fontSize={'16px'}
								onChange={(receive_email) => setFormData((state) => ({...state, receive_email}))}
							/>	
						</InputContainer>
						<InputContainer>
							<Title>Alterar Senha</Title>
							<RowInput  
								value={formPassword.password}
								label={'Senha atual'}
								isObligatory={true}
								type={'password'}
								fontSize={'16px'}
								onChange={(password) => { 
									setErrors({...errors, password: ''})
									setFormPassword((state) => ({...state, password}))}
								}
								autoFocus={true}
							/>
							<LabelError>
								{(errors.password.length !== 0 && errors.showError) && (
									<>{errors.password}</>
								)}
							</LabelError>
							<RowInput  
								value={formPassword.newPassword}
								label={'Nova senha'}
								isObligatory={true}
								type={'password'}
								fontSize={'16px'}
								onChange={(newPassword) => setFormPassword((state) => ({...state, newPassword}))}
								autoFocus={true}
							/>
							<LabelError/>
							<RowInput  
								value={formPassword.confirmNewPassword}
								label={'Confirmar nova senha'}
								isObligatory={true}
								type={'password'}
								fontSize={'16px'}
								onChange={(confirmNewPassword) => { 
									setErrors({...errors, newPassword: ''})
									setFormPassword((state) => ({...state, confirmNewPassword}))}
								}
								autoFocus={true}
							/>
							<LabelError>
								{(errors.newPassword.length !== 0 && errors.showError) && (
									<>{errors.newPassword}</>
								)}
							</LabelError>
						</InputContainer>
					</InputContent>
					<Content style={{ flex: '2', width: '100%' }}>
						<Button  onClick={onSubmit}>Editar Perfil</Button>
					</Content>
				</ContentForm>
			</Content>		
		</MainContentDiv>
	)
}

export default MyProfile