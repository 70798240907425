///////////loadingBlurStatus.js START
export const SHOW_LOADING_BLUR = 'SHOW_LOADING_BLUR'
export const HIDE_LOADING_BLUR = 'HIDE_LOADING_BLUR'
///////////loadingBlurStatus.js END

///////////User infomations START
export const USER_IS_LOGGED_IN = 'USER_IS_LOGGED_IN'
export const USER_IS_LOGGED_OUT = 'USER_IS_LOGGED_OUT'
export const USER_UPDATE_TOKEN = 'UPDATE_TOKEN'
export const USER_RESET_STATE = 'RESET_STATE'
///////////User infomations END
