import {CreateContentDiv, CreateFormDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

/**
 * @author Carlos Padovezi
 * @function CreateBranch
 * @file BranchCrud.js
 * @summary Function to create customers
 * @returns Customers create crud page
 */
export default function CreateBranch({updatePage}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [customersList, setCustomersList] = useState([])
	const [formData, setFormData] = useState({
		name: '',
		address: '',
		phone: '',
		email: '',
		client_id:
			userInfos && userInfos.profile_id === 1 ? '' : userInfos.client_id,
	})

	useEffect(() => {
		//get branch list
		createFetchRequest('client/getAll', 'get', null, async ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}
			let myList = []
			if (result && result.length > 0) {
				for (let customer of result) {
					myList.push({value: customer.id, label: customer.name})
				}
			}

			setCustomersList(myList)
		})
	}, [])

	const handleSaveNewBranch = () => {
		const context = {
			name: formData.name,
			address: formData.address,
			phone: formData.phone,
			email: formData.email,
			client_id: formData.client_id,
		}

		createFetchRequest(
			'branch/create',
			'post',
			context,
			async ({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					await updatePage()
				}
			},
		)
	}

	let validButton =
		formData.name &&
		formData.address &&
		formData.phone &&
		formData.email &&
		formData.client_id

	return (
		<CreateContentDiv>
			<CreateFormDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				<RowInput
					value={formData.address}
					label={dictionary.Address}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(address) => setFormData((state) => ({...state, address}))}
				/>
				<RowInput
					value={formData.email}
					label={dictionary.Email}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(email) => setFormData((state) => ({...state, email}))}
					type={'email'}
				/>
				<RowInput
					value={formData.phone}
					label={dictionary.Phone}
					isObligatory={true}
					type={'phone'}
					fontSize={'16px'}
					onChange={(phone) => setFormData((state) => ({...state, phone}))}
				/>
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={customersList}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id}))
						}
					/>
				) : null}
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveNewBranch}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={updatePage}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b> {dictionary.Cancel}</b>
					</Button>
				</div>
			</CreateFormDiv>
		</CreateContentDiv>
	)
}
