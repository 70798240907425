import {ButtonModal, Container, ContainerButtons, Content, Description, Image, ImageLink, Label, LabelContent, Title} from './styles'
import {EditModalBackgroundDiv, ViewModalDiv} from 'src/styles/globalStyles'
import React, { useEffect, useState } from 'react'
import {hideLoadingBlur, showLoadingBlur} from 'src/actions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PURPLE} from 'src/styles/colors'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import { createFetchRequest } from 'src/genericFunctions'
import dictionary from 'src/utils/dictionary'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import { useCallback } from 'react'

/**
 * @author Carlos Padovezi
 * @function AlertsModal
 * @file index.jsx
 * @summary Function to create alert modal
 * @returns Alert modal HTML
 */
function AlertsModal({type, device, last, closeModal, hideLoadingBlur, showLoadingBlur, loadTable}) {
	const [malfunction, setMalfunction] = useState(null)

	const loadPageData =  useCallback(() => {
		showLoadingBlur()
		let query = null
		if(last)  query = `/malfunction/${device.qrcode}`
		else query = `/malfunction/${device.qrcode}/${device.id}`

		createFetchRequest(query, 'get', null, async({ err, result}) => {
			if (err) setMalfunction(null)
			else if (!err && result) {
				setMalfunction(result)
			}

			await hideLoadingBlur()
		})
	}, [device.id, device.qrcode, hideLoadingBlur, last, showLoadingBlur])

	useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const finishedMalfunction = () => {
		let isConfirmed = window.confirm(dictionary.AreYourSureToFinished)

		if (isConfirmed) {
			
			showLoadingBlur()
			let query = null
			if(last)  query = `/malfunction/${device.qrcode}`
			else query = `/malfunction/${device.qrcode}/${device.id}`
			createFetchRequest(query, 'put', null, async({ err, result }) => {
				if (!err)
					setMalfunction(result)
				
				await hideLoadingBlur()
			})
		} else {
			hideLoadingBlur()
		}
	}

	const mySrc = process.env.PUBLIC_URL

	switch (type) {
		case 'Bateria':
		case 'battery':
			return (
				<EditModalBackgroundDiv>
					<ViewModalDiv style={{position: 'relative'}}>
						<FontAwesomeIcon
							icon={faTimesCircle}
							onClick={closeModal}
							style={{
								color: PURPLE,
								cursor: 'pointer',
								position: 'absolute',
								right: '50px',
								top: '10px',
								fontSize: '16px',
							}}
						/>
						<>
							<Title>{malfunction?.battery?.name} ({dictionary[malfunction?.type]})</Title>
							<Container>
								<Content>
									<LabelContent>
										<Label>Nome do equipamento: </Label>
										<Description>{malfunction?.battery?.name}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Tipo: </Label>
										<Description>{dictionary[malfunction?.type]}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Descrição: </Label>
										<Description>{malfunction?.notes}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Colaborador que registrou: </Label>
										<Description>{malfunction?.employee_start?.name}</Description>
									</LabelContent>
									{malfunction?.employee_end && (
										<LabelContent>
											<Label>Colaborador que finalizou: </Label>
											<Description>{malfunction?.employee_end?.name}</Description>
										</LabelContent>
									)}
									<LabelContent>
										<Label>Data do registro: </Label>
										<Description>{new Date(malfunction?.start_date).toLocaleDateString()}</Description>
									</LabelContent>
									{malfunction?.end_date && (
										<LabelContent>
											<Label>Data da finalização: </Label>
											<Description>{new Date(malfunction?.end_date).toLocaleDateString()}</Description>
										</LabelContent>
									)}
									<LabelContent>
										<Label>Ciclo do registro: </Label>
										<Description>{malfunction?.cycle}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Ciclo atual: </Label>
										<Description>{malfunction?.battery?.registered_cycles}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Observações: </Label>
										<Description>{malfunction?.obs}</Description>
									</LabelContent>
								</Content>
								{malfunction?.image !== null && (
									<LabelContent>
										<ImageLink href={`${mySrc}/public/malfunction/${malfunction?.image}`} target="_blank">
											<Image src={`${mySrc}/public/malfunction/${malfunction?.image}`} />
										</ImageLink>
									</LabelContent>
								)}
							</Container>
							<ContainerButtons>
								<ButtonModal onClick={closeModal}>Fechar</ButtonModal>
								{malfunction?.end_date == null && (<ButtonModal onClick={finishedMalfunction}>Finalizar Registro de Falha</ButtonModal>)}
							</ContainerButtons>
						</>
					</ViewModalDiv>
				</EditModalBackgroundDiv>
			)
		case 'Máquina':
		case 'forklift':
			return (
				<EditModalBackgroundDiv>
					<ViewModalDiv style={{position: 'relative'}}>
						<FontAwesomeIcon
							icon={faTimesCircle}
							onClick={closeModal}
							style={{
								color: PURPLE,
								cursor: 'pointer',
								position: 'absolute',
								right: '50px',
								top: '10px',
								fontSize: '16px',
							}}
						/>
						<>
							<Title>{malfunction?.forklift?.name} ({dictionary[malfunction?.type]})</Title>
							<Container>
								<Content>
									<LabelContent>
										<Label>Nome do equipamento: </Label>
										<Description>{malfunction?.forklift?.name}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Tipo: </Label>
										<Description>{dictionary[malfunction?.type]}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Descrição: </Label>
										<Description>{malfunction?.notes}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Colaborador que registrou: </Label>
										<Description>{malfunction?.employee_start?.name}</Description>
									</LabelContent>
									{malfunction?.employee_end && (
										<LabelContent>
											<Label>Colaborador que finalizou: </Label>
											<Description>{malfunction?.employee_end?.name}</Description>
										</LabelContent>
									)}
									<LabelContent>
										<Label>Data do registro: </Label>
										<Description>{new Date(malfunction?.start_date).toLocaleDateString()}</Description>
									</LabelContent>
									{malfunction?.end_date && (
										<LabelContent>
											<Label>Data da finalização: </Label>
											<Description>{new Date(malfunction?.end_date).toLocaleDateString()}</Description>
										</LabelContent>
									)}
									<LabelContent>
										<Label>Horímetro do registro: </Label>
										<Description>{malfunction?.hour_meter}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Horímetro atual: </Label>
										<Description>{malfunction?.forklift?.hour_meter}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Observações: </Label>
										<Description>{malfunction?.obs}</Description>
									</LabelContent>
								</Content>
								{malfunction?.image !== null && (
									<LabelContent>
										<ImageLink href={`${mySrc}/public/malfunction/${malfunction?.image}`} target="_blank">
											<Image src={`${mySrc}/public/malfunction/${malfunction?.image}`} />
										</ImageLink>
									</LabelContent>
								)}
							</Container>
							<ContainerButtons>
								<ButtonModal onClick={closeModal}>Fechar</ButtonModal>
								{malfunction?.end_date == null && (<ButtonModal onClick={finishedMalfunction}>Finalizar Registro de Falha</ButtonModal>)}
							</ContainerButtons>
						</>
					</ViewModalDiv>
				</EditModalBackgroundDiv>
			)
		case 'Carregador':
		case 'charger':
			return (
				<EditModalBackgroundDiv>
					<ViewModalDiv style={{position: 'relative'}}>
						<FontAwesomeIcon
							icon={faTimesCircle}
							onClick={closeModal}
							style={{
								color: PURPLE,
								cursor: 'pointer',
								position: 'absolute',
								right: '50px',
								top: '10px',
								fontSize: '16px',
							}}
						/>
						<>
							<Title>{malfunction?.charger?.name} ({dictionary[malfunction?.type]})</Title>
							<Container>
								<Content>
									<LabelContent>
										<Label>Nome do equipamento: </Label>
										<Description>{malfunction?.charger?.name}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Tipo: </Label>
										<Description>{dictionary[malfunction?.type]}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Descrição: </Label>
										<Description>{malfunction?.notes}</Description>
									</LabelContent>
									<LabelContent>
										<Label>Colaborador que registrou: </Label>
										<Description>{malfunction?.employee_start?.name}</Description>
									</LabelContent>
									{(malfunction?.employee_end && malfunction.employee_end != null) && (
										<LabelContent>
											<Label>Colaborador que finalizou: </Label>
											<Description>{malfunction?.employee_end?.name}</Description>
										</LabelContent>
									)}
									<LabelContent>
										<Label>Data do registro: </Label>
										<Description>{new Date(malfunction?.start_date).toLocaleDateString()}</Description>
									</LabelContent>
									{malfunction?.end_date && (
										<LabelContent>
											<Label>Data da finalização: </Label>
											<Description>{new Date(malfunction?.end_date).toLocaleDateString()}</Description>
										</LabelContent>
									)}
									<LabelContent>
										<Label>Observações: </Label>
										<Description>{malfunction?.obs}</Description>
									</LabelContent>
								</Content>
								{malfunction?.image !== null && (
									<LabelContent>
										<ImageLink href={`${mySrc}/public/malfunction/${malfunction?.image}`} target="_blank">
											<Image src={`${mySrc}/public/malfunction/${malfunction?.image}`} />
										</ImageLink>
									</LabelContent>
								)}
							</Container>
							<ContainerButtons>
								<ButtonModal onClick={closeModal}>Fechar</ButtonModal>
								{malfunction?.end_date == null && (<ButtonModal onClick={finishedMalfunction}>Finalizar Registro de Falha</ButtonModal>)}
							</ContainerButtons>
						</>
					</ViewModalDiv>
				</EditModalBackgroundDiv>
			)
		default:
			return null
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(AlertsModal)