import {
	BLUE_DARK,
	BLUE_DARK_2,
	PURPLE_DARK,
	PURPLE_LIGHT,
	PURPLE_MEDIUM,
} from '../../styles/colors'

import styled from 'styled-components'

export const MainDivOpen = styled.div`
	width: 100%;
	height: 100%;
	text-align: left;
	display: flex;
	flex-direction: column;
	background-color: ${PURPLE_DARK};
`
export const ButtonsList = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	transition: height 0.5s;

	&::-webkit-scrollbar {
		width: 7px; /* width of the entire scrollbar */
	}

	&::-webkit-scrollbar-track {
		background: rgb(253, 253, 253, 0); /* color of the tracking area */
	}

	&::-webkit-scrollbar-thumb {
		background-color: #656565;
		border-radius: 20px;
		border: 1px solid ${PURPLE_DARK};
	}
`
export const PageButton = styled.button`
	width: 100%;
	height: 35px;
	font-size: 13px;
	text-align: left;
	cursor: pointer;
	border: none;
	color: ${(props) => (props.currentPage ? PURPLE_LIGHT : '#999')};
	background-color: ${(props) =>
		props.currentPage ? BLUE_DARK_2 : PURPLE_DARK};
	outline: none;
	margin: 3px 0px;

	&:hover {
		color: ${PURPLE_LIGHT};
		background-color: ${BLUE_DARK_2};
	}
`
export const ListTitle = styled.span`
	font-size: 15px;
	text-align: left;
	margin-left: 15px;
	color: ${PURPLE_LIGHT};
	display: flex;
`

export const ListTitleIconEnd = styled.span`
	font-size: 15px;
	text-align: left;
	margin-right: 15px;
	color: ${PURPLE_LIGHT};
`

export const ListTitleIconStart = styled.div`
	width: ${(props) => (props.isCoolapsed ? '100%' : '20px')};
	font-size: 15px;
	text-align: ${(props) => (props.isCoolapsed ? 'center' : 'left')};
	margin-right: 5px;
	color: ${PURPLE_LIGHT};
`

export const PagesList = styled.div`
	display: ${(props) => (props.showList ? 'block' : 'none')};
`

export const UserCardDiv = styled.div`
	width: 100%;
	height: 100px;
	background-color: ${BLUE_DARK};
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	flex-direction: column;

	&:hover {
		opacity: 0.8;
	}
`
export const UserCardInfosDiv = styled.div`
	width: 180px;
	font-size: 12px;
	color: ${PURPLE_LIGHT};
`

export const ImgDiv = styled.div`
	margin: 3px;
	width: ${(props) => (props.isCollapsed ? '20px' : '30px')};
	height: ${(props) => (props.isCollapsed ? '20px' : '30px')};
	min-width: ${(props) => (props.isCollapsed ? '20px' : '30px')};
	min-height: ${(props) => (props.isCollapsed ? '20px' : '30px')};
	background-color: ${PURPLE_MEDIUM};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: ${(props) => (props.isCollapsed ? '15px' : '20px')};
	color: ${PURPLE_DARK};
`

export const UserCardArrowFunctionDiv = styled.div`
	height: 100%;
	margin: 3px;
	display: flex;
	justify-content: center;
	align-items: start;
	font-size: 20px;
	color: ${PURPLE_MEDIUM};
`

export const UserCardFunctionsListDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: absolute;
	bottom: 100px;
`

export const UserCardFunctionButtonDiv = styled.div`
	width: calc(90% - 4px);
	height: 40px;
	color: ${PURPLE_MEDIUM};
	text-align: right;
	line-height: 40px;
	cursor: pointer;
	font-size: 16px;
	border: 2px solid #273856;
	transform: translateY(-20px);
	/* position: absolute; */
	background-color: #fff;
	color: ${PURPLE_DARK};

	&:hover {
		opacity: 0.8;
	}
`
export const PageButtonCollapsed = styled.button`
	width: 100%;
	height: 35px;
	font-size: 13px;
	text-align: center;
	cursor: pointer;
	border: none;
	color: ${PURPLE_LIGHT};
	background-color: ${(props) =>
		props.currentPage ? BLUE_DARK_2 : PURPLE_DARK};
	outline: none;
	margin: 3px 0px;

	&:hover {
		color: ${PURPLE_LIGHT};
		background-color: ${BLUE_DARK_2};
	}
`
