import {
	HIDE_LOADING_BLUR,
	SHOW_LOADING_BLUR,
	USER_IS_LOGGED_IN,
	USER_IS_LOGGED_OUT,
	USER_RESET_STATE,
	USER_UPDATE_TOKEN,
} from '../actions/actionTypes'

///////////EmployeesCrud.js START
//Loading blur handle states functions
export const showLoadingBlur = () => ({
	type: SHOW_LOADING_BLUR,
})
export const hideLoadingBlur = () => ({
	type: HIDE_LOADING_BLUR,
})
///////////EmployeesCrud.js END

///////////User informations START
//User informations handle states functions
export const userIsLoggedIn = (value) => ({
	type: USER_IS_LOGGED_IN,
	infos: value,
})
export const userUpdateToken = (value) => ({
	type: USER_UPDATE_TOKEN,
	newToken: value,
})
export const userIsLoggedOut = () => ({
	type: USER_IS_LOGGED_OUT,
})
export const userResetState = () => ({
	type: USER_RESET_STATE,
})
///////////User informations END
