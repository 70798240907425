import {PURPLE, PURPLE_LIGHT} from 'src/styles/colors'
import styled from 'styled-components'

export const CreateContentDiv = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`

export const CreateFormDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
`

export const ButtonAdd = styled.button`
	padding: 5px;
	color: ${PURPLE};
	border: 1px solid ${PURPLE};
	background-color: ${PURPLE_LIGHT};
	margin-left: 40px;
	margin-top: 10px;
	cursor: pointer;
	:hover {
		background-color: ${PURPLE};
		color: ${PURPLE_LIGHT};
	}
`

export const Title = styled.h1`
	font-size: 20px;
	color: ${PURPLE};
	text-align: center;
`

export const Container = styled.div`
	height: 100%;
	flex-direction: column;
`

export const Row = styled.div`
	display: flex;
	flex-direction: row;
`

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
`

export const InputLabel = styled.label`
	padding-bottom: 5px;
	color: ${PURPLE};
	font-size: 20px;
`

export const InputValueToken = styled.input.attrs({
	type: 'number',
	placeholder: 'Valor do token',
})`
	border: 1px solid ${PURPLE};
	border-radius: 5px;
	color: ${PURPLE};
	width: 100px;
	padding: 4px;
	margin: 4px;
`

export const FormContent = styled.div`
	padding: 20px;
`

export const Select = styled.select`
	border: 1px solid ${PURPLE};
	border-radius: 5px;
	color: ${PURPLE};
	width: 100px;
	padding: 4px;
	margin: 4px;
`
export const InputLabelError = styled.label`
	padding-bottom: 5px;
	color: red;
	font-size: 14px;
	min-height: 15px;
`
