import {createContext, useContext, useState} from 'react'

import {getItem} from 'src/utils/localStorage'

const SessionContext = createContext()

const SessionProvider = ({children}) => {
	const [isLogged, setLogged] = useState(false)
	const login = () => setLogged(true)
	const logout = () => setLogged(false)

	const userInfo = getItem('session')
	const userToken = getItem('token')
	const selectedClient = getItem('selected_client')

	const clientId =
		selectedClient && selectedClient.selectedClient
			? selectedClient.selectedClient
			: userInfo
			? userInfo.client_id
			: 0

	if (isLogged === false && userInfo && userToken) login()

	return (
		<SessionContext.Provider
			value={{
				isLogged,
				login,
				logout,
				clientId,
			}}>
			{children}
		</SessionContext.Provider>
	)
}

const useSession = () => {
	const context = useContext(SessionContext)

	if (!context) {
		throw new Error('useSession must be used within an SessionProvider.')
	}

	return context
}

export {SessionProvider, useSession}
