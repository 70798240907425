import React from 'react'

const ModalContext = React.createContext()

const ModalProvider = ({children}) => {
	const [modal, setModal] = React.useState({
		show: false,
		title: '',
		message: '',
		icon: null,
		component: null,
	})

	const showModal = React.useCallback(
		({show, title, message, icon, component}) => {
			setModal({
				show,
				title,
				message,
				icon,
				component,
			})
		},
		[],
	)

	const hideModal = React.useCallback(() => {
		setModal({
			show: false,
			title: '',
			message: '',
			icon: null,
		})
	}, [])

	return (
		<ModalContext.Provider
			value={{
				showModal,
				modal,
				hideModal,
			}}>
			{children}
		</ModalContext.Provider>
	)
}

const useInfoModal = () => {
	const context = React.useContext(ModalContext)

	if (!context) {
		throw new Error('useInfoModal must be used within an SessionProvider.')
	}

	return context
}

export {ModalProvider, useInfoModal}
