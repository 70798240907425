import {Colors} from 'src/styles'
import {PURPLE} from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: flex-start;
	display: flex;
	flex-direction: column;
`

export const Title = styled.h1`
	margin: 35px 0;
`

export const FormContent = styled.div`
	padding: 20px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
`

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	margin: 10px;
	border-radius: 10px;
	box-shadow: 10px 10px 30px #bebebe, -10px -10px 20px #ffffff;
`
export const InputLabel = styled.label`
	padding-bottom: 5px;
	color: ${PURPLE};
	font-size: 16px;
`
export const InputLabelError = styled.label`
	padding-bottom: 5px;
	color: red;
	font-size: 14px;
	min-height: 15px;
`
export const InputValueToken = styled.input.attrs({
	type: 'number',
	placeholder: 'Valor do token',
})`
	border: 1px solid ${PURPLE};
	border-radius: 5px;
	color: ${PURPLE};
	width: 100px;
	padding: 4px;
	margin: 4px;
`
export const Row = styled.div`
	display: flex;
	flex-direction: row;
`
export const Select = styled.select`
	border: 1px solid ${PURPLE};
	border-radius: 5px;
	color: ${PURPLE};
	width: 100px;
	padding: 4px;
	margin: 4px;
`

export const IsActive = styled.div`
	padding: 5px;
	border: none;
	cursor: pointer;
	background-color: ${({active}) =>
		active ? Colors.GREEN : Colors.GRAY_MEDIUM};
	color: ${Colors.WHITE};
`
export const IsDisabled = styled.div`
	padding: 5px;
	border: none;
	cursor: pointer;
	background-color: ${({active}) => (active ? Colors.RED : Colors.GRAY_MEDIUM)};
	color: ${Colors.WHITE};
`

export const Label = styled.label`
	width: 300px;
`

export const ButtonChecklist = styled.button`
	border: none;
	border-radius: 2px;
	background-color: ${({active}) => (active ? Colors.PURPLE : Colors.BLACK)};
	padding: 8px;
	color: ${Colors.WHITE};
	margin: 10px;
	cursor: pointer;
	:hover {
		opacity: 0.4;
	}
`
