import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'
import {useCallback, useEffect, useState} from 'react'

import CreateForkliftModel from './ForkliftModelCreate.jsx'
import {CrudModal} from '../../components/CrudModal/index.jsx'
import EditForkliftModel from './ForkliftModelEdit.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MainContentDiv} from '../../styles/globalStyles'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'

const translatedForkliftsModels = (forkliftModels) => {
	const newModelsList = []

	for (let model of forkliftModels) {
		newModelsList.push({
			...model,
			forklift_brand:
				model.forklift_brand && model.forklift_brand.name
					? model.forklift_brand.name
					: '',
			brand_id:
				model.forklift_brand && model.forklift_brand.id
					? model.forklift_brand.id
					: '',
		})
	}

	return newModelsList
}

//CRUD list configuration
const columns = [
	{accessor: 'name', Header: dictionary.Name},
	{
		accessor: 'voltage',
		Header: dictionary.Voltage,
		valueFormatter: (value) => `${value}V`,
		getStyle: () => ({width: '75px'}),
	},
	{
		accessor: 'forklift_brand',
		Header: dictionary.Brand,
	},
	{
		accessor: 'description',
		Header: dictionary.Description,
	},
]

/**
 * @author Carlos Padovezi
 * @function ForkliftModelsCrud
 * @file ForkliftModelsCrud.js
 * @summary Function to create battery Models crud view
 * @returns Battery Models crud page
 */
function ForkliftModelsCrud({hideLoadingBlur, showLoadingBlur}) {
	const [crudSelected, changeCrudSelected] = useState('list')
	const [crudList, setCrudList] = useState([])
	const [editingFokliftModel, setEditingFokliftModel] = useState(null)

	const loadPageData = useCallback(() => {
		showLoadingBlur()

		createFetchRequest('forkliftModel/getAll', 'get', null, ({err, result}) => {
			if (err) setCrudList([])
			else if (!err && result) {
				const myResult = translatedForkliftsModels(result)
				setCrudList(myResult)
			}

			changeCrudSelected('list')
			setEditingFokliftModel(null)
			hideLoadingBlur()
		})
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const handleOpenEditForkliftModel = (params) => {
		const rowValue = params

		const startValues = {
			name: rowValue.name,
			description: rowValue.description,
			voltage: rowValue.voltage,
			brand_id: rowValue.brand_id,
			id: rowValue.id,
		}

		setEditingFokliftModel(startValues)
	}

	const handleCancelEdit = () => setEditingFokliftModel(null)

	const handleDeleteButtonClick = (params) => {
		const isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			const context = {id: params.id}

			createFetchRequest(
				'forkliftModel/delete/' + params.id,
				'delete',
				context,
				({err, result}) => {
					if (err) {
						console.log(err)
					}

					//Reset inputs values
					loadPageData()
				},
			)
		}
	}

	const crudsObjPages = {
		list: {
			title: dictionary.ModelsList,
			list: {
				columns,
				rows: crudList,
			},
			onRowEditClick: handleOpenEditForkliftModel,
			onRowEditLabel: 'Editar',
			onRowEditIcon: <FontAwesomeIcon icon={faEdit} />,
			onRowDeleteClick: handleDeleteButtonClick,
			onRowDeleteLabel: 'Excluir',
			onRowDeleteIcon: <FontAwesomeIcon icon={faTrash} />
		},
		create: {
			title: dictionary.ModelRegister,
			content: <CreateForkliftModel updatePage={loadPageData} />,
		},
	}

	const editModal = (
		<EditForkliftModel
			handleCancelEdit={handleCancelEdit}
			updatePage={loadPageData}
			editData={editingFokliftModel}
		/>
	)

	return (
		<MainContentDiv>
			<CrudModal
				crudsObj={crudsObjPages}
				crudSelected={crudSelected}
				changeCrudSelected={(newCrud) => changeCrudSelected(newCrud)}
			/>
			{editingFokliftModel === null ? null : editModal}
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(ForkliftModelsCrud)
