import {Button} from 'src/styles/globalStyles'
import styled from 'styled-components'

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 80%;
	align-items: center;
`

export const ConfirmationButton = styled(Button)`
	background-color: #40987b;
	border: 1px solid #fff;
	&:hover {
		background-color: #fff;
		border: 1px solid #40987b;
		color: #40987b;
	}
`
export const CancelButton = styled(Button)`
	background-color: #e63b2e;
	border: 1px solid #fff;
	&:hover {
		background-color: #fff;
		border: 1px solid #e63b2e;
		color: #e63b2e;
	}
`
