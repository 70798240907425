import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import {hideLoadingBlur, showLoadingBlur} from '../../actions/index'
import {useCallback, useEffect, useState} from 'react'

import CreateBatteryRoom from './BatteryRoomCreate.jsx'
import {CrudModal} from '../../components/CrudModal/index.jsx'
import EditBatteryRoom from './BatteryRoomEdit.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {MainContentDiv} from '../../styles/globalStyles'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

const translatedBatteryRoomList = (batteryRoomList) => {
	let newBatteryRoomList = []

	for (let batteryRoom of batteryRoomList) {
		newBatteryRoomList.push({
			...batteryRoom,
			branch:
				batteryRoom.branch && batteryRoom.branch.name
					? batteryRoom.branch.name
					: '',
			branch_id:
				batteryRoom.branch && batteryRoom.branch.id
					? batteryRoom.branch.id
					: '',
			client:
				batteryRoom.branch &&
				batteryRoom.branch.client &&
				batteryRoom.branch.client.name
					? batteryRoom.branch.client.name
					: '',
			client_id:
				batteryRoom.branch &&
				batteryRoom.branch.client &&
				batteryRoom.branch.client.id
					? batteryRoom.branch.client.id
					: '',
		})
	}

	return newBatteryRoomList
}

/**
 * @author Carlos Padovezi
 * @function BatteryRoomsCrud
 * @file BatteryRoomsCrud.js
 * @summary Function to create battery rooms crud view
 * @returns Battery rooms crud page
 */
function BatteryRoomsCrud({hideLoadingBlur, showLoadingBlur}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [crudSelected, changeCrudSelected] = useState('list')
	const [crudList, setCrudList] = useState([])
	const [editingbatteryRoom, setEditingbatteryRoom] = useState(null)

	const loadPageData = useCallback(() => {
		showLoadingBlur()

		createFetchRequest(
			'batteryRoom/getAll',
			'get',
			null,
			async ({err, result}) => {
				if (err) setCrudList([])
				else if (!err && result) {
					const myResult = translatedBatteryRoomList(result)
					await setCrudList(myResult)
				}

				await setEditingbatteryRoom(null)
				await changeCrudSelected('list')
				await hideLoadingBlur()
			},
		)
	}, [hideLoadingBlur, showLoadingBlur])

	useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const handleOpenEditBratteryRoom = (params) => {
		let rowValue = params

		let startValues = {
			name: rowValue.name,
			branch_id: rowValue.branch_id,
			client_id: rowValue.client_id,
			id: rowValue.id,
		}

		setEditingbatteryRoom(startValues)
	}

	const handleCancelEdit = () => {
		setEditingbatteryRoom(null)
	}

	const handleDeleteBatteryRoom = async (params) => {
		let isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

		if (isConfirmed) {
			await showLoadingBlur()

			var context = {
				id: params.id,
			}

			await createFetchRequest(
				'batteryRoom/delete/' + params.id,
				'delete',
				context,
				async ({err, result}) => {
					if (err) {
						console.log(err)
					}

					await loadPageData()
				},
			)
		} else {
			await hideLoadingBlur()
		}
	}

	//CRUD list configuration
	const columns = [
		{accessor: 'name', Header: dictionary.Name},
		{
			accessor: 'client',
			Header: dictionary.Customer,
			showColumn: userInfos.profile_id === 1,
		},
		{
			accessor: 'branch',
			Header: dictionary.Branch,
		},
	]

	let crudsObjPages = {
		list: {
			title: dictionary.BatteryRoomsList,
			list: {
				columns,
				rows: crudList,
			},
			onRowEditClick: handleOpenEditBratteryRoom,
			onRowEditLabel: 'Editar',
			onRowEditIcon: <FontAwesomeIcon icon={faEdit} />,
			onRowDeleteClick: handleDeleteBatteryRoom,
			onRowDeleteLabel: 'Excluir',
			onRowDeleteIcon: <FontAwesomeIcon icon={faTrash} />
		},
		create: {
			title: dictionary.BatteryRoomsRegister,
			content: <CreateBatteryRoom updatePage={loadPageData} />,
		},
	}

	const editModal = (
		<EditBatteryRoom
			editData={editingbatteryRoom}
			handleCancelEdit={handleCancelEdit}
			updatePage={loadPageData}
		/>
	)

	return (
		<MainContentDiv>
			<CrudModal
				crudsObj={crudsObjPages}
				crudSelected={crudSelected}
				changeCrudSelected={(newCrud) => changeCrudSelected(newCrud)}
			/>
			{editingbatteryRoom === null ? null : editModal}
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(BatteryRoomsCrud)
