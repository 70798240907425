import {isUndefinedOrNull} from 'src/genericFunctions'

export const setItem = (key, value) => {
	const jsonValue = JSON.stringify(value)
	localStorage.setItem(key, jsonValue)
}

export const getItem = (key) => {
	const jsonValue = localStorage.getItem(key)

	if (!isUndefinedOrNull(jsonValue)) {
		return JSON.parse(jsonValue)
	}

	return null
}

export const clear = (key) => {
	localStorage.removeItem(key)
}
