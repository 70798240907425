import { Container, DateContainer, DateRow, Title } from "./styles"
import { Input } from "@material-ui/core"
import dayjs from "dayjs"

const DashboardHeader = ({ onChangeDate, date }) => {
	return (
		<Container>
			<DateContainer>
				<Title>DASHBOARD</Title>
			</DateContainer>
			<DateRow>
				<DateContainer>
					{/* <DateTitle>Data de exibição</DateTitle> */}

					<DateRow>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', fontWeight: 'bold' }}>
							<label>Data inicial</label>
							<Input type="date" value={date.startDate} onChange={(e) => onChangeDate('startDate', dayjs(e.target.value).format('YYYY-MM-DD'))} />
						</div>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', fontWeight: 'bold' }}>
							<label>Data final</label>
							<Input type="date" value={date.finalDate} onChange={(e) => onChangeDate('finalDate', dayjs(e.target.value).format('YYYY-MM-DD'))} />
						</div>
					</DateRow>
				</DateContainer>
			</DateRow>
		</Container>
	)
}

export default DashboardHeader