import { Container, ContentInfo, ModalContainer, TitleHeader } from './styles'
import { CreateContentDiv, CreateFormDiv } from '../../styles/globalStyles'
import React, { useEffect, useState } from 'react'

import { Button } from '../../styles/globalStyles'
import { RowCalendar } from '../../components/RowCalendar/index.jsx'
import { RowInput } from '../../components/RowInput/index.jsx'
import { RowSelect } from '../../components/RowSelect/index.jsx'
import { RowSelectBranch } from 'src/components/RowSelectBranch'
import { createFetchRequest } from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'
import moment from 'moment'

/**
 * @author Carlos Padovezi
 * @function CreateBattery
 * @file BatteriesCreate.js
 * @summary Function to create Battery rooms register view
 * @returns Battery rooms create create page
 */
export default function CreateBattery({ updatePage, onChangeView, view, name, registerTitle, preview }) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [formData, setFormData] = useState({
		name: '',
		serial: '',
		manufacturing_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
		estimated_cycles: '',
		forklift_model_id: '',
		model_id: '',
		client_id:
			userInfos && userInfos.profile_id === 1 ? '' : userInfos.client_id,
		branch_id: '',
	})
	const [clientsList, setClientList] = useState([])
	const [modelsList, setModelsList] = useState([])
	const [modelsListOriginal, setModelListOriginal] = useState([])
	const [forkligtModelsList, setForkliftModelsList] = useState([])

	useEffect(() => {
		if (preview) {
			setFormData((prev) => ({
				...prev,
				manufacturing_date: moment(new Date(preview.manufacturing_date)).format('YYYY-MM-DD HH:mm:ss'),
				model_id: preview.model_id,
				client_id: preview.client_id,
				branch_id: preview.branch_id,
				forklift_model_id: preview.forklift_model_id,
				estimated_cycles: preview.estimated_cycles,
			}))
		}
	}, [preview])

	useEffect(() => {
		//get branch list
		createFetchRequest('client/getAll', 'get', null, async ({ err, result }) => {
			if (err) {
				console.log(err)
				return
			}
			let myList = []
			console.log(result)
			if (result && result.length > 0) {
				for (let client of result) {
					myList.push({ value: client.id, label: client.name })
				}
			}

			setClientList(myList)

			//get battery model list
			createFetchRequest(
				'batteryModel/getAll',
				'get',
				null,
				async ({ err, result }) => {
					if (err) {
						console.log(err)
						return
					}
					setModelListOriginal(result)

					let myList = []
					if (result && result.length > 0) {
						for (let model of result) {
							myList.push({ value: model.id, label: model.name })
						}
					}

					setModelsList(myList)

					//get forklifts models list
					createFetchRequest(
						'forkliftModel/getAll',
						'get',
						null,
						async ({ err, result }) => {
							if (err) {
								console.log(err)
								return
							}
							let myList = []
							if (result && result.length > 0) {
								for (let forklift of result) {
									myList.push({ value: forklift.id, label: forklift.name })
								}
							}

							setForkliftModelsList(myList)
						},
					)
				},
			)
		})
	}, [])

	function changeBatteryEstimatedCycles(value) {
		var model = modelsListOriginal.find((e) => e.id === parseInt(value))
		setFormData((state) => ({
			...state,
			model_id: value,
			estimated_cycles: model.estimated_manufactorer_cycles,
		}))
	}

	async function handleSaveNewBattery() {
		const context = {
			name: formData.name,
			serial: formData.serial,
			manufacturing_date: formData.manufacturing_date,
			estimated_cycles: formData.estimated_cycles,
			forklift_model_id: formData.forklift_model_id,
			model_id: formData.model_id,
			branch_id: formData.branch_id,
		}

		createFetchRequest(
			'battery/create',
			'post',
			context,
			async ({ err, result }) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					await updatePage()
					onChangeView('table')
				}
			},
		)
	}

	let validButton =
		formData.name &&
		formData.serial &&
		formData.manufacturing_date &&
		formData.forklift_model_id &&
		formData.model_id &&
		formData.branch_id

	return (
		<CreateContentDiv>
			<ModalContainer>
				{(onChangeView && typeof (onChangeView) === 'function') && (
					<Container>
						<ContentInfo>
							<div style={{ display: 'flex' }}>
								{(onChangeView && typeof (onChangeView) === 'function') && (
									<>
										<TitleHeader isSelected={view && view === 'table'} onClick={() => onChangeView('table')}>{name}</TitleHeader>
										<TitleHeader isSelected={view && view === 'register'} onClick={() => onChangeView('register')}>{registerTitle}</TitleHeader>
									</>
								)}

							</div>
						</ContentInfo>
					</Container>
				)}
				{clientsList.length > 0 && modelsList.length > 0 && forkligtModelsList.length > 0 && (
					<CreateFormDiv>
						<RowInput
							value={formData.name}
							label={dictionary.Name}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(name) => setFormData((state) => ({ ...state, name }))}
							autoFocus={true}
						/>
						<RowInput
							value={formData.serial}
							label={dictionary.Serial}
							maxLength={15}
							minLength={10}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(serial) => setFormData((state) => ({ ...state, serial }))}
						/>
						<RowCalendar
							value={formData.manufacturing_date}
							label={dictionary.ManufacturingDate}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(manufacturing_date) =>
								setFormData((state) => ({ ...state, manufacturing_date }))
							}
						/>
						<RowSelect
							value={formData.model_id}
							optionsList={modelsList}
							label={dictionary.Model}
							isObligatory={true}
							fontSize={'16px'}
							onChange={changeBatteryEstimatedCycles}
						/>
						<RowInput
							value={formData.estimated_cycles}
							label={dictionary.EstimatedCycles}
							readOnly={true}
							type={'number'}
							fontSize={'16px'}
							onChange={(estimated_cycles) =>
								setFormData((state) => ({ ...state, estimated_cycles }))
							}
						/>
						<RowSelect
							value={formData.forklift_model_id}
							optionsList={forkligtModelsList}
							label={dictionary.ForkliftModel}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(forklift_model_id) =>
								setFormData((state) => ({ ...state, forklift_model_id }))
							}
						/>
						{userInfos && userInfos.profile_id === 1 ? (
							<RowSelect
								value={formData.client_id}
								label={dictionary.Customer}
								optionsList={clientsList}
								isObligatory={true}
								fontSize={'16px'}
								onChange={(client_id) =>
									setFormData((state) => ({ ...state, client_id }))
								}
							/>
						) : null}
						<RowSelectBranch
							clientId={formData.client_id}
							value={formData.branch_id}
							isObligatory={true}
							fontSize={'16px'}
							onChange={(branch_id) =>
								setFormData((state) => ({ ...state, branch_id }))
							}
						/>
						<div>
							<Button
								name={'buttonConfirm'}
								disabled={!validButton}
								onClick={handleSaveNewBattery}
								style={{ marginTop: '10px', marginRight: '3px', width: '100px' }}>
								<b>{dictionary.Save}</b>
							</Button>
							<Button
								name={'buttonCancel'}
								disabled={false}
								onClick={updatePage}
								style={{ marginTop: '10px', marginLeft: '3px', width: '100px' }}>
								<b> {dictionary.Cancel}</b>
							</Button>
						</div>
					</CreateFormDiv>
				)}
			</ModalContainer>
		</CreateContentDiv>
	)
}
