const { DateInput } = require("./styles")

const DateFilter = ({ onChange, value }) => {
	console.log(onChange)
	const onChangeDebounce = (e) => {
		console.log(e.target.value)
		onChange(e)
	}


	return (
		<DateInput onChange={onChangeDebounce} value={value} />
	)
}

export default DateFilter