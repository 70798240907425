const initialState = {
	show: false,
}

export const loadingBlurStatus = (state = initialState, action) => {
	switch (action.type) {
		case 'SHOW_LOADING_BLUR':
			return {
				...state,
				show: true,
			}
		case 'HIDE_LOADING_BLUR':
			return {
				...state,
				show: false,
			}
		default:
			return state
	}
}
