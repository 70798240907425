import {Colors} from 'src/styles'
import styled from 'styled-components'

export const Row = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
	color: ${({color}) => color ?? '#000000'};
	font-size: 14px;
	font-weight: 600;
`

export const Content = styled.div`
	width: 100%;
	padding: 10px;
	background: ${Colors.WHITE};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

export const Header = styled.div`
	background-color: ${Colors.PURPLE};
	text-align: center;
	padding: 10px;
	color: ${Colors.WHITE};
	width: 100%;
	font-size: 20px;
	font-weight: bold;
	border-radius: 5px;
`

export const Items = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

export const NameEquipment = styled.h2``
