import {PURPLE, PURPLE_LIGHT, WHITE} from './colors'

import styled from 'styled-components'

export const TextLink = styled.a`
	font-weight: 500;
	color: #000;
	cursor: pointer;

	&:hover {
		font-size: 14px;
		color: ${PURPLE};
	}
`

export const Button = styled.button`
	width: ${(props) => (props.width ? props.width : '150px')};
	height: ${(props) => (props.height ? props.height : '60px')};
	border-radius: 20px;

	border: ${(props) =>
		props.disabled ? '1px solid ' + PURPLE_LIGHT : '1px solid ' + PURPLE};
	outline: ${(props) => (props.disabled ? PURPLE_LIGHT : PURPLE)};
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	background-color: ${(props) => (props.disabled ? PURPLE_LIGHT : PURPLE)};
	color: #fff;

	&:hover {
		background-color: ${(props) => (props.disabled ? PURPLE_LIGHT : '#FFF')};
		color: ${(props) => (props.disabled ? '#FFF' : PURPLE)};
	}
`

export const CreateContentTitleDiv = styled.div`
	padding: 0px 10px;
`

export const CreateContentDiv = styled.div`
	width: 100%;
	height: 100%;
`

export const CreateFormDiv = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 100%;
	align-items: center;
	justify-content: center;
	background-color: ${WHITE};
`

export const EditModalBackgroundDiv = styled.div`
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #000000b3;
	position: absolute;
	z-index: 300;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const EditModalDiv = styled.div`
	max-height: 80%;
	width: inherit;
	padding: 30px;
	background-color: #fff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	min-height: 80%;
`

export const ViewModalDiv = styled.div`
	max-height: 80%;
	width: 70%;
	padding: 30px;
	background-color: #fff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`

export const MainContentDiv = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	position: relative;
`

export const PageParentDiv = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
`
export const FlexCenter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

export const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
`
export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`

export const FlexContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`
