import {CreateContentDiv, CreateFormDiv} from '../../styles/globalStyles'
import React, {useEffect, useState} from 'react'

import {Button} from '../../styles/globalStyles'
import {RowInput} from '../../components/RowInput/index.jsx'
import {RowSelect} from '../../components/RowSelect/index.jsx'
import {RowSelectBranch} from 'src/components/RowSelectBranch'
import {createFetchRequest} from '../../genericFunctions'
import dictionary from 'src/utils/dictionary'
import {getItem} from 'src/utils/localStorage'

/**
 * @author Carlos Padovezi
 * @function CreateBatteryRoom
 * @file CreateBatteryRoom.js
 * @summary Function to create Battery rooms register view
 * @returns Battery rooms create crud page
 */
export default function CreateBatteryRoom({updatePage}) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()

	const [clientsList, setClientsList] = useState([])
	const [formData, setFormData] = useState({
		client_id:
			userInfos && userInfos.profile_id === 1 ? '' : userInfos.client_id,
		name: '',
		branch_id: '',
	})

	useEffect(() => {
		//get client list
		createFetchRequest('client/getAll', 'get', null, async ({err, result}) => {
			if (err) {
				console.log(err)
				return
			}

			let myList = []
			if (result && result.length > 0) {
				for (let client of result) {
					myList.push({value: client.id, label: client.name})
				}
			}

			setClientsList(myList)
		})
	}, [])

	async function handleSaveNewBatteryRoom() {
		const context = {
			name: formData.name,
			branch_id: formData.branch_id,
		}

		createFetchRequest(
			'batteryRoom/create',
			'post',
			context,
			async ({err, result}) => {
				if (err) {
					console.log(err)
				} else {
					//Reset inputs values
					await updatePage()
				}
			},
		)
	}

	let validButton = formData.name && formData.branch_id

	return (
		<CreateContentDiv>
			<CreateFormDiv>
				<RowInput
					value={formData.name}
					label={dictionary.Name}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(name) => setFormData((state) => ({...state, name}))}
					autoFocus={true}
				/>
				{userInfos && userInfos.profile_id === 1 ? (
					<RowSelect
						value={formData.client_id}
						label={dictionary.Customer}
						optionsList={clientsList}
						isObligatory={true}
						fontSize={'16px'}
						onChange={(client_id) =>
							setFormData((state) => ({...state, client_id}))
						}
					/>
				) : null}
				<RowSelectBranch
					clientId={formData.client_id}
					value={formData.branch_id}
					isObligatory={true}
					fontSize={'16px'}
					onChange={(branch_id) =>
						setFormData((state) => ({...state, branch_id}))
					}
				/>
				<div>
					<Button
						name={'buttonConfirm'}
						disabled={!validButton}
						onClick={handleSaveNewBatteryRoom}
						style={{marginTop: '10px', marginRight: '3px', width: '100px'}}>
						<b>{dictionary.Save}</b>
					</Button>
					<Button
						name={'buttonCancel'}
						disabled={false}
						onClick={updatePage}
						style={{marginTop: '10px', marginLeft: '3px', width: '100px'}}>
						<b> {dictionary.Cancel}</b>
					</Button>
				</div>
			</CreateFormDiv>
		</CreateContentDiv>
	)
}
